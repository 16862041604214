import React from 'react';
import { Box, Button, Container, Typography, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatDateWithSystem } from 'utils';

import Lottie from 'components/Lottie';
import BlocPoliceInfo from '../BlocPoliceInfo';
import {
  SINISTRE_NATURE_CODE_AUTRE,
  SINISTRE_NATURE_CODE_BRIS_DE_GLACE,
  SINISTRE_NATURE_CODE_DEGAT_DES_EAUX,
  SINISTRE_NATURE_CODE_INCENDIE,
  SINISTRE_NATURE_CODE_VANDALISME,
  SINISTRE_NATURE_CODE_VOL,
} from 'constants/common/sinistreNatureCode';
import BrokenGlass from 'assets/images/bris.svg';
import Water from 'assets/images/broken-pipe.png';
import Other from 'assets/images/catastrophes.svg';
import Fire from 'assets/images/feu.svg';
import Vol from 'assets/images/vol.svg';
import CalendarLottie from 'assets/lotties/calendar.json';

ConfirmationDecla.propTypes = {
  claim: PropTypes.object.isRequired,
  police: PropTypes.object.isRequired,
};

function ConfirmationDecla({ claim, police }) {
  const { t } = useTranslation();

  const sinistre = sinistreData.find(
    (item) => item.reason === claim.nature.code
  );
  const assetSinistre = sinistre ? sinistre.asset : Other;
  const labelSinistre = sinistre
    ? t(sinistre.label)
    : t('dashboard.sinistre.form.reason.other');

  return (
    <div>
      <Container maxWidth="sm">
        <Box width={1} align="center">
          <Lottie autoPlay animationData={CalendarLottie} width={126} />
          <Typography variant="h4">
            {t('dashboard.sinistre.form.confirmation.msg_succes')}
          </Typography>
          <Typography variant="body1">
            {t('dashboard.sinistre.form.confirmation.msg_wait')}
          </Typography>
          <Box boxShadow="0px 4px 80px rgba(0, 43, 104, 0.1)" p={1} mt={3}>
            <Grid container>
              <Grid item xs={6}>
                <img src={assetSinistre} alt={labelSinistre} height="90%" />
              </Grid>
              <Grid item xs={6}>
                <Box align="left">
                  <Typography variant="h6">{labelSinistre}</Typography>
                  <Typography variant="body2" color="textPrimary">
                    N°{claim.claimsNumber}
                  </Typography>
                  <Typography variant="body2" color="textPrimary">
                    {t('dashboard.sinistre.form.confirmation.survenue')}{' '}
                    {formatDateWithSystem(claim.eventDate)}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <br />
            <br />
            <BlocPoliceInfo police={police} showPoliceDetailsButton={true} />
          </Box>

          <Box mt={7}>
            <Button variant="contained" color="primary" component={Link} to="/">
              {t('dashboard.sinistre.form.confirmation.back')}
            </Button>
          </Box>
        </Box>
      </Container>
    </div>
  );
}

const sinistreData = [
  {
    reason: SINISTRE_NATURE_CODE_INCENDIE,
    asset: Fire,
    label: 'dashboard.sinistre.form.reason.incendie',
  },
  {
    reason: SINISTRE_NATURE_CODE_DEGAT_DES_EAUX,
    asset: Water,
    label: 'dashboard.sinistre.form.reason.dde',
  },
  {
    reason: SINISTRE_NATURE_CODE_VANDALISME,
    asset: Vol,
    label: 'dashboard.sinistre.form.reason.vandalisme',
  },
  {
    reason: SINISTRE_NATURE_CODE_VOL,
    asset: Vol,
    label: 'dashboard.sinistre.form.reason.vol',
  },
  {
    reason: SINISTRE_NATURE_CODE_BRIS_DE_GLACE,
    asset: BrokenGlass,
    label: 'dashboard.sinistre.form.reason.bdg',
  },
  {
    reason: SINISTRE_NATURE_CODE_AUTRE,
    asset: Other,
    label: 'dashboard.sinistre.form.reason.other',
  },
];

export default ConfirmationDecla;
