import AddressStep from 'views/PnoSouscription/Steps/AddressStep';
import PropertyTypeStep from 'views/PnoSouscription/Steps/PropertyTypeStep';
import ActivityStep from 'views/PnoSouscription/Steps/ActivityStep';
// import EquipmentsStep from 'views/Pno/Steps/EquipmentsStep';
import PropertyInfoStep from 'views/PnoSouscription/Steps/PropertyInfoStep/index';
import FurnishedStep from 'views/PnoSouscription/Steps/FurnishedStep';
// import HistoricalStep from 'views/Pno/Steps/HistoricalStep';
import { UserInfoStep } from 'views/PnoSouscription/Steps/UserInfoStep';
// import UserInfoStep from 'views/Subscription/Steps/UserInfoStep';
import QuoteStep from 'views/PnoSouscription/Steps/QuoteStep';
import MoreInformationsStep from 'views/PnoSouscription/Steps/MoreInformationsStep';
import ExitStep from 'views/PnoSouscription/Steps/ExitStep';
import CurrentInsuranceStep from 'views/PnoSouscription/Steps/CurrentInsuranceStep';
// import CurrentInsuranceStep from 'views/Subscription/Steps/CurrentInsuranceStep';
import InsuranceSeniorityStep from 'views/PnoSouscription/Steps/InsuranceSeniorityStep';
import InsuranceStartStep from 'views/PnoSouscription/Steps/InsuranceStartStep';
// import InsuranceStartStep from 'views/Subscription/Steps/InsuranceStartStep';
import CompleteUserInfoStep from 'views/PnoSouscription/Steps/CompleteUserInfoStep';
import PersonalAddressStep from 'views/PnoSouscription/Steps/PersonalAddressStep';
import ResultStep from 'views/PnoSouscription/Steps/ResultStep';
import PaymentStep from 'views/PnoSouscription/Steps/PaymentStep/paymentForSubscription';
// import PaymentPreloader from 'views/Pno/Steps/PaymentPreloader';
import PaymentPreloader from 'views/MrhSubscription/Steps/PaymentPreloader';
import IdentityStep from 'views/MrhSubscription/Steps/IdentityStep';
import EndStep from 'views/PnoSouscription/Steps/EndStep';
import ErrorStep from 'views/MrhSubscription/Steps/ErrorStep';

import {
  PNO_TYPE_MAISON,
  PNO_TYPE_APPARTEMENT,
  PNO_TYPE_GARAGE,
  PNO_TYPE_LOCAL_COMMERCIAL,
} from 'constants/index';

const stepsIds = {
  ADDRESS: 'address',
  RESIDENCE_TYPE: 'residenceType',
  ACTIVITY_STEP: 'activity',
  PROPERTY_TYPE: 'propertyType',
  FURNISHED_STEP: 'furnished',
  HISTORICAL_STEP: 'historical',
  ASSURED_QUALITY: 'assuredQuality',
  PROPERTY_INFO: 'propertyInfo',
  EQUIPMENTS: 'equipments',
  USER_INFO: 'userInfo',
  QUOTE: 'quote',
  MORE_INFORMATIONS: 'moreInformations',
  EXIT: 'exit',
  CURRENT_WARRANTY: 'currentWarranty',
  INSURANCE_SENIORITY: 'insuranceSeniority',
  INSURANCE_START: 'insuranceStart',
  CO_INSURED: 'coInsured',
  CHILDREN: 'children',
  COMPLETE_USER_INFO: 'completeUserInfo',
  PERSONAL_ADDRESS: 'personalAddress',
  RESULT: 'result',
  PAYMENT_LOADER: 'paymentLoader',
  PAYMENT: 'payment',
  IDENTITY: 'identity',
  END: 'end',
  ERROR: 'error',
};

const getPropertyTypeNextStep = (housingType) => {
  switch (housingType) {
    case PNO_TYPE_APPARTEMENT:
    case PNO_TYPE_MAISON:
      return stepsIds.PROPERTY_INFO;
    case PNO_TYPE_GARAGE:
      return stepsIds.PROPERTY_INFO;
    case PNO_TYPE_LOCAL_COMMERCIAL:
      return stepsIds.ACTIVITY_STEP;
  }
};

const getPropertyInfoPreviousStep = (housingType) => {
  switch (housingType) {
    case PNO_TYPE_APPARTEMENT:
    case PNO_TYPE_MAISON:
      return stepsIds.PROPERTY_TYPE;
    case PNO_TYPE_GARAGE:
      return stepsIds.PROPERTY_TYPE;
    case PNO_TYPE_LOCAL_COMMERCIAL:
      return stepsIds.ACTIVITY_STEP;
  }
};

const getPropertyInfoNextStep = (housingType) => {
  switch (housingType) {
    case PNO_TYPE_APPARTEMENT:
    case PNO_TYPE_MAISON:
      return stepsIds.FURNISHED_STEP;
    case PNO_TYPE_GARAGE:
      return stepsIds.USER_INFO;
    case PNO_TYPE_LOCAL_COMMERCIAL:
      return stepsIds.USER_INFO;
  }
};

const getUserInfoPreviousStep = (housingType) => {
  switch (housingType) {
    case PNO_TYPE_APPARTEMENT:
    case PNO_TYPE_MAISON:
      return stepsIds.FURNISHED_STEP;
    case PNO_TYPE_GARAGE:
      return stepsIds.PROPERTY_INFO;
    case PNO_TYPE_LOCAL_COMMERCIAL:
      return stepsIds.PROPERTY_INFO;
  }
};

const getSteps = (data) => {
  return [
    [
      {
        id: stepsIds.ADDRESS,
        component: AddressStep,
        category: 'common.stepsCategories.myGood',
      },
      {
        id: stepsIds.PROPERTY_TYPE,
        component: PropertyTypeStep,
        category: 'common.stepsCategories.myGood',
        next: getPropertyTypeNextStep(data.housingType),
      },
      {
        id: stepsIds.ACTIVITY_STEP,
        component: ActivityStep,
        category: 'common.stepsCategories.myGood',
      },
      {
        id: stepsIds.PROPERTY_INFO,
        component: PropertyInfoStep,
        category: 'common.stepsCategories.myGood',
        next: getPropertyInfoNextStep(data.housingType),
        previous: getPropertyInfoPreviousStep(data.housingType),
      },
      {
        id: stepsIds.FURNISHED_STEP,
        component: FurnishedStep,
        category: 'common.stepsCategories.myGood',
      },
      {
        id: stepsIds.USER_INFO,
        component: UserInfoStep,
        category: 'common.stepsCategories.myGood',
        previous: getUserInfoPreviousStep(data.housingType),
      },
    ],
    [
      {
        id: stepsIds.QUOTE,
        component: QuoteStep,
        category: 'common.stepsCategories.rate',
        next:
          data.housingType === 'A'
            ? stepsIds.MORE_INFORMATIONS
            : stepsIds.CURRENT_WARRANTY,
      },
    ],
    [
      {
        id: stepsIds.MORE_INFORMATIONS,
        component: MoreInformationsStep,
        category: 'common.stepsCategories.subscription',
      },
      {
        id: stepsIds.CURRENT_WARRANTY,
        component: CurrentInsuranceStep,
        next:
          data.hasCurrentWarranty && data.hasCurrentWarranty
            ? stepsIds.INSURANCE_SENIORITY
            : stepsIds.INSURANCE_START,
        previous:
          data.housingType === 'A'
            ? stepsIds.MORE_INFORMATIONS
            : stepsIds.QUOTE,
        category: 'common.stepsCategories.subscription',
      },
      {
        id: stepsIds.INSURANCE_SENIORITY,
        component: InsuranceSeniorityStep,
        next: stepsIds.COMPLETE_USER_INFO,
        category: 'common.stepsCategories.subscription',
      },
      {
        id: stepsIds.INSURANCE_START,
        component: InsuranceStartStep,
        previous: stepsIds.CURRENT_WARRANTY,
        category: 'common.stepsCategories.subscription',
      },
      {
        id: stepsIds.RESULT,
        component: ResultStep,
        category: 'common.stepsCategories.subscription',
      },
      {
        id: stepsIds.COMPLETE_USER_INFO,
        component: CompleteUserInfoStep,
        category: 'common.stepsCategories.subscription',
        previous:
          data.hasCurrentWarranty && data.hasCurrentWarranty
            ? stepsIds.INSURANCE_SENIORITY
            : stepsIds.INSURANCE_START,
      },
      {
        id: stepsIds.PERSONAL_ADDRESS,
        component: PersonalAddressStep,
        category: 'common.stepsCategories.subscription',
      },
      {
        id: stepsIds.IDENTITY,
        component: IdentityStep,
        category: 'common.stepsCategories.finalization',
      },
      {
        id: stepsIds.PAYMENT_LOADER,
        component: PaymentPreloader,
        hideStepper: true,
        category: 'common.stepsCategories.subscription',
      },
      {
        id: stepsIds.PAYMENT,
        component: PaymentStep,
        // hideStepper: true,
        category: 'common.stepsCategories.subscription',
      },
    ],
    [
      {
        id: stepsIds.END,
        component: EndStep,
        // hideStepper: true,
        category: 'common.stepsCategories.finalization',
      },
      {
        id: stepsIds.ERROR,
        component: ErrorStep,
        hideStepper: true,
        hidden: true,
      },
      {
        id: stepsIds.EXIT,
        component: ExitStep,
        hideStepper: true,
        hidden: true,
      },
    ],
  ];
};

export default getSteps;
export { stepsIds };
