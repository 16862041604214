import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import MuiTable from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import _get from 'lodash/get';

import useStyles from './style';

const Table = (props) => {
  const classes = useStyles();
  const {
    columns,
    rows,
    page,
    rowsPerPage,
    rowId,
    onChangePage,
    onChangeRowsPerPage,
    total,
    loading,
  } = props;

  const renderCellValue = (column, row) => {
    const value = _get(row, column.id, '') || '';
    return column.format ? column.format(row) : value;
  };

  const renderDataRows = () => {
    return rows.map((row) => (
      <TableRow hover role="checkbox" tabIndex={-1} key={row[rowId]}>
        {columns.map((column) => (
          <TableCell key={column.id} align={column.align}>
            {renderCellValue(column, row)}
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  const renderLoadingRows = () => {
    const rowsList = Array(rowsPerPage).fill();
    return rowsList.map((row, index) => (
      <TableRow hover role="checkbox" tabIndex={-1} key={`r${index}`}>
        {columns.map((column) => (
          <TableCell key={column.id} align={column.align}>
            <Skeleton />
          </TableCell>
        ))}
      </TableRow>
    ));
  };

  const renderRows = () => {
    if (loading) return renderLoadingRows();
    else if (rows.length > 0) return renderDataRows();
    else
      return (
        <div className={classes.noResult}>
          <Typography variant="body1" color="secodary">
            Aucun résultat
          </Typography>
        </div>
      );
  };

  return (
    <Paper className={classes.root}>
      <TableContainer className={classes.container}>
        <MuiTable stickyHeader aria-label="Tableau des polices" size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  classes={{ root: classes.headerCell }}
                >
                  <Typography
                    color="secondary"
                    classes={{ root: classes.headerCell }}
                  >
                    {column.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>{renderRows()}</TableBody>
        </MuiTable>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 30, 100]}
        component="div"
        count={total}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </Paper>
  );
};

Table.propTypes = {
  columns: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  rowId: PropTypes.string.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangeRowsPerPage: PropTypes.func.isRequired,
  total: PropTypes.number.isRequired,
  loading: PropTypes.bool,
};

export default Table;
