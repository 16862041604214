import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClockLottie from 'assets/lotties/clock.json';
import Lottie from 'components/Lottie';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

Loading.propTypes = {
  claim: PropTypes.string,
  police: PropTypes.string,
  msgHeader: PropTypes.string,
  msgContent: PropTypes.string,
};

const useStyles = makeStyles(() => ({
  gridRoot: {
    marginTop: 32,
  },
  textContent: {
    marginTop: 16,
    textAlign: 'center',
  },
}));

function Loading(props) {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    msgHeader = t('common.loading.msg_thanks'),
    msgContent = t('common.loading.msg_wait'),
  } = props;

  return (
    <Grid
      container
      className="EndStep"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.gridRoot}
      >
        <div>
          <Lottie animationData={ClockLottie} isActive autoPlay />
        </div>
        <Grid container direction="column" alignItems="center">
          <Typography color="textPrimary" variant="h4" align="center">
            {msgHeader}
          </Typography>
        </Grid>
        <Grid container direction="column" alignItems="center">
          <Typography
            color="textPrimary"
            variant="h6"
            className={classes.textContent}
          >
            {msgContent}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Loading;
