import React, { useState } from 'react';

const GlobalContext = React.createContext({});

const GlobalContextProvider = ({ children }) => {
  const [cookiesSelectorRef, setCookiesSelectorRef] = useState(null);
  const [gaMrhSiteKey, setGaMrhSiteKey] = useState(null);
  const [gaPnoSiteKey, setGaPnoSiteKey] = useState(null);
  const [gaEcSiteKey, setGaEcSiteKey] = useState(null);

  return (
    <GlobalContext.Provider
      value={{
        cookiesSelectorRef,
        setCookiesSelectorRef,
        gaMrhSiteKey,
        setGaMrhSiteKey,
        gaPnoSiteKey,
        setGaPnoSiteKey,
        gaEcSiteKey,
        setGaEcSiteKey,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export default GlobalContext;
export { GlobalContextProvider };
