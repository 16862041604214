import React, { useState } from 'react';
import { Box, Grid, Button, Typography, Hidden } from '@material-ui/core';
import Unsubcribe from 'assets/images/unsubcribe-mailing.svg';
import useStyles from './style';
import { useHistory } from 'react-router-dom';
import useQuery from 'hooks/useQuery';
import customerApi from 'api/customerApi';
import { useSnackbar } from 'notistack';
import { isEmail } from 'utils/form-validation';
import { ArrowRightAlt } from '@material-ui/icons';

UnsubcribeMailing.propTypes = {};

function UnsubcribeMailing() {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const history = useHistory();
  const [finish, setFinish] = useState(false);

  const unsubscribeKey = useQuery('key').getParameter();
  const mail = useQuery('mail').getParameter();
  if (!unsubscribeKey || !mail) history.push('/login');

  const handleClick = async () => {
    if (!mail) return;
    if (!isEmail(mail)) {
      enqueueSnackbar('Veuillez saisir un adresse email valide', {
        variant: 'error',
      });
      return;
    }
    try {
      await customerApi.unsubcribe({ mail, unsubscribeKey });
      setFinish(true);
    } catch (error) {
      enqueueSnackbar(
        'Une erreur est survenue, merci de réessayer ultérieurement',
        { variant: 'error' }
      );
    }
  };

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.header}>
        <Box className={'logo'}>
          <img
            src={`${localStorage.getItem(
              'IMAGE_MANAGER_URL_GET'
            )}/assurimo/base/logo-assurimo.png`}
            className={classes.logo}
            width={200}
          />
        </Box>
        <Box className={'navigation'}>
          <Button
            variant="contained"
            className={'btn btn-register'}
            onClick={() => history.push('/souscription')}
          >
            {'S\'inscrire'}
          </Button>
          <Button
            variant="contained"
            className={'btn btn-console'}
            onClick={() => history.push('/login')}
          >
            Espace Client
          </Button>
        </Box>
      </Box>
      <main>
        <Box component="section" className={classes.root}>
          <Grid container>
            <Grid item md={7} xs={12}>
              <Box className={'left'}>
                {!finish ? (
                  <Box>
                    <Typography className={'typography'}>
                      Nous sommes désolés d'apprendre que vous souhaitez vous
                      désabonner
                    </Typography>
                    <Button
                      className={'btn'}
                      variant="contained"
                      onClick={handleClick}
                    >
                      Je me désabonne
                    </Button>
                  </Box>
                ) : (
                  <Box>
                    <Typography className={'typography'}>
                      Votre désabonnement a bien été pris en compte
                    </Typography>
                    <Typography variant="body2" className={'typography-body'}>
                      Pour des questions ou commentaires, veuillez nous
                      contacter à l'adresse:
                    </Typography>
                    <Typography>
                      <a
                        target="_blank"
                        href="mailto:particuliers@assurimo.fr"
                        rel="noreferrer"
                      >
                        particuliers@assurimo.fr
                      </a>
                    </Typography>
                    <Typography
                      className={'typography-end'}
                      onClick={() => history.push('/login')}
                    >
                      <ArrowRightAlt fontSize="large" />
                      Retour à Assurimo
                    </Typography>
                  </Box>
                )}
              </Box>
            </Grid>
            <Grid item md={5} xs={12}>
              <Hidden smDown>
                <Box className={'right'}>
                  <img src={Unsubcribe} className={'img'} />
                </Box>
              </Hidden>
            </Grid>
          </Grid>
        </Box>
      </main>
    </Box>
  );
}

export default UnsubcribeMailing;
