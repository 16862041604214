import moment from 'moment';

function customDateSerializer(key, value) {
  if (moment.isMoment(value)) {
    return value.format();
  } else if (typeof value === 'object') {
    for (const prop in value) {
      if (value.hasOwnProperty(prop)) {
        value[prop] = customDateSerializer(prop, value[prop]);
      }
    }
  }
  return value;
}

export const persistState = (storageKey, state) => {
  window.localStorage.setItem(
    storageKey,
    JSON.stringify(state, customDateSerializer)
  );
};

export const deleteState = (storageKey) => {
  window.localStorage.removeItem(storageKey);
};

export const getInitialState = (storageKey) => {
  const savedState = window.localStorage.getItem(storageKey);

  try {
    const state = JSON.parse(savedState ?? '{}');

    // Persist state only if last state modification was <24 hours ago
    if (
      state.time &&
      moment(state.time).isBefore(moment().subtract(1, 'day'))
    ) {
      return undefined;
    }

    // Reset file inputs
    if (state.data.identity) {
      state.data.identity = {
        type: 'cni',
        fichiers: {
          cni_recto: { name: '', file: '' },
          cni_verso: { name: '', file: '' },
          passeport: { name: '', file: '' },
        },
      };
    }

    return state;
  } catch (e) {
    return undefined;
  }
};
