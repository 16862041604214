import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import Popin from 'components/Popin';
import ExternalLogin from 'views/Authentication/Login/External';
import { LOGIN_STEPS } from 'constants';
import useStyles from './style';

const LoginPopin = (props) => {
  const { onClose, open, goToNextStep, currentSubscriptionStep } = props;
  const { t } = useTranslation();
  const [loginStep, setLoginStep] = useState(null);
  const loginRef = useRef();
  const classes = useStyles();
  const location = useLocation();

  const handleClose = () => {
    onClose();
  };

  const handleValidate = (e) => {
    loginRef.current.handleClickNext(e);

    if (loginStep === LOGIN_STEPS.RESET_PASSWORD) handleCancel();
  };

  const handleCancel = (e) => {
    if (loginStep === LOGIN_STEPS.RESET_PASSWORD)
      loginRef.current.handleGoPrevious(e);
    else handleClose();
  };

  const handleLoginStepChange = (newLoginStep) => {
    setLoginStep(newLoginStep);
  };

  const getTitle = () => {
    if (loginStep === LOGIN_STEPS.RESET_PASSWORD)
      return t('auth.recovery.sureToResetPassword');
    else return t('common.header.loginPopinTitle');
  };

  const getValidateButtonLabel = () => {
    if (loginStep === LOGIN_STEPS.RESET_PASSWORD) return t('common.yes');
    else return t('common.next');
  };

  const getCancelButtonLabel = () => {
    if (loginStep === LOGIN_STEPS.RESET_PASSWORD) return t('common.no');
    else return t('common.cancel');
  };

  return (
    <Popin
      open={open}
      onClose={handleClose}
      title={getTitle()}
      onValidate={handleValidate}
      validateButtonLabel={getValidateButtonLabel()}
      showCancelButton={true}
      cancelButtonLabel={getCancelButtonLabel()}
      onCancel={handleCancel}
      content={
        <div className={classes.login}>
          <ExternalLogin
            redirectTo={location.pathname}
            goToNextStep={goToNextStep}
            ref={loginRef}
            hideButton={true}
            onLoginStepChange={handleLoginStepChange}
            currentSubscriptionStep={currentSubscriptionStep}
          />
        </div>
      }
    />
  );
};

LoginPopin.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  goToNextStep: PropTypes.func,
  currentSubscriptionStep: PropTypes.string,
};

export default LoginPopin;
