import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import useStyles from 'components/PolicyItem/style';
import Popin from 'components/Popin';
import { Typography } from '@material-ui/core';
import policyApi from 'api/policyApi';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import { POLICE_MOUVEMENT_ANNP } from 'constants';
import { isPoliceEligibleADeclaSin } from 'views/Dashboard/Customer/SinistreDeclarationPage/helper';

const ActivePolicyItemActions = ({ police }) => {
  const auth = useAuth();
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();

  const [optionsPopin, setOptionsPopin] = useState({
    open: false,
  });
  const [loading, setLoading] = useState(false);

  const { policyNumber } = police;

  const linkSinistre = `/polices/${policyNumber}/nouveau-sinistre`;
  const linkToPolicyPage = `/polices/${policyNumber}`;

  useEffect(() => {
    setOptionsPopin({
      ...optionsPopin,
      validateLoading: loading,
      useValidateLoader: loading,
    });
  }, [loading]);

  const handleRemovePolice = () => {
    const policeNumber = police.policyNumber;
    if (!policeNumber) return;

    setOptionsPopin({
      ...optionsPopin,
      open: true,
      title: t('common.popin.confirm_title'),
      onValidate: () => handleRemovePolicy(policeNumber),
      validateButtonLabel: t('common.yes'),
      showCancelButton: true,
      content: (
        <Box margin={3}>
          <Typography variant="body1">
            {t('dashboard.policies.terminationDraftMsg')}
          </Typography>
        </Box>
      ),
    });
  };

  const handleRemovePolicy = async (policeNumber) => {
    try {
      setLoading(true);
      await policyApi.terminationPoliceDraft(auth, policeNumber);
      handlePopinClose();
    } catch (error) {
      console.log(error);

      enqueueSnackbar(t('dashboard.policies.terminateMsgError'), {
        variant: 'error',
      });
    } finally {
      history.push('/polices');
      setLoading(false);
    }
  };

  const handlePopinClose = () => {
    setOptionsPopin({
      ...optionsPopin,
      open: false,
    });
  };

  const afficherBoutonDeclarationSin = isPoliceEligibleADeclaSin(police);
  const isUnDevis = police && police.status.code === POLICE_MOUVEMENT_ANNP;

  return (
    <Box className={classes.actions} display="flex" alignItems="center">
      {/** Bouton Déclarer sinistre */}
      {afficherBoutonDeclarationSin && (
        <Box className={classes.action}>
          <Button
            align="center"
            variant="outlined"
            color="primary"
            size="small"
            component={Link}
            to={linkSinistre}
          >
            {t('dashboard.policies.declareClaim')}
          </Button>
        </Box>
      )}

      {/** Bouton supprimer devis */}
      {isUnDevis && (
        <Box className={classes.action}>
          <Button
            align="center"
            variant="outlined"
            color="primary"
            size="small"
            onClick={handleRemovePolice}
          >
            {t('dashboard.policies.deleteThisQuote')}
          </Button>
        </Box>
      )}

      {/** Bouton Gerer le contrat */}
      <Box className={classes.action}>
        <Button
          align="center"
          variant="contained"
          color="primary"
          size="small"
          endIcon={<ArrowForwardIcon />}
          component={Link}
          to={linkToPolicyPage}
        >
          {t('dashboard.policies.details')}
        </Button>
      </Box>

      <Popin onClose={handlePopinClose} maxWidth="sm" {...optionsPopin} />
    </Box>
  );
};

ActivePolicyItemActions.propTypes = {
  police: PropTypes.object.isRequired,
  onRemove: PropTypes.func,
};

export { ActivePolicyItemActions };
