import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import LanguageIcon from '@material-ui/icons/Language';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Menu from '@material-ui/core/Menu';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import { firstLetterUppercase } from 'utils';
import useStyles from './style';

const LOCALES = {
  en: { code: 'en', label: 'English' },
  fr: { code: 'fr', label: 'Français' },
};

const LocaleSelector = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { i18n } = useTranslation();
  const { textClasses, variant } = props;
  const [locale, setLocale] = useState(LOCALES[i18next.resolvedLanguage]);

  const handleChangeMobile = (locale) => {
    setLocale(locale);
    i18n.changeLanguage(locale.code);
    handleClose();
  };

  useEffect(() => {
    setLocale(LOCALES[i18next.resolvedLanguage]);
  }, []);

  useEffect(() => {
    if (!locale) setLocale(LOCALES['fr']);
  }, [locale]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (variant && variant === 'sidebar') {
    return (
      <>
        <ListItem button onClick={handleMenuClick}>
          <ListItemIcon>
            <LanguageIcon color="secondary" />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography
                variant="body1"
                classes={{ root: classes.text }}
                style={{
                  color: theme.palette.blue.main,
                }}
              >
                {LOCALES[locale.code].label}
              </Typography>
            }
          />
        </ListItem>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
          // transformOrigin={{ vertical: 'center', horizontal: 'right' }}
        >
          {Object.keys(LOCALES).map((key) => {
            return (
              <MenuItem
                key={key}
                value={LOCALES[key]}
                onClick={() => handleChangeMobile(LOCALES[key])}
                selected={locale.code === LOCALES[key]}
              >
                <div className={classes.menuItem}>
                  <Typography classes={textClasses}>
                    {LOCALES[key].label}
                  </Typography>
                </div>
              </MenuItem>
            );
          })}
        </Menu>
      </>
    );
  } else
    return (
      <div>
        <IconButton
          onClick={handleMenuClick}
          classes={{ root: classes.localeButton }}
        >
          <LanguageIcon color="secondary" />
          <Typography color="secondary">
            {firstLetterUppercase(locale.code)}
          </Typography>
        </IconButton>
        <Popover
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {Object.keys(LOCALES).map((key) => {
            return (
              <MenuItem
                key={key}
                value={LOCALES[key]}
                onClick={() => handleChangeMobile(LOCALES[key])}
                selected={locale.code === LOCALES[key]}
              >
                <div className={classes.menuItem}>
                  <Typography classes={textClasses}>
                    {LOCALES[key].label}
                  </Typography>
                </div>
              </MenuItem>
            );
          })}
        </Popover>
      </div>
    );
};

LocaleSelector.propTypes = {
  textClasses: PropTypes.object,
  variant: PropTypes.string,
};

export default LocaleSelector;
