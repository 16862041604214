import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import QuestionsStep from 'components/QuestionsStep/QuestionsStep';
import { formatDate } from 'utils';
import Title from 'components/Title';

const PropertyTypeStep = ({
  data,
  onConfirm,
  setUserData,
  setUserDataAndChangePage,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Title title={t('steps.currentInsuranceStep.title')} />
      <QuestionsStep
        propertyKey="hasCurrentWarranty"
        choices={[
          {
            label: t('common.yes'),
            value: true,
          },
          {
            label: t('common.no'),
            value: false,
          },
        ]}
        onSelect={(key, value) => {
          setUserData(key, value);
          if (value === true) {
            setUserData(
              'contractStartDate',
              formatDate(moment().utc().add(40, 'day').startOf('day'))
            );
          } else if (value === false) {
            setUserData(
              'contractStartDate',
              formatDate(moment().utc().add(1, 'day').startOf('day'))
            );
          }
        }}
        onConfirm={onConfirm}
        onItemClick={setUserDataAndChangePage}
        invalid={
          data.hasCurrentWarranty === null ||
          data.hasCurrentWarranty === undefined
        }
        value={data.hasCurrentWarranty}
      />
    </>
  );
};

PropertyTypeStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserDataAndChangePage: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
};

export default PropertyTypeStep;
