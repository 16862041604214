import React from 'react';
import { Grid, Typography, Link, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Lottie from 'components/Lottie';
import ClockLottie from 'assets/lotties/clock.json';

const ExitStep = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Grid
      container
      className="EndStep"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{
          marginTop: 32,
        }}
      >
        <div
          style={{
            maxWidth: 200,
          }}
        >
          <Lottie animationData={ClockLottie} isActive autoPlay />
        </div>
        <Grid container direction="column" alignItems="center">
          <Typography color="primary" variant="h4" align="center">
            {t('pno.exitStep.thanksForInterest')}
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          <Typography variant="h6" align="center">
            {t('pno.exitStep.yourContratRequire')}.
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          <Typography variant="h6" align="center">
            {t('pno.exitStep.youCanContactUs')}
          </Typography>
          <Typography variant="h5" color="primary" align="center">
            <a
              href={`tel:${t('common.assurimoPhoneNumber')}`}
              style={{
                cursor: 'pointer',
                color: theme.palette.primary.main,
              }}
            >
              {t('common.assurimoPhoneNumber')}
            </a>
          </Typography>
          <Typography variant="h6" component="span" align="center">
            {` ${t('pno.exitStep.or')} `}
          </Typography>
          <Typography variant="h5" color="primary" align="center">
            <a
              href="mailto:contact@assurimo.fr"
              style={{
                cursor: 'pointer',
                color: theme.palette.primary.main,
              }}
            >
              {t('common.assurimoEmail')}
            </a>
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          <Typography variant="h6" align="center">
            {t('pno.exitStep.weWillBehappy')}.
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          alignItems="center"
          style={{ marginTop: 20 }}
        >
          <Typography variant="h6" align="center">
            {t('pno.exitStep.needInsurance')}
          </Typography>
          <Typography variant="h5" color="primary" align="center">
            <Link
              href={`http://${t('common.assurimoWebsite')}`}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              {t('common.assurimoWebsite')}
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ExitStep;
