import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  login: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    maxWidth: 600,
    margin: 'auto',
    paddingTop: 30,
    paddingBottom: 30,
  },
}));

export default useStyles;
