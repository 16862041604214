import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  menuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  localeButton: {
    padding: 6,
  },
  text: {
    fontSize: 14,
  },
}));

export default useStyles;
