import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './Lottie.scss';
import bodymovin from 'lottie-web';
import useStyles from './style';

const Lottie = (props) => {
  const {
    animationData,
    isFloor,
    isNotFloor,
    destinationFrame,
    isActive,
    autoPlay,
    preventMouseLeaveEffectIfActive,
    lottieWidth,
  } = props;

  const containerRef = useRef();
  const [lottie, setLottie] = useState();
  const [isHover, setIsHover] = useState();
  const [prevDestination, setPrevDestination] = useState(1);
  const classes = useStyles(props);

  useEffect(() => {
    const anim = bodymovin.loadAnimation({
      wrapper: containerRef.current,
      animType: 'svg',
      loop: !isFloor,
      animationData: animationData,
      autoplay: autoPlay || isActive,
    });

    setLottie(anim);

    if (isFloor) {
      anim.playSegments(
        [
          (parseInt(prevDestination) - 1) * 80,
          (parseInt(destinationFrame) - 1) * 80,
        ],
        true
      );
      setPrevDestination(destinationFrame);
    }

    return () => {
      anim.destroy();
    };
  }, [animationData, destinationFrame, isFloor]);

  useEffect(() => {
    if (!isFloor && lottie) {
      if (isActive) {
        lottie.play();
      } else {
        lottie.pause();
        setIsHover(false);
      }
    }
  }, [isActive]);

  const handleMouseEnter = () => {
    if (!isFloor) {
      lottie.play();
      setIsHover(true);
    }
  };

  const handleMouseLeave = () => {
    if (
      !preventMouseLeaveEffectIfActive ||
      (preventMouseLeaveEffectIfActive && !isFloor && !isActive)
    ) {
      lottie.pause();
      setIsHover(false);
    }
  };

  return (
    <div className={`${classes.lottie} lottie`}>
      <div
        style={{ width: lottieWidth ? lottieWidth : 'inherit' }}
        className={`${classes.container} ${isHover ? classes.hovered : ''} ${
          isFloor ? classes.isFloor : ''
        } ${isNotFloor ? classes.isNotFloor : ''}${
          autoPlay || isActive ? classes.color : ''
        }`}
        ref={containerRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
    </div>
  );
};

Lottie.propTypes = {
  animationData: PropTypes.any,
  isFloor: PropTypes.bool,
  isNotFloor: PropTypes.bool,
  destinationFrame: PropTypes.any,
  isActive: PropTypes.bool,
  autoPlay: PropTypes.bool,
  preventMouseLeaveEffectIfActive: PropTypes.bool,
  lottieWidth: PropTypes.number,
};

Lottie.defaultProps = {
  autoPlay: false,
  preventMouseLeaveEffectIfActive: false,
};

export default Lottie;
