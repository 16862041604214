import React from 'react';
import Box from '@material-ui/core/Box';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';

import Popin from 'components/Popin';
import PaperImage from 'assets/images/paper.svg';

const Succes = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Popin
      open={isOpen}
      onClose={() => {
        onClose();
        history.push('/polices');
      }}
      title={t('dashboard.police.retractation.popin_title')}
      content={
        <Box
          style={{
            display: 'flex',
            margin: 32,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 25,
          }}
        >
          <Typography variant="h5">
            {t('dashboard.police.retractation.succesDemande_head')}
          </Typography>
          <img
            src={PaperImage}
            alt="Assurimo"
            style={{
              width: 160,
            }}
          />

          <Typography variant="body3">
            <Trans i18nKey="dashboard.police.retractation.info_succes" />
          </Typography>
        </Box>
      }
      maxWidth="sm"
      useValidateLoader={true}
    />
  );
};

Succes.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Succes;
