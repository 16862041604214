import React from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';
import PasswordField from './PasswordField';
import NumberField from './NumberField';
import DateField from './DateField';
import RadioField from './RadioField';
import SelectField from './SelectField';
import PhoneField from './PhoneField';
import Checkbox from './Checkbox';
import AutomaticField from './AutomaticField';
import AutocompleteField from './AutoCompleteField';
// import ChoiceField from './ChoiceField';
// address ?

const Field = (props) => {
  const { type, ...otherProps } = props;

  switch (type) {
    case 'text':
      return <TextField {...otherProps} />;
    case 'password':
      return <PasswordField {...otherProps} />;
    case 'number':
      return <NumberField {...otherProps} />;
    case 'date':
      return <DateField {...otherProps} />;
    case 'radio':
      return <RadioField {...otherProps} />;
    case 'select':
      return <SelectField {...otherProps} />;
    case 'phone':
      return <PhoneField {...otherProps} />;
    case 'checkbox':
      return <Checkbox {...otherProps} />;
    case 'autocomplete':
      return <AutocompleteField {...otherProps} />;

    case 'automatic': {
      return <AutomaticField {...otherProps} />;
    }
    // case 'choices':
    //   return <ChoiceField {...otherProps} />;
  }
};

Field.propTypes = {
  type: PropTypes.oneOf([
    'text',
    'number',
    'date',
    'radio',
    'phone',
    'checkbox',
    'autocomplete',
    'password',
  ]),
};

export default Field;
