import { PNO_CODE_RISQUE } from 'constants/index';
import moment from 'moment';
import {
  PNO_TYPE_LOCAL_COMMERCIAL,
  PNO_TYPE_APPARTEMENT,
  PNO_TYPE_MAISON,
  getHousingType,
} from 'constants/common/bienType';

const getPnoSubscriptionData = (data, parcoursId) => {
  const getTypeActivite = () => {
    if (data.housingType !== PNO_TYPE_LOCAL_COMMERCIAL || !data.typeActivite) {
      return null;
    } else return data.typeActivite;
  };

  const getFurnished = () => {
    if ([PNO_TYPE_APPARTEMENT, PNO_TYPE_MAISON].includes(data.housingType))
      return data.furnished;
    else return false;
  };

  const user = {
    address: data.personalAddress,
    contact: {
      mail: data.contact.mail,
      mobilePhone: data.contact.phone.value,
    },
    firstName: data.contact.firstName,
    lastName: data.contact.lastName,
    birthDate: data.contact.birthdate,
    birthCity: data.contact.birthCity,
    countryBirth: data.contact.countryBirth,
    title: data.contact.gender,
    createReason: 'PNO',
  };

  const oldPolicy = data.hasCurrentWarranty
    ? {
        policyNumber: data.policyNumber ? data.policyNumber : null,
        insuranceCompany: data.insuranceCompany ? data.insuranceCompany : null,
        terminationTodo: true,
      }
    : { terminationTodo: false };

  const policy = {
    customerPhone: data.contact.phone.value,
    customerBirthDate: data.contact.birthdate,
    riskType: PNO_CODE_RISQUE,
    startDate: moment(data.contractStartDate).format('YYYY-MM-DD'),
    paymentSplittingCode: 'A',
    annualTTC: data.price.annualTTC,
    externalAccountManager: {
      firstName: data.externalAccountManager
        ? data.externalAccountManager.firstName
        : null,
      lastName: data.externalAccountManager
        ? data.externalAccountManager.lastName
        : null,
      title: data.externalAccountManager
        ? data.externalAccountManager.title
        : null,
      usualMail: data.externalAccountManager
        ? data.externalAccountManager.usualMail
        : null,
      company: data.externalAccountManager
        ? data.externalAccountManager.company
        : null,
      groupmentCompanyN1: data.externalAccountManager
        ? data.externalAccountManager.groupmentCompanyN1
        : null,
      groupmentCompanyN2: data.externalAccountManager
        ? data.externalAccountManager.groupmentCompanyN2
        : null,
    },
    risk: {
      typeActivite: getTypeActivite(),
      address: data.contractAddress,
      assuredQuality: data.assuredQuality,
      housingType: getHousingType(data),
      numberPieces: parseInt(data.numberOfPrincipalRooms),
      floor: data.floor ? parseInt(data.floor) : null,
      area: data.area ? parseInt(data.area) : null,
      furnished: getFurnished(),
      equipment: {},
    },
    forecastFinishDate: data.contractEndDate,
    parcoursSouscriptionUuid: parcoursId,
  };

  if (data.from) {
    policy.originalCode = data.from;
  }

  if (data.promotionCode) {
    policy.promotionCode = data.promotionCode;
  }

  if (data.forceControl) {
    policy.forceControl = data.forceControl;
  }

  return {
    user,
    policy: {
      ...policy,
      oldPolicy,
    },
  };
};

export default getPnoSubscriptionData;
