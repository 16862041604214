import { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';

export default function () {
  const { authState } = useOktaAuth();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (authState.isAuthenticated) {
      const {
        accessToken: { accessToken: token },
      } = authState;
      setAuthenticated(true);
      setToken(token);
    } else {
      setAuthenticated(false);
      setToken(null);
    }
  }, [authState.isAuthenticated]);

  return { isAuthenticated, token };
}
