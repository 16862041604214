import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import InternalLoginPage from './Internal';
import ExternalLoginPage from './External';
import useStyles from './style';
import Lottie from 'components/Lottie';
import Home from 'assets/lotties/home.json';
import HomeMobile from 'assets/lotties/home-mobile.json';

const Login = (props) => {
  const { system, oktaConfig } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  if (system === 'internal') {
    return <InternalLoginPage config={oktaConfig} />;
  }

  return (
    <div className={classes.wrapper}>
      <Box className={classes.boxLottie}>
        <Hidden xsDown>
          <Lottie animationData={Home} isActive width={'100%'} />
        </Hidden>
        <Hidden smUp>
          <Lottie animationData={HomeMobile} isActive />
        </Hidden>
      </Box>

      <Paper variant="elevation" elevation={0} className={classes.container}>
        <Paper
          variant="elevation"
          elevation={0}
          className={classes.logoWrapper}
        >
          <img
            src={`${localStorage.getItem(
              'IMAGE_MANAGER_URL_GET'
            )}/assurimo/base/logo-assurimo.png`}
            className={classes.logo}
            width={200}
          />
        </Paper>
        {/* </Paper>
      <Paper variant="elevation" elevation={3} className={classes.logoWrapper}> */}
        <Paper variant="elevation" elevation={0} className={classes.paper}>
          {/* <img src="src/assets/images/logo.png" className={classes.logo} /> */}

          <Typography variant="subtitle2" classes={{ root: classes.title }}>
            {t('auth.login.login')}
          </Typography>

          <ExternalLoginPage config={oktaConfig} />
        </Paper>
      </Paper>
    </div>
  );
};

Login.propTypes = {
  system: PropTypes.string.isRequired,
  oktaConfig: PropTypes.shape().isRequired,
};

export default Login;
