import React from 'react';
import PropTypes from 'prop-types';
import { DataGrid as MuiDataGrid } from '@material-ui/data-grid';
import useStyles from './style';

const DataGrid = (props) => {
  const { getRowId, rows, columns, loading, pageSize, ...otherProps } = props;
  const classes = useStyles();

  return (
    <MuiDataGrid
      rows={rows}
      columns={columns}
      pageSize={pageSize}
      disableSelectionOnClick
      getRowId={getRowId}
      disableExtendRowFullWidth
      isCellEditable={false}
      density="compact"
      loading={loading}
      classes={{
        root: classes.dataGrid,
        // columnHeader: classes.header,
        // row: classes.row,
        // cell: classes.cell,
      }}
      {...otherProps}
    />
  );
};

DataGrid.propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  getRowId: PropTypes.func.isRequired,
  pageSize: PropTypes.number,
};

export default DataGrid;
