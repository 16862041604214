import { SecureRoute } from '@okta/okta-react';
import React from 'react';
import { Redirect, Switch, useRouteMatch } from 'react-router-dom';
import ListPage from './pages/ListPage';

function Card() {
  const match = useRouteMatch();

  return (
    <Switch>
      <SecureRoute exact path={`${match.url}`} component={ListPage} />
      <SecureRoute path="/*" render={() => <Redirect to="/polices" />} />
    </Switch>
  );
}

export default Card;
