import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginBottom: 40,
  },
  activityWrapper: {
    margin: 40,
  },
}));

export default useStyles;
