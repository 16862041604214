import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { PnoContext } from 'contexts/pno';
import { fetchRatings } from './fetchRating';
import QuoteStepPreloader from './Preloader';
import { getMessageError } from 'constants/common/errorCode';
import devisApi from 'api/devisApi';

import Details from './Details';

const QuoteStep = ({ data, onConfirm, stay, parentRef }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const { dispatch, parcoursId } = useContext(PnoContext);
  const [loaded, setLoaded] = useState(false);
  const [rating, setRating] = useState([]);
  //const [ratingPromo, setRatingPromo] = useState(null);

  const handleValidate = async () => {
    try {
      devisApi.genererDevisEtNotifierClient(
        parcoursId
      ); /** Pas besoin d'attendre le retour */
      onConfirm();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    dispatch({
      type: 'SET_DATA',
      payload: { key: 'landscaping', value: null },
    });

    if (data.contractAddress.address1 != '')
      fetchRatings(data)
        .then((res) => {
          if (Array.isArray(res.errors))
            enqueueSnackbar(res.errors.join(' | '), { variant: 'error' });
          else if (res.error_description)
            enqueueSnackbar(res.error_description, { variant: 'error' });
          else {
            setRating(res);

            setTimeout(() => setLoaded(true), 5000);
          }
        })
        .catch((err) => {
          if (
            err.code === 'PNO_NUMBER_ROOM_SUP8_ERROR' ||
            err.code === 'PNO_AREA_SUP400_ERROR' ||
            err.code === 'PNO_FLOOR_SUP30_ERROR'
          ) {
            setRating(err);
            setTimeout(() => setLoaded(true), 5000);
          } else handleError(err);
        });
  }, [data]);

  /*useEffect(() => {
    (async () => {
      if (data.promotionCode) {
        try {
          const res = await fetchRatings(data, true);
          setRatingPromo(res);
        } catch (error) {
          enqueueSnackbar(t('steps.quoteStep.promotionCodeError'), {
            variant: 'error',
          });
          dispatch({
            type: 'SET_DATA',
            payload: { key: 'promotionCode', value: null },
          });
        }
      }
    })();
  }, []);*/

  const handleError = (error) => {
    console.error('error', error);
    const messageError = getMessageError(error);
    enqueueSnackbar(t(messageError), { variant: 'error' });
  };

  return (
    <Grid
      container
      className="Step"
      alignItems="center"
      justifyContent="center"
    >
      {!loaded && <QuoteStepPreloader data={data} />}
      {loaded && rating && (
        <Details
          data={data}
          rating={rating}
          onConfirm={handleValidate}
          stay={stay}
          parentRef={parentRef}
        />
      )}
    </Grid>
  );
};

QuoteStep.propTypes = {
  data: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  stay: PropTypes.bool,
  parentRef: PropTypes.any,
};

export default QuoteStep;
