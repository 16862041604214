import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import { useTheme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CircularProgress from '@material-ui/core/CircularProgress';
import LinearProgress from '@material-ui/core/LinearProgress';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useTranslation } from 'react-i18next';

import useStyles from './style';
import './style.css';

const Popin = React.memo((props) => {
  const { t } = useTranslation();

  const {
    open,
    onClose,
    onValidate,
    title,
    cancelButtonLabel = t('common.popin.btn_close'),
    maxWidth,
    onCancel,
    showActionsButtons,
    disableEscapeKeyDown,
    isValidateButtonDisabled,
    validateButtonLabel,
    validateButtonVariant,
    useValidateLoader,
    validateLoading,
    loading,
    setCurrentPage,
    equalPageSize,
    pages,
    currentPage,
    validationErrors,
    showBackIcon,
    titleAreaOnMobile,
    error,
  } = props;
  const classes = useStyles(props);
  const theme = useTheme();
  const isDownXs = useMediaQuery(theme.breakpoints.down('xs'));

  const [page, setPage] = React.useState();

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else onClose();
  };

  const getPageContent = () => {
    const { component, ...otherProps } = page;

    return React.createElement(component, { ...otherProps, validationErrors });
  };

  useEffect(() => {
    const newPage = pages.find((page) => page.key === currentPage);
    setPage(newPage);
  }, [currentPage]);

  return page ? (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={'paper'}
      fullWidth={true}
      maxWidth={maxWidth}
      fullScreen={!!isDownXs}
      className={equalPageSize ? classes.dialog : ''}
      disableEscapeKeyDown={disableEscapeKeyDown}
      classes={{
        paper: equalPageSize ? classes.dialog : '',
        paperScrollPaper: classes.scrollingPaper,
      }}
    >
      {/* {enableTitleArea && ( */}
      {(titleAreaOnMobile || !isDownXs) && (
        <DialogTitle
          id="scroll-dialog-title"
          classes={{ root: classes.titleWrapper }}
          disableTypography
        >
          {showBackIcon && (
            <IconButton
              disabled={!page.previousPage}
              aria-label="back"
              onClick={() => setCurrentPage(page.previousPage())}
              className={classes.titleIcon}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Typography color="textPrimary" classes={{ root: classes.title }}>
            {title}
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            className={classes.titleIcon}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
      )}
      {/* )} */}
      <DialogContent dividers={true} className={classes.content}>
        {loading && (
          <LinearProgress
            variant="indeterminate"
            classes={{
              root: classes.linearProgressRoot,
              colorPrimary: classes.linearProgressColorPrimary,
              bar: classes.linearProgressBar,
            }}
          />
        )}
        <SwitchTransition>
          <CSSTransition
            key={currentPage}
            addEndListener={(node, done) => {
              node.addEventListener('transitionend', done, false);
            }}
            classNames="fade"
          >
            {/*/!\ Ne pas supprimer cette div - Elle est nécessaire au fonctionnement de la transition */}
            <div>
              <div className="page">{getPageContent()}</div>
              {error && (
                <Box
                  m={2}
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Typography
                    variant="caption"
                    classes={{ root: classes.error }}
                  >
                    {error}
                  </Typography>
                </Box>
              )}
            </div>
          </CSSTransition>
        </SwitchTransition>
      </DialogContent>
      {showActionsButtons && (
        <DialogActions classes={{ root: classes.dialogActions }}>
          {isDownXs && (
            <IconButton
              disabled={!page.previousPage}
              aria-label="back"
              onClick={() => setCurrentPage(page.previousPage())}
              className={classes.titleIcon}
            >
              <ArrowBackIcon />
            </IconButton>
          )}
          <Button onClick={handleCancel}>{cancelButtonLabel}</Button>
          {validateButtonLabel && (
            <Button
              onClick={onValidate}
              color="primary"
              disabled={isValidateButtonDisabled}
              variant={validateButtonVariant}
            >
              {useValidateLoader && validateLoading ? (
                <CircularProgress color="primary" size={16} />
              ) : (
                validateButtonLabel
              )}
            </Button>
          )}
        </DialogActions>
      )}
    </Dialog>
  ) : (
    <></>
  );
});

Popin.displayName = 'Popin';

Popin.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  icon: PropTypes.string,
  content: PropTypes.node,
  showActionsButtons: PropTypes.bool,
  maxWidth: PropTypes.string,
  validateButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onValidate: PropTypes.func,
  isValidateButtonDisabled: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  enableTitleArea: PropTypes.bool,
  equalPageSize: PropTypes.bool,
  validateButtonVariant: PropTypes.string,
  useValidateLoader: PropTypes.bool,
  validateLoading: PropTypes.bool,
  pages: PropTypes.array,
  currentPage: PropTypes.string,
  setCurrentPage: PropTypes.func,
  maxHeight: PropTypes.number,
  minHeight: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.string,
  validationErrors: PropTypes.shape(),
  loading: PropTypes.bool,
  showBackIcon: PropTypes.bool,
  titleAreaOnMobile: PropTypes.bool,
};

Popin.defaultProps = {
  open: false,
  icon: null,
  showCancelButton: false,
  maxWidth: 'lg',
  isValidateButtonDisabled: false,
  disableEscapeKeyDown: false,
  enableTitleArea: true,
  useValidateLoader: false,
  validateLoading: false,
  loading: false,
  equalPageSize: true,
  showBackIcon: true,
  titleAreaOnMobile: false,
  showActionsButtons: true,
};

export default Popin;
