import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ASSURIMO_INDIVIDUAL_EMAIL, ASSURIMO_PHONE_NUMBER } from 'constants';
import Popin from 'components/Popin';
import PaperImage from 'assets/images/paper.svg';

const ResilImpossible = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Popin
      open={isOpen}
      onClose={onClose}
      title={t('dashboard.police.termination.popin_title')}
      maxWidth="sm"
      content={
        <Box
          style={{
            display: 'flex',
            margin: 32,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 25,
          }}
        >
          <img
            src={PaperImage}
            alt="Assurimo"
            style={{
              width: 160,
            }}
          />
          <Typography variant="body3" style={{ textAlign: 'center' }}>
            {t('dashboard.policies.terminateContractPnoPopin.forAnyterm')}{' '}
            <a
              style={{ whiteSpace: 'nowrap' }}
              href={`tel:${ASSURIMO_PHONE_NUMBER}`}
            >
              {ASSURIMO_PHONE_NUMBER}
            </a>{' '}
            {t('dashboard.policies.terminateContractPnoPopin.orByEmail')}{' '}
            <a href={`mailto:${ASSURIMO_INDIVIDUAL_EMAIL}`}>
              {ASSURIMO_INDIVIDUAL_EMAIL}
            </a>
            .
          </Typography>
        </Box>
      }
      showCancelButton={false}
      isValidateButtonDisabled={false}
      useValidateLoader={true}
    />
  );
};

ResilImpossible.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default ResilImpossible;
