import { Grid, Typography, Box } from '@material-ui/core';
import Icon from 'components/Icon';
import { DOC_CONTRACTUEL } from 'constants/index';
import { useTranslation } from 'react-i18next';
import '../index.scss';
import useStyles from '../style';
import React, { useContext } from 'react';
import { UserContext } from 'contexts/user';
import { useSnackbar } from 'notistack';
import useDocContractuel from 'hooks/useDocContractuel';
import documentContractuelService from 'services/documentContractuelService';

function GuaranteeDocument(props) {
  const { codeTarif, codeRisque, typeGuarantee } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const { docContractuels } = useDocContractuel(codeRisque, codeTarif, null);
  const [userState] = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const downloadFile = async (doc) => {
    try {
      const label = `${t(DOC_CONTRACTUEL[doc.codeDocument] ? DOC_CONTRACTUEL[doc.codeDocument].label : doc.labelDefault)}.pdf`;
      await documentContractuelService.downloadFile(userState.auth, doc.id, label);
    } catch (error) {
      console.log('Une erreur est survenue quand on telecharge le document', error);
      enqueueSnackbar(t('steps.resultStep.error_generate'), { variant: 'error' });
    } 
  };

  if(!docContractuels) return <></>;

  return (
    <Grid item xs={12} className={classes.boxAction}>
      <div className={classes.filesWrapper}>
      {
          docContractuels.map((doc, index) => (
            <Box key={index}>
              <div className={classes.fileWrapper}>
                <div
                  className={classes.file}
                  onClick={() => downloadFile(doc)}
                >
                  <Icon
                    className="fas fa-download"
                    classes={{ root: classes.pdfIcon }}
                  />
                  <Typography>{t(DOC_CONTRACTUEL[doc.codeDocument] ? DOC_CONTRACTUEL[doc.codeDocument].label : doc.labelDefault)}</Typography>
                </div>
              </div>
            </Box>
          ))
        }
      </div>
    </Grid>
  );
}

export default GuaranteeDocument;
