import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  Box,
  CardContent,
  CardHeader,
  CardActions,
  Typography,
  Container,
} from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Icon from 'components/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { CheckCircle } from '@material-ui/icons';
import Lottie from 'components/Lottie';
import NextStepButton from 'components/NextStepButton/NextStepButton';
import { isEmpty } from '../../utils/form-validation';
import './QuestionsStep.scss';
import useStyles from './style';

const QuestionsStep = ({
  propertyKey,
  choices,
  alternateChoices,
  allowMultiple,
  onSelect,
  onConfirm,
  invalid,
  emptyHelperText,
  getText,
  text,
  onItemClick,
  value,
}) => {
  const [fade, setFade] = useState(false);
  const allChoices = [...choices, ...alternateChoices];
  const [openTooltips, setOpenTooltips] = useState({});
  const [selectedValues, setSelectedValues] = useState(value);
  const classes = useStyles();

  const handleTooltipClose = (event, choice) => {
    event.stopPropagation();
    const newOpenTooltips = { ...openTooltips };
    newOpenTooltips[choice.value] = false;
    setOpenTooltips(newOpenTooltips);
  };

  const handleTooltipOpen = (event, choice) => {
    event.stopPropagation();
    const newOpenTooltips = {};
    newOpenTooltips[choice.value] = true;
    setOpenTooltips(newOpenTooltips);
  };

  useEffect(() => {
    setTimeout(() => {
      setFade(true);
    }, 10);

    return () => {
      setFade(false);
    };
  }, []);

  useEffect(() => {
    if (propertyKey) {
      onSelect(propertyKey, selectedValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValues]);

  const setMultipleValues = (choice) => {
    let newSelectedValues = [];

    if (selectedValues.includes(choice.value)) {
      newSelectedValues = selectedValues.filter(
        (selectedValue) => selectedValue !== choice.value
      );
    } else if (alternateChoices.includes(choice)) {
      const valuesNotInAlternate = selectedValues.reduce(
        (acc, selectedValue) => {
          const isInAlternate = alternateChoices.find(
            (alternateChoice) => alternateChoice.value === selectedValue
          );
          if (!isInAlternate) return [...acc, selectedValue];
          else return acc;
        },
        []
      );
      newSelectedValues = [...valuesNotInAlternate, choice.value];
    } else {
      newSelectedValues = [...selectedValues, choice.value];
    }
    setSelectedValues(newSelectedValues);
  };

  const setSingleValue = (choice) => {
    if (selectedValues === choice.value) setSelectedValues(null);
    else if (onItemClick && propertyKey) {
      onItemClick(propertyKey, choice.value);
    } else setSelectedValues(choice.value);
  };

  const handleClick = (choice) => {
    if (allowMultiple) {
      setMultipleValues(choice);
    } else {
      setSingleValue(choice);
    }
  };

  const isSelected = (choice) => {
    if (Array.isArray(value)) {
      return value.includes(choice.value);
    } else return value === choice.value;
  };

  return (
    <Container maxWidth="md" className="QuestionsStep">
      <Grid container alignItems="center" justifyContent="center">
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          spacing={3}
          item
          xs={12}
          sm={10}
        >
          {allChoices.map((choice, i) => {
            return (
              <Grid
                key={i}
                item
                xs={6}
                md={allChoices.length > 3 ? 3 : 4}
                className={`QuestionsStep_Choice ${fade ? 'fade' : ''}`}
                align="center"
              >
                <div
                  className={`${classes.container} ${
                    choice.ribbon && classes.hiddenOverflow
                  }`}
                >
                  {choice.ribbon && (
                    <div className={classes.ribbon}>
                      <Typography
                        className={classes.ribbonText}
                        variant="body2"
                      >
                        {choice.ribbon}
                      </Typography>
                    </div>
                  )}
                  <Card
                    variant="outlined"
                    className={`QuestionsStep__Card ${
                      allChoices.length >= 6 ? 'small' : ''
                    }`}
                    classes={{
                      root: isSelected(choice)
                        ? `selected ${classes.card}`
                        : classes.card,
                    }}
                    onClick={
                      choice.disabled
                        ? null
                        : () => {
                            handleClick(choice);
                          }
                    }
                  >
                    <CardHeader
                      classes={{ root: classes.cardHeader }}
                      action={
                        <CheckCircle
                          color="primary"
                          style={{
                            opacity: isSelected(choice) ? 1 : 0,
                          }}
                        />
                      }
                    />
                    <CardContent
                      classes={{ root: classes.cardContent }}
                      className={`${
                        choice.asset === undefined
                          ? 'QuestionsStep__Card__content--no-asset'
                          : 'QuestionsStep__Card__content--asset'
                      }`}
                    >
                      {choice.asset && <Lottie animationData={choice.asset} />}
                      {choice.asset === undefined && (
                        <Typography
                          align="center"
                          variant={choice.asset ? 'body1' : 'h6'}
                        >
                          {choice.label}
                        </Typography>
                      )}
                      {choice.info && (
                        <ClickAwayListener
                          onClickAway={(event) =>
                            handleTooltipClose(event, choice)
                          }
                        >
                          <Tooltip
                            arrow
                            open={!!openTooltips[choice.value]}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title={choice.info}
                          >
                            <IconButton
                              onClick={(event) =>
                                handleTooltipOpen(event, choice)
                              }
                              classes={{ root: classes.infoButton }}
                            >
                              <Icon
                                className="fas fa-info-circle"
                                classes={{ root: classes.infoIcon }}
                              />
                            </IconButton>
                          </Tooltip>
                        </ClickAwayListener>
                      )}
                    </CardContent>
                    {choice.asset && (
                      <CardActions
                        className={`${
                          isSelected(choice) ? 'selected' : ''
                        } QuestionsStep__Card__title`}
                      >
                        <Typography align="center" variant="body1">
                          {choice.label}
                        </Typography>
                      </CardActions>
                    )}
                  </Card>
                </div>
              </Grid>
            );
          })}
        </Grid>
        {emptyHelperText && (
          <Grid
            container
            justifyContent="center"
            className={classes.emptyHelperText}
            style={{ visibility: isEmpty(value) ? 'visible' : 'hidden' }}
          >
            <Box mt={3} classes={{ root: classes.captionWrapper }}>
              <Typography variant="caption" classes={{ root: classes.caption }}>
                {emptyHelperText}
              </Typography>
            </Box>
          </Grid>
        )}
        {getText && (
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={3}
          >
            <Grid container alignItems="center" justifyContent="center">
              <Box mt={5}>{getText(selectedValues)}</Box>
            </Grid>
          </Grid>
        )}
      </Grid>
      <Box mt={2}>
        {text && <Typography variant="caption">{text}</Typography>}
        <NextStepButton onClick={onConfirm} disabled={invalid} />
      </Box>
    </Container>
  );
};

QuestionsStep.propTypes = {
  alternateChoices: PropTypes.array,
  propertyKey: PropTypes.string.isRequired,
  getText: PropTypes.func,
};

QuestionsStep.defaultProps = {
  alternateChoices: [],
};
export default QuestionsStep;
