import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import InputsStep from 'components/InputsStep/InputsStep';
import Title from 'components/Title/index';
import { isEmpty } from 'utils/form-validation';

const MoreInformationsStep = (props) => {
  const { data, onConfirm, setUserData } = props;
  const { contractAddress } = data;
  const { t } = useTranslation();

  const fields = [
    {
      id: 'contractAddress.appartementNumber',
      label: t('pno.moreInformationsStep.apartmentNumber'),
      type: 'number',
      inputProps: { min: 0 },
      invalid: isEmpty(contractAddress.appartementNumber) && isEmpty(contractAddress.emplacement),
      helperText: (() => {
        if (isEmpty(contractAddress.appartementNumber) && isEmpty(contractAddress.emplacement)) {
          return t('error.fields.fieldRequired');
        }
      })(),
      value: contractAddress.appartementNumber
        || '',
    },
    {
      id: 'contractAddress.emplacement',
      label: t('pno.moreInformationsStep.location'),
      type: 'text',
      invalid: isEmpty(contractAddress.emplacement) && isEmpty(contractAddress.appartementNumber),
      helperText: (() => {
        if (isEmpty(contractAddress.emplacement) && isEmpty(contractAddress.appartementNumber)) {
          return t('error.fields.fieldRequired');
        }
      })(),
      value: contractAddress.emplacement || ''
    },
  ];

  const secondaryFields = [
    {
      id: 'contractAddress.residency',
      label: t('pno.moreInformationsStep.residenceName'),
      type: 'text',
      value: contractAddress.residency || '',
    },
    {
      id: 'contractAddress.building',
      label: t('pno.moreInformationsStep.buildingNumber'),
      type: 'text',
      value: contractAddress.building || '',
    },
  ];

  return (
    <>
      <Title
        title={t('pno.moreInformationsStep.title')}
        align='left'
        maxWidth={570}
      />
      <InputsStep
        onChange={setUserData}
        onConfirm={onConfirm}
        twoPerLine={true}
        fields={fields}
        fieldsTitle={t('pno.moreInformationsStep.firstLineTitle')}
        secondaryFields={secondaryFields}
        secondaryFieldsTitle={t('pno.moreInformationsStep.secondLineTitle')}
      />
    </>
  );
};

MoreInformationsStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
};

export default MoreInformationsStep;
