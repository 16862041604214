import React from 'react';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from '@material-ui/core';
import { Delete, Panorama } from '@material-ui/icons';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import './FileItem.scss';

const useStyles = makeStyles((theme) => ({
  header: {
    paddingBottom: 0,
    position: 'absolute',
    width: '100%',
  },
  button: {
    right: 16,
  },
  icon: {
    color: theme.palette.red.main,
  },
}));

const FileItem = ({ name = '', onRemove }) => {
  const classes = useStyles();

  const StyledCard = withStyles({
    root: {
      textAlign: 'center',
      border: '1px dashed #B3BFD2',
    },
  })(Card);

  const StyledCardContent = withStyles({
    root: {
      padding: '37px 0 30px 0 !important',
    },
  })(CardContent);

  const handleRemove = (event) => {
    event.preventDefault();
    if (!onRemove) return;
    onRemove();
  };

  return (
    <Grid item xs={6} md={4} className="FileItem">
      <label>
        <StyledCard
          variant="outlined"
          onClick={() => {}}
          classes={{
            root: 'valid',
          }}
        >
          <CardHeader
            className={classes.header}
            action={
              <IconButton onClick={handleRemove} className={classes.button}>
                <Delete color="primary" className={classes.icon} />
              </IconButton>
            }
          />
          <StyledCardContent>
            <Grid
              alignItems="center"
              justifyContent="center"
              container
              direction="column"
            >
              <Typography align="center">
                <Panorama style={{ fontSize: 60 }} />
              </Typography>
              <Typography align="center" variant="body1">
                {name.length > 15 ? `${name.substring(0, 15)}...` : name}
              </Typography>
            </Grid>
          </StyledCardContent>
        </StyledCard>
      </label>
    </Grid>
  );
};

export default FileItem;
