import React, { useEffect, useContext } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import queryString from 'query-string';
import { MrhContext } from 'contexts/mrh';
import { PnoContext } from 'contexts/pno';

const FromAnotherSource = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const queryParameters = queryString.parse(search);
  let currentContext;
  let route;

  if (pathname.toLowerCase().includes('/souscription/pno')) {
    currentContext = PnoContext;
    route = '/souscription/pno';
  } else {
    currentContext = MrhContext;
    route = '/souscription/mrh';
  }

  const { dispatch } = useContext(currentContext);

  const { from } = useParams();

  const setData = (key, value, action) => {
    dispatch({
      type: action,
      payload: {
        key,
        value,
      },
    });
  };

  useEffect(() => {
    const { info, codepromo } = queryParameters;
    if (codepromo) {
      setData('promotionCode', codepromo, 'SET_DATA');
    } else if (info) {
      const objectInfo = JSON.parse(info);
      // %7B"contractAddress"%3A%7B"address1"%3A"1%20Rue%20Marcel"%2C"address2"%3A""%2C"zipCode"%3A"94100"%2C"city"%3A"Saint-Maur-des-Fossés"%7D%7D

      if (objectInfo.promotionCode) {
        setData('promotionCode', objectInfo.promotionCode, 'SET_DATA');
      }
    }
    if (from) {
      setData('from', from, 'SET_DATA');

      history.push(route);
    }
  }, []);

  return <></>;
};

export default FromAnotherSource;
