import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    borderBottom: '1px solid #e0e6ed',
  },
  previousText: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
    borderRadius: 4,
  },
  popoverLabel: {
    fontSize: 12,
  },
  headerRight: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
  },
  resetButton: {
    padding: 6,
    marginRight: 10,
  },
  menuItem: {
    cursor: 'pointer',
    color: theme.palette.secondary.main,
  },
  userNameMenuItem: {
    borderBottom: `1px solid ${theme.palette.grey.main}`,
    fontWeight: 'bold',
  },
  login: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 600,
    margin: 'auto',
    paddingTop: 30,
    paddingBottom: 30,
  },
  stepHeader: {
    marginBottom: 20,
  },
}));

export default useStyles;
