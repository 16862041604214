import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { LANGUAGES } from 'constants';
import {
  Typography,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Slider,
  Icon,
} from '@material-ui/core';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Fade from '@material-ui/core/Fade';
import Fab from '@material-ui/core/Fab';
import GarantiesImage from 'assets/images/garanties.svg';
import HighValueImage from 'assets/images/high_value.svg';
import LowValueImage from 'assets/images/low_value.svg';
import MediumValueImage from 'assets/images/medium_value.svg';
import SuperHighValueImage from 'assets/images/superhigh_value.svg';
import Advantages from 'components/Advantages';
import Guarantees from 'components/Guarantees';
import Price from 'components/Price';
import Swiper from 'components/Swiper';
import performantGuaranteesList from 'constants/performantGuarantees/mrh';
import guaranteesList from 'constants/guarantees/mrh';
import { MrhContext } from 'contexts/mrh';
import {
  firstLetterUppercase,
  formatNumberWithoutDecimal,
  formatNumberWithoutSpace,
} from 'utils';
import _get from 'lodash/get';
import { useSnackbar } from 'notistack';
import BienAssureInfo from './BienAssureInfo';
import DeductibleSelector from './DeductibleSelector';
import OutdoorFacilitiesSelector from './OutdoorFacilitiesSelector';
import './QuoteStep.scss';
import SplittingSelector from './SplittingSelector/index';
import useStyles from './style';
import { MRH_CODE_RISQUE } from 'constants/index';

const formatMovableAmountOptions = (options) => {
  return options.map((option) => formatMovableAmountOption(option));
};

const formatMovableAmountOption = (option) => {
  return {
    value: formatNumberWithoutSpace(option.label),
    label: `${formatNumberWithoutDecimal(option.label)} €`,
    code: option.code,
  };
};

const findRating = (
  ratings,
  movableCapitalAmount,
  deductible,
  outdoorFacilities
) => {
  return ratings.find(
    (rating) =>
      rating.movableCapitalAmount.code === movableCapitalAmount.code &&
      rating.deductible.code === deductible.code &&
      rating.landscaping.code === outdoorFacilities.code
  );
};

const QuoteStepDetails = (props) => {
  const { data, rating, onConfirm, stay, parentRef } = props;
  const { t } = useTranslation();
  const topRef = React.useRef(null);

  const splittingPeriodicities = [
    { code: 'M', label: t('steps.quoteStep.everyMonth') },
    { code: 'A', label: t('steps.quoteStep.everyYear') },
  ];

  const { tarifPropose, tarifDeBase } = rating;
  const { proposition, ratings } = tarifPropose;

  let propositionDeBase = null;
  let ratingsDeBase = null;

  if (tarifDeBase) {
    propositionDeBase = tarifDeBase.proposition;
    ratingsDeBase = tarifDeBase.ratings;
  }

  const [periodicity, setPeriodicity] = useState(
    data.paymentSplitting || splittingPeriodicities[0]
  );

  const [ratingCurrent, setRatingCurrent] = useState(tarifPropose);

  const theme = useTheme();
  const isDownXs = useMediaQuery(theme.breakpoints.down('xs'));

  const landScapingOptions = ratingCurrent.options.landscapings.map(
    (landscaping) => {
      let price = null;
      if (landscaping.code === 'WITHOUT_SWIM') {
        price = { monthlyTTC: '5.00', annualTTC: '60.00' };
      } else if (landscaping.code === 'WITH_SWIM') {
        price = {
          monthlyTTC: '8.00',
          annualTTC: '96.00',
        };
      }
      return { ...landscaping, price };
    }
  );

  const options = {
    ...ratingCurrent.options,
    landscapings: landScapingOptions,
  };

  const defaultPrice = {
    annualTTC: proposition.annualTtcWithSurtaxe,
    monthlyTTC: proposition.monthlyTTC,
    annualTTCDeBase: propositionDeBase
      ? propositionDeBase.annualTtcWithSurtaxe
      : null,
    monthlyTTCDeBase: propositionDeBase ? propositionDeBase.monthlyTTC : null,
    annualSurtaxe: proposition.annualSurtaxe,
  };

  const [movableCapitalAmount, setMovableCapitalAmount] = useState(
    data.movableCapitalAmount || ratingCurrent.proposition.movableCapitalAmount
  );

  const showOutdoorFacilitiesOptions = landScapingOptions.find(
    (option) => option.code !== 'NONE'
  );

  const [deductible, setDeductible] = useState(
    data.deductible || ratingCurrent.proposition.deductible
  );

  const [outdoorFacilities, setOutdoorFacilities] = useState(
    data.landscaping || ratingCurrent.proposition.landscaping
  );

  const [summaryActive, setSummaryActive] = useState(false);
  const [goToTopActive, setGoToTopActive] = useState(false);
  const [price, setPrice] = useState(defaultPrice);
  const { dispatch } = useContext(MrhContext);
  const [valuableObjectAmount, setValuableObjectAmount] = useState(
    data.valuableObjectAmount || ratingCurrent.proposition.valuableObjectAmount
  );

  const classes = useStyles();

  const handleScroll = () => {
    const activate =
      parentRef.current.scrollTop > parentRef.current.offsetHeight * 0.35;
    setSummaryActive(activate);
    setGoToTopActive(activate);
  };

  useEffect(() => {
    const currentRef = parentRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
      return () => {
        currentRef.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    dispatch({
      type: 'SET_DATA',
      payload: {
        key: 'price',
        value: {
          annualTTC: price.annualTTC,
          monthlyTTC: price.monthlyTTC,
          annualTTCDeBase: price.annualTTCDeBase,
          monthlyTTCDeBase: price.monthlyTTCDeBase,
          annualSurtaxe: price.annualSurtaxe,
        },
      },
    });
  }, [price]);

  useEffect(() => {
    dispatch({
      type: 'SET_DATA',
      payload: {
        key: 'deductible',
        value: deductible,
      },
    });
  }, [deductible]);

  useEffect(() => {
    dispatch({
      type: 'SET_DATA',
      payload: {
        key: 'movableCapitalAmount',
        value: movableCapitalAmount,
      },
    });
  }, [movableCapitalAmount]);

  useEffect(() => {
    dispatch({
      type: 'SET_DATA',
      payload: {
        key: 'valuableObjectAmount',
        value: valuableObjectAmount,
      },
    });
  }, [valuableObjectAmount]);

  useEffect(() => {
    dispatch({
      type: 'SET_DATA',
      payload: {
        key: 'landscaping',
        value: outdoorFacilities,
      },
    });
  }, [outdoorFacilities]);

  useEffect(() => {
    dispatch({
      type: 'SET_DATA',
      payload: {
        key: 'paymentSplitting',
        value: periodicity,
      },
    });
  }, [periodicity]);

  useEffect(() => {
    handlePriceChange();
  }, [movableCapitalAmount, deductible, outdoorFacilities]);

  const goToStep = (stepId) => {
    dispatch({
      type: 'SET_STEP',
      payload: { stepId, skipSteps: true },
    });
  };

  const handleDeductibleChange = (newDeductible) => {
    setDeductible(newDeductible);
  };

  const handleOutdoorFacilitiesChange = (newFacility) => {
    setOutdoorFacilities(newFacility);
  };

  const handleMovableCapitalAmountChange = (newValue) => {
    const newAmount = options.movableCapitalAmounts.find((option) => {
      return parseInt(option.label) === newValue;
    });

    if (newAmount) {
      setMovableCapitalAmount(newAmount);
      const newRating = findRating(
        ratingCurrent.ratings,
        newAmount,
        deductible,
        outdoorFacilities
      );
      setValuableObjectAmount(newRating.valuableObjectAmount);
    }
  };

  const handlePriceChange = () => {
    const matchingRating = findRating(
      ratings,
      movableCapitalAmount,
      deductible,
      outdoorFacilities
    );
    const matchingRatingDeBase = ratingsDeBase
      ? findRating(
          ratingsDeBase,
          movableCapitalAmount,
          deductible,
          outdoorFacilities
        )
      : null;

    if (matchingRating) {
      const newPrice = {
        annualTTC: matchingRating.annualTtcWithSurtaxe,
        monthlyTTC: matchingRating.monthlyTTC,
        annualTTCDeBase: matchingRatingDeBase
          ? matchingRatingDeBase.annualTtcWithSurtaxe
          : null,
        monthlyTTCDeBase: matchingRatingDeBase
          ? matchingRatingDeBase.monthlyTTC
          : null,
        annualSurtaxe: matchingRating.annualSurtaxe,
      };

      setPrice(newPrice);
      dispatch({
        type: 'SET_DATA',

        payload: { key: 'riskRate', value: matchingRating.riskRate },
      });
    }
  };

  const getValueImage = ({ code }) => {
    const QUALITY_VALUE_IMAGE = {
      BASE: LowValueImage,
      PLUS20: MediumValueImage,
      PLUS40: HighValueImage,
      PLUS50: SuperHighValueImage,
    };

    if (!Object.keys(QUALITY_VALUE_IMAGE).includes(code)) return LowValueImage;
    return QUALITY_VALUE_IMAGE[code];
  };

  const personalizeRef = React.useRef(null);
  const guaranteesRef = React.useRef(null);

  const scrollTo = (ref) =>
    ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });

  const handleGoToTop = () => {
    topRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  const getMinValue = (data) => {
    return data.reduce((min, item) => {
      const value = parseInt(item.label);
      return value < min ? value : min;
    }, 1000000000);
  };

  const getMaxValue = (data) => {
    return data.reduce((max, item) => {
      const value = parseInt(item.label);
      return value > max ? value : max;
    }, 0);
  };

  const getDefaultSlide = () =>
    options.movableCapitalAmounts.findIndex(
      (amount) => amount.code === movableCapitalAmount.code
    );

  return (
    <Grid className="EndStep" ref={topRef}>
      <Fade in={goToTopActive}>
        <Fab
          color="primary"
          aria-label="add"
          classes={{ root: classes.goToTop }}
          onClick={handleGoToTop}
        >
          <Icon className="fas fa-arrow-up" />
        </Fab>
      </Fade>
      <div
        className={`EndStep_Summary_Fixed ${
          summaryActive ? 'active' : window.innerWidth < 960 ? 'active' : ''
        }`}
      >
        <Container maxWidth="md">
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
              classes={{ root: classes.container }}
            >
              <Price
                price={price}
                size="small"
                periodicity={periodicity.code}
              />
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="subtitle2" color="textPrimary">
                {t('steps.quoteStep.valueOfMyGoods')}
              </Typography>
              <Typography variant="h6" color="primary">
                {`${formatNumberWithoutDecimal(movableCapitalAmount.label)} €`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="subtitle2" color="textPrimary">
                {t('steps.quoteStep.myDeductible')}
              </Typography>
              <Typography variant="h6" color="primary">
                {`${formatNumberWithoutDecimal(deductible.label)} €`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={2}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {!stay && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={onConfirm}
                  endIcon={<ArrowForwardIcon />}
                >
                  {firstLetterUppercase(t('common.continue'))}
                </Button>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="EndStep_Hero">
        <Container maxWidth="md">
          <Grid container alignItems="center">
            <Grid
              container
              direction="column"
              xs={12}
              md={6}
              item
              justifyContent="center"
            >
              <span>
                <Typography variant="h3" color="textPrimary" component="span">
                  {`${t('steps.quoteStep.thankYou')} `}
                </Typography>
                <Typography variant="h3" color="primary" component="span">
                  {`${data.contact.firstName}`},
                </Typography>
              </span>
              <br />
              <BienAssureInfo data={data} goToStep={goToStep}>
                <Typography variant="h6" color="textPrimary" component="span">
                  {`${t('steps.quoteStep.bestOffer')} `}
                </Typography>
              </BienAssureInfo>
              {!isDownXs && (
                <div className={classes.refsButtonWrapper}>
                  <Button
                    className={classes.configureOfferButton}
                    variant="outlined"
                    color="secondary"
                    endIcon={<ArrowDownwardIcon />}
                    onClick={() => {
                      scrollTo(personalizeRef);
                    }}
                  >
                    {t('steps.quoteStep.personizeYourOffer')}
                  </Button>
                  <Button
                    className={classes.configureOfferButton}
                    variant="outlined"
                    color="secondary"
                    endIcon={<ArrowDownwardIcon />}
                    onClick={() => {
                      scrollTo(guaranteesRef);
                    }}
                  >
                    {t('common.discoverYourGuarantees')}
                  </Button>
                </div>
              )}
            </Grid>
            <Grid
              container
              item
              xs={12}
              md={6}
              alignItems="center"
              justifyContent="flex-end"
            >
              <div>
                <Card className="EndStep_Hero_CardPrice">
                  <CardContent>
                    <Price
                      price={price}
                      size="big"
                      periodicity={periodicity.code}
                    />
                    <div className="EndStep_Hero_CardPrice_AddressCta">
                      <Typography variant="body1">
                        {t('steps.quoteStep.forYourLodging')}
                      </Typography>
                      <Typography
                        variant="h6"
                        component="div"
                        className="EndStep_Hero_CardPrice_AddressCta_Address"
                      >
                        {`${_get(data, 'contractAddress.address1', '')} ${_get(
                          data,
                          'contractAddress.address2',
                          ''
                        )} à ${_get(data, 'contractAddress.city', '')}`}
                      </Typography>
                    </div>
                  </CardContent>
                </Card>
              </div>
            </Grid>
            <Button
              variant="outlined"
              color="secondary"
              endIcon={<ArrowDownwardIcon />}
              className={classes.configureOfferButtonMobile}
              onClick={() => {
                scrollTo(personalizeRef);
              }}
            >
              {t('steps.quoteStep.personizeYourOffer')}
            </Button>
          </Grid>
        </Container>
      </div>
      <Grid
        ref={personalizeRef}
        className="EndStep_Content"
        alignItems="center"
        justifyContent="center"
        container
        direction="column"
      >
        <Grid
          item
          xs={10}
          className="EndStep_Content_Value"
          container
          justifyContent="center"
        >
          <Typography variant="h4" color="textPrimary" align="center">
            {t('steps.quoteStep.propertyToInsure')}
          </Typography>

          <Grid item xs={12} lg={10} container justifyContent="center">
            <div className={classes.slider}>
              <img
                src={getValueImage(movableCapitalAmount)}
                alt="Salon télé"
                className="EndStep_Content_Value_Image"
              />
              <Slider
                className="EndStep_Content_Value_Slider"
                marks={formatMovableAmountOptions(
                  options.movableCapitalAmounts
                )}
                min={getMinValue(options.movableCapitalAmounts)}
                max={getMaxValue(options.movableCapitalAmounts)} //{options.movableCapitalAmounts.length}
                value={
                  parseInt(movableCapitalAmount.label)
                  // getMinValue(options.movableCapitalAmounts)
                }
                valueLabelFormat={(label) => formatNumberWithoutDecimal(label)}
                step={null}
                onChange={(event, newValue) => {
                  handleMovableCapitalAmountChange(newValue);
                }}
                valueLabelDisplay="off"
                aria-labelledby="discrete-slider-custom"
              />

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                <Typography>
                  <Typography variant="body1" color="primary" component="span">
                    {`${formatNumberWithoutDecimal(
                      movableCapitalAmount.label
                    )} € `}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    component="span"
                  >
                    {t('steps.quoteStep.propertyList')}
                  </Typography>
                </Typography>

                <Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    component="span"
                  >
                    {i18next.resolvedLanguage === LANGUAGES.en && 'and '}
                    {i18next.resolvedLanguage === LANGUAGES.fr && 'et '}
                  </Typography>
                  <Typography variant="body1" color="primary" component="span">
                    {`${formatNumberWithoutDecimal(
                      valuableObjectAmount.label
                    )} € `}
                  </Typography>
                  <Typography
                    variant="body1"
                    color="textPrimary"
                    component="span"
                  >
                    {t('steps.quoteStep.valuableList')}
                  </Typography>
                </Typography>
              </div>
            </div>
            {options.movableCapitalAmounts && (
              <Swiper
                className={classes.swiper}
                defaultSlide={getDefaultSlide()}
                onSlideChange={(slideIndex) => {
                  handleMovableCapitalAmountChange(
                    parseInt(options.movableCapitalAmounts[slideIndex].label)
                  );
                }}
              >
                {options.movableCapitalAmounts.map((amount) => {
                  return (
                    <div key={amount.code} className={classes.slide}>
                      <img
                        src={getValueImage(amount)}
                        alt="Salon télé"
                        className="EndStep_Content_Value_Image"
                      />
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '100%',
                        }}
                      >
                        <Typography variant="h6" color="primary" component="p">
                          {`${formatNumberWithoutDecimal(amount.label)} € `}
                        </Typography>
                        <Typography
                          variant="body1"
                          color="textPrimary"
                          component="span"
                        >
                          {t('steps.quoteStep.propertyList')}
                        </Typography>

                        <Typography>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                            component="span"
                          >
                            {i18next.resolvedLanguage === LANGUAGES.en &&
                              'and '}
                            {i18next.resolvedLanguage === LANGUAGES.fr && 'et '}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="primary"
                            component="span"
                          >
                            {`${formatNumberWithoutDecimal(
                              valuableObjectAmount.label
                            )} € `}
                          </Typography>
                          <Typography
                            variant="body1"
                            color="textPrimary"
                            component="span"
                          >
                            {t('steps.quoteStep.valuableList')}
                          </Typography>
                        </Typography>
                      </div>
                    </div>
                  );
                })}
              </Swiper>
            )}
          </Grid>
          <DeductibleSelector
            options={options.deductibles}
            deductible={deductible}
            onChange={handleDeductibleChange}
          />
          {showOutdoorFacilitiesOptions && (
            <OutdoorFacilitiesSelector
              options={options.landscapings}
              selectedOption={outdoorFacilities}
              onChange={handleOutdoorFacilitiesChange}
              periodicity={periodicity.code}
            />
          )}
          <SplittingSelector
            options={splittingPeriodicities}
            periodicity={periodicity}
            onChange={setPeriodicity}
          />
        </Grid>

        <div ref={guaranteesRef} className="EndStep_Content_Guarantees">
          <Guarantees
            guarantees={guaranteesList(t)}
            codeTarif={data?.riskRate?.code}
            codeRisque={MRH_CODE_RISQUE}
          />
        </div>
        <div className="EndStep_Content_Advantages">
          <Advantages
            image={GarantiesImage}
            title={t('common.assurimoAdvantages')}
            guarantees={performantGuaranteesList(t)}
            lg={4}
          />
        </div>
      </Grid>
    </Grid>
  );
};

QuoteStepDetails.propTypes = {
  data: PropTypes.object.isRequired,
  rating: PropTypes.object.isRequired,
  onConfirm: PropTypes.func.isRequired,
  stay: PropTypes.bool,
  parentRef: PropTypes.any,
};

export default QuoteStepDetails;
