import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import AuthErrorPopin from './authErrorPopin.jsx';
import { MrhContext } from 'contexts/mrh';
import { stepsIds } from 'constants/steps/steps';
import Popin from 'components/Popin/index';
import CountdownTimer from 'components/CountdownTimer/index';
import {
  CUSTOMER_HAVE_POLICE_ACTIVE_MAIN_RESIDENCE,
  CUSTOMER_HAVE_POLICE_ACTIVE_SAME_ADDRESS,
  NEED_ADVISOR_STATUS_FAIL,
  NEED_ADVISOR_STATUS_PENDING,
} from 'constants/common/errorCode';

const ErrorPopin = (props) => {
  const { error, handleValidate, firstName } = props;
  console.log('error', error);
  const code = error?.code;
  const { t } = useTranslation();
  const { dispatch } = useContext(MrhContext);
  const [optionsPopin, setOptionsPopin] = useState({
    open: false,
  });

  const goHome = () => history.push('/');

  const goErrorFinscan = () => {
    dispatch({
      type: 'SET_STEP',
      payload: { stepId: stepsIds.ERROR, skipSteps: true },
    });
  };

  const showPopinConfirm = (message, actionClose) => {
    setOptionsPopin({
      open: true,
      title: t('common.popin.confirm_title'),
      onValidate: handleValidate,
      validateButtonLabel: t('common.yes'),
      showCancelButton: true,
      content: (
        <Box margin={3}>
          <Typography variant="body1">{message}</Typography>
        </Box>
      ),
      onCancel: () =>
        handlePopinClose(
          t('steps.paymentLoader.msg_confirm_not_continue'),
          actionClose
        ),
      cancelButtonLabel: t('common.no'),
    });
  };

  const showPopinErrorAuth = (onClose) => {
    setOptionsPopin({
      open: true,
      content: () => <AuthErrorPopin firstName={firstName} />,
      onValidate: onClose,
      showValidateButton: false,
    });
  };

  const handlePopinClose = (msg, actionClose) => {
    setOptionsPopin({
      open: true,
      title: t('common.popin.info_title'),
      onValidate: actionClose,
      showCancelButton: false,
      validateButtonLabel: (
        <>
          {t('common.yes')} {'('}
          <CountdownTimer
            targetDate={new Date().getTime() + 6 * 1000}
            onFinishCountDown={actionClose}
          />
          {')'}
        </>
      ),
      content: (
        <Box margin={3}>
          <Typography variant="body1">{msg}</Typography>
        </Box>
      ),
    });
  };

  if (NEED_ADVISOR_STATUS_PENDING === code) {
    handlePopinClose(
      t('steps.paymentLoader.msg_finscan_pending'),
      goErrorFinscan
    );
  } else if (NEED_ADVISOR_STATUS_FAIL === code) {
    handlePopinClose(t('steps.paymentLoader.msg_finscan_fail'), goErrorFinscan);
  } else if (CUSTOMER_HAVE_POLICE_ACTIVE_SAME_ADDRESS === code) {
    showPopinConfirm(t('steps.paymentLoader.msg_confirm_same_addr'), goHome);
  } else if (CUSTOMER_HAVE_POLICE_ACTIVE_MAIN_RESIDENCE === code) {
    showPopinConfirm(
      t('steps.paymentLoader.msg_confirm_residence_principal'),
      goHome
    );
  } else {
    showPopinErrorAuth(goHome);
  }

  return (
    <Popin
      onClose={() => setOptionsPopin({ open: false })}
      maxWidth="sm"
      {...optionsPopin}
    />
  );
};

ErrorPopin.propTypes = {
  error: PropTypes.shape().isRequired,
  handleValidate: PropTypes.func.isRequired,
  firstName: PropTypes.string.isRequired,
};

export default ErrorPopin;
