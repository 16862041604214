import wideAssistance from './wideAssistance/wideAssistance.html';
import relocationGuaranteed from './relocation/guaranteed.html';
import mortgageLoanGuaranteed from './mortgageLoan/guaranteed.html';
import rescueDamages from './rescueDamages/rescueDamages.html';
import resortGuaranteed from './resort/guaranteed.html';
import rescueFeesGuaranteed from './rescueFees/guaranteed.html';
import contractWithoutCommitment from './contractWithoutCommitment/contractWithoutCommitment.html';

const performantGuaranteesList = (t) => [
  {
    id: 'performantGuaranty1',
    title: t('common.pluses.mrh.wideAssistance.title'),
    description: { __html: wideAssistance },
    categories: [],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
  },
  {
    id: 'performantGuaranty2',
    title: t('common.pluses.mrh.relocationGuaranteed.title'),
    description: {
      __html: relocationGuaranteed,
    },
    categories: [],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
  },
  {
    id: 'performantGuaranty3',
    title: t('common.pluses.mrh.mortgageLoanGuaranteed.title'),
    categories: [],
    description: { __html: mortgageLoanGuaranteed },
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
  },
  {
    id: 'performantGuaranty4',
    title: t('common.pluses.mrh.rescueDamages.title'),
    description: { __html: rescueDamages },
    categories: [],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
  },
  {
    id: 'performantGuaranty5',
    title: t('common.pluses.mrh.resortGuaranteed.title'),
    categories: [],
    description: { __html: resortGuaranteed },
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
  },
  {
    id: 'performantGuaranty6',
    title: t('common.pluses.mrh.rescueFeesGuaranteed.title'),
    categories: [],
    description: { __html: rescueFeesGuaranteed },
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
  },
  {
    id: 'whithoutCommitment',
    title: t('common.pluses.mrh.contractWithoutCommitment.title'),
    categories: [],
    description: { __html: contractWithoutCommitment },
  },
];

export default performantGuaranteesList;
