import React from 'react';
import PropTypes from 'prop-types';

import { CLAIMS_STATUS_CODE } from 'constants/common/sinistreStatusCode';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

SinistreActions.propTypes = {
  sinistre: PropTypes.object,
};

function SinistreActions({ sinistre }) {
  const history = useHistory();
  const { t } = useTranslation();

  const {
    status: { code: statusCode },
    claimformLink,
  } = sinistre;

  const { EC, PREDECLA, PARCOURS } = CLAIMS_STATUS_CODE;

  const onContacterSupportClick = () =>
    history.push({
      pathname: '/contact',
      state: {
        thematique: 'sinistre_decla',
        numeroSinistre: sinistre?.claimsNumber?.toString(),
        numeroPolice: sinistre?.policy?.policyNumber,
        onSuccesBackUrl: '/polices/' + sinistre?.policy?.policyNumber,
      },
    });

  const onRedirigerVersClaims = () => {
    if (claimformLink) window.open(claimformLink, '_self');
  };

  const afficherBtnContactSupport = [
    EC.code,
    PREDECLA.code,
    PARCOURS.code,
  ].includes(statusCode);

  const afficherBtnRedirectionClaims = [PARCOURS.code].includes(statusCode);

  return (
    <>
      {afficherBtnContactSupport && (
        <Button
          style={{ marginTop: 5, width: '100%', cursor: 'pointer' }}
          onClick={onContacterSupportClick}
          color="primary"
          variant={'contained'}
        >
          {t('dashboard.sinistre.list.contacter_support')}
        </Button>
      )}

      {afficherBtnRedirectionClaims && (
        <Button
          style={{ marginTop: 5, width: '100%', cursor: 'pointer' }}
          onClick={onRedirigerVersClaims}
          color="primary"
          variant={'contained'}
        >
          {t('dashboard.sinistre.list.reprise_claimsia')}
        </Button>
      )}
    </>
  );
}

export default SinistreActions;
