import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  radioContainer: {
    border: `1px solid ${theme.palette.grey.main}`,
    marginTop: 10,
    padding: 10,
    width: '100%',
    cursor: 'pointer',
    borderRadius: 10,
    '&:hover': {
      backgroundColor: theme.palette.greyBlue.main,
    },
  },
  radioContainerSelected: {
    backgroundColor: theme.palette.greyBlue.main,
  },
  radioGroup: {
    width: '100%',
  },
  centeredTypography: {
    textAlign: 'center',
  },
  title: {
    fontWeight: 'bold',
  },
}));

export default useStyles;
