import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  useTheme,
  useMediaQuery,
  Grid,
  Typography,
  Button,
  Container,
  Icon,
} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Fab from '@material-ui/core/Fab';

import GarantiesImage from 'assets/images/garanties.svg';
import Guarantees from 'components/Guarantees';
import Advantages from 'components/Advantages';
import guaranteesList from 'constants/guarantees/mrh';
import Price from 'components/Price';
import performantGuaranteesList from 'constants/performantGuarantees/mrh';
import './ResultStep.scss';
import MyGood from './MyGood';
import MySelection from './MySelection';
import MyPricing from './MyPricing';
import MyProfile from './MyProfile';
import useStyles from './style';
import { ASSURIMO_LEGAL_MANSIONS_URL } from 'constants';
import { DOC_CONTRACTUEL, MRH_CODE_RISQUE } from 'constants/index';
import documentContractuelService from 'services/documentContractuelService';
import { UserContext } from 'contexts/user';
import { useSnackbar } from 'notistack';
import { MrhContext } from 'contexts/mrh';

const PERIODICITIES = [
  { code: 'M', label: 'mensuel' },
  { code: 'A', label: 'annuel' },
];

const ResultStep = ({ onConfirm, setUserData, parentRef }) => {
  const [summaryActive, setSummaryActive] = useState(false);
  const [goToTopActive, setGoToTopActive] = useState(false);
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const topRef = React.useRef(null);
  const guaranteesRef = React.useRef(null);
  const { state } = useContext(MrhContext);
  let { data } = state;
  const [userState] = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const scrollTo = (ref) => {
    ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const periodicity = data.paymentSplitting || PERIODICITIES[0];

  const handleScroll = () => {
    const activate =
      parentRef.current.scrollTop > parentRef.current.offsetHeight * 0.35;
    setSummaryActive(activate);
    setGoToTopActive(activate);
  };

  const handleGoToTop = () => {
    topRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  useEffect(() => {
    scrollTo(topRef);
    const currentRef = parentRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
      return () => {
        currentRef.removeEventListener('scroll', handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadDocument = async (codeDocument) => {
    try {
      const params = {
        codeTarif: data?.riskRate?.code,
        codeRisque: MRH_CODE_RISQUE,
      };
      const document = await documentContractuelService.get(
        userState.auth,
        codeDocument,
        params
      );
      await documentContractuelService.downloadFile(
        userState.auth,
        document.id,
        `${document.labelDefault}.pdf`
      );
    } catch (error) {
      console.log(
        'Une erreur est survenue quand on telecharge le document',
        error
      );
      enqueueSnackbar(t('steps.resultStep.error_generate'), {
        variant: 'error',
      });
    }
  };

  const renderLegalMansion = () => {
    return (
      <Typography className={classes.legalMansion}>
        {t('steps.resultStep.clickingSubscribe')}{' '}
        <a
          className={classes.legalMansionLink}
          onClick={() => downloadDocument(DOC_CONTRACTUEL.CG.code)}
        >
          {t('common.generalConditions')}
        </a>
        , {t('steps.resultStep.ofThe')}{' '}
        <a
          className={classes.legalMansionLink}
          onClick={() => downloadDocument(DOC_CONTRACTUEL.IPID.code)}
        >
          {t('common.ipidPage')}
        </a>{' '}
        {t('steps.resultStep.andThe')}{' '}
        {
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            className={classes.legalMansionLink}
            href={ASSURIMO_LEGAL_MANSIONS_URL}
            target="_blank"
          >
            {t('common.legalNotice')}
          </a>
        }
      </Typography>
    );
  };

  const handleConfirm = async () => {
    onConfirm();
  };

  return (
    <Grid ref={topRef} className="EndStep ResultStep">
      <Fade in={goToTopActive}>
        <Fab
          color="primary"
          aria-label="add"
          classes={{ root: classes.goToTop }}
          onClick={handleGoToTop}
        >
          <Icon className="fas fa-arrow-up" />
        </Fab>
      </Fade>
      <div
        className={`EndStep_Summary_Fixed_Result ${
          // eslint-disable-next-line no-nested-ternary
          summaryActive ? 'active' : window.innerWidth < 960 ? 'active' : ''
        }`}
      >
        <Container maxWidth="md">
          <Grid container>
            <Grid
              item
              xs={12}
              md={4}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Price
                price={data.price}
                size="small"
                periodicity={periodicity.code}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={5}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {renderLegalMansion()}
            </Grid>

            <Grid
              item
              xs={12}
              md={3}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {mobile && renderLegalMansion()}
              <br />
              <Button
                variant="contained"
                color="primary"
                onClick={handleConfirm}
                endIcon={<ArrowForward />}
              >
                {t('steps.resultStep.subscribe')}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="EndStep_Hero">
        <Container>
          <Grid
            container
            item
            xs={12}
            spacing={3}
            classes={{ root: classes.title }}
          >
            <Grid item xs={12}>
              <Typography variant="h3" color="textPrimary" component="span">
                {t('steps.resultStep.hereWeAre')}
                {','}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <span>
                <Typography variant="h6" color="textPrimary" component="span">
                  {t('steps.resultStep.hereIs')}{' '}
                </Typography>
                <Typography variant="h6" color="primary" component="span">
                  {t('steps.resultStep.recap')}{' '}
                </Typography>
                <Typography variant="h6" color="textPrimary" component="span">
                  {t('steps.resultStep.insuranceProject')}
                  {'.'}
                </Typography>
              </span>
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={3}
            direction={mobile ? 'column-reverse' : 'row'}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              spacing={3}
              xs={12}
              md={8}
              item
              alignItems="stretch"
              direction={mobile ? 'column' : 'row'}
            >
              <MyGood data={data} />
              <MySelection data={data} />
              <MyProfile data={data} />
            </Grid>
            <Grid
              container
              classes={{ root: classes.pricingContainer }}
              alignContent="center"
              spacing={3}
              xs={12}
              md={4}
              item
            >
              <MyPricing
                data={data}
                setUserData={setUserData}
                onConfirm={onConfirm}
              />
            </Grid>
          </Grid>
          <Button
            variant="outlined"
            color="secondary"
            classes={{ root: classes.guaranteesButton }}
            endIcon={<ArrowDownward />}
            onClick={() => {
              scrollTo(guaranteesRef);
            }}
          >
            {t('steps.resultStep.guaranteesReminder')}
          </Button>
        </Container>
      </div>
      <div ref={guaranteesRef} className="EndStep_Content_Guarantees">
        <Guarantees
          image={GarantiesImage}
          title="Nos garanties"
          guarantees={guaranteesList(t)}
          showButton={false}
          codeTarif={data?.riskRate?.code}
          codeRisque={MRH_CODE_RISQUE}
        />
      </div>
      <div className="EndStep_Content_Advantages">
        <Advantages
          image={GarantiesImage}
          title={t('common.assurimoAdvantages')}
          guarantees={performantGuaranteesList(t)}
          lg={4}
        />
      </div>
    </Grid>
  );
};

ResultStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  parentRef: PropTypes.any,
};

export default ResultStep;
