import { Box, Grid, Typography, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import customerApi from 'api/customerApi';
import { UserContext } from 'contexts/user';
import { useSnackbar } from 'notistack';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import TabPanel from 'components/TabPanel/index';
import CardList from '../components/CardList';
import CardNew from '../components/CardNew';
import Popin from 'components/Popin/index';
import useAuth from 'hooks/useAuth';
import CardSkeleton from 'components/CardSkeleton/index';
import { getMessageError } from 'constants/common/errorCode';

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const TAB_CARD_EXIST_VALUE = 0;
const TAB_CARD_NEW_VALUE = 1;

function ListPage() {
  const { t } = useTranslation();
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const auth = useAuth();
  const [user] = useContext(UserContext);
  const [cards, setCards] = useState([]);
  const [value, setValue] = useState(TAB_CARD_EXIST_VALUE);
  const history = useHistory();

  const [optionsPopin, setOptionsPopin] = useState({ open: false });
  const [loading, setLoading] = useState(false);

  const getData = async (auth) => {
    try {
      setLoading(true);
      const res = await customerApi.listCard(auth, user.customerNumber);
      if (!res || !res.length) handleChangeTab(null, TAB_CARD_NEW_VALUE);
      setCards(res);
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (auth.isAuthenticated) {
        getData(auth);
      }
    })();
  }, [auth.isAuthenticated]);

  const applyMainCard = async (card) => {
    try {
      if (!auth.isAuthenticated) return false;

      await customerApi.setMainCard(
        auth,
        user.customerNumber,
        card.bankcardUuid
      );
      enqueueSnackbar(t('dashboard.card.list.msg_succes'), {
        variant: 'success',
      });

      getData(auth);
    } catch (err) {
      handleError(err);
    } finally {
      setOptionsPopin({ ...optionsPopin, open: false });
    }
  };

  const redirectPolices = () => {
    history.push('/polices');
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleError = (error) => {
    console.error('Error', error);
    const messageError = getMessageError(error);
    enqueueSnackbar(t(messageError), { variant: 'error' });
  }

  return (
    <>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          className={classes.title}
          justify-content="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="h3">{t('dashboard.card.title')}</Typography>
          </Box>
        </Grid>
        <Grid container item xs={12}>
          <Box className={classes.boxContainer}>
            <Box className={classes.boxTab}>
              <Tabs
                value={value}
                onChange={handleChangeTab}
                aria-label={t('dashboard.card.tabs.label')}
                variant="fullWidth"
              >
                <Tab
                  label={
                    <Typography
                      className={classes.textNotTransform}
                      variant="subtitle1"
                    >
                      {t('dashboard.card.tabs.cards')}
                    </Typography>
                  }
                  {...a11yProps(TAB_CARD_EXIST_VALUE)}
                />
                <Tab
                  label={
                    <Typography
                      className={classes.textNotTransform}
                      variant="subtitle1"
                    >
                      {t('dashboard.card.tabs.new_card')}
                    </Typography>
                  }
                  {...a11yProps(TAB_CARD_NEW_VALUE)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={TAB_CARD_EXIST_VALUE}>
              {loading ? (
                <CardSkeleton length={3} />
              ) : (
                <CardList
                  cards={cards}
                  onSetMainCard={applyMainCard}
                  onGotoPolice={redirectPolices}
                  setOptionsPopin={setOptionsPopin}
                />
              )}
            </TabPanel>
            <TabPanel value={value} index={TAB_CARD_NEW_VALUE}>
              <CardNew customerNumber={user.customerNumber} />
            </TabPanel>
          </Box>
        </Grid>
      </Grid>

      <Popin
        title={t('common.popin.confirm_title')}
        validateButtonLabel={t('common.yes')}
        cancelButtonLabel={t('common.no')}
        showCancelButton
        {...optionsPopin}
        onClose={() => setOptionsPopin({ ...optionsPopin, open: false })}
        maxWidth="sm"
      />
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  boxContainer: {
    marginTop: theme.spacing(4),
    width: '100%',
  },
  boxTab: {
    borderBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  textNotTransform: {
    textTransform: 'none',
  },
}));

export default ListPage;
