const responseWithJson = (res) => {
  if (res.ok) return res.json();
  throw new Error(`${res.status} - ${res.statusText}`);
};

const diversApi = {
  async getIframeUrl() {
    const url = '/payment-url';

    const response = await fetch(url);
    return responseWithJson(response);
  },
};

export default diversApi;
