import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import PolicyInfo from 'components/Dashboard/PolicyInfo';
import useStyles from './style';

const ContractSelectionPage = (props) => {
  const { policies, onClick } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <Box
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body1" align="center" className={classes.title}>
          {t('dashboard.contact.contractSelection.title')}
        </Typography>
      </Box>

      <Box
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {policies.map((policy) => {
          return (
            <PolicyInfo
              key={policy.policyNumber}
              policy={policy}
              displayLinkButton={false}
              onClick={onClick}
            />
          );
        })}
      </Box>
    </>
  );
};

ContractSelectionPage.propTypes = {
  policies: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default ContractSelectionPage;
