const useGA = () => {
  const ga = {
    gtag: (title, location, path, gKey) => {
      window.gtag('event', 'page_view', {
        page_title: title,
        page_location: location,
        page_path: path,
        send_to: gKey,
      });
    },
    gtagEvent: (action, { category, label, value }, gKey) => {
      const parameters = {
        event_category: category,
        event_label: label,
        send_to: gKey,
      };

      if (value) parameters.value = value;
      window.gtag('event', action, parameters);
    },
    gtagPurchase: (value, gKey) => {
      window.gtag('event', 'purchase', {
        value: parseFloat(value),
        currency: 'EUR',
        send_to: gKey,
      });
    },
  };
  return ga;
};

export default useGA;
