import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation, Trans } from 'react-i18next';

import PolicyInfo from 'components/Dashboard/PolicyInfo';
import useStyles from './style';

const HSMA = 'HSMA';
const PRIVFONCIA = 'PRIVFONCIA';

const AssistancePage = (props) => {
  const classes = useStyles();
  const { onChange, policy } = props;
  const { t } = useTranslation();

  const getPhoneNumber = () => {
    if (isAssistance24()) {
      if (policy.riskType && policy.riskType.code === HSMA) {
        return t('common.SMAPhoneNumber'); // SMA
      }

      if (
        policy.riskType &&
        policy.riskType.code === PRIVFONCIA &&
        policy.insuranceCompany &&
        policy.insuranceCompany.code === 'X1062'
      ) {
        return t('common.BPCEPhoneNumber'); // BPCE
      }
    } else {
      return t('common.assurimoPhoneNumber');
    }
  };

  const isAssistance24 = () => {
    if (policy.riskType) {
      if (policy.riskType.code === PRIVFONCIA || policy.riskType.code === HSMA)
        return true;
    }

    return false;
  };

  return (
    <>
      <Box
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <PolicyInfo
          key={policy.policyNumber}
          policy={policy}
          displayLinkButton={false}
        />
      </Box>
      <Box
        mt={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Button variant="outlined" color="primary" onClick={onChange}>
          {t('dashboard.sinistre.form.police.button_change')}
        </Button>
      </Box>
      <Box
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {isAssistance24() && (
          <Typography
            classes={{ root: classes.centeredTypography }}
            variant="body1"
          >
            {t('dashboard.contact.24Assistance.contractBenefits')}
          </Typography>
        )}
        <Box m={2}>
          <Typography
            classes={{ root: classes.centeredTypography }}
            variant="h5"
          >
            {getPhoneNumber()}
          </Typography>
        </Box>
        <Box m={2}>
          <Button
            variant="contained"
            color="primary"
            href={`tel:${getPhoneNumber()}`}
          >
            {isAssistance24()
              ? t('dashboard.contact.24Assistance.callAssistance')
              : t('dashboard.contact.assurimoAssistance.callAssurimo')}
          </Button>
        </Box>
        {isAssistance24() && (
          <>
            <Typography
              classes={{ root: classes.centeredTypography }}
              variant="caption"
              style={{ textAlign: 'justify', marginBottom: 10 }}
            >
              <Trans i18nKey="dashboard.contact.24Assistance.assistanceCosts" />
              {policy.riskType.code === HSMA
                ? t('dashboard.contact.24Assistance.smaContract')
                : '.'}
            </Typography>

            <Typography
              classes={{ root: classes.centeredTypography }}
              variant="caption"
              style={{ textAlign: 'justify' }}
            >
              {t('dashboard.contact.24Assistance.goToGeneralConditions')}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
};

AssistancePage.propTypes = {
  policy: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AssistancePage;
