import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import QuestionsStep from 'components/QuestionsStep/QuestionsStep';
import Title from 'components/Title';
import MeubleLottie from 'assets/lotties/meuble.json';
import NonMeubleLottie from 'assets/lotties/non_meuble.json';
import { isEmpty } from 'utils/form-validation';

const FurnishedStep = ({
  data,
  onConfirm,
  setUserData,
  setUserDataAndChangePage,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Title title={t('pno.furnishedStep.title')} />
      <QuestionsStep
        propertyKey="furnished"
        choices={[
          {
            label: t('common.yes'),
            value: true,
            asset: MeubleLottie,
          },
          {
            label: t('common.no'),
            value: false,
            asset: NonMeubleLottie,
          },
        ]}
        onSelect={setUserData}
        onItemClick={setUserDataAndChangePage}
        onConfirm={onConfirm}
        invalid={
          isEmpty(data.furnished) ||
          data.furnished === undefined ||
          data.furnished === null
        }
        value={data.furnished}
      />
    </>
  );
};

FurnishedStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  setUserDataAndChangePage: PropTypes.func.isRequired,
};

export default FurnishedStep;
