import React, { useContext, useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import i18next from 'i18next';
import moment from 'moment';
import 'moment/locale/fr';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';

import GlobalContext from 'contexts/global';
import Icon from 'components/Icon';
import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { useSnackbar } from 'notistack';

import Title from 'components/Title';
import useGa from 'hooks/useGA';
import { formatNumber } from 'utils';
import premiumApi from 'api/premiumApi';

import useStyles from './style';
import {
  PAYMENT_SPLITTING_CODE_ANNUEL,
  PAYMENT_SPLITTING_CODE_MENSUEL,
} from 'constants/index';
import IframePayment from './components/IframePayment';
import PaymentMonthlyConnected from './components/PaymentMonthlyConnected';
import Loading from 'components/Loadding/Loading';
import customerApi from 'api/customerApi';
import { UserContext } from 'contexts/user';
import useAuth from 'hooks/useAuth';
import { getMessageError } from 'constants/common/errorCode';

const codes = {
  TAXE_ATTENTAT: 'TAXE_ATTENTAT',
  ECH_PRORATA: 'ECH_PRORATA',
  ECH_COMPLETE: 'ECH_COMPLETE',
  PROCH_ECH: 'PROCH_ECH',
};

const PaymentStep = ({ onConfirm, premiumUuid, address }) => {
  moment.locale(i18next.resolvedLanguage);
  const { gaPnoSiteKey, gaMrhSiteKey } = useContext(GlobalContext);

  const { authState } = useOktaAuth();
  const { pathname } = useLocation();
  const [success, setSuccess] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { gtagPurchase } = useGa();

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [amountDetails, setAmountDetails] = useState();
  const [open, setOpen] = useState(false);
  const [cards, setCards] = useState([]);
  const [user] = useContext(UserContext);
  const connected = authState.isAuthenticated;
  const auth = useAuth();

  let gaSiteKey;

  if (pathname.toLowerCase().includes('pno')) {
    gaSiteKey = gaPnoSiteKey;
  } else gaSiteKey = gaMrhSiteKey;

  const getPremium = async (auth) => {
    if (premiumUuid) {
      setLoading(true);
      try {
        const jsonResponse = await premiumApi.getAmountDetails(
          auth,
          premiumUuid
        );

        setAmountDetails(jsonResponse);
      } catch (error) {
        console.error('Failed to get Payment infos', error);
        enqueueSnackbar(
          // eslint-disable-next-line
          "Une erreur inattendue s'est produite, veuillez réessayer ultérieurement ou contacter le support Assurimo si le problème persiste",
          { variant: 'error' }
        );
        return Promise.reject();
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getPremium(auth);
  }, [premiumUuid]);

  useEffect(() => {
    if (amountDetails) {
      if (isMonthly) {
        getListCard();
      }
    }
  }, [amountDetails]);

  const getListCard = async () => {
    if (auth.isAuthenticated) {
      try {
        setLoading(true);
        const res = await customerApi.listCard(auth, user.customerNumber);
        const list = res.map((card) => ({
          ...card,
          selected: card.principal && card.valid,
        }));
        setCards(list);
      } catch (err) {
        console.error(err);
        const messageError = getMessageError(error);
        enqueueSnackbar(t(messageError), { variant: 'error' });
      } finally {
        setLoading(false);
      }
    }
  };
  const handleTooltipClose = (event) => {
    event.stopPropagation();
    setOpen(false);
  };

  const handleTooltipOpen = (event) => {
    event.stopPropagation();
    setOpen(true);
  };

  useEffect(() => {
    if (success) onConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [success]);

  const receiveMessage = (event) => {
    const { message, value } = event.data;

    if (message === 'result' && value === 'success') {
      setSuccess(true);
      gtagPurchase(getTotal(), gaSiteKey);
      window.localStorage.removeItem('steps');
    }
  };

  const handleChangeCard = (newList) => {
    setCards(newList);
  };

  const isMonthly = () => {
    return (
      amountDetails.polices[0].fractionnement === PAYMENT_SPLITTING_CODE_MENSUEL
    );
  };

  const isAnnual = () => {
    return (
      amountDetails.polices[0].fractionnement === PAYMENT_SPLITTING_CODE_ANNUEL
    );
  };

  const formatAmount = (amount) => {
    return `${formatNumber(amount)} €`;
  };

  const getTotal = () => {
    return amountDetails.montantTotal;
  };

  const getFirstLineText = (quittance) => {
    const firstLine = getDetailsByCode(codes.ECH_PRORATA, quittance);

    if (isMonthly()) {
      return t('steps.paymentStep.insuranceForMonth', {
        month: moment(firstLine.periode).format('MMMM YYYY'),
      });
    } else if (isAnnual()) {
      return t('steps.paymentStep.insuranceForEndOfYear', {
        year: firstLine.periode,
      });
    }
  };

  const getFirstLineAmount = (quittance) => {
    const firstLine = getDetailsByCode(codes.ECH_PRORATA, quittance);

    return firstLine.montant;
  };

  const getDetailsByCode = (code, quittance) => {
    return quittance.details.find((details) => details.code === code);
  };

  const getSecondDetailsLine = (quittance) => {
    if (isMonthly()) {
      return t('steps.paymentStep.insuranceForMonth', {
        month: moment(
          getDetailsByCode(codes.ECH_COMPLETE, quittance).periode
        ).format('MMMM YYYY'),
      });
    } else if (isAnnual()) {
      return t('steps.paymentStep.insuranceForYear', {
        year: getDetailsByCode(codes.ECH_COMPLETE, quittance).periode,
      });
    } else return null;
  };

  const getSecondDetailsLineValue = (quittance) => {
    return getDetailsByCode(codes.ECH_COMPLETE, quittance).montant;
  };

  const getTaxeAmount = (quittance) => {
    const detailTaxeAttentat = getDetailsByCode(codes.TAXE_ATTENTAT, quittance);

    if (detailTaxeAttentat) {
      return formatAmount(detailTaxeAttentat.montant);
    }
  };

  const getNextPaymentDetailsLine = (quittance) => {
    if (isMonthly())
      return t('steps.paymentStep.insuranceForMonth', {
        month: moment(
          getDetailsByCode(codes.PROCH_ECH, quittance).periode
        ).format('MMMM YYYY'),
      });
    else
      return t('steps.paymentStep.insuranceForYear', {
        year: moment(
          getDetailsByCode(codes.PROCH_ECH, quittance).periode
        ).format('YYYY'),
      });
  };

  const getNextPaymentDetailsValue = (quittance) => {
    return formatAmount(getDetailsByCode(codes.PROCH_ECH, quittance).montant);
  };

  return (
    <>
      <Box align="center" mt={4} mb={4} />

      <Container>
        {loading ? (
          <Loading />
        ) : (
          <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>
              <Title title={t('steps.paymentStep.title')} />
            </div>

            <div className={classes.titleDescription}>
              <Typography display="inline">
                {t('steps.paymentStep.description.insuranceForAccomodation')}
              </Typography>
              <Typography display="inline" variant="body2">
                {' '}
                {address}
              </Typography>
            </div>

            {amountDetails && (
              <div className={classes.contentWrapper}>
                <div className={classes.paymentModule}>
                  {(!connected || !isMonthly()) && (
                    <IframePayment
                      premiumUuid={premiumUuid}
                      isEnrolement={isMonthly()}
                      onMessage={receiveMessage}
                    />
                  )}
                  {connected && isMonthly() && (
                    <PaymentMonthlyConnected
                      premiumUuid={premiumUuid}
                      isEnrolement={isMonthly()}
                      onMessage={receiveMessage}
                      setLoading={setLoading}
                      cards={cards}
                      onChangeCard={handleChangeCard}
                    />
                  )}
                </div>

                {/************************ Total Amount ***********************/}
                <div className={classes.paymentInfos}>
                  <div className={classes.total}>
                    <Typography variant="h5" className={classes.text}>
                      {t('steps.paymentStep.total')}
                    </Typography>
                    {isMonthly() && (
                      <Typography variant="body2" className={classes.text}>
                        {t('steps.paymentStep.firstMonthlyPayment')}
                      </Typography>
                    )}
                    <div className={classes.priceWrapper}>
                      <Typography
                        variant="h2"
                        className={`${classes.text} ${classes.price}`}
                      >
                        {formatAmount(getTotal())}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.details}>
                    <div className={classes.contentTitle}>
                      <Typography variant="h5" className={classes.text}>
                        {t('steps.paymentStep.details')}
                      </Typography>
                    </div>

                    {amountDetails.polices.map((police) => {
                      return (
                        <div key={police.police}>
                          {amountDetails.polices.length > 1 && (
                            <Typography>{police.police}</Typography>
                          )}

                          {police.quittances.map((quittance) => (
                            <div key={quittance.quittance}>
                              {police.quittances.length > 1 && (
                                <Typography>{quittance.quittance}</Typography>
                              )}

                              <Paper
                                elevation={2}
                                className={classes.detailsPaper}
                              >
                                {/************************ First details line ***********************/}
                                {getDetailsByCode(
                                  codes.ECH_PRORATA,
                                  quittance
                                ) && (
                                  <div>
                                    <div className={classes.priceLine}>
                                      <Typography
                                        variant="body2"
                                        className={classes.text}
                                      >
                                        {getFirstLineText(quittance)}
                                      </Typography>
                                      <Typography
                                        variant="body2"
                                        className={classes.text}
                                      >
                                        {formatAmount(
                                          getFirstLineAmount(quittance)
                                        )}
                                      </Typography>
                                    </div>
                                    <Typography
                                      variant="caption"
                                      className={classes.caption}
                                    >
                                      {`${t('steps.paymentStep.from')} ${moment(
                                        getDetailsByCode(
                                          codes.ECH_PRORATA,
                                          quittance
                                        ).dateDebut
                                      ).format('DD MMM yyyy')} ${t(
                                        'steps.paymentStep.to'
                                      )} ${moment(
                                        getDetailsByCode(
                                          codes.ECH_PRORATA,
                                          quittance
                                        ).dateFin
                                      ).format('DD MMM yyyy')}`}
                                    </Typography>
                                  </div>
                                )}

                                {/************************ Second details line ***********************/}
                                {getDetailsByCode(
                                  codes.ECH_COMPLETE,
                                  quittance
                                ) && (
                                  <div className={classes.priceLine}>
                                    <Typography
                                      variant="body2"
                                      className={classes.text}
                                    >
                                      {getSecondDetailsLine(quittance)}
                                    </Typography>
                                    <Typography
                                      variant="body2"
                                      className={classes.text}
                                    >
                                      {formatAmount(
                                        getSecondDetailsLineValue(quittance)
                                      )}
                                    </Typography>
                                  </div>
                                )}

                                {/************************ TAXE_ATTENTAT ***********************/}
                                {getDetailsByCode(
                                  codes.TAXE_ATTENTAT,
                                  quittance
                                ) && (
                                  <>
                                    <div className={classes.priceLine}>
                                      <div className={classes.attack}>
                                        <Typography
                                          variant="body2"
                                          className={classes.text}
                                        >
                                          {t('steps.paymentStep.attackTax')}
                                        </Typography>
                                        <ClickAwayListener
                                          onClickAway={(event) =>
                                            handleTooltipClose(event)
                                          }
                                        >
                                          <Tooltip
                                            classes={{
                                              popper: classes.attackToolTip,
                                            }}
                                            arrow
                                            // placement="top-end"
                                            PopperProps={{
                                              disablePortal: true,
                                            }}
                                            open={open}
                                            disableFocusListener
                                            disableHoverListener
                                            disableTouchListener
                                            title={t(
                                              'steps.paymentStep.attackTaxDescription'
                                            )}
                                          >
                                            <IconButton
                                              onClick={(event) =>
                                                handleTooltipOpen(event)
                                              }
                                              classes={{
                                                root: classes.infoButton,
                                              }}
                                            >
                                              <Icon
                                                className="fas fa-info-circle"
                                                classes={{
                                                  root: classes.infoIcon,
                                                }}
                                              />
                                            </IconButton>
                                          </Tooltip>
                                        </ClickAwayListener>
                                      </div>
                                      <Typography
                                        variant="body2"
                                        className={classes.text}
                                      >
                                        {getTaxeAmount(quittance)}
                                      </Typography>
                                    </div>
                                  </>
                                )}
                              </Paper>
                            </div>
                          ))}
                        </div>
                      );
                    })}
                  </div>

                  {/************************ Prochaine écheance ***********************/}
                  {amountDetails.polices.map((police) => {
                    return police.quittances.map(
                      (quittance) =>
                        getDetailsByCode(codes.PROCH_ECH, quittance) && (
                          <div
                            key={quittance.quittance}
                            className={classes.nextPayment}
                          >
                            <div className={classes.contentTitle}>
                              <Typography variant="h5" className={classes.text}>
                                {t('steps.paymentStep.nextSettlement')}
                              </Typography>
                            </div>
                            <Paper
                              elevation={3}
                              className={classes.nextPaymentPaper}
                            >
                              <div>
                                <div className={classes.priceLine}>
                                  <Typography
                                    variant="body2"
                                    className={classes.text}
                                  >
                                    {getNextPaymentDetailsLine(quittance)}
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    className={classes.text}
                                  >
                                    {getNextPaymentDetailsValue(quittance)}
                                  </Typography>
                                </div>
                                {isMonthly() && (
                                  <Typography
                                    variant="caption"
                                    className={classes.caption}
                                  >
                                    {t('steps.paymentStep.withdrawn')}
                                  </Typography>
                                )}
                              </div>
                            </Paper>
                          </div>
                        )
                    );
                  })}
                </div>
              </div>
            )}
          </div>
        )}
      </Container>
    </>
  );
};

PaymentStep.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  premiumUuid: PropTypes.string,
  address: PropTypes.string,
};

export default PaymentStep;
