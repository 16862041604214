import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import QuestionsStep from 'components/QuestionsStep/QuestionsStep';
import Title from 'components/Title';
import HouseLottie from 'assets/lotties/house.json';
import ApartmentLottie from 'assets/lotties/apartment.json';
import { isEmpty } from 'utils/form-validation';

const PropertyTypeStep = ({
  data,
  onConfirm,
  setUserData,
  setUserDataAndChangePage,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Title title={t('steps.propertyTypeStep.title')} />
      <QuestionsStep
        propertyKey="housingType"
        choices={[
          {
            label: t('common.house'),
            value: 'M',
            asset: HouseLottie,
          },
          {
            label: t('common.apartment'),
            value: 'A',
            asset: ApartmentLottie,
          },
        ]}
        onSelect={setUserData}
        onItemClick={setUserDataAndChangePage}
        onConfirm={onConfirm}
        invalid={isEmpty(data.housingType) || data.housingType.length !== 1}
        value={data.housingType}
      />
    </>
  );
};

PropertyTypeStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  setUserDataAndChangePage: PropTypes.func.isRequired,
};

export default PropertyTypeStep;
