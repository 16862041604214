import ratingApi from 'api/ratingApi';
import { getHousingType } from 'constants/common/bienType';

const fetchRatings = (data) => {
  const params = {
    returnAll: true,
    risk: {
      address: data.contractAddress,
      housingType: getHousingType(data),
      numberPieces: data.numberOfPrincipalRooms,
      furnished: data.furnished,
      area: data.area,
      floor: data.floor,
      equipment: {},
    },
    originalCode: data.from ? data.from : null,
    promotionCode: data.promotionCode ? data.promotionCode : null,
  };

  return ratingApi.getPnoRatings(params);
};

export { fetchRatings };
