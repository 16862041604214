import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    // maxHeight: 440,
  },
  headerCell: {
    fontWeight: 'bolder',
  },
  noResult: {
    padding: theme.spacing(2),
  },
}));

export default useStyles;
