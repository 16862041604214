import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  separator: {
    width: '100%',
    // borderBottom: `1px solid ${theme.palette.grey.main}`,
    marginTop: 40,
    marginBottom: 40,
  },
  titleWrapper: {
    marginBottom: '-60px',
    width: '100%',
  },
}));

export default useStyles;
