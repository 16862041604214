import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { formatNumberWithoutSpace } from 'utils';
import SmallPrice from './SmallPrice';
import ChipPrice from './ChipPrice';
import BigPrice from './BigPrice';
import './index.scss';
import useStyles from './style';
import DashboardPrice from './DashboardPrice';

const Price = ({ price, size, periodicity, namely }) => {
  const classes = useStyles();
  const [priceChanged, setPriceChanged] = useState(null);

  const annualTTC = formatNumberWithoutSpace(price.annualTTC);
  const annualTTCDeBase = formatNumberWithoutSpace(price.annualTTCDeBase);
  const monthlyTTC = price.monthlyTTC
    ? formatNumberWithoutSpace(price.monthlyTTC)
    : null;
  const monthlyTTCDeBase = price.monthlyTTCDeBase
    ? formatNumberWithoutSpace(price.monthlyTTCDeBase)
    : null;

  const isPromo = annualTTCDeBase || monthlyTTCDeBase;

  const formatedPrice = {
    annualTTC,
    monthlyTTC,
    annualTTCDeBase,
    monthlyTTCDeBase,
    annualSurtaxe: price.annualSurtaxe,
  };

  const getPriceParts = () => {
    let parts = null;

    if (periodicity === 'M') parts = monthlyTTC.toString().split('.');
    else if (periodicity === 'A') parts = annualTTC.toString().split('.');

    if (!parts[1]) parts[1] = '00';

    return parts;
  };

  const getPricePromoParts = () => {
    let parts = null;
    if (isPromo) {
      if (periodicity === 'M') {
        parts = monthlyTTC.toString().split('.');
      } else parts = annualTTC.toString().split('.');
    }

    if (parts && !parts[1]) parts[1] = '00';

    return parts;
  };

  useEffect(() => {
    if (priceChanged === null) {
      setPriceChanged(false);
    }
    setPriceChanged(true);
    setTimeout(() => {
      setPriceChanged(false);
    }, 1800);
  }, [price, periodicity]);

  const classBlink = priceChanged ? classes.blink : '';

  if (size === 'chip')
    return (
      <ChipPrice
        priceParts={getPriceParts()}
        pricePromoParts={getPricePromoParts()}
        periodicity={periodicity}
      />
    );
  else if (size === 'big')
    return (
      <BigPrice
        price={formatedPrice}
        priceParts={getPriceParts()}
        pricePromoParts={getPricePromoParts()}
        periodicity={periodicity}
        classBlink={classBlink}
        namely={namely}
      />
    );
  else if (size === 'dashboard')
    return <DashboardPrice price={formatedPrice} periodicity={periodicity} />;
  else
    return (
      <SmallPrice
        price={formatedPrice}
        isPromo={isPromo}
        periodicity={periodicity}
        classBlink={classBlink}
      />
    );
};

Price.propTypes = {
  size: PropTypes.oneOf(['small', 'big', 'chip', 'dashboard']),
  price: PropTypes.shape({
    annualTTC: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    monthlyTTC: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    annualTTCDeBase: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    monthlyTTCDeBase: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    annualSurtaxe: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }).isRequired,
  periodicity: PropTypes.oneOf(['A', 'M']),
  namely: PropTypes.bool,
};

Price.defaultProps = {
  size: 'small',
  periodicity: 'M',
  namely: true,
};

export default Price;
