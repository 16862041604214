import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useTranslation } from 'react-i18next';

import GuaranteeDetail from './components/GuaranteeDetail';
import GuaranteeDocument from './components/GuaranteeDocument';
import GuaranteeList from './components/GuaranteeList';
import GuaranteePopin from './components/GuaranteePopin';
import './index.scss';
import useStyles from './style';

const Guarantees = (props) => {
  const {
    guarantees,
    showButton,
    removePadding,
    hideDocuments,
    typeGuarantee,
    codeTarif,
    codeRisque
  } = props;
  const [showPopin, setShowPopin] = useState(false);
  const [guaranteeToShow, setGuaranteeToShow] = useState(null);
  const { t } = useTranslation();
  const classes = useStyles();

  const [listGuarantees, setListGuarantees] = useState([]);
  const [selectedGuarantee, setSelectedGuarantee] = useState(null);

  useEffect(() => {
    const newList = guarantees.map((item) => ({ ...item, active: false }));
    const selectedIndex = !selectedGuarantee
      ? 0
      : newList.findIndex((item) => item.id === selectedGuarantee.id);
    newList[selectedIndex].active = true;

    setListGuarantees(newList);
    setSelectedGuarantee(newList[selectedIndex]);
  }, [guarantees]);

  const handleClosePopin = () => {
    setShowPopin(false);
  };

  const handleShowPopin = (guarantee) => {
    setGuaranteeToShow(guarantee);
    setShowPopin(true);
  };

  const guaranteesRef = useRef(null);
  const scrollTo = (ref) => {
    ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  useEffect(() => {
    if (guaranteeToShow !== null) setShowPopin(true);
  }, [guaranteeToShow]);

  const handleClick = (guarantee) => {
    const indexOf = listGuarantees.findIndex(
      (item) => item.id === guarantee.id
    );
    const newList = listGuarantees.map((item) => ({ ...item, active: false }));
    newList[indexOf].active = true;
    setListGuarantees(newList);
    setSelectedGuarantee(newList[indexOf]);
  };

  return (
    <>
      <Grid
        container
        spacing={removePadding ? 0 : 3}
        className={removePadding ? null : 'Guarantees_Grid'}
      >
        {showButton && (
          <Button
            variant="outlined"
            color="secondary"
            endIcon={<ArrowDownwardIcon />}
            className={classes.guaranteeButton}
            onClick={() => {
              scrollTo(guaranteesRef);
            }}
          >
            {t('common.discoverYourGuarantees')}
          </Button>
        )}
        <Grid
          item
          container
          ref={guaranteesRef}
          classes={{
            root: removePadding
              ? classes.guaranteeGridListFullWidth
              : classes.guaranteeGridList,
          }}
        >
          <GuaranteeList
            listGuarantees={listGuarantees}
            onClick={handleClick}
            itemSel={selectedGuarantee}
          />
          {selectedGuarantee && (
            <GuaranteeDetail
              item={selectedGuarantee}
              onClick={handleShowPopin}
            />
          )}
          {!hideDocuments && (
            <GuaranteeDocument typeGuarantee={typeGuarantee} codeTarif={codeTarif} codeRisque={codeRisque} />
          )}
        </Grid>
      </Grid>
      {guaranteeToShow && (
        <GuaranteePopin
          open={showPopin}
          onClose={handleClosePopin}
          guarantee={guaranteeToShow}
        />
      )}
    </>
  );
};

Guarantees.propTypes = {
  guarantees: PropTypes.array.isRequired,
  showButton: PropTypes.bool,
  removePadding: PropTypes.bool,
  hideDocuments: PropTypes.bool,
  typeGuarantee: PropTypes.string,
  codeTarif: PropTypes.string,
  codeRisque: PropTypes.string,
};

Guarantees.defaultProps = {
  showButton: true,
  removePadding: false,
  hideDocuments: false,
};

export default Guarantees;
