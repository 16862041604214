import React from 'react';
import Box from '@material-ui/core/Box';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useHistory } from 'react-router-dom';

import Popin from 'components/Popin';
import PaperImage from 'assets/images/paper.svg';
import { formatDate } from 'utils';

const SuccesSansEngag = ({ dateEffetResiliation, isOpen, onClose }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Popin
      open={isOpen}
      onClose={() => {
        onClose();
        history.push('/polices');
      }}
      title={t('dashboard.police.termination.popin_title')}
      content={
        <Box
          style={{
            display: 'flex',
            margin: 32,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 25,
          }}
        >
          <Typography variant="h5">
            {t('dashboard.police.termination.succesDemande_head')}
          </Typography>
          <img
            src={PaperImage}
            alt="Assurimo"
            style={{
              width: 160,
            }}
          />
          <Typography variant="body3" style={{ textAlign: 'center' }}>
            <Trans
              i18nKey="dashboard.police.termination.succesDemande"
              values={{
                dateEffetResil: formatDate(dateEffetResiliation, 'DD/MM/YYYY'),
              }}
              components={{ style: <ResilInfos /> }}
              tOptions={{ interpolation: { escapeValue: false } }}
            />
          </Typography>
        </Box>
      }
      maxWidth="sm"
      useValidateLoader={true}
    />
  );
};

SuccesSansEngag.propTypes = {
  dateEffetResiliation: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ResilInfos = ({ children }) => (
  <Typography
    color={'primary'}
    display={'inline'}
    style={{ fontWeight: 'bold' }}
  >
    {children}
  </Typography>
);

export default SuccesSansEngag;
