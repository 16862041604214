import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/fr';
import { LANGUAGES } from 'constants';
import { formatDate, getStartOfDate } from 'utils';

const DateField = (props) => {
  const { id, defaultValue, variant, value, onChange, ...otherProps } = props;
  const { t } = useTranslation();

  const week = {
    week: { dow: i18next.resolvedLanguage === LANGUAGES.en ? 0 : 1 },
  };
  moment.locale(i18next.resolvedLanguage, week);

  const tomorrow = moment().startOf('day').add(1, 'day');
  const today = moment().startOf('day');

  const formatSelectedDate = (date) => {
    if (!moment(date).isValid()) {
      return '';
    }

    const startOfDay = moment(date).utc(false);

    if (startOfDay.isSame(tomorrow)) return t('common.tomorrow');
    if (startOfDay.isSame(today)) return t('common.today');
    return formatDate(date, 'DD/MM/YYYY');
  };

  const handleChange = (value) => {
    const newDate = getStartOfDate(value);

    onChange({
      target: {
        id,
        value: newDate,
      },
    });
  };

  useEffect(() => {
    if (!value && defaultValue !== undefined) handleChange(defaultValue);
  }, []);

  const renderBirthdatePicker = () => {
    return (
      <KeyboardDatePicker
        disableFuture
        disableToolbar
        variant="inline"
        inputVariant="filled"
        openTo="year"
        className="date-picker-input"
        format="DD/MM/yyyy"
        mask="__/__/____"
        label="Date de naissance"
        views={['year', 'month', 'date']}
        value={value}
        onChange={handleChange}
        {...otherProps}
      />
    );
  };

  const renderDatePicker = () => {
    return (
      <KeyboardDatePicker
        disableToolbar
        variant="inlined"
        inputVariant="filled"
        format="DD/MM/yyyy"
        mask="__/__/____"
        className="date-picker-input"
        invalidDateMessage={''}
        labelFunc={formatSelectedDate}
        onChange={handleChange}
        value={value}
        {...otherProps}
      />
    );
  };

  return (
    <MuiPickersUtilsProvider
      libInstance={moment}
      utils={MomentUtils}
      locale={i18next.resolvedLanguage}
    >
      {variant === 'birthdate' ? renderBirthdatePicker() : renderDatePicker()}
    </MuiPickersUtilsProvider>
  );
};

DateField.propTypes = {
  id: PropTypes.string.isRequired,
  defaultValue: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.object,
  minDateMessage: PropTypes.string,
  variant: PropTypes.oneOf(['birthdate']),
};

export default DateField;
