import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#F2F7FF',
    borderRadius: 10,
    boxSizing: 'border-box',
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: 40,
      height: 40,
      padding: 0,
      minWidth: 'auto',
    },
  },
  buttonIcon: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '-8px',
    },
  },
  visual: {
    width: 58,
    height: 58,
    background: theme.palette.white.main,
    position: 'relative',
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: 48,
      height: 48,
    },
  },
  linkIconContainer: {
    position: 'absolute',
    top: -7,
    right: -7,
    color: theme.palette.white.main,
    background: '#36A6C1',
    borderRadius: '50%',
    width: 20,
    height: 20,
    fontSize: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  linkIcon: {
    width: '1rem',
  },
}));

export default useStyles;
