import { Box, Grid } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

GuaranteeItem.propTypes = {
  item: PropTypes.object,
  onClick: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  item: {
    margin: '0!important',
    display: 'flex',
  },
  boxItem: {
    alignItems: 'center',
    backgroundColor: '#d2e3fa',
    borderRadius: '5px',
    color: '#002b68',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1),
    textAlign: 'center',
    minHeight: '50px',
    flex: 1,

    '&.active': {
      backgroundColor: '#325e9b',
      color: '#fff',
    },
  },
  typography: {
    fontSize: '12px!important',
    fontWeight: 700,
  },
}));

function GuaranteeItem(props) {
  const { item, onClick } = props;
  const classes = useStyles();

  const handleClick = () => {
    onClick && onClick(item);
  };

  return (
    <Grid item xs={12} sm={6} md={3} align="center" className={classes.item}>
      <Box
        onClick={handleClick}
        className={`${classes.boxItem} ${item.active ? 'active' : ''}`}
      >
        <Typography className={classes.typography} variant="subtitle2">
          {item.title}
        </Typography>
      </Box>
    </Grid>
  );
}

export default GuaranteeItem;
