import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import QuestionsStep from 'components/QuestionsStep/QuestionsStep';
import Title from 'components/Title';
import LocataireLottie from 'assets/lotties/locataire.json';
import KeysLottie from 'assets/lotties/keys.json';
import { isEmpty } from 'utils/form-validation';

const PropertyTypeStep = ({
  data,
  onConfirm,
  setUserData,
  setUserDataAndChangePage,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Title title={t('steps.assuredQualityStep.title')} />
      <QuestionsStep
        propertyKey="assuredQuality"
        choices={[
          {
            label: t('common.tenant'),
            value: 'L',
            asset: LocataireLottie,
          },
          {
            label: t('common.owner'),
            value: 'P',
            asset: KeysLottie,
          },
        ]}
        onSelect={setUserData}
        onItemClick={setUserDataAndChangePage}
        onConfirm={onConfirm}
        invalid={
          isEmpty(data.assuredQuality) || data.assuredQuality.length !== 1
        }
        value={data.assuredQuality}
      />
    </>
  );
};

PropertyTypeStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  setUserDataAndChangePage: PropTypes.func.isRequired,
};

export default PropertyTypeStep;
