import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { Link, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { Box, Button, Grid, Typography } from '@material-ui/core';

import {
  getMessageError,
  POLICE_HAVE_CLAIM_SAME_DATE_SURVENANCE_NATURE,
} from 'constants/common/errorCode';
import { CODE_DOCUMENT_GED_SINISTRE_PIECE_JOINT } from 'constants/common/codeDocumentGed';
import { formatDate } from 'utils';
import { useStyles } from './style';
import claimApi from 'api/claimApi';
import policyApi from 'api/policyApi';
import Header from 'components/DashboardHeader';
import Loading from 'components/Loadding/Loading';
import Popin from 'components/Popin';
import { WhiteContainedButton } from 'components/WhiteContainedButton';
import CountdownTimer from 'components/CountdownTimer';
import BlocPoliceInfo from '../BlocPoliceInfo';
import BlocDescriptionSin from '../BlocDescriptionSin';
import BlocPj from '../BlocPJ';
import ConfirmationDecla from '../ConfirmationDecla';

const SuiteDeclaration = ({ reason, date, police }) => {
  const {
    params: { id: policyNumber },
  } = useRouteMatch({
    path: '/polices/:id/nouveau-sinistre',
  });

  const { authState } = useOktaAuth();
  const [auth, setAuth] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [description, setDescription] = useState('');
  const [files, setFiles] = useState([]);
  const { t } = useTranslation();
  const [createSucces, setCreateSucces] = useState(false);
  const [claim, setClaim] = useState(null);
  const [loading, setLoading] = useState(false);
  const [optionsPopin, setOptionsPopin] = useState({
    open: false,
  });

  useEffect(() => {
    (async () => {
      const {
        isAuthenticated,
        accessToken: { accessToken: token },
      } = authState;
      const auth = { isAuthenticated, token };
      setAuth(auth);
    })();
  }, [authState, policyNumber]);

  const handleChangeFile = (evt) => {
    const file = {
      name: evt.target.files[0].name,
      file: evt.target.files[0],
    };

    setFiles([...files, file]);
  };

  const handleRemoveFile = (index) => {
    const newFiles = files;
    newFiles.splice(index, 1);

    setFiles([...newFiles]);
  };

  const uploadFileToClaim = async (claimUuid) => {
    const promises = files.map((file) => {
      const formData = new FormData();
      formData.append('file', file.file);
      return claimApi.uploadFileToGed(
        auth,
        claimUuid,
        formData,
        CODE_DOCUMENT_GED_SINISTRE_PIECE_JOINT
      );
    });

    await Promise.all(promises);
  };

  const handleValidate = async (forceControl) => {
    try {
      setLoading(true);
      const claimRes = await policyApi.createClaim(auth, policyNumber, {
        eventDate: formatDate(date, 'YYYY-MM-DDTHH:mm:ss:SSS[Z]'),
        natureCode: reason,
        customerDescription: description,
        forceControl,
      });
      const { claimsUuid } = claimRes;

      //upload du claim
      files && files.length && (await uploadFileToClaim(claimsUuid));

      setClaim(claimRes);
      setCreateSucces(true);
    } catch (error) {
      handError(error);
    } finally {
      setLoading(false);
    }
  };

  const handError = (error) => {
    const { code } = error;
    if (POLICE_HAVE_CLAIM_SAME_DATE_SURVENANCE_NATURE === code) {
      showPopinConfirm(t('dashboard.sinistre.form.control.msg_control'));
    } else {
      showError(error);
    }
  };

  const showError = (error) => {
    const messageError = getMessageError(error);
    console.error(t(messageError), error);
    enqueueSnackbar(t(messageError), { variant: 'error' });
  };

  const showPopinConfirm = (message) => {
    setOptionsPopin({
      open: true,
      title: t('common.popin.confirm_title'),
      onValidate: () => handleValidate(false),
      validateButtonLabel: t('common.yes'),
      showCancelButton: true,
      content: (
        <Box margin={3}>
          <Typography variant="body1">{message}</Typography>
        </Box>
      ),
      onCancel: handlePopinClose,
      cancelButtonLabel: t('common.no'),
    });
  };

  const handlePopinClose = () => {
    setOptionsPopin({
      open: true,
      title: t('common.popin.info_title'),
      onValidate: () => history.push(`/polices/${policyNumber}`),
      showCancelButton: false,
      validateButtonLabel: (
        <>
          {t('common.yes')} {'('}
          <CountdownTimer
            targetDate={new Date().getTime() + 6 * 1000}
            onFinishCountDown={() => history.push(`/polices/${policyNumber}`)}
          />
          {')'}
        </>
      ),
      content: (
        <Box margin={3}>
          <Typography variant="body1">
            {t('dashboard.sinistre.form.control.msg_not_continue')}
          </Typography>
        </Box>
      ),
    });
  };

  if (createSucces) {
    return <ConfirmationDecla claim={claim} police={police} />;
  }

  return (
    <Box mb={10}>
      <Header
        title={t('dashboard.sinistre.form.header.title')}
        mainAction={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.goBack()}
          >
            {t('dashboard.sinistre.form.header.btn_close')}
          </Button>
        }
      />

      {loading && <Loading />}
      {!loading && police && (
        <Box className={classes.policyContainer}>
          <Grid container>
            <Box
              className={classes.container}
              width={1}
              display="flex"
              justifyContent="center"
            >
              <Grid container xs={12} lg={8}>
                <BlocPoliceInfo
                  police={police}
                  showPoliceDetailsButton={false}
                />
                <BoutonChangerPolice
                  label={t('dashboard.sinistre.form.police.button_change')}
                />
                <BlocDescriptionSin
                  description={description}
                  setDescription={setDescription}
                />
                <BlocPj
                  files={files}
                  onRemove={handleRemoveFile}
                  onChange={handleChangeFile}
                />
              </Grid>
            </Box>
          </Grid>
          <Grid container>
            <Box
              display="flex"
              width={1}
              alignItems="center"
              flexDirection="column"
              className={classes.confirmContainer}
            >
              <Box my={3} width={1} display="flex" justifyItems="center">
                <Grid container justifyContent="center">
                  <WhiteContainedButton
                    align="center"
                    variant="contained"
                    color="primary"
                    onClick={() => handleValidate(true)}
                  >
                    {t('dashboard.sinistre.form.info.send')}
                  </WhiteContainedButton>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Box>
      )}
      <Popin
        onClose={() => setOptionsPopin({ open: false })}
        maxWidth="sm"
        {...optionsPopin}
      />
    </Box>
  );
};

const BoutonChangerPolice = ({ label }) => (
  <Box
    mb={6}
    display="flex"
    width={1}
    alignItems="center"
    flexDirection="column"
    mt={2}
  >
    <Button
      variant="outlined"
      color="primary"
      component={Link}
      to="/sinistres/choix-police"
    >
      {label}
    </Button>
  </Box>
);

BoutonChangerPolice.propTypes = {
  label: PropTypes.string,
};

SuiteDeclaration.propTypes = {
  reason: PropTypes.string,
  date: PropTypes.object,
  police: PropTypes.object,
};
export default SuiteDeclaration;
