import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  advantagesWrapper: {
    padding: '42px 146px',
    width: 'calc(100% - 84px)',
  },
  outdoorDetailsButton: {
    marginTop: 60,
  },
  cardsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  thanks: {
    display: 'inline-flex',
  },
  configureOfferButton: {
    // backgroundColor: `${theme.palette.white.main} !important`,
    borderRadius: 52,
    border: `2px solid ${theme.palette.blue.light} !important`,
    color: theme.palette.blue.light,
    whiteSpace: 'nowrap',
    width: 'fit-content',
    marginRight: 10,
  },
  configureOfferButtonMobile: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 18px)',
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: 52,
    border: `2px solid ${theme.palette.primary.light} !important`,
    color: theme.palette.white.main,
    whiteSpace: 'nowrap',
  },
  swiperWrapper: {
    position: 'relative',
  },
  swiperSlide: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: 'calc(100% - 40px)',
    height: 300,
  },
  swiperSlideImage: {
    display: 'block',
    width: '100%',
  },
  swiperContainer: {
    width: '100%',
    paddingTop: 50,
    paddingBottom: 50,
  },
  slide: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 4,
    backgroundColor: theme.palette.white.main,
    padding: 20,
    minWidth: 200,
  },
  slider: {
    '@media (max-width: 550px)': {
      display: 'none',
    },
  },
  swiper: {
    '@media (min-width: 550px)': {
      display: 'none',
    },
  },
  promoContainer: {
    marginTop: theme.spacing(1),
  },
  promoInput: {
    backgroundColor: '#fff!important',
    borderLeft: 'unset',
  },
  promoButton: {
    display: 'block!important',
    textAlign: 'center',
  },
  promoCardContent: {
    paddingBottom: '0 !important',
  },
  container: {
    width: 300,
  },
  refsButtonWrapper: {
    marginTop: 40,
  },
  goToTop: {
    position: 'absolute',
    zIndex: 11,
    bottom: 10,
    right: 30,
    transition: 'transform 0.4s ease-out',
    borderRadius: 5,
  },

  goToTopMobile: {
    position: 'absolute',
    zIndex: 11,
    bottom: 10,
    right: 12,
    transition: 'transform 0.4s ease-out',
    borderRadius: 5,
  },
  summary: {
    position: 'fixed',
    display: 'flex',
    bottom: 0,
    left: 0,
    width: '100%',
    zIndex: 10,
    transform: 'translate3d(0, 100%, 0)',
    transition: 'transform 0.4s ease-out',
    justifyContent: 'center',
  },
  summaryActive: {
    transform: 'translate3d(0, 0, 0)',
  },
  summaryContentWrapper: {
    boxShadow: '0px 4px 80px rgba(0, 43, 104, 0.1)',
    backgroundColor: theme.palette.white.main,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    overflow: 'hidden',
    width: 'fit-content',
    minWidth: 400,
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  headerWrapper: {
    [theme.breakpoints.up('md')]: {
      flexWrap: 'nowrap',
    },
  },
  priceTopContainer: {
    [theme.breakpoints.down('md')]: {
      marginTop: 30,
    },
  },
  priceContinueButtonWrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  error: {
    color: theme.palette.red.pdf,
  },
  priceContainer: {
    backgroundColor: theme.palette.primary.price,
    padding: '18px 10px',
    flex: 1,
    '& > h6': {
      color: theme.palette.white.main,
    },
  },
  exclusionContainer: {
    display: 'flex',
    flexDirection: 'row',
    padding: '18px 18px',
    flex: 1.5,
    backgroundColor: theme.palette.blue.light2,
  },
  buttonContainer: {
    backgroundColor: theme.palette.white.main,
    padding: '18px 10px',
    flex: 1,
  },
  exclusionText: {
    fontSize: 14,
    color: theme.palette.secondary.main,
    flex: 1,
    cursor: 'pointer',
  },
  checkBox: {
    color: theme.palette.secondary.main,
  },
  nokButton: {
    borderColor: theme.palette.blue.light,
    color: theme.palette.blue.light,
  },
}));

export default useStyles;
