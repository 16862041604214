import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Button from '@material-ui/core/Button';
import Price from 'components/Price';
import GuaranteePopin from 'components/Guarantees/components/GuaranteePopin';
import guaranteesList from 'constants/guarantees/mrh';
import useStyles from '../style';

const OutdoorFacilitiesSelector = (props) => {
  const { options, selectedOption, onChange, periodicity } = props;
  const [showPopin, setShowPopin] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();

  const outdoorFacilitiesGuarantee = guaranteesList(t).find(
    (guarantee) => guarantee.id === 'outdoorFacilities'
  );

  const isSelectedOption = (option) => selectedOption.code === option.code;

  const handleClosePopin = () => {
    setShowPopin(false);
  };

  const handleShowPopin = () => {
    setShowPopin(true);
  };

  const getPoolOptionsLabel = (option) => {
    if (options.length > 2) {
      if (option.code !== 'NONE') {
        if (option.code === 'WITHOUT_SWIM')
          return t('steps.quoteStep.withoutPool');
        else if (option.code === 'WITH_SWIM')
          return t('steps.quoteStep.withPool');
      } else {
        return t('steps.quoteStep.dontWantGardenInsurance');
      }
    } else {
      if (option.code === 'NONE') {
        return t('common.no');
      } else {
        return t('common.yes');
      }
    }
  };

  return (
    <div>
      <div className="EndStep_Content_Value_Franchise">
        <Typography variant="h4" color="textPrimary" align="center">
          {t('steps.quoteStep.gardenInsurance')}
        </Typography>
        <Grid
          container
          className="QuestionsStep"
          spacing={3}
          justifyContent="center"
        >
          {options.map((option) => {
            return (
              <Card
                key={option.code}
                variant="outlined"
                className="QuestionsStep__Card"
                classes={{
                  root: isSelectedOption(option) ? 'selected' : '',
                }}
                onClick={() => onChange(option)}
              >
                <CardHeader
                  action={
                    <CheckCircle
                      color="primary"
                      style={{
                        opacity: isSelectedOption(option) ? 1 : 0,
                      }}
                    />
                  }
                />
                <CardContent className="QuestionsStep__Card__content--no-asset">
                  {option.price && (
                    <div className="QuestionsStep__Card__priceChip">
                      <Price
                        price={option.price}
                        size="chip"
                        periodicity={periodicity.code}
                      />
                    </div>
                  )}
                  <Typography
                    component="span"
                    variant="h5"
                    color={isSelectedOption(option) ? 'primary' : 'textPrimary'}
                    align="center"
                    className="EndStep_Content_Value_Franchise_Value"
                  >
                    {getPoolOptionsLabel(option)}
                  </Typography>
                </CardContent>
              </Card>
            );
          })}
        </Grid>
      </div>
      <Button
        onClick={() => handleShowPopin()}
        color="primary"
        size="small"
        className={classes.outdoorDetailsButton}
      >
        {t('common.details')}
      </Button>
      <GuaranteePopin
        open={showPopin}
        onClose={handleClosePopin}
        guarantee={outdoorFacilitiesGuarantee}
      />
    </div>
  );
};

OutdoorFacilitiesSelector.propTypes = {
  options: PropTypes.array.isRequired,
  selectedOption: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  periodicity: PropTypes.string.isRequired,
};

export default OutdoorFacilitiesSelector;
