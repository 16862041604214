import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
// import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import GlobalContext from 'contexts/global';
import Lottie from 'components/Lottie';
import ClockLottie from 'assets/lotties/clock.json';
import subscribeApi from 'api/subscribeApi';
import customerApi from 'api/customerApi';
import getMrhSubscriptionData from './getMrhSubscriptionData';
import getPnoSubscriptionData from './getPnoSubscriptionData';
import {
  CUSTOMER_HAVE_POLICE_ACTIVE_MAIN_RESIDENCE,
  CUSTOMER_HAVE_POLICE_ACTIVE_SAME_ADDRESS,
  NEED_ADVISOR_STATUS_FAIL,
  NEED_ADVISOR_STATUS_PENDING,
} from 'constants/common/errorCode';
import useAuth from 'hooks/useAuth';
import useGa from 'hooks/useGA';
import Popin from 'components/Popin/index';
import { useHistory } from 'react-router-dom';
import CountdownTimer from 'components/CountdownTimer/index';
import { stepsIds } from 'constants/steps/steps';
import { PnoContext } from 'contexts/pno';
import { MrhContext } from 'contexts/mrh';

const sources = {
  MRH: 'mrh',
  PNO: 'pno',
};

const PaymentPreloader = (props) => {
  const { onConfirm, setUserData, subscriptionSource } = props;

  const pnoContext = useContext(PnoContext);
  const mrhContext = useContext(MrhContext);

  let state =
    subscriptionSource === sources.MRH ? mrhContext.state : pnoContext.state;
  let dispatch =
    subscriptionSource === sources.MRH
      ? mrhContext.dispatch
      : pnoContext.dispatch;
  let parcoursId =
    subscriptionSource === sources.MRH
      ? mrhContext.parcoursId
      : pnoContext.parcoursId;

  let { data } = state;
  console.log('data', data);

  const { gaMrhSiteKey, gaPnoSiteKey } = useContext(GlobalContext);
  const [loaded, setLoaded] = useState(false);
  // const { enqueueSnackbar } = useSnackbar();
  const { gtagEvent } = useGa();
  const { t } = useTranslation();
  const auth = useAuth();
  const [optionsPopin, setOptionsPopin] = useState({
    open: false,
  });
  const history = useHistory();

  useEffect(() => {
    createPolice(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSubscriptionData = (dataToManage) => {
    if (subscriptionSource === sources.MRH)
      return getMrhSubscriptionData(dataToManage, parcoursId);
    if (subscriptionSource === sources.PNO)
      return getPnoSubscriptionData(dataToManage, parcoursId);
  };

  const getSiteKey = () => {
    if (subscriptionSource === sources.MRH) return gaMrhSiteKey;
    if (subscriptionSource === sources.PNO) return gaPnoSiteKey;
  };

  const createPolice = async (forceControl) => {
    try {
      const subscriptionData = getSubscriptionData({ ...data, forceControl });
      const subscriptionResponse = await subscribeApi.subscribe(
        auth,
        subscriptionData,
        subscriptionSource
      );

      gtagEvent(
        'create_prospect',
        {
          category: 'engagement',
          label: 'Création de prospect',
        },
        getSiteKey()
      );

      setUserData('policy', subscriptionResponse);

      Promise.all(
        Object.keys(data.identityFiles).map(async (fileKey) => {
          const file = data.identityFiles[fileKey];
          const formData = new FormData();
          formData.append('file', file.file);

          try {
            await customerApi.uploadFileToGed(
              auth,
              subscriptionResponse.customerNumber,
              file.type,
              formData
            );
            return Promise.resolve();
          } catch (errorUpload) {
            return Promise.reject(t('error.unknown'));
          }
        })
      ).catch((error) => {
        throw error;
      });
      setLoaded(true);
    } catch (error) {
      handError(error);
    }
  };

  const handError = (error) => {
    const { code } = error;
    const goHome = () => history.push('/');
    const goErrorFinscan = () =>
      dispatch({
        type: 'SET_STEP',
        payload: { stepId: stepsIds.ERROR, skipSteps: true },
      });
    if (NEED_ADVISOR_STATUS_PENDING === code) {
      handlePopinClose(
        t('steps.paymentLoader.msg_finscan_pending'),
        goErrorFinscan
      );
    } else if (NEED_ADVISOR_STATUS_FAIL === code) {
      handlePopinClose(
        t('steps.paymentLoader.msg_finscan_fail'),
        goErrorFinscan
      );
    } else if (CUSTOMER_HAVE_POLICE_ACTIVE_SAME_ADDRESS === code) {
      showPopinConfirm(t('steps.paymentLoader.msg_confirm_same_addr'), goHome);
    } else if (CUSTOMER_HAVE_POLICE_ACTIVE_MAIN_RESIDENCE === code) {
      showPopinConfirm(
        t('steps.paymentLoader.msg_confirm_residence_principal'),
        goHome
      );
    } else {
      showPopinErrorAuth(goHome);
    }
  };

  const showPopinErrorAuth = (actionClose) => {
    setOptionsPopin({
      open: true,
      content: (
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{
            marginTop: 15,
          }}
        >
          <Grid container direction="column">
            <Typography
              style={{
                marginLeft: 15,
              }}
            >
              {t('steps.paymentLoader.unknownError.hello')}{' '}
              {data.contact.firstName},
            </Typography>
          </Grid>
          <Grid container direction="column">
            <Typography
              style={{
                marginTop: 15,
                marginLeft: 15,
              }}
            >
              {t('steps.paymentLoader.unknownError.body')}
              <a
                target="_blank"
                href="mailto:particuliers@assurimo.fr"
                rel="noreferrer"
              >
                {t('steps.paymentLoader.unknownError.emailParticulier')}.
              </a>
            </Typography>
            <Typography
              style={{
                marginTop: 15,
                marginLeft: 15,
              }}
            >
              {t('steps.paymentLoader.unknownError.thankYou')}
            </Typography>
            <Typography
              style={{
                marginTop: 15,
                marginLeft: 15,
              }}
            >
              {t('steps.paymentLoader.unknownError.sincerly')}
            </Typography>
            <Typography
              style={{
                marginTop: 15,
                marginLeft: 15,
                marginBottom: 15,
              }}
            >
              {t('steps.paymentLoader.unknownError.assurimoTeam')}
            </Typography>
          </Grid>
        </Grid>
      ),
      onValidate: actionClose,
      showValidateButton: false,
    });
  };

  useEffect(() => {
    if (loaded) onConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  const showPopinConfirm = (message, actionClose) => {
    setOptionsPopin({
      open: true,
      title: t('common.popin.confirm_title'),
      onValidate: handleValidate,
      validateButtonLabel: t('common.yes'),
      showCancelButton: true,
      content: (
        <Box margin={3}>
          <Typography variant="body1">{message}</Typography>
        </Box>
      ),
      onCancel: () =>
        handlePopinClose(
          t('steps.paymentLoader.msg_confirm_not_continue'),
          actionClose
        ),
      cancelButtonLabel: t('common.no'),
    });
  };

  const handleValidate = () => {
    setOptionsPopin({
      open: false,
    });

    createPolice(false);
  };

  const handlePopinClose = (msg, actionClose) => {
    setOptionsPopin({
      open: true,
      title: t('common.popin.info_title'),
      onValidate: actionClose,
      showCancelButton: false,
      validateButtonLabel: (
        <>
          {t('common.yes')} {'('}
          <CountdownTimer
            targetDate={new Date().getTime() + 6 * 1000}
            onFinishCountDown={actionClose}
          />
          {')'}
        </>
      ),
      content: (
        <Box margin={3}>
          <Typography variant="body1">{msg}</Typography>
        </Box>
      ),
    });
  };

  return (
    <>
      <Grid
        container
        className="EndStep"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          container
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{
            marginTop: 32,
          }}
        >
          <div
            style={{
              maxWidth: 200,
            }}
          >
            <Lottie animationData={ClockLottie} isActive autoPlay />
          </div>
          <Grid container direction="column" alignItems="center">
            <Typography color="primary" variant="h3">
              {data.contact.firstName},
            </Typography>
            <Typography color="textPrimary" variant="h4" align="center">
              {t('steps.paymentLoader.thankYou')}.
            </Typography>
          </Grid>
          <Grid container direction="column" alignItems="center">
            <Typography
              style={{
                marginTop: 32,
              }}
              color="textPrimary"
              variant="body1"
              align="center"
            >
              {t('steps.paymentLoader.wait')}.
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Popin
        onClose={() => setOptionsPopin({ open: false })}
        maxWidth="sm"
        {...optionsPopin}
      />
    </>
  );
};

PaymentPreloader.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  stepsContext: PropTypes.shape().isRequired,
  subscriptionSource: PropTypes.string.isRequired,
};

export default PaymentPreloader;
