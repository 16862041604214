import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import _get from 'lodash/get';

import { stepsIds } from 'constants/steps/steps';
import PaymentStep from 'views/PnoSouscription/Steps/PaymentStep';
import { TYPE_POLICE_PNO, TYPE_POLICE_H } from 'constants/index';

const PaymentForDashboard = () => {
  const location = useLocation();
  const history = useHistory();
  const { state: routerState } = location;

  const routerParams = _get(routerState, 'params', null);
  const premiumUuid = _get(routerParams, 'premiumUuid', null);
  const policyNumber = _get(routerParams, 'policyNumber', null);
  const address = _get(routerParams, 'address', null);
  const policyStatus = _get(routerParams, 'policyStatus', null);
  const contractType = _get(routerParams, 'contractType', null);

  const getSubscriptionPath = () => {
    switch (contractType) {
      case TYPE_POLICE_PNO:
        return 'pno';
      case TYPE_POLICE_H:
        return 'mrh';
      default:
        return contractType.toLowerCase();
    }
  };

  const onConfirm = () => {
    if (policyStatus === 'ANNP') {
      let next = {};
      next[stepsIds.IDENTITY] = `/polices/${policyNumber}?refresh=true`;
      history.push(`/souscription/${getSubscriptionPath()}`, {
        stepId: stepsIds.IDENTITY,
        params: {
          premiumUuid: premiumUuid,
          policyNumber: policyNumber,
        },
        next,
      });
    } else {
      const params = {
        refresh: true,
      };
      history.push(`/polices/${policyNumber}`, { ...params });
    }
  };

  return (
    <PaymentStep
      onConfirm={onConfirm}
      premiumUuid={premiumUuid}
      policyNumber={policyNumber}
      address={address}
    />
  );
};

PaymentForDashboard.propTypes = {};

export default PaymentForDashboard;
