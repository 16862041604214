import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  useTheme,
  useMediaQuery,
  Grid,
  Typography,
  Button,
  Container,
  Icon,
  Checkbox,
} from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import Fab from '@material-ui/core/Fab';

import { PnoContext } from 'contexts/pno';
import GarantiesImage from 'assets/images/garanties.svg';
import Guarantees from 'components/Guarantees';
import Advantages from 'components/Advantages';
import guaranteesList from 'constants/guarantees/pno';
import Price from 'components/Price';
import performantGuaranteesList from 'constants/performantGuarantees/pno';
import './ResultStep.scss';
import MyGood from './MyGood';
import MyPricing from './MyPricing';
import MyProfile from './MyProfile';
import useStyles from './style';

import { ASSURIMO_LEGAL_MANSIONS_URL } from 'constants';
import { DOC_CONTRACTUEL, PNO_CODE_RISQUE } from 'constants/index';
import { UserContext } from 'contexts/user';
import documentContractuelService from 'services/documentContractuelService';
import { useSnackbar } from 'notistack';

const ResultStep = ({ data, onConfirm, setUserData, parentRef }) => {
  const [summaryActive, setSummaryActive] = useState(false);
  const [goToTopActive, setGoToTopActive] = useState(false);
  const { dispatch } = useContext(PnoContext);
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const topRef = React.useRef(null);
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const guaranteesRef = React.useRef(null);
  const [userState] = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const scrollTo = (ref) => {
    ref.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleScroll = () => {
    const activate =
      parentRef.current.scrollTop > parentRef.current.offsetHeight * 0.35;
    setSummaryActive(activate);
    setGoToTopActive(activate);
  };

  const handleGoToTop = () => {
    topRef.current.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  const handleCheckboxClick = () => {
    dispatch({
      type: 'SET_DATA',
      payload: {
        key: 'agreements',
        value: !data.agreements,
      },
    });
  };

  useEffect(() => {
    const currentRef = parentRef.current;
    if (currentRef) {
      currentRef.addEventListener('scroll', handleScroll);
      return () => {
        currentRef.removeEventListener('scroll', handleScroll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const downloadDocument = async (codeDocument) => {
    try {
      const params = {
        codeTarif: data?.riskRate?.code,
        codeRisque: PNO_CODE_RISQUE,
      };
      const document = await documentContractuelService.get(
        userState.auth,
        codeDocument,
        params
      );
      await documentContractuelService.downloadFile(
        userState.auth,
        document.id,
        `${document.labelDefault}.pdf`
      );
    } catch (error) {
      console.log(
        'Une erreur est survenue quand on telecharge le document',
        error
      );
      enqueueSnackbar(t('steps.resultStep.error_generate'), {
        variant: 'error',
      });
    }
  };

  const renderLegalMansion = () => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={classes.legalMansion}>
          {t('steps.resultStep.clickingSubscribe')}{' '}
          <a
            className={classes.legalMansionLink}
            onClick={() => downloadDocument(DOC_CONTRACTUEL.NI.code)}
          >
            {t('common.generalDispositions')}
          </a>
          , {t('steps.resultStep.ofThe')}{' '}
          {
            // eslint-disable-next-line react/jsx-no-target-blank
            <a
              className={classes.legalMansionLink}
              onClick={() => downloadDocument(DOC_CONTRACTUEL.IPID.code)}
            >
              {t('common.ipidPage')}
            </a>
          }{' '}
          {t('steps.resultStep.and')} {t('steps.resultStep.the')}{' '}
          <a
            className={classes.legalMansionLink}
            href={ASSURIMO_LEGAL_MANSIONS_URL}
            target="_blank"
          >
            {t('common.legalNotice')}
          </a>
        </Typography>
        <Checkbox
          checked={!!data.agreements}
          classes={{ root: classes.checkBox }}
          onClick={handleCheckboxClick}
        />
      </div>
    );
  };

  const handleConfirm = async () => {
    onConfirm();
  };

  return (
    <Grid ref={topRef} className="EndStep ResultStep">
      {!mobile && (
        <Fade in={goToTopActive}>
          <Fab
            color="primary"
            aria-label="add"
            classes={{
              root: mobile
                ? classes.goToTopMobile
                : isDownMd
                ? classes.goToTopMd
                : classes.goToTop,
            }}
            onClick={handleGoToTop}
          >
            <Icon className="fas fa-arrow-up" />
          </Fab>
        </Fade>
      )}
      <div
        className={`EndStep_Summary_Fixed_Result ${
          // eslint-disable-next-line no-nested-ternary
          'active'
        }`}
      >
        <Container maxWidth="md">
          <Grid container direction={mobile ? 'column' : 'row'}>
            <Grid
              item
              md={4}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Price
                price={data.price}
                size="small"
                periodicity={data.periodicity?.code}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {renderLegalMansion()}
            </Grid>
            <Grid
              item
              md={3}
              container
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              {mobile && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    width: '80%',
                  }}
                >
                  {renderLegalMansion()}
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleConfirm}
                    disabled={!data.agreements}
                  >
                    {t('steps.resultStep.subscribe')}
                  </Button>
                </div>
              )}
              {!mobile && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleConfirm}
                  disabled={!data.agreements}
                >
                  {t('steps.resultStep.subscribe')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Container>
      </div>
      <div className="EndStep_Hero">
        <Container>
          <Grid
            container
            item
            xs={12}
            spacing={3}
            classes={{ root: classes.title }}
          >
            <Grid item xs={12}>
              <Typography variant="h3" color="textPrimary" component="span">
                {t('steps.resultStep.hereWeAre')}
                {','}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <span>
                <Typography variant="h6" color="textPrimary" component="span">
                  {t('steps.resultStep.hereIs')}{' '}
                </Typography>
                <Typography variant="h6" color="primary" component="span">
                  {t('steps.resultStep.recap')}{' '}
                </Typography>
                <Typography variant="h6" color="textPrimary" component="span">
                  {t('steps.resultStep.insuranceProject')}
                  {'.'}
                </Typography>
              </span>
            </Grid>
          </Grid>
          <Grid
            container
            item
            spacing={3}
            direction={mobile ? 'column-reverse' : 'row'}
            justifyContent="center"
            alignItems="center"
          >
            <Grid
              container
              spacing={3}
              xs={12}
              md={8}
              item
              alignItems="stretch"
              direction={mobile ? 'column' : 'row'}
            >
              <MyGood data={data} />
              <MyProfile data={data} />
            </Grid>
            <Grid
              container
              classes={{ root: classes.pricingContainer }}
              alignContent="center"
              spacing={3}
              xs={12}
              md={4}
              item
            >
              <MyPricing
                data={data}
                setUserData={setUserData}
                onConfirm={onConfirm}
              />
            </Grid>
          </Grid>
          <Button
            variant="outlined"
            color="secondary"
            classes={{ root: classes.guaranteesButton }}
            onClick={() => {
              scrollTo(guaranteesRef);
            }}
          >
            {t('steps.resultStep.guaranteesReminder')}
          </Button>
        </Container>
      </div>
      <div ref={guaranteesRef} className="EndStep_Content_Guarantees">
        <Guarantees
          image={GarantiesImage}
          title="Nos garanties"
          guarantees={guaranteesList(t)}
          showButton={false}
          codeTarif={data?.riskRate?.code}
          codeRisque={PNO_CODE_RISQUE}
        />
      </div>
      <div className="EndStep_Content_Advantages">
        <Advantages
          image={GarantiesImage}
          title={t('common.assurimoAdvantages')}
          guarantees={performantGuaranteesList(t)}
          lg={4}
        />
      </div>
    </Grid>
  );
};

ResultStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  parentRef: PropTypes.any,
};

export default ResultStep;
