import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  stepHeader: {
    height: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  counter: {
    position: 'relative',
    lineHeight: 1.8,
    '&::after': {
      content: '',
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      height: 1,
      backgroundColor: theme.palette.primary.main,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: 20,
    paddingBottom: 50,
  },
  text: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  progressWrapper: {
    position: 'relative',
  },
  textWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  circle: {
    width: 46,
    height: 46,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.secondary.main}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.blue.light2,
  },
  circleToDo: {
    width: 46,
    height: 46,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.blue.light3}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.blue.light2,
  },
  circleDoing: {
    width: 46,
    height: 46,
    borderRadius: '50%',
    backgroundColor: theme.palette.blue.light2,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  littleCircle: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.secondary.main}`,
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  principalIcon: {
    fontSize: 16,
    color: theme.palette.secondary.main,
    position: 'absolute',
  },
  link: {
    width: 60,
    height: 2,
    borderTop: `2px solid ${theme.palette.secondary.main}`,
    '&:nth-child(1)': {
      display: 'none',
    },
  },
  linkToDo: {
    width: 60,
    height: 2,
    borderTop: `2px solid ${theme.palette.blue.light3}`,
  },

  /////
  circleBackground: {
    stroke: theme.palette.blue.light3,
    fill: theme.palette.blue.light2,
  },

  circleProgress: {
    stroke: theme.palette.secondary.main,
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
    fill: 'none',
  },

  circleText: {
    fontSize: 16,
    fontWeight: 'bold',
  },

  ///////
  step: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  validatedStep: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.secondary.main}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.secondary.main,
  },
  stepLink: {
    width: 40,
    height: 2,
    borderTop: `2px solid ${theme.palette.secondary.main}`,
  },
  stepToDo: {
    width: 15,
    height: 15,
    borderRadius: '50%',
    border: `2px solid ${theme.palette.secondary.main}`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.blue.light2,
  },
  secondaryIcon: {
    color: theme.palette.white.main,
    fontSize: 15,
  },
  currentStep: {
    paddingLeft: 8,
    paddingRight: 8,
  },
}));

export default useStyles;
