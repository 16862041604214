import brokenGlassGuaranted from './brokenGlass/guaranteed.html';
import brokenGlassNotGuaranted from './brokenGlass/notGuaranteed.html';
import climateGuaranted from './climate/guaranteed.html';
import climateNotGuaranted from './climate/notGuaranteed.html';
import defenseGuaranted from './defense/guaranteed.html';
import defenseNotGuaranted from './defense/notGuaranteed.html';
import electricGuaranted from './electric/guaranteed.html';
import electricNotGuaranted from './electric/notGuaranteed.html';
import fireGuaranted from './fire/guaranteed.html';
import fireNotGuaranted from './fire/notGuaranteed.html';
import liabilityGuaranted from './liability/guaranteed.html';
import liabilityNotGuaranted from './liability/notGuaranteed.html';
import naturalDisasterGuaranted from './naturalDisaster/guaranteed.html';
import technologicalDisasterGuaranted from './technologicalDisaster/guaranteed.html';
import collapseGuaranted from './collapse/guaranteed.html';
import collapseNotGuaranted from './collapse/notGuaranteed.html';
import theftGuaranted from './theft/guaranteed.html';
import theftNotGuaranted from './theft/notGuaranteed.html';
import vandalismGuaranted from './vandalism/guaranteed.html';
import waterGuaranted from './water/guaranteed.html';
import waterNotGuaranted from './water/notGuaranteed.html';
import BrokenGlass from 'assets/lotties/guarantee/brokenGlass.json';
import Water from 'assets/lotties/guarantee/water.json';
import Climate from 'assets/lotties/guarantee/climate.json';
import Fire from 'assets/lotties/guarantee/fire.json';
import Vandalism from 'assets/lotties/guarantee/vandalism.json';
import Vol from 'assets/lotties/guarantee/vol.json';
import Defense from 'assets/lotties/guarantee/recoursResponsable.json';
import ResponsabiliteCivil from 'assets/lotties/guarantee/responsabiliteCivil.json';
import Effondrement from 'assets/lotties/guarantee/effondrement.json';
import ElectricDamage from 'assets/lotties/guarantee/electricDamage.json';

const guaranteesList = (t) => [
  {
    id: 'fire',
    title: t('common.pno.guarantees.fire.title'),
    examples: t('common.pno.guarantees.fire.examples', { returnObjects: true }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: fireGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: fireNotGuaranted },
      },
    ],
    additional: t('common.pno.guarantees.forFullWarranty'),
    icon: 'fas fa-fire',
    link: '#',
    symbol: Fire,
  },
  {
    id: 'electric',
    title: t('common.pno.guarantees.electric.title'),
    examples: t('common.pno.guarantees.electric.examples', {
      returnObjects: true,
    }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: electricGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: electricNotGuaranted },
      },
    ],
    additional: t('common.pno.guarantees.forFullWarranty'),
    icon: 'fas fa-plug',
    link: '#',
    symbol: ElectricDamage,
  },
  {
    id: 'collapse',
    title: t('common.pno.guarantees.collapse.title'),
    examples: t('common.pno.guarantees.collapse.examples', {
      returnObjects: true,
    }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: collapseGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: collapseNotGuaranted },
      },
    ],
    additional: t('common.pno.guarantees.forFullWarranty'),
    icon: 'fas fa-poo-storm',
    link: '#',
    symbol: Effondrement,
  },
  {
    id: 'climate',
    title: t('common.pno.guarantees.climate.title'),
    examples: t('common.pno.guarantees.climate.examples', {
      returnObjects: true,
    }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: climateGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: climateNotGuaranted },
      },
    ],
    additional: t('common.pno.guarantees.forFullWarranty'),
    icon: 'fas fa-poo-storm',
    link: '#',
    symbol: Climate,
  },
  {
    id: 'water',
    title: t('common.pno.guarantees.water.title'),
    examples: t('common.pno.guarantees.water.examples', {
      returnObjects: true,
    }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: waterGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: waterNotGuaranted },
      },
    ],
    additional: t('common.pno.guarantees.forFullWarranty'),
    icon: 'fas fa-tint',
    link: '#',
    symbol: Water,
  },
  {
    id: 'brokenGlass',
    title: t('common.pno.guarantees.glass.title'),
    examples: t('common.pno.guarantees.glass.examples', {
      returnObjects: true,
    }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: brokenGlassGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: brokenGlassNotGuaranted },
      },
    ],
    additional: t('common.pno.guarantees.forFullWarranty'),
    icon: 'fas fa-columns',
    link: '#',
    symbol: BrokenGlass,
  },
  {
    id: 'naturalDisaster',
    title: t('common.pno.guarantees.natural.title'),
    examples: t('common.pno.guarantees.natural.examples', {
      returnObjects: true,
    }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: naturalDisasterGuaranted },
      },
    ],
    additional: t('common.pno.guarantees.forFullWarranty'),
    icon: 'fas fa-house-damage',
    link: '#',
    symbol: Climate,
  },
  // {
  //   id: 'technologicDisaster',
  //   title: t('common.pno.guarantees.technologic.title'),
  //   examples: t('common.pno.guarantees.technologic.examples', {
  //     returnObjects: true,
  //   }),
  //   categories: [
  //     {
  //       title: 'Ce que nous garantissons',
  //       description: { __html: technologicalDisasterGuaranted },
  //     },
  //   ],
  //   additional:
  //     'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
  //   icon: 'fas fa-house-damage',
  //   link: '#',
  //   symbol: Climate,
  // },
  {
    id: 'theft',
    title: t('common.pno.guarantees.theft.title'),
    examples: t('common.pno.guarantees.theft.examples', {
      returnObjects: true,
    }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: theftGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: theftNotGuaranted },
      },
    ],
    additional: t('common.pno.guarantees.forFullWarranty'),
    icon: 'fas fa-hammer',
    link: '#',
    symbol: Vol,
  },
  {
    id: 'vandalism',
    title: t('common.pno.guarantees.vandalism.title'),
    examples: t('common.pno.guarantees.vandalism.examples', {
      returnObjects: true,
    }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: vandalismGuaranted },
      },
    ],
    additional: t('common.pno.guarantees.forFullWarranty'),
    icon: 'fas fa-hammer',
    link: '#',
    symbol: Vandalism,
  },
  {
    id: 'liability',
    title: t('common.pno.guarantees.publicLiability.title'),
    examples: t('common.pno.guarantees.publicLiability.examples', {
      returnObjects: true,
    }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: liabilityGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: liabilityNotGuaranted },
      },
    ],
    additional: t('common.pno.guarantees.forFullWarranty'),
    icon: 'fas fa-id-card-alt',
    link: '#',
    symbol: ResponsabiliteCivil,
  },
  {
    id: 'defense',
    title: t('common.pno.guarantees.defense.title'),
    examples: t('common.pno.guarantees.defense.examples', {
      returnObjects: true,
    }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: defenseGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: defenseNotGuaranted },
      },
    ],
    additional: t('common.pno.guarantees.forFullWarranty'),
    icon: 'fas fa-shield-alt',
    link: '#',
    symbol: Defense,
  },
];

export default guaranteesList;
