import { Box, Button, Grid, TextField } from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Icon from 'components/Icon';
import { passwordStructure } from 'utils/form-validation';
import SignUpSendNewLink from './SignUpSendNewLink';
import useStyles from './style';

const SignUpConfirmForm = (props) => {
  const classes = useStyles();
  const { onSubmit } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const [showPassword, setShowPassword] = useState(false);
  const [userInputs, setUserInputs] = useState({});
  const [complexityPasswordError, setComplexityPasswordError] = useState(null);
  const [samePasswordError, setSamePasswordError] = useState(null);
  const [containsUpperCase, setContainsUpperCase] = useState(false);
  const [containsLowerCase, setContainsLowerCase] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [isUpToMinimumLength, setIsUpToMinimumLength] = useState(false);
  const [containsSpecialChars, setContainsSpecialChars] = useState(false);

  const handleChange = (event) => {
    const { id, value } = event.target;
    setUserInputs({ ...userInputs, [id]: value });
  };

  const handleSubmit = async () => {
    if(!isFormValid()){
      return;
    }
    
    if(onSubmit)  onSubmit(userInputs.newPassword);
  };

  const getPasswordRules = () => {
    return passwordStructure(userInputs.newPassword);
  };

  const isFormValid = () => {
    const passwordRules = getPasswordRules();
    const goodPasswordCondition =
      passwordRules.containsUpperCase() &&
      passwordRules.containsLowerCase() &&
      passwordRules.containsSpecialChars() &&
      passwordRules.containsNumbers() &&
      passwordRules.isUpToMinimumLength();

    if (!goodPasswordCondition) setComplexityPasswordError(true);
    else setComplexityPasswordError(false);

    const goodPasswordMatchCondition =
      userInputs.confirmPassword === userInputs.newPassword;
    if (!goodPasswordMatchCondition) setSamePasswordError(true);
    else setSamePasswordError(false);

    return (
      goodPasswordCondition &&
      goodPasswordMatchCondition
    );
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const getVisibilityIcon = () => {
    return showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />;
  };

  const getPasswordHelperText = () => {
    return (
      <Box className={classes.container}>
        <Typography classes={{ root: classes.rulesTitle }}>
          {t('auth.passwordCreation.passwordPolicyRequirementsTitle')}
        </Typography>
        <ul className={classes.ul}>
          <li className={isUpToMinimumLength ? classes.liOk : classes.li}>
            <Typography>
              {t(
                'auth.passwordCreation.passwordPolicyRequirements.minimumCharacters'
              )}
            </Typography>
          </li>
          <li
            className={
              containsLowerCase && containsUpperCase && containsNumber
                ? classes.liOk
                : classes.li
            }
          >
            <Typography>
              {t(
                'auth.passwordCreation.passwordPolicyRequirements.typeOfCharacters'
              )}
            </Typography>
          </li>
          <li className={containsSpecialChars ? classes.liOk : classes.li}>
            <Typography>
              {t(
                'auth.passwordCreation.passwordPolicyRequirements.specialCharacter'
              )}
            </Typography>
            <Typography variant="caption" classes={{ root: classes.caption }}>
              {t('auth.passwordCreation.passwordPolicyRequirements.example')}
            </Typography>
          </li>
          <div
            style={{
              display: 'flex',
              marginLeft: -17,
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <Icon className="fas fa-info-circle" style={{ fontSize: 15 }} />
            <Typography style={{ lineHeight: 1, marginLeft: 5 }}>
              {t(
                'auth.passwordCreation.passwordPolicyRequirements.passwordMustNotContain'
              )}
            </Typography>
          </div>
        </ul>
      </Box>
    );
  };


  useEffect(() => {
    if (userInputs.newPassword) {
      setContainsUpperCase(getPasswordRules().containsUpperCase());
      setContainsLowerCase(getPasswordRules().containsLowerCase());
      setContainsSpecialChars(getPasswordRules().containsSpecialChars());
      setContainsNumber(getPasswordRules().containsNumbers());
      setIsUpToMinimumLength(getPasswordRules().isUpToMinimumLength());
    }
  }, [userInputs]);

  return (
    <Grid container item>
      <div className={classes.containerBottom}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <TextField
              id="newPassword"
              type={showPassword ? 'text' : 'password'}
              label={t('auth.initPassword.newPassword')}
              onChange={handleChange}
              error={samePasswordError}
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {getVisibilityIcon()}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {complexityPasswordError && (
              <FormHelperText
                error={complexityPasswordError}
                component="div"
              >
                {getPasswordHelperText()}
              </FormHelperText>
            )}
          </Grid>
          <Grid item>
            <TextField
              id="confirmPassword"
              type={showPassword ? 'text' : 'password'}
              label={t('auth.initPassword.confirmPassword')}
              onChange={handleChange}
              helperText={
                samePasswordError &&
                t('dashboard.settings.password.errors.samePassword')
              }
              error={samePasswordError}
              variant="outlined"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {getVisibilityIcon()}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              type="submit"
              color="primary"
              fullWidth
              classes={{ root: classes.button }}
              disabled={
                !getPasswordRules().containsUpperCase() ||
                !getPasswordRules().containsLowerCase() ||
                !getPasswordRules().containsSpecialChars() ||
                !getPasswordRules().containsNumbers() ||
                !getPasswordRules().isUpToMinimumLength() ||
                userInputs.confirmPassword !== userInputs.newPassword
              }
              onClick={handleSubmit}
            >
              <Typography classes={{ root: classes.buttonText }}>
                {t('common.save')}
              </Typography>
            </Button>
            <SignUpSendNewLink />
          </Grid>
        </Grid>
      </div>
      <Grid container item>
        {getPasswordHelperText()}
      </Grid>
  </Grid>
  );
};

export default SignUpConfirmForm;
