import {
  HSMA,
  PRIVFONCIA,
  TYPE_POLICE_H,
  TYPE_POLICE_PNO,
  PNODIGIT,
  PNOBAIL,
} from 'constants/index';
import HolidaysHouse from 'assets/lotties/holiday-house.json';
import SchoolLottie from 'assets/lotties/school.json';
import TeleworkLottie from 'assets/lotties/telework.json';

export const getCertificates = (coInsured, asset) => {
  return {
    [TYPE_POLICE_H]: {
      [HSMA]: [
        {
          reason: 'POL_ATT',
          asset,
          title: 'dashboard.police.attestation.housingTitle',
          label: 'dashboard.police.attestation.housingLabel',
          params: [
            {
              id: 'beneficiary',
              label: 'dashboard.police.attestation.input_beneficiary',
              type: 'select',
              items: coInsured,
            },
          ],
        },
        {
          reason: 'POL_ATT_SCO',
          asset: SchoolLottie,
          title: 'dashboard.police.attestation.schoolTitle',
          label: 'dashboard.police.attestation.schoolLabel',
          params: [
            {
              id: 'beneficiary',
              label: 'dashboard.police.attestation.input_beneficiary',
              type: 'select',
              items: coInsured,
            },
          ],
        },
        {
          reason: 'POL_ATT_TELE_TRAVAIL',
          asset: TeleworkLottie,
          title: 'dashboard.police.attestation.teleworkTitle',
          label: 'dashboard.police.attestation.teleworkLabel',
          params: [
            {
              id: 'beneficiary',
              label: 'dashboard.police.attestation.input_beneficiary',
              type: 'select',
              items: coInsured,
            },
            {
              id: 'inputField1',
              label: 'dashboard.police.attestation.input_societe',
              type: 'text',
            },
          ],
        },
        {
          reason: 'POL_ATT_VILLEGIATURE',
          asset: HolidaysHouse,
          title: 'dashboard.police.attestation.resortTitle',
          label: 'dashboard.police.attestation.resortLabel',
          params: [
            {
              id: 'beneficiary',
              label: 'dashboard.police.attestation.input_beneficiary',
              type: 'select',
              items: coInsured,
            },
            {
              id: 'inputField1',
              label: 'dashboard.police.attestation.input_adress',
              type: 'text',
            },
          ],
        },
      ],
      [PRIVFONCIA]: [
        {
          reason: 'POL_ATT',
          asset,
          title: 'dashboard.police.attestation.housingAndSchoolTitle',
          label: 'dashboard.police.attestation.housingLabel',
          params: [
            {
              id: 'beneficiary',
              label: 'dashboard.police.attestation.input_beneficiary',
              type: 'select',
              items: coInsured,
            },
          ],
        },
        {
          reason: 'POL_ATT_TELE_TRAVAIL',
          asset: TeleworkLottie,
          title: 'dashboard.police.attestation.teleworkTitle',
          label: 'dashboard.police.attestation.teleworkLabel',
          params: [
            {
              id: 'beneficiary',
              label: 'dashboard.police.attestation.input_beneficiary',
              type: 'select',
              items: coInsured,
            },
            {
              id: 'inputField1',
              label: 'dashboard.police.attestation.input_societe',
              type: 'text',
            },
          ],
        },
        {
          reason: 'POL_ATT_VILLEGIATURE',
          asset: HolidaysHouse,
          title: 'dashboard.police.attestation.resortTitle',
          label: 'dashboard.police.attestation.resortLabel',
          params: [
            {
              id: 'beneficiary',
              label: 'dashboard.police.attestation.input_beneficiary',
              type: 'select',
              items: coInsured,
            },
            {
              id: 'inputField1',
              label: 'dashboard.police.attestation.input_adress',
              type: 'text',
            },
          ],
        },
      ],
    },
    [TYPE_POLICE_PNO]: {
      [PNODIGIT]: [
        {
          reason: 'POL_ATT',
          asset,
          title: 'dashboard.police.attestation.pnoTitle',
          label: 'dashboard.police.attestation.pnoLabel',
          params: [
            {
              id: 'beneficiary',
              label: 'dashboard.police.attestation.input_beneficiary',
              type: 'select',
              items: [{ ...coInsured[0], selected: true }],
            },
          ],
          hideOptionManageBenificiaire: true,
        },
      ],
      [PNOBAIL]: [
        {
          reason: 'POL_ATT',
          asset,
          title: 'dashboard.police.attestation.pnoTitle',
          label: 'dashboard.police.attestation.pnoLabel',
          params: [
            {
              id: 'beneficiary',
              label: 'dashboard.police.attestation.input_beneficiary',
              type: 'select',
              items: [{ ...coInsured[0], selected: true }],
            },
          ],
          hideOptionManageBenificiaire: true,
        },
      ],
    },
  };
};
