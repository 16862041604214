import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'space-between',
    // height: 30,
  },
  title: {
    fontSize: 17,
    fontWeight: 'bolder',
    flexBasis: '100%',
    textAlign: 'center',
  },
  content: {
    padding: 0,
  },
  icon: { width: 'auto', marginRight: 10, color: theme.palette.primary.main },
  dialog: {
    minHeight: (props) => (props.minHeight ? props.minHeight : 600),
    // [theme.breakpoints.down('xs')]: {
    //   paddingTop: 40,
    // },
  },
  dialogActions: {
    justifyContent: 'space-between',
  },
  scrollingPaper: {
    maxHeight: (props) => (props.maxHeight ? props.maxHeight : 600),
    [theme.breakpoints.down('xs')]: {
      maxHeight: '100% !important',
    },
  },
  titleIcon: {
    color: theme.palette.primary.main,
  },
  page: {
    height: '100%',
  },
  error: {
    color: theme.palette.red.main,
  },
}));

export default useStyles;
