import ratingApi from 'api/ratingApi';

const findDataInArray = (data, key) => {
  return data?.find((equipement) => equipement === key);
};

const fetchRatings = (data) => {
  const params = {
    returnAll: true,
    risk: {
      address: data.contractAddress,
      area: data.area,
      assuredQuality: data.assuredQuality,
      housingType: data.housingType,
      housingUsageType: 'P',
      numberOfRoom: data.numberOfPrincipalRooms,
      equipment: {
        swimmingPool: !!findDataInArray(
          data.equipements,
          'gardenWithSwimmingPool'
        ),
        veranda: !!findDataInArray(data.equipements, 'veranda'),
        outbuilding: findDataInArray(data.equipements, 'outbuilding')
          ? 'INF120'
          : 'NONE',
        landscaping:
          !!findDataInArray(data.equipements, 'gardenWithSwimmingPool') ||
          !!findDataInArray(data.equipements, 'gardenWithoutSwimmingPool'),
      },
    },
    rateOptions: {},
  };

  if (data.deductible)
    params.rateOptions.deductibleOptionCode = data.deductible.code;

  if (data.movableCapitalAmount)
    params.rateOptions.movableCapitalAmountsOptionCode =
      data.movableCapitalAmount.code;

  if (data.from) {
    params.originalCode = data.from;
  }

  if (data.promotionCode) {
    params.promotionCode = data.promotionCode;
  }
  return ratingApi.getHRatings(params);
};

export { fetchRatings };
