import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

import Field from 'components/Field';
import useStyle from './style';

const Activity = (props) => {
  const { value, setValue } = props;
  const classes = useStyle();
  const { t } = useTranslation();

  return (
    <div className={classes.activityWrapper}>
      <Grid container item alignItems="center" justifyContent="center">
        <Field
          type="text"
          value={value}
          label={t('pno.activityStep.activity.activityField')}
          inputProps={{ maxLength: 60 }}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          fullWidth
        />
      </Grid>
    </div>
  );
};

Activity.propTypes = {
  value: PropTypes.func,
  setValue: PropTypes.func,
};

export default Activity;
