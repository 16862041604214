const getOktaAuthConfig = (
  oktaDomain,
  oktaClientId,
  authenticationServerId,
  onAuthRequired
) => ({
  issuer: `${oktaDomain}/oauth2/${authenticationServerId}`,
  clientId: oktaClientId,
  redirectUri: window.location.origin + '/login/callback',
  postLogoutRedirectUri: window.location.origin + '/login',
  onAuthRequired: onAuthRequired,
  useInteractionCodeFlow: true,
  // pkce: true,
});

const getOktaSignInConfig = (
  oktaDomain,
  oktaClientId,
  authenticationServerId
) => ({
  logo: `${localStorage.getItem('IMAGE_MANAGER_URL_GET')}/assurimo/base/logo-assurimo.png`,
  baseUrl: oktaDomain,
  clientId: oktaClientId,
  redirectUri: window.location.origin + '/login/callback',
  postLogoutRedirectUri: window.location.origin + '/login',
  authParams: {
    issuer: `${oktaDomain}/oauth2/${authenticationServerId}`,
    // pkce: true,
  },
});

export { getOktaAuthConfig, getOktaSignInConfig };
