import documentApi from "api/documentApi";
import { downloadFileFromBlob } from 'utils/index';

const documentContractuelService = {
  async downloadFile (auth, idDocument, label){
    const blob = await documentApi.download(auth, idDocument);
    downloadFileFromBlob(blob, label);
  },
  async get(auth, codeDocument, params){
    const documentList = await documentApi.get(auth, codeDocument, params);
    if(!documentList || !documentList.length){
      throw `${codeDocument} not existe`;
    }

    return documentList[0];
  }
}

export default documentContractuelService;