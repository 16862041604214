import React from 'react';
import PropTypes from 'prop-types';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import useStyles from './style';

const RadioField = (props) => {
  const {
    choices,
    direction,
    id,
    onChange,
    helperText,
    disabled,
    fullWidth,
    ...otherProps
  } = props;
  const classes = useStyles();

  const handleChange = (event) => {
    onChange({
      target: {
        id,
        value: event.target.value,
      },
    });
  };

  return (
    <FormControl error={helperText}>
      <RadioGroup onChange={handleChange} {...otherProps}>
        <div
          className={
            direction === 'horizontal' ? classes.horizontal : classes.vertical
          }
        >
          {choices.map((choice, index) => {
            return (
              <FormControlLabel
                key={`radio-${index}`}
                id="test"
                label={choice.label}
                value={choice.value}
                control={<Radio disabled={disabled} />}
                labelPlacement="end"
              />
            );
          })}
        </div>
      </RadioGroup>
      <FormHelperText error={helperText} variant="outlined">
        {helperText && helperText}
      </FormHelperText>
    </FormControl>
  );
};

RadioField.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  choices: PropTypes.array.isRequired,
  direction: PropTypes.oneOf(['horizontal', 'vertical']),
  helperText: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

RadioField.defaultProps = {
  direction: 'vertical',
};

export default RadioField;
