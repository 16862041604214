import { Box, Grid } from '@material-ui/core';
import { useRouteMatch, useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';

import { addUnitTime } from 'utils';
import policyApi from 'api/policyApi';
import useStyles from './styles';
import { WhiteContainedButton } from 'components/WhiteContainedButton';
import Loading from 'components/Loadding/Loading';
import Header from 'components/DashboardHeader';
import InfoAncienneCompagnie from './InfoAncienneCompagnie';
import PoliceInfo from './PoliceInfo';
import AttestationAssurance from './AttestationAssurance';

const NB_DAYS_LIMIT_RAPPEL_HAMON = 30;

function ResilHamon() {
  const {
    params: { id: policyNumber },
  } = useRouteMatch();
  const classes = useStyles();
  const { authState } = useOktaAuth();
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const [police, setPolice] = useState(null);
  const [form, setForm] = useState({});
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState({});
  const [auth, setAuth] = useState(null);

  useEffect(() => {
    (async () => {
      const {
        isAuthenticated,
        accessToken: { accessToken: token },
      } = authState;
      const auth = { isAuthenticated, token };
      setAuth(auth);

      try {
        const policePromise = policyApi.getPolice(auth, policyNumber);

        const policeRes = await policePromise;
        setPolice(policeRes);
      } catch (error) {
        enqueueSnackbar(t('dashboard.resilHamon.form.message.error'), {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    })();
  }, [authState]);

  const handleConfirm = async () => {
    const errorObj = {
      hasError: !form.inssuranceId || !form.ancienPolicyNumber,
      msgError: t('dashboard.resilHamon.form.message.error_form'),
    };

    setError(errorObj);

    if (errorObj.hasError) return;

    try {
      setLoading(true);

      let promises = [];

      if (files && files.length) {
        promises = files.map((file) => {
          const formData = new FormData();
          formData.append('file', file.file);
          return policyApi.uploadFileToGed(
            auth,
            policyNumber,
            file.type,
            formData
          );
        });
      }

      promises.push(policyApi.resilHamon(auth, policyNumber, form));

      await Promise.all(promises);
      history.push(`/polices/${policyNumber}`);

      enqueueSnackbar(t('dashboard.resilHamon.form.message.msg'), {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(t('dashboard.resilHamon.form.message.error'), {
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleChangeFile = ({ target: { files: newFiles } }) => {
    const newfile = {
      name: newFiles[0].name,
      file: newFiles[0],
      type: 'POL_ATT',
    };

    setFiles([...files, newfile]);
  };

  const handleRemoveFile = (index) => {
    const newFiles = files;
    newFiles.splice(index, 1);

    setFiles([...newFiles]);
  };

  if (loading || !police) return <Loading />;

  const dateLimitRappel = addUnitTime(police.startDate, {
    nbDays: -1 * NB_DAYS_LIMIT_RAPPEL_HAMON,
  });

  const dateLimiteRappelDepassee =
    !dateLimitRappel || new Date().getTime() > dateLimitRappel.getTime();

  const documentsHamonDejaEnvoyes =
    police.informationPoliceHamon?.inssuranceName ||
    police.informationPoliceHamon?.ancienPolicyNumber;

  const policeNonConcerneeParResilHamon =
    !police?.informationPoliceHamon?.terminationTodo;

  if (
    policeNonConcerneeParResilHamon ||
    dateLimiteRappelDepassee ||
    documentsHamonDejaEnvoyes
  ) {
    history.push(`/polices/${policyNumber}`);
  }

  return (
    <Box mb={10}>
      <Header title={t('dashboard.resilHamon.form.header.title')} />
      <Box className={classes.policyContainer}>
        <Grid container>
          <Box
            className={classes.container}
            width={1}
            display="flex"
            justifyContent="center"
          >
            <Grid item xs={12} lg={8}>
              <PoliceInfo displayLinkButton={false} police={police} />
              <InfoAncienneCompagnie
                auth={auth}
                form={form}
                onChange={(newForm) => setForm(newForm)}
                error={error}
                files={files}
                setFiles={setFiles}
              />
              <AttestationAssurance
                files={files}
                onRemove={handleRemoveFile}
                onChange={handleChangeFile}
              />
            </Grid>
          </Box>
        </Grid>
        <Grid container>
          <Box
            display="flex"
            width={1}
            alignItems="center"
            flexDirection="column"
            className={classes.confirmContainer}
          >
            <Box my={3} width={1} display="flex" justifyItems="center">
              <Grid container justifyContent="center">
                <WhiteContainedButton
                  align="center"
                  variant="contained"
                  color="primary"
                  onClick={handleConfirm}
                >
                  {t('dashboard.resilHamon.form.info.send')}
                </WhiteContainedButton>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
}

export { NB_DAYS_LIMIT_RAPPEL_HAMON };

export default ResilHamon;
