import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { withStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';

import Field from 'components/Field';
import useStyles from './style';

const CenteredDialogTitle = withStyles({
  root: {
    textAlign: 'center',
  },
})(DialogTitle);

const ChildrenModal = (props) => {
  const { open, onClose, child, onValidate } = props;
  const [fields, setFields] = useState(child);
  const classes = useStyles();
  const { t } = useTranslation();

  const LINK_TYPES = [
    { code: '', label: '' },
    { code: 'ENFANT', label: t('common.child') },
    {
      code: 'COLOCATAIRE',
      label: `${t('common.roommate')}/${t('common.coInsured')}`,
    },
  ];

  const handleChange = (e) => {
    const newFields = { ...fields };

    newFields[e.target.id] = e.target.value;
    setFields(newFields);
  };

  const handleChangeDate = (key, date) => {
    const newDate = date.target ? date.target.value : date;
    const newFields = { ...fields };
    newFields[key] = newDate;
    setFields(newFields);
  };

  const handleSelectChange = (event) => {
    const { target } = event;
    const { value } = target;

    const newFields = { ...fields };
    const foundItem = LINK_TYPES.find((item) => item.code === value);
    if (foundItem) {
      newFields[target.name] = foundItem;
      setFields(newFields);
    }
  };

  const handleValidate = () => {
    const newFields = { ...fields };
    setFields(null);

    if (child) onValidate(newFields, true);
    else onValidate(newFields);
  };

  React.useEffect(() => {
    setFields(child);
  }, [child]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="sm"
      justifyContent="center"
      className="ChildrenModal"
    >
      <Box p={5}>
        <CenteredDialogTitle>
          {child ? t('common.modification') : t('common.addition')}
        </CenteredDialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <RadioGroup
                  row
                  value={fields ? fields['title'] : ''}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="M."
                    control={<Radio id="title" />}
                    label={t('common.formFields.mr')}
                    labelPlacement="start"
                  />
                  <FormControlLabel
                    value="Mme"
                    control={<Radio id="title" />}
                    label={t('common.formFields.ms')}
                    labelPlacement="start"
                  />
                </RadioGroup>
              </Grid>
              <Grid item>
                <FormControl fullWidth>
                  <InputLabel
                    id="type"
                    classes={{
                      formControl: classes.inputLabelFormControl,
                      shrink: classes.inputLabelShrink,
                    }}
                  >
                    Type
                  </InputLabel>
                  <Select
                    name="linkType"
                    labelId="linkType"
                    onChange={handleSelectChange}
                    label="Type"
                    defaultValue={
                      fields
                        ? _get(fields, 'linkType.code', '')
                        : LINK_TYPES[0].code
                    }
                    variant="outlined"
                    labelWidth={400}
                    fullWidth
                  >
                    {LINK_TYPES.map((linkType) => {
                      return (
                        <MenuItem key={linkType.code} value={linkType.code}>
                          {linkType.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Field
                  id="lastName"
                  onChange={handleChange}
                  label={t('common.formFields.lastname')}
                  type="text"
                  variant="outlined"
                  value={fields ? fields['lastName'] : ''}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  id="firstName"
                  onChange={handleChange}
                  label={t('common.formFields.firstname')}
                  type="text"
                  variant="outlined"
                  value={fields ? fields['firstName'] : ''}
                  fullWidth
                />
              </Grid>
              <Grid item>
                <Field
                  id="birthDay"
                  type="date"
                  label={t('common.formFields.birthDate')}
                  onChange={(date) => handleChangeDate('birthDay', date)}
                  maxDate={new Date()}
                  maxDateMessage="La date ne peut pas être postérieure à la date du jour"
                  value={fields ? fields['birthDay'] : ''}
                  defaultValue={null}
                  variant="birthdate"
                  fullWidth
                />
              </Grid>
            </Grid>
          </form>

          <Box justifyContent="center" align="center" mt={3}>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={onClose}
              style={{ marginRight: 10 }}
            >
              {t('common.cancel')}
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handleValidate}
              disabled={
                !fields ||
                !fields.title ||
                !fields.birthDay ||
                !fields.firstName ||
                !fields.lastName ||
                !fields.linkType ||
                !fields.linkType.label ||
                !fields.linkType.code
              }
            >
              {child ? t('common.update') : t('common.add')}
            </Button>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

ChildrenModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  child: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    birthDate: PropTypes.string.isRequired,
  }),
  onValidate: PropTypes.func.isRequired,
};

export default ChildrenModal;
