import { Box, Grid, Paper } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import i18next from 'i18next';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import authApi from 'api/authApi';
import HomeMobile from 'assets/lotties/home-mobile.json';
import Home from 'assets/lotties/home.json';
import Lottie from 'components/Lottie';
import SignUpConfirmForm from './SignUpConfirmForm';
import SignUpSendNewLink from './SignUpSendNewLink';
import useStyles from './style';

const SignupConfirm = () => {
  const classes = useStyles();
  const { token } = useParams();
  const { t } = useTranslation();
  const [isOK, setIsOk] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [ tokenValid, isTokenValid ] = useState(false);

  const handleSubmit = async (newPassword) => {
    try {
      setLoading(true);

      await authApi.resetPassword(
        token,
        newPassword
      );
      
      enqueueSnackbar(t('auth.initPassword.successMessage'), {
        variant: 'success',
      });

      setIsOk(true);
    } catch (error) {
      if (error.errorCode && error.errorMessage) {
        const message = error.errorMessage[i18next.resolvedLanguage];
        enqueueSnackbar(message, { variant: 'error' });
      } else {
        enqueueSnackbar(t('auth.initPassword.errorMessage'), {
          variant: 'error',
        });
      }
    }
    setLoading(false);
  };

  const verify = async () => {
    try {
      await authApi.verifyRecoveryToken(token);
      isTokenValid(true);
    } catch (error) {
      console.log("token invalid", error);
    }
  };

  useEffect(() => {
    verify();
  }, [token]);

  return (
    <div className={classes.wrapper}>
      <Box className={classes.boxLottie}>
        <Hidden xsDown>
          <Lottie animationData={Home} isActive width={'100%'} />
        </Hidden>
        <Hidden smUp>
          <Lottie animationData={HomeMobile} isActive />
        </Hidden>
      </Box>
      <div className={classes.containerTop}>
        <Paper
          variant="elevation"
          elevation={0}
          className={classes.logoWrapper}
        >
          <img
            src={`${localStorage.getItem(
              'IMAGE_MANAGER_URL_GET'
            )}/assurimo/base/logo-assurimo.png`}
            className={classes.logo}
            width={380}
          />
        </Paper>

        <Paper variant="elevation" elevation={0} className={classes.paper}>
          {loading && (
            <LinearProgress
              variant="indeterminate"
              classes={{
                root: classes.linearProgressRoot,
                colorPrimary: classes.linearProgressColorPrimary,
                bar: classes.linearProgressBar,
              }}
            />
          )}
          <Typography variant="h3" classes={{ root: classes.title }}>
            {t('auth.initPassword.title')}
          </Typography>

          {
            tokenValid ?
            <Grid container item>
              {!isOK ? <SignUpConfirmForm onSubmit={handleSubmit} /> : (
                <div className={classes.containerBottom}>
                  <Grid container direction="column" spacing={2}>
                    <Grid item>
                      <Typography>
                        {t('auth.passwordCreation.passwordCreationSuccess')}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              )}
            </Grid>
            :
            <>
            <Box mb={2}>
              <Typography variant="body1" className={classes.contentText} >
                {t('auth.passwordCreation.tokenExpiredMessage')}
              </Typography>
            </Box>
            <SignUpSendNewLink />
            </>
            
          }
        </Paper>
      </div>
    </div>
  );
};

export default SignupConfirm;
