import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import './EndStep.scss';
import useStyles from './style';

import {
  Grid,
  Button,
  Container,
  Typography,
  Card,
  CardContent,
} from '@material-ui/core';

import Price from 'components/Price';
import GoogleLogo from 'assets/images/google.png';
import TrustpilotLogo from 'assets/images/trustpilot.png';

const splittingPeriodicities = [
  { code: 'M', label: 'mensuel' },
  { code: 'A', label: 'annuel' },
];

const EndStep = ({ data }) => {
  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();
  const periodicity = data.paymentSplitting || splittingPeriodicities[0];

  const handleClick = () => {
    history.push('/');
  };

  const userEmail = data.contact.mail;

  /* ETAPE DE FIN
  Toutes les données collectées et à envoyer à vos webservices sont disponibles dans data.
  */
  return (
    <Container maxWidth="sm" className="FinalStep">
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Typography
          variant="h4"
          color="primary"
          className="FinalStep_Firstname"
        >
          {data.contact.firstName},
        </Typography>
        <Typography variant="h4" color="textPrimary">
          {t('steps.endStep.congrats')}
        </Typography>
        <Typography
          variant="body1"
          color="textPrimary"
          className="FinalStep_SubscribedText"
          align="center"
        >
          {t('steps.endStep.youSubscribedPno')}
        </Typography>
        <Card className="EndStep_Hero_CardPrice">
          <CardContent classes={{ root: classes.cardContent }}>
            <Price
              size="big"
              price={data.price}
              periodicity={periodicity.code}
            />
            {/* <div className="EndStep_Hero_CardPrice_AddressCta">
              <Typography>
                Soit {parseFloat(data.prix.replace(',', '.')) * 12}€/an
              </Typography>
            </div> */}
          </CardContent>
        </Card>
        <br />
        <Typography variant="subtitle2" color="textPrimary">
          {t('steps.endStep.nextSteps')} :
        </Typography>
        <br />
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              1.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textPrimary">
              <b>{t('steps.endStep.signSpecialConditions.title')}</b>
            </Typography>
          </Grid>
          <Typography
            variant="body2"
            color="textPrimary"
            classes={{ root: classes.description }}
          >
            <Trans i18nKey="steps.endStep.signSpecialConditions.description">
              <a
                href={`http://${userEmail.split('@')[1]}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {{ userEmail }}
              </a>
            </Trans>
          </Typography>
        </Grid>
        <br />
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              2.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textPrimary">
              <b>{t('steps.endStep.leaveReview.title')}</b>
            </Typography>
          </Grid>
          <Typography
            variant="body2"
            color="textPrimary"
            classes={{ root: classes.description }}
          >
            {t('steps.endStep.leaveReview.description')}
          </Typography>
          <Grid
            item
            container
            justifyContent="space-around"
            alignItems="center"
            direction="row"
            classes={{ root: classes.linksContainer }}
          >
            <Grid
              container
              item
              direction="column"
              xs={6}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <a
                  href="https://g.page/r/CYatEFjsZaHdEAg/review"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={GoogleLogo} height={40} />
                </a>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  href="https://g.page/r/CYatEFjsZaHdEAg/review"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('steps.endStep.writeReview')}
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              item
              direction="column"
              xs={6}
              alignItems="center"
              justifyContent="center"
            >
              <Grid item>
                <a
                  href="https://fr.trustpilot.com/evaluate/www.assurimo.fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={TrustpilotLogo} height={40} />
                </a>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  href="https://fr.trustpilot.com/evaluate/www.assurimo.fr"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t('steps.endStep.writeReview')}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <br />

        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              3.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textPrimary">
              <b>{t('steps.endStep.enjoyAccomodations.title')}</b>
            </Typography>
          </Grid>
        </Grid>
        <br />
        <Grid container alignItems="center" justifyContent="center" spacing={1}>
          <Grid item>
            <Typography variant="subtitle2" color="primary">
              4.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body2" color="textPrimary">
              <b>{t('steps.endStep.goToDashboard.title')}</b>
            </Typography>
          </Grid>
          <Typography
            variant="body2"
            color="textPrimary"
            classes={{ root: classes.description }}
          >
            {t('steps.endStep.goToDashboard.description')}
          </Typography>
        </Grid>

        <br />
        <br />
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={handleClick}
        >
          {t('steps.endStep.goToClientSpace')}
        </Button>
        <br />
      </Grid>
    </Container>
  );
};

EndStep.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default EndStep;
