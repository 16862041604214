import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  lottie: {
    display: 'flex',
    justifyContent: 'center',
    height: (props) => props.height,
    width: (props) => props.width,
    margin: 'auto',
    transform: 'translateY(-5%)',
  },
  container: {
    filter: 'grayscale(0.85)',
    transition: 'filter 0.3s ease-out',
  },
  hovered: {
    filter: 'grayscale(0)',
  },
  isNotFloor: {
    filter: 'none !important',
    transform: 'translateY(-15%)',
  },
  isFloor: {
    filter: 'none !important',
    transform: 'translateY(5%)',
  },
  '@media screen and (max-width: 960px)': {
    isFloor: {
      display: 'none',
    },
    hideLottieOnMobile: {
      display: 'none',
    },
  },
  color: {
    filter: 'none !important',
  },
}));

export default useStyles;
