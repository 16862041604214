import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Button, Typography, Hidden } from '@material-ui/core';
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons';
import LinkIcon from '@material-ui/icons/Link';
import Lottie from 'components/Lottie';
import HouseLottie from 'assets/lotties/house.json';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useStyles from './style';

const PoliceInfo = (props) => {
  const classes = useStyles();

  const { displayLinkButton, policy, onClick } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const handleClickDetail = () => {
    history.push(`/polices/${policy.policyNumber}`);
  };

  return (
    <Box
      mt={2}
      p={2}
      width="100%"
      className={`${classes.container} ${onClick ? classes.clickable : ''}`}
      onClick={() => onClick(policy)}
    >
      <Grid container alignItems="stretch">
        <Grid container item xs={12} md={12} wrap="nowrap">
          <Grid item xs={4} md={2}>
            <Box>
              <div className={classes.visual}>
                <Lottie animationData={HouseLottie} isActive height={48} />
                <div className={classes.linkIconContainer}>
                  <LinkIcon className={classes.linkIcon} />
                </div>
              </div>
            </Box>
          </Grid>
          <Grid item xs={8} md={12}>
            <Box align="left">
              <Hidden smDown>
                <Typography variant="subtitle1">
                  {t(`dashboard.sinistre.form.police.${policy.contractType.code.toLowerCase()}`)}
                </Typography>
              </Hidden>
              <Hidden mdUp>
                <Typography variant="body1">
                  {t(`dashboard.sinistre.form.police.${policy.contractType.code.toLowerCase()}`)}
                </Typography>
              </Hidden>
              <Grid container>
                <Grid item xs={12} md={4} align="left">
                  <Hidden smDown>
                    <Typography variant="caption">
                      {t('dashboard.sinistre.form.police.adress')}
                    </Typography>
                    <Typography variant="body1" className={classes.info}>
                      {policy.policyKey}
                    </Typography>
                  </Hidden>
                  <Hidden mdUp>
                    <Typography variant="caption">
                      {policy.policyKey}
                    </Typography>
                  </Hidden>
                </Grid>
                <Grid item xs={12} md={4} align="left">
                  <Hidden smDown>
                    <Typography variant="caption">
                      {t('dashboard.sinistre.form.police.policyNumber')}
                    </Typography>
                    <Typography variant="body1" className={classes.info}>
                      {policy.policyNumber}
                    </Typography>
                  </Hidden>
                  <Hidden mdUp>
                    <Typography variant="caption">
                      {policy.policyNumber}
                    </Typography>
                  </Hidden>
                </Grid>
                <Grid item xs={12} md={4} align="left">
                  <Hidden smDown>
                    <Typography variant="caption">
                      {t('dashboard.sinistre.form.police.policyNumberCompany')}
                    </Typography>
                    <Typography variant="body1" className={classes.info}>
                      {policy.policyNumberCompany}
                    </Typography>
                  </Hidden>
                  <Hidden mdUp>
                    <Typography variant="caption">
                      {policy.policyNumberCompany}
                    </Typography>
                  </Hidden>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {displayLinkButton && (
          <Grid
            item
            container
            alignItems="center"
            justify="flex-end"
            xs={0}
            md={2}
          >
            <Hidden smDown>
              <Button
                variant="outlined"
                endIcon={<ArrowForwardIosIcon className={classes.buttonIcon} />}
                className={classes.button}
                onClick={handleClickDetail}
              >
                <span>{t('dashboard.sinistre.form.police.detail')}</span>
              </Button>
            </Hidden>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

PoliceInfo.propTypes = {
  displayLinkButton: PropTypes.bool,
  policy: PropTypes.object,
  onClick: PropTypes.func,
};

export default PoliceInfo;
