import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import Collapse from '@material-ui/core/Collapse';
import { useTheme } from '@material-ui/core/styles';

import Icon from 'components/Icon';
import useStyles from './style';
import { useTranslation } from 'react-i18next';

const Item = (props) => {
  const { item } = props;
  const classes = useStyles({ open });
  const location = useLocation();
  const history = useHistory();

  const theme = useTheme();
  const { t } = useTranslation();

  const isOneOfSubItemsActive = (item) =>
    item.subItems?.find((subItem) =>
      location?.pathname.startsWith(subItem.link)
    )
      ? true
      : false;

  const [open, setOpen] = useState(isOneOfSubItemsActive(item));

  const renderItem = (item, isSub) => {
    const isActive =
      item.link === '/'
        ? location?.pathname === '/'
        : location?.pathname.startsWith(item.link) ||
          isOneOfSubItemsActive(item);

    const handleClick = (item) => {
      //item.subItems && setOpen(!open);
      item.subItems ? setOpen(!open) : history.push(item.link);
    };

    return (
      <>
        <ListItem
          component={Button}
          to={item.link}
          onClick={() => handleClick(item)}
          className={`${item} ${isSub && classes.subItem}`}
        >
          <ListItemIcon>
            <Icon
              className={item.icon}
              color={isActive ? 'primary' : 'secondary'}
              classes={{ root: classes.icon }}
            />
          </ListItemIcon>
          <ListItemText
            disableTypography
            primary={
              <Typography
                variant="body1"
                classes={{ root: classes.text }}
                style={{
                  color: isActive
                    ? theme.palette.orange.main
                    : theme.palette.blue.main,
                }}
              >
                {t(item.label)}
              </Typography>
            }
          />
          {item.subItems && (
            <ListItemIcon>
              <Icon
                className={open ? 'fas fa-angle-up' : 'fas fa-angle-down'}
                color={isActive ? 'primary' : 'secondary'}
                classes={{
                  root: classes.icon,
                }}
              />
            </ListItemIcon>
          )}
        </ListItem>
        {item.subItems && renderSubItems(item.subItems)}
      </>
    );
  };

  const renderSubItems = (subItems) => {
    return (
      <div>
        <Collapse in={open}>
          <List component="div" disablePadding>
            {subItems.map((subItem, index) => (
              <div key={index}>{renderItem(subItem, true)}</div>
            ))}
          </List>
        </Collapse>
      </div>
    );
  };

  return renderItem(item);
};

Item.propTypes = {
  item: PropTypes.shape(),
};

export default React.memo(Item);
