import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import { formatNumberWithSystem } from 'utils';
import MediumValueImage from 'assets/images/medium_value.svg';
import useStyles from './style';

const MySelection = (props) => {
  const { polichab, policy } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box mt={2} p={2} width="100%" className={classes.greyBox}>
      <Grid container>
        <Box mb={2}>
          <Typography variant="h6" color="secondary">
            {t('dashboard.police.details.mySelection.title')}
          </Typography>
        </Box>
        <Grid item container>
          <Grid item container xs={12} md={isDownMd ? 12 : 9}>
            <Grid item container xs={12}>
              <Grid item xs={4}>
                <Box mb={2}>
                  <Typography variant="body2" color="secondary">
                    {t('common.worthOfProperty')}
                  </Typography>
                  <Typography variant="body2">
                    {formatNumberWithSystem(
                      polichab.result.movableCapitalAmount
                    )}
                    €
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={4}>
                <Box mb={2}>
                  <Typography variant="body2" color="secondary">
                    {t('common.valuableItems')}
                  </Typography>
                  <Typography variant="body2">
                    {formatNumberWithSystem(
                      polichab.result.valuableObjectsAmount
                    )}
                    €
                  </Typography>
                </Box>
              </Grid>
              {policy.rateOptions && (
                <Grid item xs={4}>
                  <Typography variant="body2" color="secondary">
                    {t('common.deductible')}
                  </Typography>
                  <Typography variant="body2">
                    {policy.rateOptions.deductible.label}€
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Grid item container xs={12}>
              {policy.rateOptions && (
                <Grid item xs={4}>
                  <Typography variant="body2" color="secondary">
                    {t('common.outdoorFacilities')}
                  </Typography>
                  <Typography variant="body2">
                    {policy.rateOptions.landscaping.label}
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Hidden mdDown>
            <Grid container item md={3} xs={0}>
              <img
                src={MediumValueImage}
                alt="Valeur moyenne"
                style={{
                  maxWidth: '100%',
                }}
              />
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Box>
  );
};

MySelection.propTypes = {
  policy: PropTypes.object,
  polichab: PropTypes.object,
};

export default MySelection;
