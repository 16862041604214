import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import MuiIcon from '@material-ui/core/Icon';
import { loadCSS } from 'fg-loadcss';

const Icon = (props) => {
  const { title, className, classes } = props;
  if (className && className.includes('fa-')) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
      loadCSS(
        'https://use.fontawesome.com/releases/v5.10.2/css/all.css',
        document.querySelector('#font-awesome-css')
      );
    }, []);
  }
  return (
    <MuiIcon className={className} classes={classes} {...props}>
      {title && title}
    </MuiIcon>
  );
};

Icon.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  classes: PropTypes.object,
};

export default Icon;
