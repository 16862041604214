import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AddressForm from 'components/AddressForm';
import { isEmpty } from 'utils/form-validation';
import Title from 'components/Title';

const PersonalAddressStep = (props) => {
  const { data, onConfirm, setUserData } = props;
  const { t } = useTranslation();

  return (
    <>
      <Title marginBottom={0}>
        {t('pno.personalAddress.modifyYourPersonalAddress')}
      </Title>
      <Title variant="subtitle1">
        {t('pno.personalAddress.addressNotModified')}
      </Title>
      <AddressForm
        automaticFieldTitle={t('pno.personalAddress.whatIsPersonalAddress')}
        manualFields={[
          {
            key: 'personalAddress.address1',
            label: t('pno.addressStep.address'),
            type: 'text',
            invalid: isEmpty(data.personalAddress?.address1),
            helperText: (() => {
              if (isEmpty(data.personalAddress?.address1)) {
                return t('pno.addressStep.errors.emptyAddress');
              }
            })(),
            value: data.personalAddress?.address1 || '',
          },
          {
            key: 'personalAddress.address2',
            label: t('pno.addressStep.additionalAddress'),
            type: 'text',
            invalid: false,
            value: data.personalAddress?.address2 || '',
            shrink:
              data.personalAddress?.address1 &&
              data.personalAddress?.zipCode &&
              data.personalAddress?.city,
          },
          {
            key: 'personalAddress.zipCode',
            label: t('pno.addressStep.zipCode'),
            type: 'text',
            invalid:
              isEmpty(data.personalAddress?.zipCode) ||
              isNaN(data.personalAddress?.zipCode),
            helperText: (() => {
              if (isEmpty(data.personalAddress?.zipCode)) {
                return t('pno.addressStep.errors.emptyZipCode');
              }

              if (isNaN(data.personalAddress?.zipCode)) {
                return t('pno.addressStep.errors.invalidZipCode');
              }
            })(),
            value: data.personalAddress?.zipCode,
          },
          {
            key: 'personalAddress.city',
            label: t('pno.addressStep.city'),
            type: 'text',
            invalid: isEmpty(data.personalAddress?.city),
            helperText: (() => {
              if (isEmpty(data.personalAddress?.city)) {
                return t('pno.addressStep.errors.emptyCity');
              }
            })(),
            value: data.personalAddress?.city || '',
          },
        ]}
        onChange={setUserData}
        onConfirm={onConfirm}
        addressField={'personalAddress'}
        disabled={
          !!(
            data.personalAddress?.address1 &&
            data.personalAddress?.city &&
            data.personalAddress?.zipCode
          )
        }
        popin={true}
      />
    </>
  );
};

PersonalAddressStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
};

export default PersonalAddressStep;
