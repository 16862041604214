import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Redirect, useLocation } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import LinearProgress from '@material-ui/core/LinearProgress';
import FormHelperText from '@material-ui/core/FormHelperText';

import Field from 'components/Field';
import Popin from './Popin';
import useStyles from './style';

const Login = (props) => {
  const {
    handleChange,
    handleClickNext,
    sessionToken,
    resetPassword,
    authState,
    error,
    loading,
    steps,
    currentStep,
    isButtonDisabled,
    redirectTo,
    hideButton,
    setCurrentStep,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [isPopinOpen, setIsPopinOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const location = useLocation();
  const ref = useRef(null);

  const handleValidatePopin = () => {
    setIsPopinOpen(false);
    resetPassword();
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClickNext(event);
    }
  };

  const handleLoginChange = (event) => {
    const newEvent = { ...event };
    newEvent.target.value = newEvent.target.value.toLowerCase().trim();

    handleChange(newEvent);
  };

  const getVisibilityIcon = () => {
    return showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />;
  };

  useEffect(() => {
    if (ref.current !== null && ref.current.swiper !== null) {
      ref.current.swiper.slideNext();
    }
  }, [currentStep]);

  const renderLoginForm = () => {
    return (
      <Grid item>
        <Field
          id="login"
          onChange={handleLoginChange}
          label={t('auth.login.email')}
          type="text"
          // error={complexityPasswordError}
          variant="outlined"
          fullWidth
          autoFocus
          inputProps={{
            onKeyPress: (e) => handleKeyDown(e),
          }}
        />
      </Grid>
    );
  };

  const renderPasswordForm = () => {
    return (
      <>
        <Grid item>
          <TextField
            id="password"
            onChange={handleChange}
            label={t('auth.login.password')}
            type={showPassword ? 'text' : 'password'}
            // error={complexityPasswordError}
            variant="outlined"
            fullWidth
            autoFocus
            inputProps={{
              onKeyPress: (e) => handleKeyDown(e),
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {getVisibilityIcon()}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          {currentStep === steps.PASSWORD && (
            <Link
              component="button"
              variant="body1"
              onClick={() => {
                setCurrentStep(steps.RESET_PASSWORD);
              }}
            >
              <Typography classes={{ root: classes.forgetPassword }}>
                {t('auth.login.forgotPassword')}
              </Typography>
            </Link>
          )}
        </Grid>
      </>
    );
  };

  const renderResetPasswordForm = () => {
    return (
      <Grid container item spacing={2}>
        <Grid item>
          {!hideButton && (
            <Typography variant="subtitle1">
              {t('auth.recovery.sureToResetPassword')}
            </Typography>
          )}
          <Typography
            variant={!hideButton ? 'caption' : 'body1'}
            color="secondary"
          >
            {t('auth.recovery.resetExplanation')}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  if (sessionToken) return null;

  if (!authState) return <div>Loading...</div>;

  if (
    authState.isAuthenticated &&
    redirectTo &&
    !location.pathname === '/souscription'
  )
    return <Redirect to={{ pathname: redirectTo }} />;

  return (
    <>
      <>
        {loading && (
          <LinearProgress
            variant="indeterminate"
            classes={{
              root: classes.linearProgressRoot,
              colorPrimary: classes.linearProgressColorPrimary,
              bar: classes.linearProgressBar,
            }}
          />
        )}
        {/* <Typography classes={{ root: classes.title }}>Se connecter</Typography> */}
        <Grid container direction="column" spacing={2}>
          {currentStep === steps.LOGIN && renderLoginForm()}
          {currentStep === steps.PASSWORD && renderPasswordForm()}
          {currentStep === steps.RESET_PASSWORD && renderResetPasswordForm()}
          {!hideButton && currentStep !== steps.RESET_PASSWORD && (
            <Grid container item justifyContent="flex-end">
              <Button
                align="center"
                variant="contained"
                color="primary"
                onClick={handleClickNext}
                disabled={isButtonDisabled()}
              >
                <Typography classes={{ root: classes.buttonText }}>
                  {currentStep === steps.LOGIN
                    ? t('common.next')
                    : t('auth.login.login')}
                </Typography>
              </Button>
            </Grid>
          )}
          {!hideButton && currentStep === steps.RESET_PASSWORD && (
            <Grid container item spacing={2}>
              <Grid
                xs={6}
                item
                container
                align="center"
                justifyContent="flex-end"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => resetPassword()}
                >
                  {t('common.yes')}
                </Button>
              </Grid>
              <Grid
                xs={6}
                item
                container
                align="center"
                justifyContent="flex-start"
              >
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setCurrentStep(steps.PASSWORD)}
                >
                  {t('common.no')}
                </Button>
              </Grid>
            </Grid>
          )}
        </Grid>
        <FormHelperText error={true} variant="outlined">
          {error}
        </FormHelperText>
      </>
      <Popin
        title={t('auth.recovery.sureToResetPassword')}
        open={isPopinOpen}
        onClose={() => setIsPopinOpen(false)}
        onValidate={handleValidatePopin}
        validateButtonLabel={t('common.yes')}
        cancelButtonLabel={t('common.no')}
        showCancelButton
        disableEscapeKeyDown={false}
      />
    </>
  );
};

Login.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleClickNext: PropTypes.func.isRequired,
  sessionToken: PropTypes.any,
  resetPassword: PropTypes.func.isRequired,
  authState: PropTypes.any.isRequired,
  error: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  steps: PropTypes.shape().isRequired,
  currentStep: PropTypes.string.isRequired,
  isButtonDisabled: PropTypes.func.isRequired,
  redirectTo: PropTypes.string,
  hideButton: PropTypes.bool,
  setCurrentStep: PropTypes.func.isRequired,
};

Login.defaultProps = {
  hideButton: false,
};

export default Login;
