class ApiError extends Error {
  constructor(error) {
    super(error.errorCode);

    this.errorCode = error.errorCode;
    this.errorMessage = error.errorMessage;
    this.errorStatus = error.errorStatus;

    Error.captureStackTrace(this, ApiError);
  }
}

export default ApiError;
