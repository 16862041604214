import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loadingText: {
    color: theme.palette.white.main,
    alignSelf: 'center',
    marginBottom: 10,
  },
}));

export default useStyles;
