import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';

import policyApi from 'api/policyApi';
import { useStyles } from './style';
import { PAGE_SECTION_SHOW } from 'views/Dashboard/Customer/PolicyPage/helper';
import { UserContext } from 'contexts/user';
import { isPoliceEligibleADeclaSin } from 'views/Dashboard/Customer/SinistreDeclarationPage/helper';
import SinistreItem from './SinistreItem';

const Sinistres = ({ police, scrollRef }) => {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { t } = useTranslation();
  const [userState] = useContext(UserContext);

  const [sinisters, setSinisters] = useState([]);

  useEffect(() => {
    const sortClaims = (claims) => {
      const isInProgress = (statusCode) => {
        return ['EC', 'PREDECLA', 'PARCOURS'].includes(statusCode);
      };
      const sortByStatus = (claim1, claim2) => {
        const { code: code1 } = claim1.status;
        const { code: code2 } = claim2.status;

        if (isInProgress(code1) && isInProgress(code2)) {
          return sortByDate(claim1, claim2);
        } else if (isInProgress(code1) && !isInProgress(code2)) {
          return -1;
        } else if (!isInProgress(code1) && isInProgress(code2)) {
          return 1;
        } else if (!isInProgress(code1) && !isInProgress(code2)) {
          return sortByDate(claim1, claim2);
        }
      };

      const sortByDate = (claim1, claim2) => {
        if (moment(claim1.eventDate) < moment(claim2.eventDate)) return 1;
        else if (moment(claim1.eventDate) > moment(claim2.eventDate)) return -1;
        else return 1;
      };

      claims.sort((claim1, claim2) => sortByDate(claim1, claim2));
      return claims.sort((claim1, claim2) => sortByStatus(claim1, claim2));
    };

    (async () => {
      try {
        const response = await policyApi.listAllClaims(
          userState.auth,
          police.policyNumber
        );
        setSinisters(sortClaims(response));
      } catch (error) {
        enqueueSnackbar(t('dashboard.sinistre.list.list_error'), {
          variant: 'error',
        });
        console.log(error);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMrhOuPno = PAGE_SECTION_SHOW[police.contractType.code].sinisters;
  const isDateEffetPoliceDansPasse = moment(police.startDate).isSameOrBefore(
    moment()
  );

  const afficherBlocSinistres = isMrhOuPno && isDateEffetPoliceDansPasse;
  const afficherBoutonDeclarationSin = isPoliceEligibleADeclaSin(police);

  return (
    afficherBlocSinistres && (
      <Grid container className={classes.sinistres} ref={scrollRef}>
        <Box p={2} className={classes.container} width="100%">
          <Grid container>
            <Box mb={2} className={classes.boxHeader}>
              <Typography variant="h5" color="secondary">
                {t('dashboard.sinistre.list.title')}
              </Typography>
              {afficherBoutonDeclarationSin && (
                <Button
                  color="primary"
                  startIcon={<AddCircleIcon />}
                  component={Link}
                  to={`/polices/${police.policyNumber}/nouveau-sinistre`}
                >
                  {t('dashboard.policies.declareClaim')}
                </Button>
              )}
            </Box>
            {sinisters.length > 0 && (
              <Grid item container>
                {sinisters.map((item, index) => (
                  <SinistreItem key={index} sinistre={item} />
                ))}
              </Grid>
            )}
          </Grid>
        </Box>
      </Grid>
    )
  );
};

Sinistres.propTypes = {
  police: PropTypes.object,
  auth: PropTypes.object,
  scrollRef: PropTypes.object,
};

export default Sinistres;
