import React from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import CurrentUser from './CurentUser';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
}));

const AccountPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        className={classes.title}
        justify-content="space-between"
        alignItems="center"
      >
        <Box>
          <Typography variant="h3">{t('dashboard.menu.myAccount.title')}</Typography>
        </Box>
      </Grid>
      <Grid container item xs={12}>
        <CurrentUser />
      </Grid>
    </Grid>
  );
};

export default AccountPage;
