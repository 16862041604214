import {
  getHeaderFetch,
  responseWithJson,
  responseWithStatus,
} from './httpUtil';

const BASE_URL = '/v2/users';

const userApi = {
  getExternalUser: async (auth) => {
    const url = `${BASE_URL}/external`;

    const response = await fetch(url, { headers: getHeaderFetch(auth) });

    return responseWithStatus(response);
  },

  getInternalUser: async (auth) => {
    const url = `${BASE_URL}/internal`;

    const response = await fetch(url, { headers: getHeaderFetch(auth) });
    return responseWithJson(response);
  },
};

export default userApi;
