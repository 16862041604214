import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Lottie from 'components/Lottie';
import _get from 'lodash/get';
import getHumanReadableString from 'utils/getHumanReadableString';
import {
  PNO_TYPE_MAISON,
  PNO_TYPE_APPARTEMENT,
  PNO_TYPE_GARAGE,
  PNO_TYPE_LOCAL_COMMERCIAL,
  PNO_TYPE_MONUMENT_HISTORIQUE,
} from 'constants/index';
import HouseLottie from 'assets/lotties/house.json';
import ApartmentLottie from 'assets/lotties/apartment.json';
import GarageLottie from 'assets/lotties/garage.json';
import CommercialPremiseLottie from 'assets/lotties/localcommercial.json';
import useStyles from '../style';

const MyGood = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const { data } = props;

  const isMaleOrfemale =
    data.housingType === PNO_TYPE_MAISON ? 'female' : 'male';

  const getTypeOfThis = () => {
    switch (data.housingType) {
      case PNO_TYPE_MAISON:
        return 'female';
      case PNO_TYPE_APPARTEMENT:
      case PNO_TYPE_MONUMENT_HISTORIQUE:
        return 'male';
      case PNO_TYPE_GARAGE:
      case PNO_TYPE_LOCAL_COMMERCIAL:
        return 'maleAlt';
    }
  };

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const isTypeHabitation =
    data.housingType === PNO_TYPE_APPARTEMENT ||
    data.housingType === PNO_TYPE_MAISON;

  const getAnimationData = () => {
    switch (data.housingType) {
      case PNO_TYPE_APPARTEMENT:
        return ApartmentLottie;
      case PNO_TYPE_MAISON:
        return HouseLottie;
      case PNO_TYPE_GARAGE:
        return GarageLottie;
      case PNO_TYPE_LOCAL_COMMERCIAL:
        return CommercialPremiseLottie;
    }
  };

  return (
    <Grid item xs={12}>
      <Card classes={{ root: classes.reminderCard }}>
        <CardContent align={mobile ? 'center' : 'left'}>
          <Grid
            container
            direction={mobile ? 'column' : 'row'}
            alignItems="center"
          >
            <Grid item xs={12} md={4}>
              <Lottie animationData={getAnimationData()} isActive={true} />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" component="span">
                <Trans
                  i18nKey="pno.resultStep.myGoodInfos.wantInsurance"
                  values={{
                    interpolation: { escapeValue: false },
                    propertyType: getHumanReadableString(
                      'housingType',
                      t,
                      data.housingType
                    ).toLowerCase(),
                  }}
                />
              </Typography>
              <Typography variant="body2" component="span">
                {` ${_get(data, 'contractAddress.address1', '')}`}
                {data.contractAddress.address2 && ' '}
                {`${_get(data, 'contractAddress.address2', '')}`}
                {data.contractAddress.residency &&
                  `, ${t('common.residency')} ${_get(
                    data,
                    'contractAddress.residency',
                    ''
                  )} `}
                {data.contractAddress.building &&
                  ` ${t('common.building')} ${_get(
                    data,
                    'contractAddress.building',
                    ''
                  )}`}
                {` ${t('common.at')} ${_get(data, 'contractAddress.city', '')}`}
                {'. '}
              </Typography>
              <br />
              <br />
              <Typography variant="body1" component="span">
                {t('pno.resultStep.myGoodInfos.this', {
                  context: getTypeOfThis(),
                })}{' '}
              </Typography>
              <Typography variant="body2" component="span">
                {getHumanReadableString(
                  'housingType',
                  t,
                  data.housingType
                ).toLowerCase()}{' '}
              </Typography>

              {isTypeHabitation && (
                <>
                  <Typography variant="body1" component="span">
                    {t('pno.resultStep.myGoodInfos.isMadeUp', {
                      context: isMaleOrfemale,
                    })}{' '}
                  </Typography>
                  <Typography variant="body2" component="span">
                    {t('pno.resultStep.myGoodInfos.room', {
                      count: parseInt(data.numberOfPrincipalRooms),
                    })}
                  </Typography>
                </>
              )}

              {!isTypeHabitation && (
                <Typography variant="body1" component="span">
                  {`${t('pno.resultStep.myGoodInfos.hasAnAreaOf')} ${parseInt(
                    data.area
                  )} m²`}
                </Typography>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

MyGood.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default MyGood;
