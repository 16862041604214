import useIframe from 'hooks/useIframe';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

const IframePayment = (props) => {
  const { isEnrolement, premiumUuid, onMessage } = props;

  const { iframeUrl } = useIframe();

  useEffect(() => {
    window.addEventListener('message', onMessage);
    return () => window.removeEventListener('message', onMessage);
  });

  return (
    <>
      {iframeUrl && (
        <iframe
          // sandbox="allow-scripts allow-same-origin"
          title="Paiement"
          src={`${iframeUrl}/payment?premiumUuid=${premiumUuid}${
            isEnrolement ? '&paidMonthly=true' : ''
          }`}
          width="100%"
          style={{ border: 'none', overflow: 'hidden', minHeight: 730 }}
        />
      )}
    </>
  );
};

IframePayment.propTypes = {
  isEnrolement: PropTypes.bool,
  premiumUuid: PropTypes.string.isRequired,
  onMessage: PropTypes.func,
};

export default IframePayment;
