import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  header: {
    position: 'fixed',
    top: 55,
    left: 0,
    width: '100vw',
    backgroundColor: '#fff',
    zIndex: 2,
    padding: '6px 20px',
    [theme.breakpoints.up('md')]: {
      padding: '6px 50px',
      width: 'calc(100% - 250px)',
      left: 250,
      top: 0,
    },
    [theme.breakpoints.up('lg')]: {
      padding: '6px 100px',
    },
  },
  arrowContainer: {
    marginRight: 0,
    [theme.breakpoints.up('md')]: {
      marginRight: theme.spacing(4),
    },
  },
  text: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
  },
}));

export default useStyles;
