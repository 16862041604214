import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

import { UserContext } from 'contexts/user';
import PopinMultiPages from 'components/PopinMultiPages';
import ContractSelectionPage from './ContractSelectionPage';
import ReasonPage from './ReasonPage';
import AssurimoPage from './AssurimoPage';
import AssistancePage from './AssistancePage';
import usePrevious from 'hooks/usePrevious';
import customerApi from 'api/customerApi';
import { getFilteredPolicies } from 'utils/policies';
import { getMessageError } from 'constants/common/errorCode';

const ContactPopin = (props) => {
  const { open, onClose } = props;
  const location = useLocation();
  const { t } = useTranslation();

  const [userState] = useContext(UserContext);
  const [showPopin, setShowPopin] = useState(open);
  const [currentPage, setCurrentPage] = useState('reasonPage');
  const [policies, setPolicies] = useState([]);
  const [selectedPolicy, setSelectedPolicy] = useState();
  const { enqueueSnackbar } = useSnackbar();

  const previousPage = usePrevious(currentPage);

  const handleClose = () => {
    onClose();
    setCurrentPage('reasonPage');
    setSelectedPolicy(null);
  };

  const handleSelectPolicy = (policy) => {
    setSelectedPolicy(policy);
    setCurrentPage('assistancePage');
  };

  const getPolicyById = (policyId) => {
    try {
      const policy = policies.find(
        (policy) => policy.policyNumber === policyId
      );
      return policy;
    } catch (error) {
      return null;
    }
  };

  const getPolicies = async () => {
    try {
      const response = await customerApi.getPolicies(
        userState.auth,
        userState.customerNumber
      );
      if (Array.isArray(response.entities)) {
        const filteredPolicies = getFilteredPolicies(response.entities);
        setPolicies(filteredPolicies);
      }
    } catch (err) {
      console.error(err);
      const messageError = getMessageError(err);
      enqueueSnackbar(t(messageError), { variant: 'error' });
    }
  };

  const getPolicyIdInLocation = () => {
    const splittedLocation = location.pathname.split('/').filter((a) => a);
    if (splittedLocation[0] === 'polices' && splittedLocation[1]) {
      return splittedLocation[1];
    } else return null;
  };

  useEffect(() => {
    const policyIdFromLocation = getPolicyIdInLocation();
    const foundPolicy = getPolicyById(policyIdFromLocation);

    if (foundPolicy) setSelectedPolicy(foundPolicy);
    else if (policies && policies.length === 1) {
      const newSelectedPolicy = policies[0];
      setSelectedPolicy(newSelectedPolicy);
    } else {
      setSelectedPolicy(null);
    }

    setShowPopin(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  useEffect(() => {
    getPolicies();
  }, []);

  const reasonOptions = [
    {
      value: 'info',
      label: t('dashboard.contact.reasons.options.contract'),
      goToNextPage: () => setCurrentPage('assurimoPage'),
    },
    {
      value: 'assistance',
      label: t('dashboard.contact.reasons.options.sinisterRescue'),
      goToNextPage: () => {
        if (selectedPolicy) {
          setCurrentPage('assistancePage');
        } else if (policies.length > 1) {
          setCurrentPage('contractSelectionPage');
        } else setCurrentPage('assistancePage');
      },
    },
  ];

  const getPreviousPage = (key) => {
    if (policies.length > 1) setSelectedPolicy(null);
    return key;
  };

  const pages = [
    {
      component: ReasonPage,
      key: 'reasonPage',
      options: reasonOptions,
    },
    {
      component: AssurimoPage,
      key: 'assurimoPage',
      previousPage: () => getPreviousPage('reasonPage'),
    },
    {
      component: ContractSelectionPage,
      key: 'contractSelectionPage',
      policies: policies,
      onClick: handleSelectPolicy,
      previousPage: () => getPreviousPage('reasonPage'),
    },
    {
      component: AssistancePage,
      key: 'assistancePage',
      policy: selectedPolicy,
      previousPage: () => getPreviousPage(previousPage),
      onChange: () => setCurrentPage('contractSelectionPage'),
    },
  ];

  return showPopin ? (
    <PopinMultiPages
      open={showPopin}
      onClose={handleClose}
      enableTitleArea={false}
      maxWidth="sm"
      pages={pages}
      title={t('dashboard.contact.title')}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
      maxHeight={630}
    />
  ) : (
    <></>
  );
};

ContactPopin.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onValidate: PropTypes.func,
  loading: PropTypes.bool,
  policies: PropTypes.array,
};

ContactPopin.defaultProps = {
  open: false,
};

export default ContactPopin;
