import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Button, Typography, Hidden } from '@material-ui/core';
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons';
import LinkIcon from '@material-ui/icons/Link';
import { makeStyles } from '@material-ui/core/styles';
import Lottie from 'components/Lottie';
import HouseLottie from 'assets/lotties/house.json';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#F2F7FF',
    borderRadius: 10,
    boxSizing: 'border-box',
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: 40,
      height: 40,
      padding: 0,
      minWidth: 'auto',
    },
  },
  buttonIcon: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '-8px',
    },
  },
  visual: {
    width: 58,
    height: 58,
    background: theme.palette.white.main,
    position: 'relative',
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: 48,
      height: 48,
    },
  },
  linkIconContainer: {
    position: 'absolute',
    top: -7,
    right: -7,
    color: theme.palette.white.main,
    background: '#36A6C1',
    borderRadius: '50%',
    width: 20,
    height: 20,
    fontSize: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  linkIcon: {
    width: '1rem',
  },
}));

PoliceInfo.propTypes = {
  displayLinkButton: PropTypes.bool,
  police: PropTypes.object,
};

function PoliceInfo(props) {
  const classes = useStyles();

  const { displayLinkButton, police } = props;
  const history = useHistory();
  const { t } = useTranslation();

  const handleClickDetail = () => {
    history.push(`/polices/${police.policyNumber}`);
  };

  return (
    <Box mt={2} p={2} width="100%" className={classes.container}>
      <Grid container alignItems="stretch">
        <Grid container item xs={12} md={10} wrap="nowrap">
          <Grid item xs={4} md={2}>
            <Box>
              <div className={classes.visual}>
                <Lottie animationData={HouseLottie} isActive height={48} />
                <div className={classes.linkIconContainer}>
                  <LinkIcon className={classes.linkIcon} />
                </div>
              </div>
            </Box>
          </Grid>
          <Grid item xs={8} md={10}>
            <Box align="left">
              <Hidden smDown>
                <Typography variant="subtitle1">
                  {t(`dashboard.resilHamon.form.police.${police.contractType.code.toLowerCase()}`)}
                </Typography>
              </Hidden>
              <Hidden mdUp>
                <Typography variant="body1">
                  {t(`dashboard.resilHamon.form.police.${police.contractType.code.toLowerCase()}`)}
                </Typography>
              </Hidden>
              <Grid container>
                <Grid item xs={12} md={6} align="left">
                  <Hidden smDown>
                    <Typography variant="caption">
                      {t('dashboard.resilHamon.form.police.adress')}
                    </Typography>
                    <Typography variant="body1">{police.policyKey}</Typography>
                  </Hidden>
                  <Hidden mdUp>
                    <Typography variant="caption">
                      {police.policyKey}
                    </Typography>
                  </Hidden>
                </Grid>
                <Grid item xs={12} md={6} align="left">
                  <Hidden smDown>
                    <Typography variant="caption">
                      {t('dashboard.sinistre.form.police.policyNumber')}
                    </Typography>
                    <Typography variant="body1">
                      {police.policyNumber}
                    </Typography>
                  </Hidden>
                  <Hidden mdUp>
                    <Typography variant="caption">
                      {police.policyNumber}
                    </Typography>
                  </Hidden>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
        {displayLinkButton && (
          <Grid
            item
            container
            alignItems="center"
            justify="flex-end"
            xs={0}
            md={2}
          >
            <Hidden smDown>
              <Button
                variant="outlined"
                endIcon={<ArrowForwardIosIcon className={classes.buttonIcon} />}
                className={classes.button}
                onClick={handleClickDetail}
              >
                <span>{t('dashboard.sinistre.form.police.detail')}</span>
              </Button>
            </Hidden>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default PoliceInfo;
