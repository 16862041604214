import React, {
  useEffect,
  useRef,
  useState,
  useContext,
  useLayoutEffect,
} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { UserContext } from 'contexts/user';
import policyApi from 'api/policyApi';
import useStyles from './style';
import { getMessageError } from 'constants/common/errorCode';
import { HeaderSkeleton } from 'components/DashboardHeader/skeleton';
import CertificateBeneficiarySkeleton from './Certificates/skeleton';
import PoliceHeader from './PolicyHeader';
import Beneficiaries from './Beneficiaries';
import Attestations from './Certificates';
import Quittances from './quittance/Quittances';
import QuittancesSkeleton from './quittance/skeleton';
import Garanties from './Guarantees';
import Sinistres from 'views/Dashboard/Customer/PolicyPage/Sinistres/index';
import GeneralConditions from './GeneralConditions';
import PolicyDetails from './PolicyDetails';
import ContractDetailsSkeleton from './PolicyDetails/ContractDetails/skeleton';
import MyCriteriaSkeleton from './PolicyDetails/MyCriteria/skeleton';
import MySelectionSkeleton from './PolicyDetails/MySelection/skeleton';
import PolicyStatus from './PolicyStatus/index';
import AlertHamon from 'views/Dashboard/Customer/PolicyPage/AlertHamon';

const PolicyPage = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const location = useLocation();
  const classes = useStyles();
  const [userState] = useContext(UserContext);
  const urlParams = useParams();

  const [police, setPolice] = useState(null);
  const [polichab, setPolichab] = useState(null);
  const [beneficiaries, setBeneficiaries] = useState([]);

  const sectionRefs = {
    contractDetail: {
      ref: useRef(),
      menuLabel: 'dashboard.police.details.title_menu',
    },
    cards: {
      ref: useRef(),
      menuLabel: 'dashboard.card.police.title',
      lienSection: 'cards',
    },
    beneficiaries: {
      ref: useRef(),
      menuLabel: 'dashboard.police.beneficiaries.title',
      lienSection: 'beneficiaries',
    },
    quittances: {
      ref: useRef(),
      menuLabel: 'dashboard.police.premiums.premiums_title',
    },
    garanties: {
      ref: useRef(),
      menuLabel: 'dashboard.police.guarantees.title',
    },
    sinisters: {
      ref: useRef(),
      menuLabel: 'dashboard.sinistre.list.title',
    },
    documents: {
      ref: useRef(),
      menuLabel: 'dashboard.relatedDocuments.title',
    },
  };

  useLayoutEffect(() => {
    if (police && polichab && beneficiaries && location) {
      const hash = location.hash?.substring(1);

      if (hash && sectionRefs[hash]?.ref?.current) {
        sectionRefs[hash].ref.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location, police, polichab, beneficiaries, sectionRefs]);

  useEffect(() => {
    if (police && location.state && location.state.hash === 'beneficiaries') {
      sectionRefs.beneficiaries.ref.current.scrollIntoView();
    }
  }, [police, location]);

  useEffect(() => {
    const policeId = urlParams.id;

    (async () => {
      try {
        if (userState.auth.isAuthenticated) {
          const res = await policyApi.getPolice(userState.auth, policeId);
          const polichab = await policyApi.getPolichab(
            userState.auth,
            policeId,
            res.contractType.code
          );
          setPolice(res);
          setPolichab(polichab);
        }
      } catch (err) {
        console.error(t('Error'), err);
        const messageError = getMessageError(err);
        enqueueSnackbar(t(messageError), { variant: 'error' });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userState.auth.isAuthenticated]);

  const isLoading = !police || !polichab;

  if (isLoading) {
    return (
      <Box mb={10}>
        <HeaderSkeleton />
        <Box className={classes.policyContainer}>
          <Box className={classes.container} width="100%">
            <ContractDetailsSkeleton />
            <MyCriteriaSkeleton />
            <MySelectionSkeleton />
          </Box>
          <CertificateBeneficiarySkeleton />
          <QuittancesSkeleton />
        </Box>
      </Box>
    );
  }

  return (
    <Box mb={10}>
      <PoliceHeader
        police={police}
        polichab={polichab}
        sectionRefs={sectionRefs}
      />
      <Box className={classes.policyContainer}>
        <PolicyStatus policy={police} />
        <AlertHamon police={police} />
        <PolicyDetails
          police={police}
          polichab={polichab}
          sectionRefs={sectionRefs}
        />
        <Beneficiaries
          police={police}
          policyNumber={police.policyNumber}
          beneficiaries={beneficiaries}
          setBeneficiaries={setBeneficiaries}
          scrollRef={sectionRefs.beneficiaries.ref}
        />
        <Attestations
          policy={police}
          polichab={polichab}
          beneficiaries={beneficiaries}
        />
        <Quittances police={police} scrollRef={sectionRefs.quittances.ref} />
        <Garanties police={police} scrollRef={sectionRefs.garanties.ref} />
        <Sinistres police={police} scrollRef={sectionRefs.sinisters.ref} />
        <GeneralConditions
          police={police}
          scrollRef={sectionRefs.documents.ref}
        />
      </Box>
    </Box>
  );
};

export default PolicyPage;
