import React, { createContext, useReducer } from 'react';

const initialState = {};
const UserContext = createContext(initialState);
const USER_ACTION_TYPE = {
  SET_USER : 'SET_USER', 
  MODIFY_USER_PHONE: 'MODIFY_USER_PHONE', 
  REMOVE_USER: 'REMOVE_USER'
}

const reducer = (state, action) => {
  switch (action.type) {
    case USER_ACTION_TYPE.SET_USER:
      return {
        ...state,
        ...action.payload,
      };
    case USER_ACTION_TYPE.MODIFY_USER_PHONE:
      return {
        ...state,
        contact : {
          ...state.contact,
          mobilePhone: action.payload
        }
      };
    case USER_ACTION_TYPE.REMOVE_USER:
      return initialState;
    default:
      throw new Error('No matching action type');
  }
};

const UserProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={[state, dispatch]}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserProvider, USER_ACTION_TYPE };
