import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  policyContainer: {
    paddingTop: theme.spacing(6),
    borderRadius: 10,
  },
  container: {
    backgroundColor: '#fff',
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  confirmContainer: {
    background: theme.palette.orange.main,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  addressWrapper: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.blue.light2,
    paddingRight: '10px',
    borderRadius: '4px',
  },
  circularProgress: {
    marginLeft: 10,
  },
}));

export default useStyles;
