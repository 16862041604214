import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { UserContext, USER_ACTION_TYPE } from 'contexts/user';
import { Container, Typography, Grid, IconButton } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Popover from '@material-ui/core/Popover';
import MenuItem from '@material-ui/core/MenuItem';
import AccountCircle from '@material-ui/icons/AccountCircle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

// import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder';
import LoginPopin from 'components/Login/Popin';
import StepHeader from 'components/StepHeader/StepHeader';
import LocaleSelector from 'components/LocaleSelector';
import { stepsIds } from 'constants/steps/steps';
import { stepsIds as pnoStepsIds } from 'constants/steps/pno';
import { firstLetterUppercase } from 'utils';
import useStyles from './style';

const Header = (props) => {
  const { disablePrevious, context, getSteps } = props;
  const { state, dispatch } = useContext(context);
  const { group: groupId, step: stepId } = state.currentStep;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loginPopinOpen, setLoginPopinOpen] = useState(false);
  // const step = getSteps(state.data)[groupId][stepId];
  const group = getSteps(state.data)[groupId];
  const steps = getSteps(state.data);
  // const groupName = group[0].category;
  // const groupLength = group.length;
  // const nbSkippedSteps = group.filter((step) => step.next).length;
  // const currentStep = stepId + 1;
  const [userState, dispatchUser] = useContext(UserContext);
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();
  const classes = useStyles();
  const { oktaAuth } = useOktaAuth();
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOpenUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenLoginPopin = () => {
    setLoginPopinOpen(true);
    handleClose();
  };

  const handleCloseLoginPopin = () => {
    setLoginPopinOpen(false);
  };

  const handlePrevStep = () => {
    dispatch({
      type: 'PREV_STEP',
    });
  };

  const handleDisconnect = async () => {
    dispatchUser({ type: USER_ACTION_TYPE.REMOVE_USER });
    await oktaAuth.tokenManager.clear();
    location.reload();
    handleClose();
  };

  const isFirstStep = () => {
    const isFirstGroup = state.currentStep.group === 0;
    const isFirstStepOfGroup = state.currentStep.step === 0;
    return isFirstGroup && isFirstStepOfGroup;
  };

  const showPreviousButton = () => {
    const firstStep = isFirstStep();

    const stepsWithoutPrevious = [
      stepsIds.PAYMENT_LOADER,
      stepsIds.PAYMENT,
      stepsIds.END,
      stepsIds.ERROR,
      pnoStepsIds.EXIT,
    ];

    return (
      !disablePrevious &&
      !firstStep &&
      !stepsWithoutPrevious.some((step) => group[stepId].id === step)
    );
  };

  const showStepHeader = () => {
    return !group[stepId].hideStepper;
  };

  const isAuthenticated = () => {
    return userState.user && userState.auth.isAuthenticated;
  };

  const redirectToDashboard = () => {
    history.push('/');
  };

  return (
    <>
      <header className={classes.header}>
        <Container>
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid
              item
              container
              align="center"
              justifyContent="flex-start"
              xs={1}
              sm={4}
              onClick={handlePrevStep}
              className="button-link"
            >
              {showPreviousButton() && (
                <>
                  <ArrowBackIcon
                    style={{
                      color: '#002B68',
                    }}
                  />

                  <Typography
                    className={classes.previousText}
                    style={{
                      marginLeft: 8,
                    }}
                    color="textPrimary"
                    variant="body1"
                  >
                    {t('steps.previousQuestion')}
                  </Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6} sm={4} align={isDownSm ? 'start' : 'center'}>
              <img
                src={`${localStorage.getItem(
                  'IMAGE_MANAGER_URL_GET'
                )}/assurimo/base/logo-assurimo-sans-baseline.png`}
                alt="Assurimo"
                style={{
                  maxWidth: 180,
                }}
              />
            </Grid>
            <Grid
              item
              container
              align="right"
              justifyContent="flex-end"
              xs={4}
              className="button-link"
              classes={{ root: classes.headerRight }}
            >
              <LocaleSelector />

              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={handleOpenUserMenu}
              >
                <AccountCircle
                  color={userState.user ? 'primary' : 'secondary'}
                />
              </IconButton>
              <Popover
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {userState.user && (
                  <MenuItem className={classes.userNameMenuItem}>
                    {`${userState.user.lastName.toUpperCase()} ${firstLetterUppercase(
                      userState.user.firstName
                    )}`}
                  </MenuItem>
                )}
                {isAuthenticated() && (
                  <MenuItem
                    onClick={redirectToDashboard}
                    dense
                    className={classes.menuItem}
                  >
                    {t('common.goToMySpace')}
                  </MenuItem>
                )}
                {!isAuthenticated() && (
                  <MenuItem
                    onClick={handleOpenLoginPopin}
                    dense
                    className={classes.menuItem}
                  >
                    {t('common.login')}
                  </MenuItem>
                )}
                {isAuthenticated() && (
                  <MenuItem
                    onClick={handleDisconnect}
                    dense
                    className={classes.menuItem}
                  >
                    {t('common.logout')}
                  </MenuItem>
                )}
              </Popover>
            </Grid>
          </Grid>
        </Container>
      </header>
      <LoginPopin
        open={loginPopinOpen}
        onClose={handleCloseLoginPopin}
        currentSubscriptionStep={group[stepId].id}
      />
      <div className={classes.stepHeader}>
        {showStepHeader() && (
          <StepHeader groupId={parseInt(groupId)} steps={steps} />
        )}
      </div>
    </>
  );
};

Header.propTypes = {
  context: PropTypes.shape().isRequired,
  disablePrevious: PropTypes.bool,
  getSteps: PropTypes.func.isRequired,
};
Header.defaultProps = {
  disablePrevious: false,
};

export default Header;
