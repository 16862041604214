import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: '#f6fafe',
    // backgroundImage:
    // 'url("https://i0.wp.com/exergic.in/wp-content/uploads/2018/04/Orange-Background-Vector-Wallpaper.jpg?ssl=1")',
    // 'url("https://img.freepik.com/free-vector/blue-orange-white-modern-curve-background-design-your-business_518299-1423.jpg?w=1060")',
    // background: `linear-gradient(${theme.palette.primary.main}, ${theme.palette.white.main})`,
    // backgroundSize: 'cover',
  },

  container: {
    width: 400,
    padding: '42px 42px',
    backgroundColor: 'transparent',
    // border: '1px solid #d8d8d8',
    borderRadius: 3,
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      left: '10px',
      right: '10px',
    },
    display: 'flex',
    flexDirection: 'column',
  },

  boxLottie: {
    position: 'absolute',
    bottom: 0,
    width: '100%',

    '& .lottie': {
      display: 'block!important',
    },
  },
  paper: {
    // width: 400,
    padding: '20px 42px',
    backgroundColor: theme.palette.white.main,
    // border: '1px solid #d8d8d8',
    borderRadius: 3,
    position: 'relative',
    // [theme.breakpoints.down('sm')]: {
    //   width: 150,
    // },
    display: 'flex',
    flexDirection: 'column',
  },

  logoWrapper: {
    // width: 400,
    padding: '40px 42px',
    backgroundColor: 'transparent',
    // border: '1px solid #d8d8d8',
    borderRadius: 3,
    position: 'relative',
    // [theme.breakpoints.down('sm')]: {
    //   width: 250,
    // },
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 50,
  },

  logo: {
    // height: 100,
    width: '380px',
    alignSelf: 'center',
  },
  title: {
    marginBottom: 20,
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 'bold',
  },
  button: {
    width: '100%',
    height: 48,
    borderRadius: 3,
  },
  buttonText: {
    color: theme.palette.white.main,
  },

  linearProgressRoot: {
    height: 2,
    borderRadius: 5,
    position: 'absolute',
    top: 0,
    width: '100%',
    left: 0,
  },
  linearProgressColorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  linearProgressBar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
  forgetPassword: {
    fontSize: 12,
  },
}));

export default useStyles;
