import React from 'react';
import { Box, TextField, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

BlocDescriptionSin.propTypes = {
  description: PropTypes.string.isRequired,
  setDescription: PropTypes.func.isRequired,
};
function BlocDescriptionSin({ setDescription, description }) {
  const { t } = useTranslation();

  return (
    <>
      <Box
        mb={6}
        display="flex"
        width={1}
        alignItems="center"
        flexDirection="column"
      >
        <Typography variant="h5" color="secondary">
          {t('dashboard.sinistre.form.info.description')}
        </Typography>
        <Box mt={2} width={1}>
          <TextField
            label={t('dashboard.sinistre.form.info.description_placeholder')}
            variant="filled"
            multiline
            rows={8}
            onChange={(evt) => setDescription(evt.target.value)}
            value={description}
            style={{ width: '100%' }}
          />
        </Box>
      </Box>
    </>
  );
}

export default BlocDescriptionSin;
