import React from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';

import OktaSignInWidget from 'components/OktaSignInWidget';
import useStyles from './style';

const Login = ({ config }) => {
  const classes = useStyles();
  const { oktaAuth, authState } = useOktaAuth();

  const onSuccess = (tokens) => {
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err) => {
    console.log('error logging in', err);
  };

  if (authState.isPending) return null;
  return authState.isAuthenticated ? (
    <Redirect to="/" />
  ) : (
    <div className={classes.loginWrapper}>
      <OktaSignInWidget
        config={config}
        onSuccess={onSuccess}
        onError={onError}
      />
    </div>
  );
};

Login.propTypes = {
  config: PropTypes.shape(),
};

export default Login;
