import React from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const SelectField = (props) => {
  const { label, items, id, onChange, ...otherProps } = props;

  const handleChange = (event, child) => {
    onChange({
      target: {
        value: event.target.value,
        id: child.props.parentId,
        name: event.target.name,
      },
    });
  };

  return (
    <FormControl variant="filled" fullWidth>
      <InputLabel id={label}>{label}</InputLabel>
      <Select labelId={label} onChange={handleChange} {...otherProps}>
        {items.map((item, index) => {
          return (
            <MenuItem key={index} value={item.value} parentId={id}>
              {item.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

SelectField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.shape(),
      ]),
    })
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectField;
