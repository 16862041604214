import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';

import GlobalContext from 'contexts/global';
import useGa from 'hooks/useGA';
import ReviewsContext from 'contexts/reviews';
import LateralPopin from 'components/LateralPopin';
import { ASSURIMO_CONTACT_EMAIL } from 'constants';
import GoogleLogo from 'assets/images/google.png';
import TrustpilotLogo from 'assets/images/trustpilot.png';

const ReviewsPopin = () => {
  const { t } = useTranslation();
  const { gtagEvent } = useGa();
  const { showReviewsPopin, setShowReviewsPopin } = useContext(ReviewsContext);
  const { gaEcSiteKey } = useContext(GlobalContext);

  const REVIEW_SITE = {
    GOOGLE: {
      url: 'https://g.page/r/CYatEFjsZaHdEAg/review',
      name: 'Google',
    },
    TRUSTPILOT: {
      url: 'https://fr.trustpilot.com/evaluate/www.assurimo.fr',
      name: 'Trustpilot',
    },
  };

  const handleCloseReviewsPopin = () => {
    setShowReviewsPopin(false);
  };

  const redirectToReviewSite = (entity) => {
    handleCloseReviewsPopin();
    gtagEvent(
      `review_${entity.name}`,
      {
        category: 'review',
        label: `Avis_${entity.name}`,
      },
      gaEcSiteKey
    );
    window.open(entity.url, '_blank', 'noopener noreferrer');
  };

  const lateralPopinDialogContent = (
    <div>
      <Typography variant="h6" align="center">
        {t('common.reviews.satisfyingExperience')}
      </Typography>
      <div style={{ marginTop: 20 }}>
        <Typography variant="body1" align="center">
          <b>{t('common.reviews.letReview')}</b>
        </Typography>
      </div>
      <Grid
        item
        container
        justifyContent="space-around"
        alignItems="center"
        direction="row"
        style={{ marginTop: 20, marginBottom: 20 }}
      >
        <Grid
          container
          item
          direction="column"
          xs={6}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <a onClick={() => redirectToReviewSite(REVIEW_SITE.GOOGLE)}>
              <img src={GoogleLogo} height={40} />
            </a>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => redirectToReviewSite(REVIEW_SITE.GOOGLE)}
              style={{ textAlign: 'center' }}
            >
              {t('common.reviews.writeReview')}
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          item
          direction="column"
          xs={6}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item>
            <a onClick={() => redirectToReviewSite(REVIEW_SITE.TRUSTPILOT)}>
              <img src={TrustpilotLogo} height={40} />
            </a>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => redirectToReviewSite(REVIEW_SITE.TRUSTPILOT)}
              style={{ textAlign: 'center' }}
            >
              {t('common.reviews.writeReview')}
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Typography variant="caption" color="secondary" align="center">
        {t('common.reviews.contactUsEmail')}
        <a href={`mailto:${ASSURIMO_CONTACT_EMAIL}`}>
          {ASSURIMO_CONTACT_EMAIL}
        </a>{' '}
        {t('common.reviews.contactUsPhone')}
        <a href={`tel:${t('common.assurimoPhoneNumber')}`}>
          {t('common.assurimoPhoneNumber')}
        </a>
      </Typography>
    </div>
  );

  return (
    <LateralPopin
      open={showReviewsPopin}
      onClose={handleCloseReviewsPopin}
      content={lateralPopinDialogContent}
      maxWidth="sm"
      enableTitleArea={false}
      showCancelButton={false}
    />
  );
};

export default ReviewsPopin;
