import { getHeaderFetch, responseWithJson } from './httpUtil';
import axios from 'axios';

const BASE_URL = '/v2/drs';

const drsApi = {
  async list(auth, drsType) {
    const url = `${BASE_URL}/${drsType}`;

    const response = await fetch(url, { headers: getHeaderFetch(auth) });

    return responseWithJson(response);
  },

  async getListCompagnies(auth) {
    const url = `${BASE_URL}/COMPAGNIE_ASSURANCE`;

    const response = await axios.get(url, { headers: getHeaderFetch(auth) });

    return response.data;
  },
};

export default drsApi;
