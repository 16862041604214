import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  guaranteesContainer: {
    paddingTop: theme.spacing(1),
  },
  container: {
    borderRadius: 10,
    backgroundColor: theme.palette.blue.sky,
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
}));

export default useStyles;
