import React from 'react';
import { Grid, Box, IconButton } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Skeleton from '@material-ui/lab/Skeleton';
import { Link } from 'react-router-dom';
import useStyles from './style';

export const HeaderSkeleton = () => {
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Grid container direction="row">
        <Grid item container alignItems="center" xs={10} md={6}>
          <Grid item container direction="column" xs={2}>
            <Box className={classes.arrowContainer}>
              <IconButton color="secondary" component={Link} to="/polices">
                <ArrowBackIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item container direction="column" xs={10}>
            <Skeleton variant="rect" width={197} height={32} />
            <Skeleton variant="text" />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};
