import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import { PnoContext } from 'contexts/pno';
import { stepsIds } from 'constants/steps/pno';
import NextStepButton from 'components/NextStepButton/NextStepButton';
import Title from 'components/Title';
import Popin from 'components/Popin/index';
import Negative from './negative';
import Activity from './activity';
import useStyles from '../style';

const ActivityStep = ({ data, setUserDataAndChangePage }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isActivityPopinOpen, setIsActivityPopinOpen] = useState(false);
  const [isNegativePopinOpen, setIsNegativePopinOpen] = useState(false);
  const [value, setValue] = useState(data.typeActivite || '');
  const { dispatch } = useContext(PnoContext);

  const handleClickYes = () => {
    dispatch({
      type: 'SET_DATA',
      payload: {
        key: 'typeActivite',
        value: '',
      },
    });
    dispatch({
      type: 'SET_STEP',
      payload: {
        stepId: stepsIds.EXIT,
        skipSteps: true,
      },
    });
  };

  const handleClickNo = () => {
    setIsActivityPopinOpen(true);
  };

  const handleCloseActivityPopin = () => {
    setIsActivityPopinOpen(false);
  };

  const renderNegativeContent = () => {
    return <Negative />;
  };

  const renderActivityContent = () => {
    return <Activity value={value} setValue={setValue} />;
  };

  return (
    <>
      <Title title={t('pno.activityStep.title')} />
      <Container maxWidth="md">
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          className={{ root: classes.wrapper }}
        >
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={3}
            item
            xs={12}
            sm={10}
          >
            <ul style={{ 'line-height': 25 }}>
              {Array.from(Array(4)).map((element, index) => {
                return (
                  <li key={index}>
                    <Typography>
                      {t(`pno.activityStep.list.${index + 1}`)}
                    </Typography>
                  </li>
                );
              })}
            </ul>
          </Grid>
        </Grid>
        <NextStepButton
          onClick={handleClickNo}
          title={t('common.no')}
          cancelButtonTitle={t('common.yes')}
          onCancelClick={handleClickYes}
        />
        <Popin
          open={isNegativePopinOpen}
          onClose={() => setIsNegativePopinOpen(false)}
          content={renderNegativeContent()}
        />
        <Popin
          open={isActivityPopinOpen}
          onClose={handleCloseActivityPopin}
          content={renderActivityContent()}
          title={t('pno.activityStep.activity.title')}
          validateButtonLabel={t('common.validate')}
          cancelButtonLabel={t('common.cancel')}
          showCancelButton
          isValidateButtonDisabled={!value}
          showCloseButton
          onValidate={() => setUserDataAndChangePage('typeActivite', value)}
        />
      </Container>
    </>
  );
};

ActivityStep.propTypes = {
  setUserDataAndChangePage: PropTypes.func,
  data: PropTypes.shape(),
};

export default ActivityStep;
