import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  Grid,
  Typography,
  Card,
  CardContent,
  Button,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import { formatDate, firstLetterUppercase } from 'utils';

const useStyles = makeStyles((theme) => ({
  item: {
    border: `1px solid ${theme.palette.grey.main}`,
    marginBottom: theme.spacing(2),
    width: '100%',
    position: 'relative',
  },
  roundedIcon: {
    borderRadius: '50%',
    background: theme.palette.grey.main,
  },
  button: {
    backgroundColor: theme.palette.greyBlue.main,
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  value: {
    color: theme.palette.blue.light,
  },
}));

const ChildItem = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { child, onClick, onDelete } = props;

  return (
    <Card className={classes.item}>
      <CardContent>
        <Box>
          <Typography variant="subtitle1">{`${firstLetterUppercase(
            child.firstName
          )} ${child.lastName.toUpperCase()} (${
            child.linkType.code === 'ENFANT'
              ? t('common.child')
              : t('common.roommate')
          })`}</Typography>
        </Box>
        <Grid container item xs={12}>
          <Grid item xs={12} md={3}>
            <Typography variant="caption" color="secondary">
              {t('common.born')}
            </Typography>
            <Typography variant="body1" className={classes.value}>
              {formatDate(child.birthDay, 'DD MMMM yyyy')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="caption" color="secondary">
              {t('common.age')}
            </Typography>
            <Typography variant="body1" className={classes.value}>
              {moment().diff(child.birthDay, 'year')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} />
          <Grid item xs={12} md={3}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                startIcon={<EditIcon />}
                variant="outlined"
                color="secondary"
                onClick={onClick}
              >
                {t('common.update')}
              </Button>
            </Box>
          </Grid>
          {/* <Grid item xs={12} md={3}>
            <Box display="flex" justifyContent="flex-end">
              <Button
                startIcon={<EditIcon />}
                variant="outlined"
                color="secondary"
                onClick={onDelete}
              >
                {t('common.delete')}
              </Button>
            </Box>
          </Grid> */}
        </Grid>
      </CardContent>
    </Card>
  );
};

ChildItem.propTypes = {
  child: PropTypes.shape({
    birthDay: PropTypes.string.isRequired,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    linkType: PropTypes.shape(),
  }).isRequired,
  onClick: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default ChildItem;
