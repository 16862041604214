import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  container: {
    borderRadius: 10,
    backgroundColor: '#fff',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  button: {
    width: '100%',
    height: 48,
    borderRadius: 3,
  },
  buttonText: {
    color: theme.palette.white.main,
  },
  wrapper: {
    display: 'flex',
  },
  ul: {
    color: theme.palette.primary.main,
  },
  li: {
    color: theme.palette.grey.main,
    '&:nth-child(3)': {
      lineHeight: 0,
    },
    '&:nth-child(4)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '-18px',
      marginTop: '10px',
    },
  },
  liOk: {
    color: 'inherit',
  },
  infoIcon: {
    fontStyle: 15,
  },
  caption: {
    lineHeight: 1,
    color: theme.palette.secondary.main,
  },
  popinContentWrapper: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}));

export default useStyles;
