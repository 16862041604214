import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useIframe from 'hooks/useIframe';
import PropTypes from 'prop-types';
import React from 'react';

CardNew.propTypes = {
  customerNumber: PropTypes.number.isRequired,
};

function CardNew(props) {
  const { customerNumber } = props;
  const classes = useStyles();
  const { iframeUrl } = useIframe();

  return (
    <Grid
      container
      item
      xs={12}
      className={classes.iframeWrapper}
      alignItems="flex-start"
    >
      {iframeUrl && (
        <iframe
          title="Nouvelle carte bancaire"
          src={`${iframeUrl}/card/add/${customerNumber}`}
          width="100%"
          className={classes.iframe}
        />
      )}
    </Grid>
  );
}

const useStyles = makeStyles((theme) => ({
  iframeWrapper: {
    display: 'flex',
    height: '100%',
    paddingTop: theme.spacing(1),
  },
  iframe: {
    border: 'none',
    overflow: 'hidden',
    minHeight: 730,
  },
}));

export default CardNew;
