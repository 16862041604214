import React, { useEffect, useState, useContext, useRef } from 'react';
import { Switch, Route, useHistory, Redirect } from 'react-router-dom';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import ReactRgpdConsent, { availableServicesIds } from 'react-rgpd-consent';

import { getOktaAuthConfig, getOktaSignInConfig } from 'constants/okta';
import { MrhContextProvider } from 'contexts/mrh';
import { PnoContextProvider } from 'contexts/pno';
import { UserProvider } from 'contexts/user';
import GlobalContext from 'contexts/global';
import authApi from 'api/authApi';
import ContextRoute from 'components/ContextRoute/index';
import LoginPage from 'views/Authentication/Login';
import SignupPage from 'views/Signup';
import PasswordPageRecovery from 'views/Authentication/PasswordRecovery';
import ActivationLink from 'views/Authentication/ActivationLink';
import SignupConfirmPage from 'views/SignupConfirm';
import { MrhSubscription } from 'views/MrhSubscription';
import { PnoSouscription } from 'views/PnoSouscription';
import FromAnotherSource from 'components/FromAnotherSource';
import Lodgis from 'views/MrhSubscription/Lodgis/index';
import Dashboard from 'views/Dashboard/index';
import { useSnackbar } from 'notistack';
import cookieImage from '../../assets/images/cookie.png';
import 'react-rgpd-consent/dist/index.css';
import UnsubcribeMailing from 'views/UnsubcribeMailing/index';

const Router = () => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [oktaAuth, setOktaAuth] = useState(null);
  const [oktaSignInConfig, setOktaSingInConfig] = useState(null);
  const [loginSystem, setLoginSystem] = useState(null);
  const { gaMrhSiteKey, setCookiesSelectorRef } = useContext(GlobalContext);

  const onAuthRequired = () => {
    history.push('/login');
  };

  const getOktaConfig = async () => {
    try {
      const oktaConfig = await authApi.getConfig();
      const oktaAuthConfig = getOktaAuthConfig(
        oktaConfig.orgUrl,
        oktaConfig.clientId,
        oktaConfig.authenticationServerId,
        onAuthRequired
      );

      const signInConfig = getOktaSignInConfig(
        oktaConfig.orgUrl,
        oktaConfig.clientId,
        oktaConfig.authenticationServerId
      );

      setOktaSingInConfig(signInConfig);

      if (oktaConfig.login) setLoginSystem(oktaConfig.login);

      const newOktaAuth = new OktaAuth(oktaAuthConfig);

      setOktaAuth(newOktaAuth);
    } catch (e) {
      enqueueSnackbar(
        "Une erreur inattendue s'est produite, veuillez réessayer ultérieurement ou contacter le support Assurimo si le problème persiste",
        { variant: 'error' }
      );
    }
  };

  useEffect(() => {
    getOktaConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCookiesSelectorRef(ref);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref]);

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  const getCookiesApplicationServices = (gaMrhMeasurementId) => [
    {
      id: availableServicesIds.GOOGLE_ANALYTICS_GTAG,
      parameters: {
        gaMeasurementId: gaMrhMeasurementId,
      },
    },
  ];

  const ref = useRef(null);

  return (
    <>
      {oktaAuth ? (
        <Security
          oktaAuth={oktaAuth}
          onAuthRequired={onAuthRequired}
          restoreOriginalUri={restoreOriginalUri}
        >
          <div style={{ fontFamily: 'Inter' }}>
            <ReactRgpdConsent
              ref={ref}
              title="La boîte à cookies"
              titleIcon={
                <img src={cookieImage} height={24} alt="Cookie icon" />
              }
              description="Nous utilisons des cookies pour optimiser votre expérience utilisateur et pour améliorer nos contenus. Vous pouvez personnaliser et modifier vos choix à tout moment."
              selectionScreenTitle="La carte des cookies"
              selectionScreenDescription="Nous utilisons les cookies à des fins d'analyse de nos données de visiteurs, pour améliorer notre site Web, afficher un contenu personnalisé et vous offrir une excellente expérience de site Web. Pour plus d'informations sur les cookies que nous utilisons, ouvrez les paramètres."
              selectionScreenSelectAll="Tout sélectionner"
              disableDenyAllButton={true}
              denyAllButtonText="Tout refuser"
              configureButtonText="Paramétrage"
              acceptAllButtonText="Tout accepter"
              goBackButtonText="Retour"
              saveSelectionButtonText="Enregistrer"
              applicationServices={getCookiesApplicationServices(gaMrhSiteKey)}
            />
          </div>

          <Switch>
            <Route exact path="/login">
              <LoginPage system={loginSystem} oktaConfig={oktaSignInConfig} />
            </Route>
            <Route exact path="/login/callback" component={LoginCallback} />

            {/* PNO */}
            <ContextRoute
              exact
              path="/souscription/pno"
              component={PnoSouscription}
              context={PnoContextProvider}
            />
            <ContextRoute
              exact
              path="/parcours/pno/:parcoursId"
              component={PnoSouscription}
              context={PnoContextProvider}
            />

            {/* MRH */}
            <ContextRoute
              exact
              path="/souscription/mrh"
              component={MrhSubscription}
              context={MrhContextProvider}
            />
            <ContextRoute
              exact
              path="/parcours/mrh/:parcoursId"
              component={MrhSubscription}
              context={MrhContextProvider}
            />
            {/* Other sources */}
            <ContextRoute
              exact
              path="/souscription/:from"
              component={FromAnotherSource}
              context={MrhContextProvider}
            />

            <ContextRoute
              exact
              path="/souscription/mrh/:from"
              component={FromAnotherSource}
              context={MrhContextProvider}
            />

            <ContextRoute
              exact
              path="/souscription/pno/:from"
              component={FromAnotherSource}
              context={PnoContextProvider}
            />

            {/* Lodgis */}
            <ContextRoute
              exact
              path="/souscription/lodgis/:id"
              component={Lodgis}
              context={MrhContextProvider}
            />

            <ContextRoute
              exact
              path="/souscription/mrh/:from/:id"
              component={Lodgis}
              context={MrhContextProvider}
            />

            <Redirect from="/souscription" to="/souscription/mrh" />

            {/* Login & Subscription */}
            <ContextRoute
              path="/signup/confirm/:token"
              component={SignupConfirmPage}
              context={MrhContextProvider}
            />
            <Route path="/signup/activationLink" component={ActivationLink} />
            <Route exact path="/signup" component={SignupPage} />
            {/* <Route exact path="/recovery" component={PasswordPageRecovery} /> */}
            <Route
              exact
              path="/recovery/:recoveryToken"
              component={PasswordPageRecovery}
            />

            {/* Dashboard */}
            <ContextRoute
              path="/dashboard"
              context={UserProvider}
              secure={true}
            >
              <Dashboard loginSystem={loginSystem} />
            </ContextRoute>
            <Route
              exact
              path="/unsubcribe-mailing"
              component={UnsubcribeMailing}
            />
            <SecureRoute
              path="/*"
              component={() => <Dashboard loginSystem={loginSystem} />}
            />
          </Switch>
        </Security>
      ) : null}
    </>
  );
};

export default Router;
