import React from 'react';
import Typography from '@material-ui/core/Typography';
import useStyles from './style';
import i18next from 'i18next';
import { LANGUAGES } from 'constants';

const HouseAddressTitle = () => {
  const classes = useStyles();

  if (i18next.resolvedLanguage === LANGUAGES.en)
    return (
      <div>
        <Typography variant="h4" component="div" className={classes.title}>
          Welcome,
        </Typography>
        <Typography className={classes.subtitle} variant="subtitle1">
          In just a few clicks we are going to create{' '}
          <span className={classes.lightningSubtitle}>the insurance plan</span>{' '}
          that is best adapted to{' '}
          <span className={classes.lightningSubtitle}>you</span> for the best
          price{' '}
        </Typography>
      </div>
    );
  else
    return (
      <div>
        <Typography variant="h4" component="div" className={classes.title}>
          Bienvenue,
        </Typography>
        <Typography className={classes.subtitle} variant="subtitle1">
          Nous allons créer{' '}
          <span className={classes.lightningSubtitle}>ensemble</span>, en
          quelques clics, l'
          <span className={classes.lightningSubtitle}>
            assurance multirisque habitation
          </span>{' '}
          la plus adaptée à{' '}
          <span className={classes.lightningSubtitle}>votre profil</span>, au
          meilleur prix.
        </Typography>
      </div>
    );
};

export default HouseAddressTitle;
