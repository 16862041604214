import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paper: {
    margin: '100px auto 8px',
    width: 400,
    padding: '20px 42px',
    backgroundColor: theme.palette.background.default,
    border: '1px solid #d8d8d8',
    borderRadius: 3,
    [theme.breakpoints.down('sm')]: {
      width: 250,
    },
  },
  title: {
    marginBottom: 20,
    textAlign: 'center',
  },
  button: {
    width: '100%',
    height: 48,
    borderRadius: 3,
  },
  buttonText: {
    color: theme.palette.white.main,
  },
  questionsSelect: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'pre',
    width: 300,
  },
}));

export default useStyles;
