import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import useStyles from './style';

const AssurimoPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box
      m={2}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="body1" align="center" className={classes.title}>
        {t('dashboard.contact.assurimoAssistance.title')}
      </Typography>
      <Typography variant="body1" align="center">
        {t('dashboard.contact.assurimoAssistance.assurimoAnswerYouAt')}
      </Typography>
      <Box m={2}>
        <Typography align="center" variant="h5">
          {t('common.assurimoPhoneNumber')}
        </Typography>
      </Box>
      <Typography
        classes={{ root: classes.centeredTypography }}
        variant="caption"
      >
        {t('dashboard.contact.assurimoAssistance.schedule')}
      </Typography>
      <Box m={2}>
        <Button
          variant="contained"
          color="primary"
          href={`tel:${t('common.assurimoPhoneNumber')}`}
        >
          {t('dashboard.contact.assurimoAssistance.callAssurimo')}
        </Button>
      </Box>
    </Box>
  );
};

export default AssurimoPage;
