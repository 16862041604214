import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, Hidden, Button, Menu, MenuItem, Box } from '@material-ui/core';
import GuaranteeItem from './GuaranteeItem';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardArrowDown } from '@material-ui/icons';

GuaranteeList.propTypes = {
  listGuarantees: PropTypes.array,
  onClick: PropTypes.func,
  itemSel: PropTypes.object,
};

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down('md')]: {
      margin: '0!important',
    },
  },
  box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  button: {
    backgroundColor: '#D2E3FA!important',
    borderRadius: '20px',
    minWidth: '50%',
    fontWeight: 'bold',
    fontSize: '15px',
    padding: theme.spacing(3),
  },
  menu: {
    maxHeight: '350px',

    '& .MuiPaper-root': {
      borderRadius: '8px',

      '&::-webkit-scrollbar': {
        width: '3px',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#002b68',
      },
    },
  },
  menuItem: {
    minWidth: '50%',
    fontWeight: 'bold',
    fontSize: '15px',
    padding: theme.spacing(2.5),
    color: '#000000',

    '&.select': {
      color: '#002b68',
      backgroundColor: '#e6effb',
    },
  },
}));

function GuaranteeList(props) {
  const { listGuarantees, onClick, itemSel } = props;
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSelectMenu = (item) => {
    onClick && onClick(item);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Grid item container spacing={1} className={classes.root}>
      <Hidden smDown>
        {listGuarantees.map((guarantee, index) => (
          <GuaranteeItem key={index} item={guarantee} onClick={onClick} />
        ))}
      </Hidden>
      <Hidden mdUp>
        <Box className={classes.box}>
          <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
            variant="contained"
            className={classes.button}
            endIcon={<KeyboardArrowDown />}
          >
            {itemSel?.title}
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            className={classes.menu}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            getContentAnchorEl={null}
          >
            {listGuarantees.map((guarantee, index) => (
              <MenuItem
                key={index}
                onClick={() => handleSelectMenu(guarantee)}
                className={`${classes.menuItem} ${
                  guarantee.id === itemSel.id ? 'select' : ''
                }`}
              >
                {guarantee.title}
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </Hidden>
    </Grid>
  );
}

export default GuaranteeList;
