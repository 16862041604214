import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import useStyles from './style';

const Exclusions = (props) => {
  const { t } = useTranslation();
  const { onCancel, onValidate } = props;
  const classes = useStyles();

  return (
    <div style={{ margin: 20 }}>
      <ul style={{ lineHeight: 3 }}>
        <li>
          <Typography>{t('pno.quoteStep.exclusions.list.1')}</Typography>
        </li>
        <li>
          <Typography>{t('pno.quoteStep.exclusions.list.2')}</Typography>
        </li>
        <li>
          <Typography>{t('pno.quoteStep.exclusions.list.3')}</Typography>
        </li>
        <li>
          <Typography>{t('pno.quoteStep.exclusions.list.4')}</Typography>
        </li>
        <li>
          <Typography>{t('pno.quoteStep.exclusions.list.5')}</Typography>
        </li>
        <li>
          <Typography>{t('pno.quoteStep.exclusions.list.6')}</Typography>
        </li>
        <li>
          <Typography>{t('pno.quoteStep.exclusions.list.7')}</Typography>
        </li>
        <li>
          <Typography>{t('pno.quoteStep.exclusions.list.8')}</Typography>
        </li>
        <li>
          <Typography>{t('pno.quoteStep.exclusions.list.9')}</Typography>
        </li>
        <li>
          <Typography>{t('pno.quoteStep.exclusions.list.10')}</Typography>
        </li>
      </ul>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          gap: 40,
          marginTop: 40,
        }}
      >
        <Button
          classes={{ root: classes.nokButton }}
          variant="outlined"
          onClick={onCancel}
        >
          {t('pno.quoteStep.exclusions.nokButton')}
        </Button>
        <Button variant="contained" color="primary" onClick={onValidate}>
          {t('pno.quoteStep.exclusions.okButton')}
        </Button>
      </div>
    </div>
  );
};

Exclusions.propTypes = {
  onCancel: PropTypes.func,
  onValidate: PropTypes.func,
};

export default Exclusions;
