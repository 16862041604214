import { getHeaderFetch, responseWithBlob, responseWithJson } from './httpUtil';

const BASE_URL = '/v2/premiums';
const BASE_URL_V3 = '/v3/premiums';

const premiumApi = {
  async getPremiumByUuid(auth, uuid) {
    const url = `${BASE_URL}/${uuid}`;

    const res = await fetch(url, {
      method: 'GET',
      headers: getHeaderFetch(auth),
    });

    return responseWithJson(res);
  },

  async paidWithCardExist(auth, premiumUuid, bankcardUuid) {
    const url = `${BASE_URL}/${premiumUuid}/payment-direct/${bankcardUuid}`;

    const res = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(auth),
    });

    return responseWithJson(res);
  },

  async generatePremium(auth, premiumUuid, codeTypeFavori = 'POL_QUITTANCE') {
    const url = `${BASE_URL}/${premiumUuid}/ged/download/${codeTypeFavori}`;
    // const url = `${BASE_URL}/45382047-46ba-4a1e-a017-6359fec798f1/ged/download/${codeTypeFavori}`;
    const res = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(auth),
    });

    return responseWithBlob(res);
  },

  async getAmountDetails(auth, premiumUuid) {
    const url = `${BASE_URL_V3}/amount-detail`;
    const res = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({
        premiumsUuid: [premiumUuid],
      }),
      headers: getHeaderFetch(auth),
    });
    return responseWithJson(res);
  },
};

export default premiumApi;
