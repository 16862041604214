import { getHeaderFetch, responseWithJson, responseWithBlob } from './httpUtil';

const BASE_URL = '/v2/document';

const documentApi = {
  async download(auth, idDocContract){
    const url = `${BASE_URL}/${idDocContract}/contractualsDocuments`;
    const response = await fetch(url, { headers: getHeaderFetch(auth) });
    return responseWithBlob(response);
  },

  async list(auth, params){
    const url = `${BASE_URL}/contractualsDocuments`;
    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(auth),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  async get(auth, codeDocument, params){
    const url = `${BASE_URL}/contractualsDocuments/${codeDocument}`;
    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(auth),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },
}

export default documentApi;