import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import useStyles from './style';

const ChipPrice = (props) => {
  const { priceParts, pricePromoParts, periodicity } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.priceWrapper}>
      <div className={classes.price}>
        {pricePromoParts ? (
          <>
            <Typography className={classes.unit}>
              {pricePromoParts[0]}
            </Typography>
            <Typography className={classes.unit}>{`${t('common.insteadOf')} ${
              priceParts[0]
            }`}</Typography>
          </>
        ) : (
          <Typography className={classes.unit}>{priceParts[0]}</Typography>
        )}
        <div className={classes.decimalWrapper}>
          <Typography className={classes.decimal}>€</Typography>
        </div>
        <div className={classes.decimalWrapper}>
          <Typography className={classes.period}>
            {periodicity === 'A'
              ? `${t('common.year')}`
              : `/${t('common.month')}`}
          </Typography>
        </div>
      </div>
    </div>
  );
};

ChipPrice.propTypes = {
  priceParts: PropTypes.any,
  pricePromoParts: PropTypes.shape(),
  periodicity: PropTypes.string.isRequired,
};

export default ChipPrice;
