const strings = {
  residenceType: {
    P: 'common.lodging',
    S: 'common.secondaryResidence',
  },
  assuredQuality: {
    P: 'common.owner',
    L: 'common.tenant',
  },
  housingType: {
    A: 'common.apartment',
    M: 'common.house',
    BG: 'common.housingType.BG',
    LC: 'common.housingType.LC',
  },
  logement_en_location: 'logement en location',
  alarme_antivol: 'alarme antivol',
  cheminee: 'cheminée',
  detection_incendie: 'détection incendie',
  panneaux_solaires: 'panneaux solaires',
  outbuilding: 'common.equipments.annex',
  burglarAlarm: 'alarme antivol',
  fireDetectionEquipment: 'détection incendie',
  firePlace: 'cheminée',
  landscaping: 'aménagements extérieurs',
  solarPanels: 'panneaux solaires',
  swimmingPool: 'piscines',
  INF120: 'dépendance',
  veranda: 'common.equipments.veranda',
  gardenWithSwimmingPool: 'common.equipments.yardWithPool',
  gardenWithoutSwimmingPool: 'common.equipments.yardWithoutPool',
  WITHOUT_SWIM: 'common.equipments.yardWithoutPool',
  WITH_SWIM: 'common.equipments.yardWithPool',
};

const getHumanReadableString = (key, t, value, options) => {
  const foundItem = strings[key];
  if (t) {
    if (typeof foundItem === 'object') {
      if (options) return t(foundItem[value], options);
      return t(foundItem[value]);
    }
    return t(foundItem);
  } else return foundItem;
};

export default getHumanReadableString;
