import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    backgroundColor: '#f6fafe',
  },
  paper: {
    padding: '20px 42px',
    backgroundColor: theme.palette.white.main,
    borderRadius: 3,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },

  title: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    fontSize: 24,
    fontWeight: 'bold',
  },
  rulesWrapper: {},
  rulesTitle: {
    fontWeight: 'bolder',
  },
  containerTop: {
    width: 400,
    borderRadius: 10,
    backgroundColor: 'transparent',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
    display: 'flex',
    flexDirection: 'column',
  },
  containerBottom: {
    width: 400,
    borderRadius: 10,
    backgroundColor: 'transparent',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },

  linearProgressRoot: {
    height: 2,
    borderRadius: 5,
    position: 'absolute',
    top: 0,
    width: '100%',
    left: 0,
  },
  linearProgressColorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  linearProgressBar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
  logoWrapper: {
    padding: '40px 42px',
    backgroundColor: 'transparent',
    borderRadius: 3,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  button: {
    width: '100%',
    height: 48,
    borderRadius: 3,
  },
  buttonText: {
    color: theme.palette.white.main,
  },
  ul: {
    color: theme.palette.primary.main,
  },
  li: {
    color: theme.palette.grey.main,
    '&:nth-child(3)': {
      lineHeight: 0,
    },
    '&:nth-child(4)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      marginLeft: '-18px',
      marginTop: '10px',
    },
  },
  liOk: {
    color: 'inherit',
  },
  infoIcon: {
    fontStyle: 15,
  },
  caption: {
    lineHeight: 1,
    color: theme.palette.secondary.main,
  },
  boxLottie: {
    position: 'absolute',
    bottom: 0,
    width: '100%',

    '& .lottie': {
      display: 'block!important',
    },
  },
  contentText: {
    fontSize: '0.85rem',
    textAlign: 'justify'
  }
}));

export default useStyles;
