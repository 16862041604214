class ApiError extends Error {
  constructor(status, message) {
    super(status, message);

    this.statusCode = status;
    this.message = message;
  }
}

const getHeaderCustomFetch = (auth, headers = {}) => {
  if (auth && auth.isAuthenticated) {
    return {
      ...headers,
      Authorization: `Bearer ${auth.token}`,
    };
  }
  return headers;
};

const getHeaderFetch = (auth) => {
  const header = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  return getHeaderCustomFetch(auth, header);
};

const responseWithJson = async (res) => {
  if (res.ok) return res.json();

  const text = (await res.text()) || '{}';
  const { code, message } = JSON.parse(text);
  if (code) throw { code, message };
  throw new Error(`${res.status} - ${res.statusText}`);
};

const responseWithStatus = async (res) => {
  if (res.ok) return res.json();
  else return new ApiError(res.status, res.statusText);
};

const responseWithBlob = (res) => {
  if (res.ok) return res.blob();
  throw new Error(`${res.status} - ${res.statusText}`);
};

const responseWithVoid = async (res) => {
  if (res.ok) return;
  if (res.json) {
    const response = await res.json();

    if (response.error_description) {
      throw new Error(response.error_description);
    } else {
      throw new Error(`${res.status} - ${res.statusText}`);
    }
  } else throw new Error(`${res.status} - ${res.statusText}`);
};

export {
  getHeaderFetch,
  getHeaderCustomFetch,
  responseWithJson,
  responseWithBlob,
  responseWithVoid,
  responseWithStatus,
};
