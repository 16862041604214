import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  dataGrid: {
    backgroundColor: theme.palette.white.main,
    // maxHeight: 'fit-content !important',
    // '& .MuiDataGrid-row': {
    //   maxHeight: 'fit-content !important',
    // },
  },
  // header: {
  //   '& .MuiDataGrid-columnHeaderTitle': {
  //     overflow: 'visible',
  //     fontWeight: 'bolder',
  //   },
  // },
  // row: {
  //   color: theme.palette.secondary.main,
  //   '&:nth-child(odd)': {
  //     backgroundColor: theme.palette.greyBlue.main,
  //   },
  // },
  row: {
    maxHeight: 'fit-content !important',
  },
  cell: {
    maxHeight: 'fit-content !important',
    overflow: 'auto',
    whiteSpace: 'initial !important',
    lineHeight: '18px !important',
    display: 'flex !important',
    alignItems: 'center',
    paddingTop: '10px !important',
    paddingBottom: '10px !important',
  },
}));

export default useStyles;
