import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import policyApi from 'api/policyApi';
import { UserContext } from 'contexts/user';
import PopinMultiPages from 'components/PopinMultiPages';
import ContractPeriodicity from './Pages/ContractPeriodicity';
import Confirmation from './Pages/Confirmation';
import Result from './Pages/Result';

const PaiementPeriodicitePopin = ({ police, isOpen, onClose }) => {
  const { t } = useTranslation();
  const [userState] = useContext(UserContext);

  const [currentPage, setCurrentPage] = useState('contractPeriodicity');
  const [periodicity, setPeriodicity] = useState({
    code: 'M',
    label: t('steps.quoteStep.everyMonth'),
  });

  const handleCloseContractManagementPopin = () => {
    onClose();
    setCurrentPage('contractPeriodicity');
  };

  const pages = [
    {
      component: ContractPeriodicity,
      key: 'contractPeriodicity',
      onValidate: (newPeriodicity) => handleValidatePeriodicity(newPeriodicity),
    },
    {
      component: Confirmation,
      key: 'confirmation',
      periodicity: periodicity,
      onValidate: () => handleConfirm(),
      previousPage: () => 'contractPeriodicity',
    },
    {
      component: Result,
      key: 'result',
    },
  ];

  const getTitle = () => {
    switch (currentPage) {
      case pages[0].key:
        return t('dashboard.police.paymentPeriodicity.title');
      case pages[1].key:
        return t('dashboard.police.paymentPeriodicity.contractUpdate');
      default:
        return t('dashboard.police.paymentPeriodicity.contractUpdate');
    }
  };

  const handleValidatePeriodicity = (newPeriodicity) => {
    setCurrentPage('confirmation');
    setPeriodicity(newPeriodicity);
  };

  const handleConfirm = async () => {
    await policyApi.updatePeriodicity(
      userState.auth,
      police.policyNumber,
      periodicity.code
    );
    setCurrentPage('result');
  };

  return (
    <PopinMultiPages
      open={isOpen}
      onClose={handleCloseContractManagementPopin}
      enableTitleArea={true}
      maxWidth="md"
      pages={pages}
      title={getTitle()}
      setCurrentPage={setCurrentPage}
      currentPage={currentPage}
    />
  );
};

PaiementPeriodicitePopin.propTypes = {
  police: PropTypes.object.isRequired,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default PaiementPeriodicitePopin;
