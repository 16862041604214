import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import Popin from 'components/Popin/index';

const SinistreInfoCoordonnePopin = () => {
  const { t } = useTranslation();

  const [showPopinCoordonnes, setShowPopinCoordonnes] = useState(true);

  return (
    <Popin
      title={t('dashboard.sinistre.popinContact.titre')}
      content={
        <div style={{ padding: '30px 20px' }}>
          {t('dashboard.sinistre.popinContact.corps')}
          <a href={'/compte/informations-personnelles'}>
            {t('dashboard.sinistre.popinContact.lien')}
          </a>
          .
        </div>
      }
      open={showPopinCoordonnes}
      onClose={() => setShowPopinCoordonnes(false)}
      maxWidth={'sm'}
    />
  );
};

export { SinistreInfoCoordonnePopin };
