import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography, Box, Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Lottie from 'components/Lottie';
import { useTranslation } from 'react-i18next';

GuaranteeDetail.propTypes = {
  item: PropTypes.object.isRequired,
  onClick: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  box: {
    textAlign: 'left',
    padding: theme.spacing(3),
  },
  textTitle: {
    color: '#002b68',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  textSubtitle: {
    color: '#000000',
    marginBottom: theme.spacing(4),
    fontSize: '0.9rem',
    textAlign: 'justify',
  },
  button: {
    borderRadius: '20px',
    border: '2px solid #002b68',
    fontSize: '0.9rem',
    fontWeight: 700,
  },
  boxButton: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  gridItem: {
    [theme.breakpoints.down('md')]: {
      margin: '0!important',
    },
    '&.lottie': {
      margin: '0!important',
    },
  },
}));

function GuaranteeDetail(props) {
  const { item, onClick } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = () => {
    onClick && onClick(item);
  };

  return (
    <Grid item container direction="row-reverse" className={classes.gridItem}>
      <Grid item xs={12} md={6} className={`${classes.gridItem} lottie`}>
        {item.symbol && (
          <>
            <Hidden xsDown>
              <Lottie animationData={item.symbol} isActive height={320} />
            </Hidden>
            <Hidden smUp>
              <Lottie animationData={item.symbol} isActive height={270} />
            </Hidden>
          </>
        )}
      </Grid>
      <Grid item xs={12} md={6} className={classes.gridItem}>
        <Box className={classes.box}>
          <Typography variant="h5" className={classes.textTitle}>
            {item.title}
          </Typography>
          <Typography variant="body2" className={classes.textSubtitle}>
            {item.examples[0]}
          </Typography>
          <Box className={classes.boxButton}>
            <Button
              variant="outlined"
              className={classes.button}
              onClick={handleClick}
            >
              {t('common.details')}
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}

export default GuaranteeDetail;
