import React, { useState } from 'react';

const ReviewsContext = React.createContext({});

const ReviewsContextProvider = ({ children }) => {
  const [showReviewsPopin, setShowReviewsPopin] = useState(false);

  const addReviewDisplay = () => {
    const reviewsDisplay = getReviewsDisplay();
    const newReviewsDisplay = reviewsDisplay + 1;
    sessionStorage.setItem(
      'reviewsDisplays',
      JSON.stringify(newReviewsDisplay)
    );
  };

  const getReviewsDisplay = () => {
    const reviewsDisplays = sessionStorage.getItem('reviewsDisplays');
    return reviewsDisplays ? JSON.parse(reviewsDisplays) : 0;
  };

  return (
    <ReviewsContext.Provider
      value={{
        addReviewDisplay,
        showReviewsPopin,
        setShowReviewsPopin,
        getReviewsDisplay,
      }}
    >
      {children}
    </ReviewsContext.Provider>
  );
};

export default ReviewsContext;
export { ReviewsContextProvider };
