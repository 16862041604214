import React, { useEffect } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SinisterReason from 'components/SinisterReason/SinisterReason';
import {
  SINISTRE_NATURE_CODE_AUTRE,
  SINISTRE_NATURE_CODE_BRIS_DE_GLACE,
  SINISTRE_NATURE_CODE_DEGAT_DES_EAUX,
  SINISTRE_NATURE_CODE_INCENDIE,
  SINISTRE_NATURE_CODE_VANDALISME,
  SINISTRE_NATURE_CODE_VOL,
} from 'constants/common/sinistreNatureCode';
import BrokenGlassLottie from 'assets/lotties/claims/brokenGlass.json';
import VandalismLottie from 'assets/lotties/claims/vandalism.json';
import FireLottie from 'assets/lotties/claims/fire.json';
import OtherLottie from 'assets/lotties/claims/other.json';
import WaterLottie from 'assets/lotties/claims/water.json';
import FormHelperText from '@material-ui/core/FormHelperText';

BlocNatureSin.propTypes = {
  natureSin: PropTypes.string,
  setNatureSin: PropTypes.func,
  natureSinError: PropTypes.string,
  setNatureSinError: PropTypes.func,
};

function BlocNatureSin({
  natureSin,
  setNatureSin,
  natureSinError,
  setNatureSinError,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    if (!natureSin) {
      setNatureSinError(t('dashboard.sinistre.form.error.reason_required'));
    } else {
      setNatureSinError(null);
    }
  }, [natureSin]);

  return (
    <>
      <Box
        display="flex"
        width={1}
        alignItems="center"
        flexDirection="column"
        mb={6}
        mt={6}
      >
        <Typography variant="h5" color="secondary">
          {t('dashboard.sinistre.form.reason.title')}
        </Typography>
        <Box mt={2} width={1}>
          <Grid container alignItems="center" spacing={2}>
            {sinistreData.map((item, index) => (
              <SinisterReason
                key={index}
                selected={natureSin === item.reason}
                asset={item.asset}
                label={t(item.label)}
                onClick={() => setNatureSin(item.reason)}
              />
            ))}
          </Grid>
        </Box>
        {natureSinError && (
          <FormHelperText
            error={true}
            variant="outlined"
            style={{ marginTop: 15 }}
          >
            {natureSinError}
          </FormHelperText>
        )}
      </Box>
    </>
  );
}

const sinistreData = [
  {
    reason: SINISTRE_NATURE_CODE_INCENDIE,
    asset: FireLottie,
    label: 'dashboard.sinistre.form.reason.incendie',
  },
  {
    reason: SINISTRE_NATURE_CODE_DEGAT_DES_EAUX,
    asset: WaterLottie,
    label: 'dashboard.sinistre.form.reason.dde',
  },
  {
    reason: SINISTRE_NATURE_CODE_VANDALISME,
    asset: VandalismLottie,
    label: 'dashboard.sinistre.form.reason.vandalisme',
  },
  {
    reason: SINISTRE_NATURE_CODE_VOL,
    asset: VandalismLottie,
    label: 'dashboard.sinistre.form.reason.vol',
  },
  {
    reason: SINISTRE_NATURE_CODE_BRIS_DE_GLACE,
    asset: BrokenGlassLottie,
    label: 'dashboard.sinistre.form.reason.bdg',
  },
  {
    reason: SINISTRE_NATURE_CODE_AUTRE,
    asset: OtherLottie,
    label: 'dashboard.sinistre.form.reason.other',
  },
];

export default BlocNatureSin;
export { sinistreData };
