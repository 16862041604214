import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FormHelperText from '@material-ui/core/FormHelperText';
import AutomaticForm from './AutomaticForm';
// import ManualForm from './ManualForm';

const AutomaticField = (props) => {
  const {
    id,
    label,
    getOptionsURL,
    onChange,
    formatSelectedOption,
    automaticValue,
    minimumSearchLength,
    helperText,
  } = props;
  const [loading, setIsLoading] = useState(false);
  const [manual, setManual] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const [availableOptions, setAvailableOptions] = useState([]);
  const [value, setValue] = useState(null);

  const handleChange = (event, newValue) => {
    // setAvailableOptions(
    //   newValue ? [newValue, ...availableOptions] : availableOptions
    // );
    // setValue(newValue);

    const formattedValue = formatSelectedOption(newValue);
    setValue(formattedValue);

    onChange({ target: { id, value: formattedValue } });
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const getAvailableAddresses = async () => {
    const length = inputValue ? inputValue.length : 0;
    if (length > minimumSearchLength) {
      const response = await fetch(getOptionsURL(inputValue));

      const availableAddressesResponse = await response.json();
      setAvailableOptions(availableAddressesResponse.features);
    } else {
      setAvailableOptions([]);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    getAvailableAddresses();
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return manual ? (
    <></>
  ) : (
    <>
      <AutomaticForm
        title={label}
        availableOptions={availableOptions}
        onInputValueChange={handleInputChange}
        onChange={handleChange}
        setManual={setManual}
        loading={loading}
        value={automaticValue ? automaticValue : value}
        minimumSearchLength={minimumSearchLength}
      />
      <FormHelperText variant="outlined">{helperText}</FormHelperText>
    </>
  );
};

AutomaticField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  getOptionsURL: PropTypes.func.isRequired,
  formatSelectedOption: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  automaticValue: PropTypes.string,
  minimumSearchLength: PropTypes.number,
  helperText: PropTypes.string,
};

AutomaticField.defaultProps = {
  minimumSearchLength: 4,
};

export default AutomaticField;
