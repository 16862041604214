import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.white.main,
    zIndex: 10,
    '&:hover': {
      backgroundColor: theme.palette.white.main,
    },
  },
  popinContentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    padding: '40px 40px',
  },
}));

export default useStyles;
