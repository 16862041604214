import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Grid, Box, Button, Typography, Hidden } from '@material-ui/core';
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons';
import LinkIcon from '@material-ui/icons/Link';
import { makeStyles } from '@material-ui/core/styles';

import Lottie from 'components/Lottie';
import HouseLottie from 'assets/lotties/house.json';
import { POLICY_PROPERTYS } from 'constants/policy/policyParams';

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: '#F2F7FF',
    borderRadius: 10,
    boxSizing: 'border-box',
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: 40,
      height: 40,
      padding: 0,
      minWidth: 'auto',
    },
  },
  buttonIcon: {
    [theme.breakpoints.down('md')]: {
      marginLeft: '-8px',
    },
  },
  visual: {
    width: 58,
    height: 58,
    background: theme.palette.white.main,
    position: 'relative',
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: 48,
      height: 48,
    },
  },
  linkIconContainer: {
    position: 'absolute',
    top: -7,
    right: -7,
    color: theme.palette.white.main,
    background: '#36A6C1',
    borderRadius: '50%',
    width: 20,
    height: 20,
    fontSize: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  linkIcon: {
    width: '1rem',
  },
}));

const RelatedContract = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { policy, displayLinkButton } = props;
  const { t } = useTranslation();
  const asset =
    POLICY_PROPERTYS[policy.contractType.code][policy.housingType]?.avatar;

  return (
    <Box mt={2} p={2} width="100%" className={classes.container}>
      <Grid container alignItems="stretch">
        <Grid container item xs={12} md={10} wrap="nowrap">
          <Box>
            <div className={classes.visual}>
              <Lottie animationData={asset} isActive height={48} />
              <div className={classes.linkIconContainer}>
                <LinkIcon className={classes.linkIcon} />
              </div>
            </div>
          </Box>
          <Box align="left">
            <Hidden smDown>
              <Typography variant="subtitle1">
                {t(
                  `dashboard.police.policyTypes.${policy.contractType.code.toLowerCase()}${
                    policy.housingType
                  }`
                )}
              </Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography variant="body1">
                {t(
                  `dashboard.police.policyTypes.${policy.contractType.code.toLowerCase()}${
                    policy.housingType
                  }`
                )}
              </Typography>
            </Hidden>
            <Grid container>
              <Grid item xs={12} md={6} align="left">
                <Hidden smDown>
                  <Typography variant="caption">
                    {t('dashboard.certificates.address')}
                  </Typography>
                  <Typography variant="body1">{policy.policyKey}</Typography>
                </Hidden>
                <Hidden mdUp>
                  <Typography variant="caption">{policy.policyKey}</Typography>
                </Hidden>
              </Grid>
              <Grid item xs={12} md={6} align="left">
                <Hidden smDown>
                  <Typography variant="caption">
                    {t('dashboard.certificates.contractNumber')}
                  </Typography>
                  <Typography variant="body1">{policy.policyNumber}</Typography>
                </Hidden>
                <Hidden mdUp>
                  <Typography variant="caption">
                    {policy.policyNumber}
                  </Typography>
                </Hidden>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {displayLinkButton && (
          <Grid item container alignItems="center" justify="flex-end" md={2}>
            <Hidden smDown>
              <Button
                variant="outlined"
                endIcon={<ArrowForwardIosIcon className={classes.buttonIcon} />}
                className={classes.button}
                onClick={() => history.push(`/polices/${policy.policyNumber}`)}
              >
                <span>{t('common.details')}</span>
              </Button>
            </Hidden>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

RelatedContract.propTypes = {
  policy: PropTypes.shape().isRequired,
  displayLinkButton: PropTypes.bool,
};

RelatedContract.defaultProps = {
  displayLinkButton: true,
};

export default RelatedContract;
