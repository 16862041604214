import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import _get from 'lodash/get';
import PriceCard from 'components/PriceCard/PriceCard';
import { formatDateWithSystem, calculerDateEcheance } from 'utils';
import useStyles from './style';

const ContractDetails = (props) => {
  const classes = useStyles();

  return (
    <>
      <Box mb={2}>
        <Typography variant="h5" color="secondary">
          <Skeleton variant="rect" width={197} height={32} />
        </Typography>
      </Box>
      <Grid item container>
        <Grid item container xs={12} md={8}>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Box mb={2}>
                <Typography variant="caption" color="secondary">
                  <Skeleton variant="text" width={140} />
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    marginRight: 10,
                  }}
                >
                  <Skeleton variant="text" />
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" color="secondary">
                <Skeleton variant="text" width={140} />
              </Typography>
              <Typography
                variant="body2"
                style={{
                  marginRight: 10,
                }}
              >
                <Skeleton variant="text" />
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="caption" color="secondary">
                <Skeleton variant="text" width={140} />
              </Typography>
              <Typography
                variant="body2"
                style={{
                  marginRight: 10,
                }}
              >
                <Skeleton variant="text" />
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="secondary">
                <Skeleton variant="text" width={140} />
              </Typography>
              <Typography
                variant="body2"
                style={{
                  marginRight: 10,
                }}
              >
                <Skeleton variant="text" />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={4}
          className={classes.priceCardContainer}
        >
          <Box width={236}>
            <Skeleton variant="rect" width={222} height={92} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

ContractDetails.propTypes = {
};

export default ContractDetails;
