import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { useSnackbar } from 'notistack';
import {
  Button,
  Paper,
  Grid,
  CircularProgress,
  LinearProgress,
} from '@material-ui/core';
import FormHelperText from '@material-ui/core/FormHelperText';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import authApi from 'api/authApi';
import Lottie from 'components/Lottie';
import Field from 'components/Field';
import Home from 'assets/lotties/home.json';
import HomeMobile from 'assets/lotties/home-mobile.json';
import useStyles from './style';

const ActivationLink = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [userLogin, setUserLogin] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleValidate = async () => {
    setLoading(true);
    setErrorMessage(null);
    try {
      await authApi.reactivate({ identifier: userLogin });
      enqueueSnackbar(t('success.authentication.activationLinkSent'), {
        variant: 'warning',
        persist: false,
      });
    } catch (error) {
      console.log('error', error);
      setErrorMessage(error.errorMessage[i18next.resolvedLanguage]);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event) => {
    setErrorMessage(null);
    setUserLogin(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleValidate(event);
    }
  };
  return (
    <div className={classes.wrapper}>
      <Box className={classes.boxLottie}>
        <Hidden xsDown>
          <Lottie animationData={Home} isActive width={'100%'} />
        </Hidden>
        <Hidden smUp>
          <Lottie animationData={HomeMobile} isActive />
        </Hidden>
      </Box>
      <div className={classes.container}>
        <Paper
          variant="elevation"
          elevation={0}
          className={classes.logoWrapper}
        >
          <img
            src={`${localStorage.getItem(
              'IMAGE_MANAGER_URL_GET'
            )}/assurimo/base/logo-assurimo.png`}
            className={classes.logo}
            width={380}
          />
        </Paper>
        <Paper variant="elevation" elevation={0} className={classes.paper}>
          {loading && (
            <LinearProgress
              variant="indeterminate"
              classes={{
                root: classes.linearProgressRoot,
                colorPrimary: classes.linearProgressColorPrimary,
                bar: classes.linearProgressBar,
              }}
            />
          )}
          <Typography classes={{ root: classes.title }}>
            {t('auth.activationLink.title')}
          </Typography>

          <Grid container>
            <Grid container item spacing={3}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Field
                    id="login"
                    onChange={handleChange}
                    label={t('auth.activationLink.email')}
                    type="text"
                    variant="outlined"
                    fullWidth
                    inputProps={{
                      onKeyPress: (e) => handleKeyDown(e),
                    }}
                  />
                </Grid>
                {errorMessage && (
                  <FormHelperText
                    error={errorMessage}
                    component="div"
                    variant="outlined"
                  >
                    {errorMessage}
                  </FormHelperText>
                )}
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                    classes={{ root: classes.button }}
                    onClick={handleValidate}
                    disabled={!userLogin}
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : (
                      <Typography classes={{ root: classes.buttonText }}>
                        {t('auth.activationLink.generate')}
                      </Typography>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default ActivationLink;
