import { Box, Grid, Typography } from '@material-ui/core';
import FileInput from 'components/FileInput/FileInput';
import FileItem from 'components/FileItem/FileItem';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from 'views/Dashboard/Customer/ResilHamonPage/styles';

AttestationAssurance.propTypes = {
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onChange: PropTypes.func,
};

function AttestationAssurance({ files, onRemove, onChange }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const handleRemoveFile = (index) => {
    if (!onRemove) return;
    onRemove(index);
  };

  const handleChangeFile = (event) => {
    if (!onChange) return;
    onChange(event);
  };

  return (
    <Box className={classes.box}>
      <Box
        mb={6}
        display="flex"
        width={1}
        alignItems="center"
        flexDirection="column"
      >
        <Typography variant="h5" color="secondary">
          {t('dashboard.resilHamon.form.info.piece_joint')}
        </Typography>
        <Box mt={2} width={1} display="flex" justifyItems="center">
          <Grid
            container
            spacing={2}
            justifyContent={files.length ? 'start' : 'center'}
          >
            {files.map((file, i) => (
              <FileItem
                key={`file-${i}`}
                name={file.name}
                onRemove={() => handleRemoveFile(i)}
              />
            ))}
            {files.length < 10 && (
              <FileInput
                label={t('dashboard.resilHamon.form.info.add_file')}
                onChange={handleChangeFile}
                multiple
              />
            )}
          </Grid>
        </Box>
        <Box mt={2} width={1} justifyItems="center" alignItems="center">
          <Typography mt={2} width={1} variant="body1" align="center">
            {t('dashboard.resilHamon.form.info.pj_format')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default AttestationAssurance;
