import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2),
  },
  searchButtonWrapper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  searchButton: {
    height: '100%',
    border: `4px double ${theme.palette.white.main}`,
  },
}));

export default useStyles;
