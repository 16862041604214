import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Skeleton from '@material-ui/lab/Skeleton';
import Hidden from '@material-ui/core/Hidden';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { withStyles } from '@material-ui/core/styles';
import useStyles from './style';

const StyledCardActions = withStyles({
  root: {
    padding: '1rem',
    borderTop: '2px solid #F8F8F8',
  },
})(CardActions);

const PolicyItemSkeleton = () => {
  const classes = useStyles();
  const isActive = !['pending', 'ended'].includes(status);

  return (
    <Box mb={2} width={1}>
      <Card>
        <CardContent className={classes.content}>
          <Box
            className={classes.details}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              className={classes.contract}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
              flexGrow={1}
              marginRight={10}
            >
              <Box ml={2} mr={4} display="flex">
                <Hidden smDown>
                  <Skeleton variant="rect" width={116} height={116} />
                </Hidden>
                <Hidden mdUp>
                  <Skeleton variant="rect" width={80} height={80} />
                </Hidden>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height={1}
                width="100%"
              >
                <Box style={{ width: '100%' }}>
                  <Typography variant="h6">
                    <Skeleton variant="rect" width={100} height={32} />
                  </Typography>
                  <Typography variant="body2" className={classes.infoValue}>
                    <Skeleton variant="text" />
                  </Typography>
                </Box>
              </Box>
            </Box>
            {isActive && (
              <Hidden xsDown implementation="css">
                <Box width={236}>
                  <Skeleton variant="rect" width={222} height={92} />
                </Box>
              </Hidden>
            )}
          </Box>
        </CardContent>
        <StyledCardActions>
          <Box
            className={classes.bottom}
            width={1}
            style={{ display: 'flex', justifyContent: 'space-between' }}
          >
            <Box className={classes.infos}>
              <Grid container>
                {[1, 2, 3].map((value, index) => (
                  <Box
                    key={index}
                    className={classes.info}
                    style={{
                      marginRight: 10,
                    }}
                  >
                    <Typography variant="caption" className={classes.infoLabel}>
                      <Skeleton variant="text" width={100} />
                    </Typography>
                    <Typography
                      variant="body2"
                      className={`${classes.infoValue}`}
                    >
                      <Skeleton variant="text" />
                    </Typography>
                  </Box>
                ))}
              </Grid>
            </Box>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Skeleton
                variant="rect"
                width={145}
                height={30}
                style={{
                  marginRight: 10,
                }}
              />
              <Skeleton variant="rect" width={145} height={30} />
            </div>
          </Box>
        </StyledCardActions>
      </Card>
    </Box>
  );
};

export default PolicyItemSkeleton;
