import React from 'react';
import { Box, Grid, Typography, Hidden } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import Lottie from 'components/Lottie';
import HouseLottie from 'assets/lotties/house.json';
import ApartmentLottie from 'assets/lotties/apartment.json';

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: theme.spacing(1),
    background: theme.palette.white.main,
    paddingBottom: 0,
  },
  header: {
    borderTopLeftRadius: theme.spacing(1),
    borderTopRightRadius: theme.spacing(1),
    background: theme.palette.blue.light,
    color: theme.palette.white.main,
    padding: theme.spacing(2),
  },
  items: {
    padding: theme.spacing(2),
  },
  whiteText: {
    color: theme.palette.white.main,
  },
  visual: {
    width: 58,
    height: 58,
    background: theme.palette.white.main,
    position: 'relative',
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: 48,
      height: 48,
    },
  },
  infos: {
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      alignItems: 'flex-start',
    },
  },
  info: {
    color: theme.palette.white.main,
    [theme.breakpoints.down('md')]: {
      fontSize: 12,
      marginBottom: theme.spacing(1),
    },
  },
  linkIconContainer: {
    position: 'absolute',
    top: -7,
    right: -7,
    color: theme.palette.white.main,
    background: '#36A6C1',
    borderRadius: '50%',
    width: 20,
    height: 20,
    fontSize: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  linkIcon: {
    width: '1rem',
  },
}));

const CertificatesGroup = ({ type, title, address, period, children }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box mb={2} width={1}>
      <div className={classes.container}>
        <div className={classes.header}>
          <Box display="flex" width={1}>
            <div className={classes.visual}>
              <Lottie
                // animationData={type === 'house' ? HouseLottie : ApartmentLottie}
                animationData={HouseLottie}
                isActive
                height={48}
              />
              <div className={classes.linkIconContainer}>
                <LinkIcon className={classes.linkIcon} />
              </div>
            </div>
            <Grid container xs={12}>
              <Box>
                <Hidden mdDown>
                  <Typography variant="subtitle1" className={classes.whiteText}>
                    {title}
                  </Typography>
                </Hidden>
                <Hidden lgUp>
                  <Typography variant="body1" className={classes.whiteText}>
                    {title}
                  </Typography>
                </Hidden>
              </Box>
              <Grid container xs={12}>
                <Grid item xs={12} md={6}>
                  <Hidden mdDown>
                    <Typography variant="caption" className={classes.whiteText}>
                      {t('dashboard.certificates.address')}
                    </Typography>
                  </Hidden>
                  <Typography variant="body1" className={classes.info}>
                    {address}
                  </Typography>
                </Grid>
                {/* <Grid item xs={12} md={6}>
                  <Hidden mdDown>
                    <Typography variant="caption" className={classes.whiteText}>
                      Période
                    </Typography>
                  </Hidden>
                  <Typography variant="body1" className={classes.info}>
                    {period}
                  </Typography>
                </Grid> */}
              </Grid>
            </Grid>
          </Box>
        </div>
        <div className={classes.items}>{children}</div>
      </div>
    </Box>
  );
};

export default CertificatesGroup;
