import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AddressForm from 'components/AddressForm';
import { isEmpty } from 'utils/form-validation';
import Title from 'components/Title';
import { isZipCodeNotAllowed } from 'utils';
import HouseAddressTitle from './houseAddressTitle';

const AddressStep = (props) => {
  const { data, onConfirm, setUserData } = props;
  const { t } = useTranslation();

  return (
    <>
      <Title>
        <HouseAddressTitle />
      </Title>
      <AddressForm
        automaticFieldTitle={t('steps.addressStep.automaticFieldTitle')}
        manualFields={[
          {
            key: 'contractAddress.address1',
            label: t('steps.addressStep.address'),
            type: 'text',
            invalid: isEmpty(data.contractAddress.address1),
            helperText: (() => {
              if (isEmpty(data.contractAddress.address1)) {
                return t('steps.addressStep.errors.emptyAddress');
              }
            })(),
            value: data.contractAddress.address1 || '',
          },
          {
            key: 'contractAddress.address2',
            label: t('steps.addressStep.additionalAddress'),
            type: 'text',
            invalid: false,
            value: data.contractAddress.address2 || '',
            shrink:
              data.contractAddress.address1 &&
              data.contractAddress.zipCode &&
              data.contractAddress.city,
          },
          {
            key: 'contractAddress.zipCode',
            label: t('steps.addressStep.zipCode'),
            type: 'text',
            invalid:
              isEmpty(data.contractAddress.zipCode) ||
              isNaN(data.contractAddress.zipCode) ||
              isZipCodeNotAllowed(data.contractAddress.zipCode),
            helperText: (() => {
              if (isEmpty(data.contractAddress.zipCode)) {
                return t('steps.addressStep.errors.emptyZipCode');
              }

              if (isNaN(data.contractAddress.zipCode)) {
                return t('steps.addressStep.errors.invalidZipCode');
              }

              if (isZipCodeNotAllowed(data.contractAddress.zipCode)) {
                return t('pno.addressStep.departmentNotCovered');
              }
            })(),
            value: data.contractAddress.zipCode,
          },
          {
            key: 'contractAddress.city',
            label: t('steps.addressStep.city'),
            type: 'text',
            invalid: isEmpty(data.contractAddress.city),
            helperText: (() => {
              if (isEmpty(data.contractAddress.city)) {
                return t('steps.addressStep.errors.emptyCity');
              }
            })(),
            value: data.contractAddress.city || '',
          },
        ]}
        onChange={setUserData}
        onConfirm={onConfirm}
        addressField={'contractAddress'}
        postalPopin
      />
    </>
  );
};

AddressStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
};

export default AddressStep;
