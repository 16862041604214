import React from 'react';
import { useTranslation } from 'react-i18next';
import { Divider, Menu, MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';

import {
  PAGE_SECTION_SHOW,
  policeStatuses,
} from 'views/Dashboard/Customer/PolicyPage/helper';

const MenuHeader = ({
  isMobileVersion,
  sectionRefs,
  police,
  policyStatus,
  isMenuOpen,
  closeMenu,
  onResiliationClick,
  onRetractationClick,
  onPaymentPeriodicityClick,
  peutResilier,

  peutSeRetracter,
  buttonRef,
}) => {
  const { t } = useTranslation();

  const estUnePoliceActive = [policeStatuses.ACTIVE].includes(policyStatus);
  const autoriseAChangerPeriodicitePaiement =
    PAGE_SECTION_SHOW[police.contractType.code].changePeriodicity;

  const handleClickMenu = (sectionId) => {
    if (sectionRefs[sectionId].ref.current) {
      sectionRefs[sectionId].ref.current.style.scrollMargin = '120px';
      sectionRefs[sectionId].ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Menu
      open={isMenuOpen}
      onClose={closeMenu}
      keepMounted
      anchorEl={buttonRef.current}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      onClick={() => closeMenu()}
    >
      {[
        ...Object.keys(sectionRefs)
          .filter(
            (key) =>
              !sectionRefs[key].lienSection ||
              PAGE_SECTION_SHOW[police.contractType.code][
                sectionRefs[key].lienSection
              ]
          )
          .map((item, index) => (
            <MenuItem
              key={index}
              style={{ display: 'flex' }}
              onClick={() => handleClickMenu(item)}
            >
              {t(sectionRefs[item].menuLabel)}
            </MenuItem>
          )),
        estUnePoliceActive && autoriseAChangerPeriodicitePaiement && (
          <MenuItem
            key="managePaymentPeriodicity"
            onClick={onPaymentPeriodicityClick}
            style={{ display: 'flex' }}
          >
            {t('dashboard.policies.managePaymentPeriodicity')}
          </MenuItem>
        ),
        isMobileVersion && <Divider key="Divider" />,
        isMobileVersion && peutSeRetracter && (
          <MenuItem onClick={onRetractationClick} key="retract">
            {t('dashboard.policies.meRetracter')}
          </MenuItem>
        ),
        isMobileVersion && peutResilier && (
          <MenuItem onClick={onResiliationClick} key="resil">
            {t('dashboard.policies.terminateContract')}
          </MenuItem>
        ),
      ]}
    </Menu>
  );
};

MenuHeader.propTypes = {
  isMobileVersion: PropTypes.bool.isRequired,
  peutResilier: PropTypes.bool.isRequired,
  peutSeRetracter: PropTypes.bool.isRequired,
  sectionRefs: PropTypes.object.isRequired,
  police: PropTypes.object.isRequired,
  policyStatus: PropTypes.string.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
  onResiliationClick: PropTypes.func,
  onRetractationClick: PropTypes.func,
  onPaymentPeriodicityClick: PropTypes.func.isRequired,
  buttonRef: PropTypes.object.isRequired,
};

export default MenuHeader;
