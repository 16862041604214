import React from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Info from '../Info';

const PasswordField = (props) => {
  const { info } = props;

  return (
    <MuiTextField
      type="password"
      variant="filled"
      {...props}
      InputProps={{
        endAdornment: info && (
          <InputAdornment position="end">
            <Info title={info} />
          </InputAdornment>
        ),
      }}
    />
  );
};

PasswordField.propTypes = {
  info: PropTypes.string,
};

export default PasswordField;
