import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';

import useStyles from '../style';

const PasswordPage = (props) => {
  const { onValidate, onChange, goToResetPassword } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onValidate(password);
    }
  };

  const getVisibilityIcon = () => {
    return showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />;
  };

  const handlePasswordChange = (e) => {
    const enteredPassword = e.target.value;
    setPassword(enteredPassword);
    onChange(enteredPassword);
  };

  return (
    <>
      <Box
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body1">
          {t('auth.accountUpdate.accountAlreadyActivated')}
        </Typography>
      </Box>
      <Box
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <TextField
          id="password"
          onChange={handlePasswordChange}
          label={t('auth.login.password')}
          type={showPassword ? 'text' : 'password'}
          // error={complexityPasswordError}
          variant="outlined"
          fullWidth
          autoFocus
          inputProps={{
            onKeyPress: (e) => handleKeyDown(e),
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {getVisibilityIcon()}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Link
          component="button"
          variant="body1"
          onClick={() => {
            goToResetPassword();
          }}
        >
          <Typography classes={{ root: classes.forgetPassword }}>
            {t('auth.login.forgotPassword')}
          </Typography>
        </Link>
      </Box>
    </>
  );
};

PasswordPage.propTypes = {
  onValidate: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  goToResetPassword: PropTypes.func.isRequired,
};

export default PasswordPage;
