import { formatDate } from 'utils';
import _get from 'lodash/get';

const columns = [
  { field: 'policyNumber', headerName: 'Numéro de police', minWidth: 220 },
  {
    field: 'name',
    headerName: 'Client',
    minWidth: 220,
    valueGetter: (params) => {
      return `${_get(params.row, 'customer.firstName') || ''} ${_get(
        params.row,
        'customer.lastName'
      )}`;
    },
  },
  {
    field: 'policyKey',
    headerName: 'Adresse',
    minWidth: 170,
  },
  {
    field: 'startDate',
    headerName: "Date d'effet",
    minWidth: 170,
    valueGetter: (params) =>
      params.row.startDate
        ? formatDate(params.row.startDate, 'DD/MM/YYYY')
        : '',
  },
  {
    field: 'statusDate',
    headerName: "Date d'avenant",
    minWidth: 170,
    valueGetter: (params) =>
      params.row.statusDate
        ? formatDate(params.row.statusDate, 'DD/MM/YYYY')
        : '',
  },
  {
    field: 'status',
    headerName: 'Etat',
    minWidth: 170,
    valueGetter: (params) => _get(params.row, 'status.label', '').toUpperCase(),
  },
];

export default columns;
