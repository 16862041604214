import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { useTranslation, Trans } from 'react-i18next';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import Icon from 'components/Icon';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { formatNumber } from 'utils/index';
import useStyles from './style';

const BigPrice = (props) => {
  const {
    price,
    priceParts,
    pricePromoParts,
    periodicity,
    namely,
    classBlink,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const yearText = t('common.year');
  const monthText = t('common.month');
  const isAnnual = periodicity === 'A';

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const showExplanations = () => {
    if (price.annualTTC && price.monthlyTTC) return true;
  };

  const renderPrice = (priceItem) => {
    return (
      <div className="CardPrice_Price_DetailsWrapper">
        <div className="CardPrice_Price_Details">
          <span className={`CardPrice_Price_Details_Whole ${classBlink}`}>
            {priceItem[0]}
          </span>
          <span className={`CardPrice_Price_Details_Decimal ${classBlink}`}>
            {priceItem[1]}
          </span>
        </div>
        <div className="CardPrice_Price_Period_Wrapper">
          <span className="CardPrice_Price_Period">
            {periodicity === 'A' ? `€ / ${yearText}` : `€ / ${monthText}`}
          </span>
        </div>
      </div>
    );
  };

  const renderAttackTax = () => {
    return (
      price.annualSurtaxe && (
        <div style={{ display: 'flex', gap: '5px', justifyContent: 'center' }}>
          <Typography variant="body1" classes={{ root: classes.smallPrice }}>
            <Trans
              i18nKey={
                isAnnual
                  ? 'common.includingAttackTax'
                  : 'common.includingAttackTaxMensual'
              }
              values={{
                attackTaxPrice: formatNumber(price.annualSurtaxe),
              }}
            />
          </Typography>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                arrow
                placement="top"
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={t('common.attackTaxExplanations')}
              >
                <IconButton
                  onClick={handleTooltipOpen}
                  classes={{ root: classes.infoButton }}
                >
                  <Icon
                    className="fas fa-info-circle"
                    classes={{ root: classes.infoIcon }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </div>
      )
    );
  };

  return (
    <div className="CardPrice_Price">
      {pricePromoParts ? (
        <>
          {renderPrice(pricePromoParts)}
          <Typography
            variant="h6"
            classes={{ root: `${classes.smallPrice} ${classes.priceOld}` }}
          >
            {` ${t('common.insteadOf')} ${
              isAnnual ? price.annualTTCDeBase : price.monthlyTTCDeBase
            } € / ${isAnnual ? yearText : monthText}`}
          </Typography>
          {renderAttackTax()}
          {showExplanations() && namely && !isAnnual && (
            <Typography variant="body1" classes={{ root: classes.smallPrice }}>
              {` ${t('common.namely')} ${formatNumber(
                price.annualTTC
              )} € / ${yearText}`}
            </Typography>
          )}
        </>
      ) : (
        <>
          {renderPrice(priceParts)}
          {renderAttackTax()}
          {showExplanations() && !isAnnual && (
            <Typography variant="body1" classes={{ root: classes.smallPrice }}>
              {` ${t('common.namely')} ${price.annualTTC} € / ${yearText}`}
            </Typography>
          )}
        </>
      )}
    </div>
  );
};

BigPrice.propTypes = {
  price: PropTypes.shape({
    annualTTC: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    monthlyTTC: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    annualTTCDeBase: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    monthlyTTCDeBase: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    annualSurtaxe: PropTypes.number,
  }).isRequired,
  priceParts: PropTypes.any,
  pricePromoParts: PropTypes.shape(),
  periodicity: PropTypes.string.isRequired,
  classBlink: PropTypes.any,
  namely: PropTypes.bool,
};

export default BigPrice;
