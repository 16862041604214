import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Lottie from 'components/Lottie';
import { formatDate } from 'utils';
import LocataireLottie from 'assets/lotties/locataire.json';
import useStyles from '../style';
import { PnoContext } from 'contexts/pno';

const MyProfile = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();

  const { state } = useContext(PnoContext);
  let { data } = state;

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid item xs={12}>
      <Card classes={{ root: classes.reminderCard }}>
        <CardContent align={mobile ? 'center' : 'left'}>
          {/* <Typography variant="h6" color="textPrimary">
            {t('steps.resultStep.myGood')}
          </Typography> */}
          <Grid
            container
            direction={mobile ? 'column' : 'row'}
            alignItems="center"
          >
            <Grid item xs={12} md={4}>
              <Lottie animationData={LocataireLottie} isActive={true} />
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1" component="span">
                {`${
                  data.contact.gender === 'M.'
                    ? t('common.formFields.mr')
                    : t('common.formFields.ms')
                }`}
              </Typography>
              <Typography variant="body2" component="span">
                {` ${data.contact.firstName} ${data.contact.lastName}`}
              </Typography>{' '}
              <Typography variant="body1" component="span">
                {`${
                  data.contact.gender === 'M.'
                    ? t('steps.resultStep.pricing.born', {
                        context: 'male',
                      })
                    : t('steps.resultStep.pricing.born', {
                        context: 'female',
                      })
                }`}{' '}
              </Typography>
              <Typography variant="body2" component="span">
                {formatDate(data.contact.birthdate, 'DD MMMM YYYY')}
              </Typography>
              <Typography variant="body2">
                <span>{data.contact.mail}</span>
              </Typography>
              {/* <Typography variant="body2">
                <span>{data.contact.phone.value}</span>
              </Typography> */}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

MyProfile.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default MyProfile;
