import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'flex',
    alignItems: 'center',
  },
  subItem: {
    paddingLeft: theme.spacing(4),
  },
  icon: {
    fontSize: 14,
    width: 'auto',
  },
  text: {
    fontSize: 14,
  },
}));

export default useStyles;
