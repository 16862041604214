import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  formContactLayout: {
    '& > p': {
      marginTop: 40,
      marginBottom: 40,
    },
    '& > div': {
      marginTop: 20,
    },
    '& > button': {
      marginTop: 30,
    },
  },
}));
