import common from './common.json';
import steps from './steps.json';
import pno from './pno.json';
import dashboard from './dashboard.json';
import error from './error.json';
import success from './success.json';
import auth from './auth.json';

const en = {
  translation: {
    common,
    steps,
    pno,
    dashboard,
    error,
    success,
    auth,
  },
};

export default en;
