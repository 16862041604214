import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  accordion: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    border: 'none',
    boxShadow: 'none',
    borderBottom: 0,
    '&:first-child': {
      borderTop: 0,
    },
    '&:before': {
      display: 'none',
    },
    // '&$expanded': {
    //   margin: 'auto',
    // },
  },
  accordionExpanded: {
    margin: '0 !important',
  },
  expandIcon: {
    marginTop: '0 !important',
    marginBottom: '0 !important',
  },
  summary: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    borderBottom: 'none',
    marginBottom: -1,
    minHeight: '30px !important',
    // '&$expanded': {
    //   minHeight: '30px !important',
    // },
    marginTop: '0 !important',
  },
  zone: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  example: {
    fontSize: 13,
    fontStyle: 'italic',
  },
  detailsButton: {
    marginTop: 10,
  },
  icon: {
    width: 'auto',
  },
  additional: {
    fontSize: 13,
    fontWeight: 'bolder',
    fontStyle: 'italic',
  },
  categoryTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  filesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    marginTop: 10,
    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-evenly',
    },
  },
  fileWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  file: {
    paddingLeft: 10,
    textDecoration: 'none',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  pdfIcon: {
    color: theme.palette.secondary.main,
  },

  guaranteeButton: {
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, -18px)',
    backgroundColor: `${theme.palette.white.main} !important`,
    borderRadius: 52,
    border: `2px solid ${theme.palette.blue.light} !important`,
    color: theme.palette.blue.light,
    whiteSpace: 'nowrap',
  },
  boxAction: {
    margin: '0!important',
  },
  guaranteeGridList: {
    marginBottom: '0 !important',
    maxWidth: '60rem',
  },
  guaranteeGridListFullWidth: {
    marginBottom: '0 !important',
    maxWidth: '100% !important',
  },
}));

export default useStyles;
