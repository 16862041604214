import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { firstLetterUppercase } from 'utils/index';

const EmailActivationPage = (props) => {
  const { firstname } = props;
  const { t } = useTranslation();

  return (
    <>
      <Box
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body1">
          <Trans
            i18nKey="auth.accountActivation.description.hello"
            values={{ firstname: firstLetterUppercase(firstname) }}
          />
          <br />
          <br />
          {t('auth.accountActivation.description.weKnowEachOther')}
          <br />
          <br />
          {t('auth.accountActivation.description.activateAccount')}
        </Typography>
      </Box>
    </>
  );
};

EmailActivationPage.propTypes = {
  firstname: PropTypes.string.isRequired,
};

export default EmailActivationPage;
