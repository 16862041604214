import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

import GlobalContext from 'contexts/global';
import { UserContext } from 'contexts/user';
import InputsStep from 'components/InputsStep/InputsStep';
import Title from 'components/Title';
import LoginPopin from 'components/Login/Popin';
import NextStepButton from 'components/NextStepButton/NextStepButton';
import useGa from 'hooks/useGA';
import customerApi from 'api/customerApi';
import { isEmpty, isEmail, isContainsSlash } from 'utils/form-validation';
import useStyles from './style';
import { getMessageError } from 'constants/common/errorCode';

const UserInfoStep = ({ data, onConfirm, stepsContext, setUserData }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { gtagEvent } = useGa();
  const { t } = useTranslation();
  const classes = useStyles();
  const [userState] = useContext(UserContext);
  const { dispatch } = useContext(stepsContext);
  const [loginPopinOpen, setLoginPopinOpen] = useState(false);
  const { gaMrhSiteKey } = useContext(GlobalContext);

  const createVisitor = () => {
    return customerApi
      .createVisitor({
        contact: {
          mail: data.contact.mail,
        },
        firstName: data.contact.firstName,
        lastName: data.contact.lastName,
        title: data.contact.gender,
        createReason: 'MRH',
      })
      .then(() => {
        gtagEvent(
          'create_prospect',
          {
            category: 'engagement',
            label: 'Création de prospect',
          },
          gaMrhSiteKey
        );
      });
  };

  const handleValidate = async () => {
    try {
      await createVisitor();
      onConfirm();
    } catch (error) {
      const messageError = getMessageError(error);
      console.error(t(messageError), error);
      enqueueSnackbar(t(messageError), { variant: 'error' });
    }
  };

  const isAuthenticated = () => {
    if (userState.user && userState.auth) {
      return userState.auth.isAuthenticated;
    } else return false;
  };

  const handleOpenLoginPopin = () => {
    setLoginPopinOpen(true);
  };

  const handleCloseLoginPopin = () => {
    setLoginPopinOpen(false);
  };

  const goToNextStep = () => {
    dispatch({
      type: 'NEXT_STEP',
    });
  };

  const renderFirstPart = () => {
    return (
      !isAuthenticated() && (
        <Container maxWidth={'sm'} className="InputsStep">
          <Grid container alignItems="center" justifyContent="center">
            <Grid
              container
              alignItems="center"
              justifyContent="center"
              spacing={3}
            >
              <Grid container item xs={12} alignItems="center">
                <div className={classes.titleWrapper}>
                  <Title title={t('steps.userInfoStep.loginTitle')} />
                </div>

                <NextStepButton
                  title={t('common.login')}
                  onClick={handleOpenLoginPopin}
                />
                <div className={classes.separator} />
              </Grid>
            </Grid>
          </Grid>
        </Container>
      )
    );
  };

  return data ? (
    <>
      {renderFirstPart()}
      <Title title={t('steps.userInfoStep.title')} />

      <InputsStep
        // disabled={!!data.contact && !!data.contact.customerNumber}
        fields={[
          {
            id: 'contact.gender',
            type: 'radio',
            choices: [
              {
                label: t('common.formFields.mr'),
                value: 'M.',
              },
              {
                label: t('common.formFields.ms'),
                value: 'MME',
              },
            ],
            direction: 'horizontal',
            invalid:
              isEmpty(data.contact.gender) ||
              (data.contact.gender !== 'M.' && data.contact.gender !== 'MME'),
            helperText: (() => {
              if (
                isEmpty(data.contact.gender) ||
                (data.contact.gender !== 'M.' && data.contact.gender !== 'MME')
              ) {
                return t('error.fields.fieldRequired');
              }
            })(),
            value: data.contact.gender || '',
          },
          {
            id: 'contact.lastName',
            label: t('common.formFields.lastname'),
            type: 'text',
            inputProps: {
              maxLength: 50,
            },
            invalid:
              isEmpty(data.contact.lastName) ||
              isContainsSlash(data.contact.lastName),
            helperText: (() => {
              if (isEmpty(data.contact.lastName)) {
                return t('error.fields.fieldRequired');
              }
              if (isContainsSlash(data.contact.lastName)) {
                return t('error.fields.fieldMustNotContainSlash');
              }
            })(),
            value: data.contact.lastName || '',
          },
          {
            id: 'contact.firstName',
            label: t('common.formFields.firstname'),
            type: 'text',
            inputProps: {
              maxLength: 50,
            },
            invalid:
              isEmpty(data.contact.firstName) ||
              isContainsSlash(data.contact.firstName),
            helperText: (() => {
              if (isEmpty(data.contact.firstName)) {
                return t('error.fields.fieldRequired');
              }
              if (isContainsSlash(data.contact.firstName)) {
                return t('error.fields.fieldMustNotContainSlash');
              }
            })(),
            value: data.contact.firstName || '',
          },
          {
            id: 'contact.mail',
            label: t('common.formFields.email'),
            type: 'text',
            invalid: isEmpty(data.contact.mail) || !isEmail(data.contact.mail),
            helperText: (() => {
              if (isEmpty(data.contact.mail)) {
                return t('error.fields.fieldRequired');
              }

              if (!isEmail(data.contact.mail)) {
                return t('error.fields.invalidEmailAddress');
              }
            })(),
            value: data.contact.mail || '',
            forceLowerCase: true,
          },
        ]}
        onChange={setUserData}
        onConfirm={handleValidate}
      />
      <LoginPopin
        open={loginPopinOpen}
        onClose={handleCloseLoginPopin}
        goToNextStep={goToNextStep}
      />
    </>
  ) : (
    <></>
  );
};

UserInfoStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  goToNextStep: PropTypes.func,
  stepsContext: PropTypes.shape().isRequired,
};

export default UserInfoStep;
