import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { SecureRoute } from '@okta/okta-react';
import { UserContext } from 'contexts/user';
import { hasAccess } from 'utils/auth';
import ForbiddenAccess from 'views/Dashboard/Errors/ForbiddenAccess';

const AuthorizedRoute = (props) => {
  const [state] = useContext(UserContext);
  const userAccessRights = state.rights;
  const { component: Component, neededRights, ...rest } = props;
  const isAuthorized = hasAccess(neededRights, userAccessRights);

  return (
    <SecureRoute
      {...rest}
      component={() => (isAuthorized ? <Component /> : <ForbiddenAccess />)}
    />
  );
};

AuthorizedRoute.propTypes = {
  neededRights: PropTypes.array.isRequired,
  component: PropTypes.func.isRequired,
};

export default AuthorizedRoute;
