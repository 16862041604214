import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useTranslation } from 'react-i18next';

import SinisterReason from 'components/SinisterReason/SinisterReason';
import { POLICY_PROPERTYS } from 'constants/policy/policyParams';
import Lottie from 'components/Lottie';
import { sinistreData } from '../BlocNatureSin';
import useStyles from '../style';

const PopinClaimsia = ({ reason, date, policy }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const reasonObject = sinistreData.find((r) => reason === r.reason);
  const asset =
    POLICY_PROPERTYS[policy.contractType.code][policy.housingType]?.avatar;

  return (
    <Box className={classes.policyContainer}>
      <Box
        mt={1}
        width={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Grid container xs={12} lg={12}>
          <Box
            mb={2}
            display="flex"
            width={1}
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="body2" color="secondary">
              {t('dashboard.sinistre.popin.declare')}
            </Typography>
          </Box>
          <Box
            mb={4}
            display="flex"
            width={1}
            alignItems="center"
            flexDirection="column"
          >
            <SinisterReason
              asset={reasonObject.asset}
              label={t(reasonObject.label)}
              selected
            />
          </Box>
        </Grid>
        <Grid>
          <Box
            mb={2}
            display="flex"
            width={1}
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="body2" color="secondary">
              {t('dashboard.sinistre.popin.occured')}
            </Typography>
          </Box>
          <Box
            mb={4}
            display="flex"
            width={1}
            alignItems="center"
            flexDirection="column"
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inlined"
                inputVariant="filled"
                format="DD/MM/yyyy"
                className="date-picker-input"
                label={t('dashboard.sinistre.form.info.date')}
                value={date}
                maxDateMessage={null}
                minDateMessage={null}
                invalidDateMessage={t('error.fields.invalidDate')}
                disabled
              />
            </MuiPickersUtilsProvider>
          </Box>
        </Grid>
        <Grid>
          <Box
            mb={2}
            display="flex"
            width={1}
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="body2" color="secondary">
              {t('dashboard.sinistre.popin.at')}
            </Typography>
          </Box>
          <Box
            mb={4}
            display="flex"
            width={1}
            alignItems="center"
            justifyContent="center"
            className={classes.visual}
          >
            <div className={classes.addressWrapper}>
              <Box>
                <Lottie animationData={asset} isActive height={92} />
              </Box>

              <Box>
                <Typography variant="caption">
                  {t('dashboard.certificates.address')}
                </Typography>
                <Typography variant="body1">{policy.policyKey}</Typography>
              </Box>
            </div>
          </Box>
        </Grid>
        <Grid>
          <Box
            mb={4}
            display="flex"
            width={1}
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="body2" color="secondary">
              {t('dashboard.sinistre.popin.redirect')}
            </Typography>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

PopinClaimsia.propTypes = {
  date: PropTypes.any,
  reason: PropTypes.shape(),
  policy: PropTypes.shape(),
};

export default PopinClaimsia;
