import React from 'react';
import PropTypes from 'prop-types';
import { Container, Box, Typography } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';

import useStyles from './style';

const StepHeader = ({ groupId, steps }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const renderValidatedStep = (index) => {
    return (
      <div key={`validatedStep${index}`} className={classes.step}>
        <div className={classes.validatedStep}>
          <CheckIcon classes={{ root: classes.secondaryIcon }} />
        </div>
        <div className={classes.stepLink} />
      </div>
    );
  };

  const renderStepToDo = (index) => {
    return (
      <div key={`steptoDo${index}`} className={classes.step}>
        <div className={classes.stepToDo} />
        {index !== steps.length - 1 && <div className={classes.stepLink} />}
      </div>
    );
  };

  const renderCurrentStep = (index) => {
    return (
      <div key={`currentStep${index}`} className={classes.step}>
        <div className={classes.currentStep}>
          <Typography variant="h6">{`${t(
            steps[groupId][0].category
          )}`}</Typography>
        </div>
        {index !== steps.length - 1 && <div className={classes.stepLink} />}
      </div>
    );
  };

  return (
    <>
      <Container className={classes.stepHeader}>
        <Box align="center" display="flex" justifyContent="center">
          {steps.map((group, index) => {
            if (groupId === index) return renderCurrentStep(index);
            if (index < groupId) return renderValidatedStep(index);
            if (index > groupId) return renderStepToDo(index);
          })}
        </Box>
      </Container>
    </>
  );
};

StepHeader.propTypes = {
  groupId: PropTypes.number,
  steps: PropTypes.array,
};

export default StepHeader;
