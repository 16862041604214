import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Icon from 'components/Icon';
import useStyles from './style';
import { useTranslation } from 'react-i18next';

const Popin = (props) => {
  const { t } = useTranslation();

  const {
    open,
    onClose,
    title,
    icon,
    content,
    validateButtonLabel = t('common.popin.btn_close'),
    cancelButtonLabel = t('common.popin.btn_close'),
    showCancelButton,
    maxWidth,
    isValidateButtonDisabled,
    isCancelButtonDisabled,
    onValidate,
    onCancel,
    disableEscapeKeyDown,
    enableTitleArea,
    dialogActionsClasses,
    validateButtonVariant,
    useValidateLoader,
    validateLoading,
    showActionsButtons,
    showCloseButton,
  } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isDownXs = useMediaQuery(theme.breakpoints.down('xs'));

  const handleValidate = () => {
    if (onValidate) onValidate();
    else onClose();
  };

  const handleCancel = () => {
    if (onCancel) {
      onCancel();
    } else onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullWidth={true}
      maxWidth={maxWidth}
      fullScreen={!!isDownXs}
      className={classes.dialog}
      disableEscapeKeyDown={disableEscapeKeyDown}
    >
      {enableTitleArea && (
        <DialogTitle
          id="scroll-dialog-title"
          classes={{ root: classes.titleWrapper }}
          disableTypography
        >
          {icon && <Icon className={icon} classes={{ root: classes.icon }} />}
          <Typography color="textPrimary" classes={{ root: classes.title }}>
            {title}
          </Typography>
          {showCloseButton && (
            <IconButton
              aria-label="close"
              className={classes.closeButton}
              onClick={onClose}
              size="small"
            >
              <CloseIcon />
            </IconButton>
          )}
        </DialogTitle>
      )}
      <DialogContent dividers={true} className={classes.content}>
        {content ? content : null}
      </DialogContent>
      {showActionsButtons && (
        <DialogActions classes={dialogActionsClasses}>
          {showCancelButton && (
            <Button onClick={handleCancel} disabled={isCancelButtonDisabled}>
              {cancelButtonLabel}
            </Button>
          )}
          <Button
            onClick={handleValidate}
            color="primary"
            disabled={isValidateButtonDisabled}
            variant={validateButtonVariant}
          >
            {useValidateLoader && validateLoading ? (
              <CircularProgress color="primary" size={16} />
            ) : (
              validateButtonLabel
            )}
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

Popin.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  icon: PropTypes.string,
  content: PropTypes.node,
  showActionsButtons: PropTypes.bool,
  showCancelButton: PropTypes.bool,
  maxWidth: PropTypes.string,
  validateButtonLabel: PropTypes.node,
  cancelButtonLabel: PropTypes.string,
  onCancel: PropTypes.func,
  onValidate: PropTypes.func,
  isValidateButtonDisabled: PropTypes.bool,
  isCancelButtonDisabled: PropTypes.bool,
  disableEscapeKeyDown: PropTypes.bool,
  enableTitleArea: PropTypes.bool,
  dialogActionsClasses: PropTypes.object,
  validateButtonVariant: PropTypes.string,
  useValidateLoader: PropTypes.bool,
  validateLoading: PropTypes.bool,
  showCloseButton: PropTypes.bool,
};

Popin.defaultProps = {
  open: false,
  icon: null,
  showCancelButton: false,
  maxWidth: 'md',
  isValidateButtonDisabled: false,
  isCancelButtonDisabled: false,
  disableEscapeKeyDown: false,
  enableTitleArea: true,
  useValidateLoader: false,
  validateLoading: false,
  showActionsButtons: true,
  showCloseButton: false,
};

export default Popin;
