import React, { useEffect } from 'react';
import {
  FormControl,
  Grid,
  Typography,
  Box,
  Container,
} from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import NextStepButton from 'components/NextStepButton/NextStepButton';
import Field from 'components/Field';

const NewDateStep = ({
  dataKey,
  label,
  message,
  value,
  onChange,
  onConfirm,
  minDate,
  text,
  invalid,
}) => {
  const { t } = useTranslation();

  const handleChangeDate = (date) => {
    const newDate = date.target ? date.target.value : date;
    onChange(dataKey, newDate);
  };

  useEffect(() => {
    if (!value || moment(value).isBefore(minDate)) {
      handleChangeDate(minDate);
    }
  }, []);

  return (
    <Container maxWidth="sm" className="OldDateStep">
      <Grid container alignItems="center" justifyContent="center">
        <Grid container alignItems="center" justifyContent="center" spacing={3}>
          <form noValidate autoComplete="off">
            <FormControl fullWidth margin="dense">
              <Field
                id="dataKey"
                type="date"
                label={label}
                value={value || minDate}
                onChange={handleChangeDate}
                minDate={minDate}
                minDateMessage={t('steps.insuranceStartStep.minDateMessage')}
              />
            </FormControl>
          </form>
          {message && (
            <Grid container alignItems="center" justifyContent="center">
              <Box mt={5}>
                <Typography variant="body1" align="center">
                  {message}
                </Typography>
              </Box>
            </Grid>
          )}
          {text && (
            <Grid container alignItems="center" justifyContent="center">
              <Box mt={5}>
                <Typography variant="body1" align="center">
                  {text}
                </Typography>
              </Box>
            </Grid>
          )}
        </Grid>
        <Box mt={5}>
          <NextStepButton onClick={onConfirm} disabled={invalid} />
        </Box>
      </Grid>
    </Container>
  );
};

export default NewDateStep;
