import moment from 'moment';
import policyApi from 'api/policyApi';

const getLastDayOfStartingDateYear = (date) => {
  return moment(date).endOf('year').format('YYYY-MM-DDTHH:mm:ss[Z]');
};

const getPaymentPlans = (data) => {
  const body = {
    amountInclTax: data.price.annualTTCPromo || data.price.annualTTC,
    amountPaymentSplittingCode: 'A',
    startDate: moment(data.contractStartDate).format('YYYY-MM-DDTHH:mm:ss[Z]'),
    endDate: getLastDayOfStartingDateYear(data.contractStartDate),
    ratingCode: data.riskRate.code,
    paymentSplittingCodes: [data.paymentSplitting.code],
  };

  return policyApi.getPaymentPlan(body);
};

export default getPaymentPlans;
