import { makeStyles } from '@material-ui/core/styles';

const drawerWidth = 250;
const withStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    [theme.breakpoints.up('md')]: {
      width: drawerWidth,
      flexShrink: 0,
      paddingTop: 76,
    },
  },
  appBar: {
    zIndex: 1310,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    backgroundColor: theme.palette.white.main,
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    position: 'absolute',
    left: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
    color: theme.palette.secondary.main,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  mainContainer: {
    flexGrow: 1,
    width: `calc(100% - ${drawerWidth}px)`,
    backgroundColor: '#F8F8F8',
    minHeight: '100vh',
    padding: '116px 10px 40px 10px',
    [theme.breakpoints.up('md')]: {
      padding: '40px 50px 40px 50px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '40px 100px 40px 100px',
    },
  },
  content: {
    maxWidth: 1280,
    margin: '0 auto',
  },
}));

export default withStyles;
