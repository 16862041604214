import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import OktaSignIn from '@okta/okta-signin-widget';
import './index.scss';
import '@okta/okta-signin-widget/dist/css/okta-sign-in.min.css';

const OktaSignInWidget = ({ config, onSuccess, onError }) => {
  const widgetRef = useRef();
  useEffect(() => {
    if (!widgetRef.current) return false;

    const widget = new OktaSignIn(config);

    widget
      .showSignInToGetTokens({
        el: widgetRef.current,
        scopes: ['openid', 'profile', 'email'],
        // scopes: ['openid', 'profile', 'email', 'userinfo'],
      })
      .then(onSuccess)
      .catch(onError);

    return () => widget.remove();
  }, [config, onSuccess, onError]);

  return <div ref={widgetRef} />;
};

OktaSignInWidget.propTypes = {
  config: PropTypes.shape().isRequired,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};
export default OktaSignInWidget;
