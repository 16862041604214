import _get from 'lodash/get';
import { renderCellExpand } from 'components/DataGrid/CellExpand';

const formatAnniversaryDate = (date) => {
  if (date.length === 3) {
    return `0${date.substring(0, 1)}/${date.substring(1)}`;
  } else return `${date.substring(0, 2)}/${date.substring(2)}`;
};

const getColumns = () => [
  // {
  //   field: 'numeroImmeuble',
  //   headerName: 'N° Immeuble',
  //   flex: 1,
  // },
  {
    field: 'buildingAddress',
    headerName: 'Adresse',
    flex: 1.5,
    renderCell: (params) =>
      renderCellExpand(params, `${_get(params.row, 'policyKey') || ''}`),
  },
  // {
  //   field: 'buildingCity',
  //   headerName: 'Ville',
  //   flex:1,
  //   valueGetter: (params) => `${_get(params.row, 'ville') || ''}`,
  // },
  // {
  //   field: 'nbSinistres',
  //   headerName: 'Nb Sinistres',
  //   flex:1,
  //   valueGetter: (params) => `${_get(params.row, 'nbSinistres') || ''}`,
  // },
  { field: 'policyNumber', headerName: 'Contrat', flex: 1 },
  { field: 'annualTtc', headerName: 'TTC Annuel', flex: 1 },
  {
    field: 'contractAnniversaryDate',
    headerName: 'Echéance',
    flex: 1.5,
    renderCell: (params) =>
      _get(params.row, 'contractAnniversaryDate', null)
        ? formatAnniversaryDate(params.row.contractAnniversaryDate.toString())
        : '',
  },
];

export default getColumns;
