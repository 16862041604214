import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FormHelperText from '@material-ui/core/FormHelperText';
import { TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './style';

function AutocompleteField(props) {
  const {
    options,
    getOptionLabel,
    onChange,
    value,
    defaultValue,
    label,
    helperText,
    ...otherProps
  } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const handleChange = (event, option) => {
    onChange({
      target: {
        id: otherProps.id,
        value: option.label,
      },
    });
  };

  return (
    <>
      <Autocomplete
        disableClearable
        getOptionLabel={getOptionLabel}
        options={options}
        onChange={handleChange}
        loadingText={t('common.loadingText')}
        defaultValue={defaultValue}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputProps={{
              ...params.InputProps,
            }}
            margin="normal"
            variant="filled"
            fullWidth
            value={value}
            classes={{ root: classes.textField }}
          />
        )}
        {...otherProps}
      />
      <FormHelperText variant="outlined">{helperText}</FormHelperText>
    </>
  );
}

AutocompleteField.propTypes = {
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  options: PropTypes.array.isRequired,
  getOptionLabel: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  helperText: PropTypes.bool,
};

AutocompleteField.defaultProps = {
  value: null,
  defaultValue: null,
};

export default AutocompleteField;
