import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  rootCard: {
    backgroundColor: theme.palette.minBlue.main,
  },
  info: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  contract: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
    },
  },
  card: {
    boxShadow: '0px 4px 80px rgba(0, 43, 104, 0.1)',
    borderRadius: 10,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 46,
    '&.inProgress': {
      background: theme.palette.orange.main,
    },
    '&.close': {
      background: theme.palette.lightBlue.main,
    },
  },
  cardFooter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(1),
  },
  cardButton: {
    marginTop: theme.spacing(1),
  },
  statusCard: {
    width: 236,
    [theme.breakpoints.down('sx')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    '& .inProgress': {
      cursor: 'pointer',
    },
  },
  statusText: {
    color: theme.palette.white.main,
    textDecoration: 'none',
    boxShadow: 'none',
  },
}));
