import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Typography,
  Card,
  CardContent,
  CardHeader,
} from '@material-ui/core';
import { CheckCircle, Panorama, AddToPhotos } from '@material-ui/icons';
import { withStyles, useTheme } from '@material-ui/core/styles';
import './FileInput.scss';

const FileInput = ({
  label,
  dataKey,
  onChange,
  value,
  fileName = '',
  multiple,
}) => {
  const theme = useTheme();

  const StyledCard = withStyles({
    root: {
      textAlign: 'center',
      border: '1px dashed #B3BFD2',
    },
  })(Card);

  const StyledCardContent = withStyles({
    root: {
      padding: '0 0 30px 0 !important',
    },
  })(CardContent);

  return (
    <Grid item xs={6} md={4} className="FileInput">
      <input
        type="file"
        hidden
        accept="image/gif, image/jpeg, image/jpg, image/png, application/pdf"
        onChange={onChange}
        id={dataKey}
      />
      <label htmlFor={dataKey}>
        <StyledCard
          variant="outlined"
          onClick={() => {}}
          style={{
            position: 'relative',
            cursor: 'pointer',
          }}
          classes={{
            root: value ? 'valid' : '',
          }}
        >
          <CardHeader
            style={{ paddingBottom: 0 }}
            action={
              <CheckCircle color="primary" style={{ opacity: value ? 1 : 0 }} />
            }
          />
          <StyledCardContent>
            <Grid
              alignItems="center"
              justifyContent="center"
              container
              direction="column"
            >
              <Typography align="center">
                {multiple ? (
                  <AddToPhotos style={{ fontSize: 60 }} />
                ) : (
                  <Panorama style={{ fontSize: 60 }} />
                )}
              </Typography>
              <Typography align="center" variant="body1">
                {label}
              </Typography>
              <Typography
                variant="caption"
                align="center"
                style={{
                  position: 'absolute',
                  bottom: 8,
                  left: 0,
                  width: '100%',
                  color: theme.palette.secondary,
                }}
              >
                {fileName.length > 30
                  ? `${fileName.substring(0, 30)}...`
                  : fileName}
              </Typography>
            </Grid>
          </StyledCardContent>
        </StyledCard>
      </label>
    </Grid>
  );
};

FileInput.propTypes = {
  label: PropTypes.string.isRequired,
  dataKey: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  fileName: PropTypes.string,
  multiple: PropTypes.bool,
};

FileInput.defaultProps = {
  dataKey: 'fileInput',
  fileName: '',
  multiple: false,
};

export default FileInput;
