const hasAccess = (neededRights, rights) => {
  if (typeof neededRights === 'string') return rights?.includes(neededRights);
  if (Array.isArray(neededRights))
    return neededRights.reduce((response, right) => {
      if (response === true) return response;
      else return rights?.includes(right);
    }, false);
};

export { hasAccess };
