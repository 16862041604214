const ASSURIMO_CONTACT_EMAIL = 'contact@assurimo.fr';
const ASSURIMO_PHONE_NUMBER = '04 72 77 89 10';
const ASSURIMO_INDIVIDUAL_EMAIL = 'particuliers@assurimo.fr';
const ASSURIMO_LEGAL_MANSIONS_URL = 'https://assurimo.fr/mentions-legales';

export {
  ASSURIMO_CONTACT_EMAIL,
  ASSURIMO_INDIVIDUAL_EMAIL,
  ASSURIMO_LEGAL_MANSIONS_URL,
  ASSURIMO_PHONE_NUMBER,
};
