import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  contactActionButton: {
    position: 'fixed',
    bottom: 10,
    right: 10,
    zIndex: 1200,
  },
  centeredTypography: {
    textAlign: 'center',
  },
}));

export default useStyles;
