import React from 'react';
import PropTypes from 'prop-types';
import { Box, Hidden } from '@material-ui/core';

import Lottie from 'components/Lottie';
import { SINISTRE_NATURE_CODE_AUTRE } from 'constants/common/sinistreNatureCode';

SinistreLottie.propTypes = {
  sinistre: PropTypes.object,
  sinistreData: PropTypes.object,
};

function SinistreLottie({ sinistre, sinistreData }) {
  const { asset } = sinistreData[sinistre.nature.code] || {
    ...sinistreData[SINISTRE_NATURE_CODE_AUTRE],
    label: sinistre.nature.code,
  };

  return (
    <Box ml={2} mr={4} display="flex">
      <Hidden smDown>
        <Lottie animationData={asset} isActive width={116} height={116} />
      </Hidden>
      <Hidden mdUp>
        <Lottie animationData={asset} isActive width={80} height={80} />
      </Hidden>
    </Box>
  );
}

export default SinistreLottie;
