import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  priceCardContainer: {
    width: '100%',
    marginTop: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginTop: 0,
      width: 'auto',
    },
  },
  priceCard: {
    boxShadow: '0px 4px 80px rgba(0, 43, 104, 0.1)',
    borderRadius: 10,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
    },
  },
  priceCardHeader: {
    background: theme.palette.blue.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 46,
  },
  priceCardHeaderClickable: {
    // cursor: 'pointer',
  },
  priceSplitting: {
    color: theme.palette.white.main,
    fontWeight: 'bold',
  },
  priceCardFooter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  popover: {
    maxWidth: 500,
  },
}));

export default useStyles;
