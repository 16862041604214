import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import getPaymentPlans from '../getPaymentPlans';
import Mobile from './Mobile';
import Desktop from './Desktop';
import useStyles from '../style';
import NoticeInformationPNO from "public/files/202401_Notice_d'information_Assurimo.pdf";
import IpidPNO from 'public/files/202401_Fiche_IPID_Assurimo.pdf';

import { ASSURIMO_LEGAL_MANSIONS_URL } from 'constants';
import { PnoContext } from 'contexts/pno';
import { getMessageError } from 'constants/common/errorCode';

const MyPricing = (props) => {
  const { state } = useContext(PnoContext);
  let { data } = state;
  const { setUserData, onConfirm } = props;
  const [paymentPlan, setPaymentPlan] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const classes = useStyles();

  const fetchPaymentPlans = async () => {
    try {
      const paymentPlans = await getPaymentPlans(data);
      const newPaymentPlan = paymentPlans.paymentPlan[0];
      setUserData('paymentPlan', newPaymentPlan);
      setPaymentPlan(newPaymentPlan);
    } catch (error) {
      console.error('erreur', error);
      const messageError = getMessageError(error);
      enqueueSnackbar(t(messageError), { variant: 'error' });
    }
  };

  const renderLegalMansion = () => {
    return (
      <Typography className={classes.legalMansion}>
        {t('steps.resultStep.clickingSubscribe')}{' '}
        <a
          className={classes.legalMansionLink}
          href={NoticeInformationPNO}
          download="202401_Notice_d'information_Assurimo.pdf"
        >
          {t('common.generalDispositions')}
        </a>
        , {t('steps.resultStep.ofThe')}{' '}
        <a
          className={classes.legalMansionLink}
          href={IpidPNO}
          download="202401_Fiche_IPID_Assurimo.pdf"
        >
          {t('common.ipidPage')}
        </a>{' '}
        {t('steps.resultStep.andThe')}{' '}
        {
          // eslint-disable-next-line react/jsx-no-target-blank
          <a
            className={classes.legalMansionLink}
            href={ASSURIMO_LEGAL_MANSIONS_URL}
            target="_blank"
          >
            {t('common.legalNotice')}
          </a>
        }
      </Typography>
    );
  };

  useEffect(() => {
    fetchPaymentPlans();
  }, []);

  if (mobile)
    return (
      <Mobile
        data={data}
        paymentPlan={paymentPlan}
        renderLegalMansion={renderLegalMansion}
        onConfirm={onConfirm}
      />
    );
  else
    return (
      <Desktop
        data={data}
        paymentPlan={paymentPlan}
        renderLegalMansion={renderLegalMansion}
        onConfirm={onConfirm}
      />
    );
};

MyPricing.propTypes = {
  data: PropTypes.shape().isRequired,
  setUserData: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default MyPricing;
