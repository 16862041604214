import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  questionStep: {
    position: 'relative',
  },
  wrapper: {
    margin: 0,
  },
  container: {
    position: 'relative',
    width: '100%',
    maxWidth: 230,
  },
  hiddenOverflow: {
    overflow: 'hidden',
  },
  ribbonText: {
    [theme.breakpoints.down('sm')]: {
      color: theme.palette.primary.main,
    },

    [theme.breakpoints.up('sm')]: {
      color: theme.palette.white.main,
    },
  },
  ribbon: {
    position: 'absolute',
    zIndex: 3,

    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: 10,
      paddingBottom: 10,
      borderBottom: `1px solid ${theme.palette.secondary.main}`,
    },
    [theme.breakpoints.up('sm')]: {
      backgroundColor: theme.palette.primary.main,
      color: 'white',
      width: 350,
      textAlign: 'center',
      textTransform: 'uppercase',
      borderBottom: 'none',
      padding: 5,
      '&::before': {
        position: 'absolute',
        zIndex: -1,
        content: '',
        display: 'block',
        border: '5px solid #2980b9',
      },
      '&::after': {
        position: 'absolute',
        zIndex: -1,
        content: '',
        display: 'block',
        border: '5px solid #2980b9',
      },
      transform: 'rotate(-45deg)',
      top: 60,
      marginLeft: -100,
    },
  },
  selected: {
    borderColor: `${theme.palette.primary.main} !important`,
    color: theme.palette.primary.main,
  },
  choice: {
    opacity: 0,
    visibility: 'hidden',
    transition: 'all 0.6s ease-out',
  },
  fade: {
    opacity: 1,
    visibility: 'visible',
  },
  infoIcon: {
    color: theme.palette.prussiaBlue.main,
    fontSize: 18,
    width: 'auto',
  },
  infoButton: {
    padding: 0,
    position: 'absolute',
    bottom: 10,
    right: 10,
  },
  cardContent: {
    position: 'relative',
    overflow: 'visible',
    zIndex: 0,
  },
  card: {
    overflow: 'visible',
    cursor: 'pointer',
    maxWidth: 230,
    position: 'relative',
  },
  cardHeader: {
    position: 'absolute',
    right: 0,
    zIndex: 1,
  },
  cardTitle: {
    borderTop: `1px solid ${theme.palette.blue.main}`,
    justifyContent: 'center',
    minHeight: 60,
  },
  cardContentNoAsset: {
    height: '130px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardContentAsset: {
    padding: '0 !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lottieContainer: {
    maxHeight: 200,
    '@media screen and (min-width: 1024px)': {
      minHeight: 200,
    },
  },
  alarmAntvol: {
    // safari fix
    whiteSpace: 'nowrap',
  },
  captionWrapper: {
    textAlign: 'center',
  },
  caption: {
    fontSize: 18,
    paddingTop: 10,
    paddingBottom: 10,
    textAlign: 'center',
  },
  emptyHelperText: {
    bottom: 52,
  },

  // questionStep: {
  //   position: 'relative',
  // },
  // selected: {
  //   borderColor: theme.palette.primary.main,
  //   color: theme.palette.primary.main,
  // },
  // choice: {
  //   opacity: 0,
  //   visibility: 'hidden',
  //   transition: 'all 0.6s ease-out',
  // },
  // fade: {
  //   opacity: 1,
  //   visibility: 'visible',
  // },
}));

export default useStyles;
