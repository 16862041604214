import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Field from 'components/Field';

const EmailPage = (props) => {
  const { userEmail, onChange, onValidate, validationErrors } = props;
  const { t } = useTranslation();

  const [email, setEmail] = useState(userEmail);

  const handleEmailChange = (e) => {
    const newEmail = e.target.value.toLowerCase();

    setEmail(newEmail);
    onChange(newEmail);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onValidate(email);
    }
  };

  return (
    <>
      <Box
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body1">
          {t('auth.accountUpdate.changeEmail')}
        </Typography>
      </Box>
      <Box
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Field
          id="email"
          onChange={handleEmailChange}
          label={t('auth.login.email')}
          type="text"
          variant="outlined"
          fullWidth
          autoFocus
          value={email || ''}
          inputProps={{
            onKeyPress: (e) => handleKeyDown(e),
          }}
          error={!!validationErrors.email}
          helperText={validationErrors.email}
        />
      </Box>
    </>
  );
};

EmailPage.propTypes = {
  userEmail: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  validationErrors: PropTypes.shape(),
};

export default EmailPage;
