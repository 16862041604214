import React from 'react';
import moment from 'moment';
import Switch from '@material-ui/core/Switch';

import NextQuittance from './NextQuittance';
import _get from 'lodash/get';
import PreviewPremium from './PreviewPremium';
import { renderCellExpand } from 'components/DataGrid/CellExpand';

const formatAnniversaryDate = (date) => {
  if (date.length === 3) {
    return `0${date.substring(0, 1)}/${date.substring(1)}`;
  } else return `${date.substring(0, 2)}/${date.substring(2)}`;
};

const getPaymentMethodValue = (paymentMethod) => {
  if (paymentMethod.toUpperCase().startsWith('VIR')) return true;
  else if (paymentMethod.toUpperCase().startsWith('PRE')) return false;
  else return null;
};

const getTotal = (premiums) => {
  if (premiums)
    return premiums.reduce((total, premium) => {
      const sens = premium.sens === 'C' ? -1 : 1;
      return (total * 100 + sens * premium.montant * 100) / 100;
    }, 0);
  else return null;
};

const getColumns = (onChangePaymentMode) => [
  {
    field: 'numeroImmeuble',
    headerName: 'N° Immeuble',
    flex: 0.5,
  },
  {
    field: 'buildingAddress',
    headerName: 'Adresse Immeuble',
    flex: 1.5,
    renderCell: (params) =>
      renderCellExpand(params, `${_get(params.row, 'policyKey') || ''}`),
  },
  {
    field: 'nextQuittance',
    headerName: 'Quittance',
    filterable: false,
    flex: 1,
    sortComparator: (v1, v2, param1, param2) => {
      return (
        getTotal(param1.api.getRow(param1.id).premiums) -
        getTotal(param2.api.getRow(param2.id).premiums)
      );
    },
    renderCell: (params) => <NextQuittance row={params.row} />,
  },
  { field: 'policyNumber', headerName: 'Numéro de police', flex: 1 },
  // {
  //   field: 'policyType',
  //   headerName: 'Type de police',
  //   minWidth: 220,
  //   valueGetter: (params) => `${_get(params.row, 'typePolicy.code') || ''}`,
  // },
  // {
  //   field: 'fonciaOffice',
  //   headerName: 'Agence Foncia',
  //   minWidth: 170,
  //   valueGetter: (params) => `${_get(params.row, 'agence') || ''}`,
  //   hide: true,
  // },
  // {
  //   field: 'buildingCity',
  //   headerName: 'Ville',
  //   minWidth: 170,
  //   valueGetter: (params) => `${_get(params.row, 'ville') || ''}`,
  // },
  // { field: 'annualTtc', headerName: 'TTC annuel', minWidth: 170 },
  // {
  //   field: 'splitting',
  //   headerName: 'Frac.',
  //   minWidth: 100,
  //   valueGetter: (params) =>
  //     `${_get(params.row, 'paymentSplitting.code') || ''}`,
  // },
  // {
  //   field: 'startDate',
  //   headerName: "Date d'effet",
  //   minWidth: 170,
  //   valueGetter: (params) =>
  //     _get(params.row, 'startDate', null)
  //       ? moment(params.row.startDate).format('DD/MM/YYYY')
  //       : '',
  // },
  // {
  //   field: 'contractAnniversaryDate',
  //   headerName: 'Echéance',
  //   minWidth: 130,
  //   renderCell: (params) =>
  //     _get(params.row, 'contractAnniversaryDate', null)
  //       ? formatAnniversaryDate(params.row.contractAnniversaryDate.toString())
  //       : '',
  // },
  // {
  //   field: 'paymentMethod',
  //   headerName: 'Prélèvement',
  //   minWidth: 170,
  //   filterable: false,
  //   sortable: false,
  //   renderCell: (params) => {
  //     if (_get(params.row, 'premiums', null) && params.row.premiums.length > 0)
  //       return (
  //         <Switch
  //           checked={params.row.premiums[0].paymentMethod.code === 'PRLV'}
  //           onChange={() => onChangePaymentMode(params.row)}
  //         />
  //       );
  //     else return <></>;
  //   },
  // },
  {
    field: 'preview',
    headerName: 'Visualiser',
    filterable: false,
    sortable: false,
    flex: 0.5,
    renderCell: (params) => {
      if (_get(params.row, 'premiums', null) && params.row.premiums.length > 0)
        return <PreviewPremium row={params.row} />;
    },
  },
];

export default getColumns;
