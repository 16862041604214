import React from 'react';
import PropTypes from 'prop-types';
import { useCountdown } from 'hooks/useCountdown';

CountdownTimer.propTypes = {
  targetDate: PropTypes.number.isRequired,
  onFinishCountDown: PropTypes.func,
};

function CountdownTimer({ targetDate, onFinishCountDown }) {
  const [days, hours, minutes, seconds] = useCountdown(targetDate);

  if (seconds < 0) {
    onFinishCountDown && onFinishCountDown();
  }

  return seconds;
}

export default CountdownTimer;
