import cbCard from 'assets/images/card-cb.jpeg';
import mastercard from 'assets/images/mastercard.png';
import visa from 'assets/images/visa.png';

const CARD_BRAND = {
  visa: { image: visa, label: 'dashboard.card.info.type_visa' },
  mastercard: {
    image: mastercard,
    label: 'dashboard.card.info.type_mastercard',
  },
  other: { image: cbCard, label: 'dashboard.card.info.type_cb' },
};

const getLogoCard = (cardBrand) => {
  if (CARD_BRAND[cardBrand]) return CARD_BRAND[cardBrand].image;
  return CARD_BRAND.other.image;
};

const getTypeCard = (cardBrand) => {
  if (CARD_BRAND[cardBrand]) return CARD_BRAND[cardBrand].label;
  return CARD_BRAND.other.label;
};

export { getLogoCard, getTypeCard };
