import { getHeaderFetch, responseWithJson } from './httpUtil';

const BASE_URL = '/v3/contact';

const contactApi = {
  async envoyerAuSupport(auth, objet, numeroPolice, numeroSinistre, message) {
    const url = `${BASE_URL}/`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(auth),
      body: JSON.stringify({
        objet: objet,
        police: numeroPolice,
        sinistre: numeroSinistre,
        message: message,
      }),
    });
    return responseWithJson(response);
  },
};

export default contactApi;
