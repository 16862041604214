import React, { useState, useEffect } from 'react';
import Link from '@material-ui/core/Link';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

const SignUpConfirmForm = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const handleGenerateNewLink = () => {
    history.push('/signup/activationLink');
  };

  return (
    <Link component="button" onClick={handleGenerateNewLink}>
      {t('auth.passwordCreation.receiveNewActivationLink')}
    </Link>
  );
};

export default SignUpConfirmForm;
