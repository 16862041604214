import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import useStyles from './style';

const Confirmation = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.topWrapper}>
      <Box
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        flex={1}
      >
        <Typography variant="body1" align="center" className={classes.title}>
          {t('dashboard.police.paymentPeriodicity.resultSuccess')}
        </Typography>
      </Box>

      <Box m={3}>
        <Typography variant="body1" align="center">
          {t('dashboard.police.paymentPeriodicity.contactUs')}
        </Typography>
        &nbsp;
        <Typography variant="body1" align="center" className={classes.title}>
          {t('common.assurimoPhoneNumber')}
        </Typography>
      </Box>
    </div>
  );
};

export default Confirmation;
