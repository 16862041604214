import React from 'react';
import { Grid, Box } from '@material-ui/core';
import PropTypes from 'prop-types';

import ContractDetails from './ContractDetails';
import MyCriteria from './MyCriteria';
import MySelection from './MySelection';
import CardSelection from './CardSelection/index';
import useStyles from '../style';
import { PAGE_SECTION_SHOW } from '../helper';

const PoliceDetails = ({ sectionRefs, police, polichab }) => {
  const classes = useStyles();

  if (!police || !polichab) return null;

  return (
    <Grid container>
      <Box className={classes.container} width="100%">
        <div ref={sectionRefs.contractDetail.ref}>
          <Grid container>
            {PAGE_SECTION_SHOW[police.contractType.code].contractDetail && (
              <ContractDetails policy={police} polichab={polichab} />
            )}
            {PAGE_SECTION_SHOW[police.contractType.code].myCriteria && (
              <MyCriteria polichab={polichab} police={police} />
            )}
            {PAGE_SECTION_SHOW[police.contractType.code].selection && (
              <MySelection policy={police} polichab={polichab} />
            )}
            {PAGE_SECTION_SHOW[police.contractType.code].cards && (
              <div style={{ width: '100%' }} ref={sectionRefs.cards.ref}>
                <CardSelection policy={police} />
              </div>
            )}
          </Grid>
        </div>
      </Box>
    </Grid>
  );
};

PoliceDetails.propTypes = {
  sectionRefs: PropTypes.object.isRequired,
  police: PropTypes.object,
  polichab: PropTypes.object,
};

export default PoliceDetails;
