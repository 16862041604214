import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

const Negative = () => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        margin: 20,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        height: 200,
      }}
    >
      <div style={{ textAlign: 'center' }}>
        <Typography variant="h6">
          {t('pno.quoteStep.exclusions.contact.forAContract')}
        </Typography>
        <Typography variant="h6">
          {t('pno.quoteStep.exclusions.contact.pleaseContact')}
        </Typography>
      </div>
      <div>
        <Typography variant="h6" color="primary">
          {t('common.assurimoPhoneNumber')}
        </Typography>
      </div>
      <div style={{ display: 'flex', gap: 5 }}>
        <Typography variant="h6">
          {t('pno.quoteStep.exclusions.contact.withCode')}
        </Typography>
        <Typography variant="h6" color="primary">
          {t('pno.quoteStep.exclusions.contact.pnoDigital')}
        </Typography>
      </div>
    </div>
  );
};

export default Negative;
