import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  priceCardContainer: {
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
}));

export default useStyles;
