import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  buttonsWrapper: {
    display: 'flex',
  },
  container: {
    borderRadius: 10,
    backgroundColor: '#fff',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  pdfWrapper: {
    border: '1px solid black',
    marginBottom: theme.spacing(2),
  },

  elementWrapper: {
    border: `1px solid ${theme.palette.grey.main}`,
  },
}));
