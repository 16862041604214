import React from 'react';
import { Switch } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';

import ForbiddenAccess from 'views/Dashboard/Errors/ForbiddenAccess';
import DashboardLayout from 'layouts/Dashboard/index';
import AuthorizedRoute from 'components/AuthorizedRoute';
import Contracts from './Contracts';
import TermPolicies from './TermPolicies';
import DmriContracts from './DmriContracts';
import { NEEDED_RIGHTS as CONTRACT_NEEDED_RIGHTS } from './Contracts/neededRights';
import { NEEDED_RIGHTS as PLANNED_PAYMENTS_NEEDED_RIGHTS } from './TermPolicies/neededRights';

const AdministrativeDirector = () => {
  const { t } = useTranslation();

  const items = [
    {
      id: 'policies',
      link: '/contrats',
      label: t('dashboard.menu.contractsPerMonth'),
      icon: 'fas fa-folder',
    },
    {
      id: 'termPolicies',
      link: '/termpolicies',
      label: t('dashboard.menu.plannedPayments'),
      icon: 'fas fa-folder',
    },
    {
      id: 'dmriContracts',
      link: '/dmri-contracts',
      label: t('dashboard.menu.DMRIContracts'),
      icon: 'fas fa-folder',
    },
  ];

  return (
    <Switch>
      <SecureRoute path="/forbiddenAccess" component={ForbiddenAccess} />
      <DashboardLayout items={items}>
        <AuthorizedRoute
          exact
          path="/termpolicies"
          component={TermPolicies}
          neededRights={CONTRACT_NEEDED_RIGHTS}
        />
        <AuthorizedRoute
          exact
          path="/contrats"
          component={Contracts}
          neededRights={PLANNED_PAYMENTS_NEEDED_RIGHTS}
        />
        <AuthorizedRoute
          exact
          path="/dmri-contracts"
          component={DmriContracts}
          neededRights={PLANNED_PAYMENTS_NEEDED_RIGHTS}
        />
      </DashboardLayout>
    </Switch>
  );
};

export default AdministrativeDirector;
