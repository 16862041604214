import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Router from 'components/Router/index';
import { UserProvider } from 'contexts/user';
import GlobalContext from 'contexts/global';

const App = () => {
  const [gtmLoaded, setGtmLoaded] = useState(false);
  const { gaMrhSiteKey, setGaMrhSiteKey } = useContext(GlobalContext);
  const { gaPnoSiteKey, setGaPnoSiteKey } = useContext(GlobalContext);
  const { gaEcSiteKey, setGaEcSiteKey } = useContext(GlobalContext);

  const { pathname } = useLocation();

  const gtagEndPoint = (siteKey) => {
    return `https://www.googletagmanager.com/gtag/js?id=${siteKey}`;
  };

  const isGtmScriptLoadable = async (siteKey) => {
    try {
      await window.fetch(gtagEndPoint(siteKey), { method: 'HEAD' });
      return true;
    } catch (err) {
      return false;
    }
  };

  const scriptGtmLoadHandler = () => setGtmLoaded(true);

  const injectGTMScript = (
    useGTag,
    gaMrhSiteKey,
    gaPnoSiteKey,
    gaEcSiteKey
  ) => {
    const gtagScript = document.createElement('script');
    gtagScript.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag() { dataLayer.push(arguments); } 
    gtag('js', new Date()); 
    gtag('config', '${gaMrhSiteKey}', { 'send_page_view': false });
    gtag('config', '${gaPnoSiteKey}', { 'send_page_view': false });
    gtag('config', '${gaEcSiteKey}', { 'send_page_view': false });`;

    document.head.appendChild(gtagScript);

    if (useGTag) {
      const gtmScript = document.createElement('script');
      gtmScript.async = true;
      gtmScript.src = gtagEndPoint(gaMrhSiteKey);
      document.head.appendChild(gtmScript);
      gtmScript.addEventListener('load', scriptGtmLoadHandler);
      return gtmScript;
    } else {
      setGtmLoaded(true);
      return null;
    }
  };

  const fetchGoogleAnalyticsSiteKey = () => {
    return fetch('/analytics-key').then((res) => {
      if (res.ok) return res.json();
      else throw new Error(res.status + '-' + res.statusText);
    });
  };

  const injectGtmScript = async () => {
    if (gaMrhSiteKey && gaPnoSiteKey) {
      const useGtag = await isGtmScriptLoadable(gaMrhSiteKey);
      const gtmScript = injectGTMScript(
        useGtag,
        gaMrhSiteKey,
        gaPnoSiteKey,
        gaEcSiteKey
      );
      return () => {
        if (gtmScript) {
          gtmScript.removeEventListener('load', scriptGtmLoadHandler);
        }
      };
    }
  };

  const getGaSiteKey = async () => {
    const { mrhSiteKey, pnoSiteKey, ecSiteKey } =
      await fetchGoogleAnalyticsSiteKey();

    setGaMrhSiteKey(mrhSiteKey);
    setGaPnoSiteKey(pnoSiteKey);
    setGaEcSiteKey(ecSiteKey);
  };

  const fetchImageManager = async () => {
    const res = await fetch('/image-manager');
    if (res.ok) {
      const { url } = await res.json();
      localStorage.setItem('IMAGE_MANAGER_URL_GET', url);
    } else {
      throw new Error(res.status + '-' + res.statusText);
    }
  };

  useEffect(() => {
    getGaSiteKey();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    injectGtmScript();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gaMrhSiteKey, gaPnoSiteKey, gaEcSiteKey]);

  useEffect(() => {
    fetchImageManager();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!gtmLoaded) return null;
  return (
    <UserProvider>
      <Router />
    </UserProvider>
  );
};

export default App;
