import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  infoIcon: {
    color: theme.palette.primary.main,
    fontSize: 18,
    width: 'auto',
  },
  infoButton: {
    padding: 0,
  },
}));

export default useStyles;
