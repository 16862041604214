import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  content: {
    padding: '0 !important',
  },
  contentWrapper: {
    padding: '0 20px 20px 20px',
  },
  closeButton: {
    marginTop: 5,
    marginRight: 5,
  },
}));

export default useStyles;
