import React from 'react';

import { useTranslation } from 'react-i18next';

import Popin from 'components/Popin/index';
import PropTypes from 'prop-types';

const SinistreExistantPopin = ({
  numeroPolice,
  showPopinErreurCreation,
  onClose,
}) => {
  const { t } = useTranslation();

  return (
    <Popin
      title={t('dashboard.sinistre.popinSinistreDejaCree.titre')}
      content={
        <div style={{ padding: '30px 20px' }}>
          {t('dashboard.sinistre.popinSinistreDejaCree.corps')}
        </div>
      }
      open={showPopinErreurCreation}
      validateButtonLabel={t('dashboard.sinistre.popinSinistreDejaCree.bouton')}
      onValidate={() =>
        window.location.replace(`/polices/${numeroPolice}#sinisters`)
      }
      showCloseButton={true}
      onClose={onClose}
      maxWidth={'sm'}
    />
  );
};

SinistreExistantPopin.propTypes = {
  numeroPolice: PropTypes.string.isRequired,
  showPopinErreurCreation: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export { SinistreExistantPopin };
