import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    padding: `0 ${theme.spacing(3)}px`,
    paddingTop: '90px',
  },
  header: {
    alignItems: 'center',
    backgroundColor: '#fff',
    display: 'flex',
    height: '90px',
    justifyContent: 'space-between',
    left: '0',
    position: 'fixed',
    top: '0',
    width: '100%',
    zIndex: '10',

    '& .logo': {
      marginLeft: theme.spacing(3),
    },
    '& .navigation': {
      marginRight: theme.spacing(3),

      '& .btn': {
        borderRadius: '20px',
        fontWeight: 'bold',
        fontSize: theme.spacing(1.6),
      },
      '& .btn-register': {
        backgroundColor: '#ffd396',
        color: '#002b68',
        marginRight: theme.spacing(1.5),
      },
      '& .btn-console': {
        backgroundColor: '#0193b8',
        color: '#fff',
      },
    },
  },
  root: {
    backgroundColor: '#e1eeff',
    minHeight: 'calc(100vh - 90px)',
    display: 'flex',
    padding: theme.spacing(3),

    '& .right': {
      backgroundColor: '#f6faff',
      borderRadius: '5px',
      height: '100%',

      '& .img': {
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
    },
    '& .left': {
      display: 'flex',
      justifyContent: 'center',

      [theme.breakpoints.up('lg')]: {
        padding: `${theme.spacing(7)}px 0`,
        marginLeft: '150px',
        marginRight: '150px',
      },
      [theme.breakpoints.down('md')]: {
        height: '100%',
        alignItems: 'center',
        marginLeft: '50px',
        marginRight: '50px',
      },

      '& .typography': {
        color: '#002b68',
        fontSize: theme.spacing(4.5),
        fontWeight: 'bold',
      },
      '& .btn': {
        borderRadius: '20px',
        fontWeight: 'bold',
        backgroundColor: '#ffd396',
        color: '#002b68',
        marginTop: theme.spacing(3),
        fontSize: theme.spacing(1.8),
      },
      '& .typography-body': {
        color: '#000000',
        marginTop: theme.spacing(5),
      },
      '& .typography-end': {
        marginTop: theme.spacing(3),
        display: 'flex',
        alignItems: 'center',
        cursor: 'pointer',
      },
    },
  },
}));

export default useStyles;
