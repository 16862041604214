import { getHeaderCustomFetch, responseWithVoid } from './httpUtil';

const BASE_URL = '/v2/claims';

const claimApi = {
  async uploadFileToGed(auth, claimsUuid, params, fileType) {
    const url = `${BASE_URL}/${claimsUuid}/ged/upload/${fileType}`;
    const headers = {};

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderCustomFetch(auth, headers),
      body: params,
    });
    return responseWithVoid(response);
  },
};

export default claimApi;
