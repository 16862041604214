import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@material-ui/core';
import noCard from 'assets/lotties/no-card.json';
import Lottie from 'components/Lottie';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CardItem from './CardItem';

CardList.propTypes = {
  cards: PropTypes.array,
  onUpdateCard: PropTypes.func,
  size: PropTypes.string,
  onPaid: PropTypes.func,
};

function CardList(props) {
  const { cards, onUpdateCard, size, onPaid } = props;
  const { t } = useTranslation();

  const isSelectedCard = cards.some((c) => c.selected);

  const handleSelectCard = (card) => {
    let newListCard = [...cards];
    const cardSelIdx = cards.findIndex(
      (c) => c.bankcardUuid === card.bankcardUuid
    );
    if (cardSelIdx >= 0) {
      newListCard = newListCard.map((c) => ({ ...c, selected: false }));
      newListCard[cardSelIdx] = { ...newListCard[cardSelIdx], selected: true };
    }
    onUpdateCard(newListCard);
  };

  const handlePaid = () => {
    onPaid && onPaid();
  };

  return (
    <>
      <Box width="100%">
        {cards.length ? (
          <>
            <TableContainer component={Paper}>
              <Table aria-label="simple table">
                <TableBody>
                  {cards.map((card, index) => (
                    <CardItem
                      key={index}
                      card={card}
                      onSelect={handleSelectCard}
                      size={size}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {isSelectedCard && (
              <Box display="flex" justifyContent="center" marginTop={2}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={handlePaid}
                >
                  {t('dashboard.card.list.btn_paid')}
                </Button>
              </Box>
            )}
          </>
        ) : (
          <>
            <Lottie animationData={noCard} isActive height={480} />
            <Typography align="center" color="inherit">
              {t('dashboard.card.list.no_card')}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
}

export default CardList;
