import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  details: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  contract: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
      width: '100%',
    },
  },
  priceCard: {
    boxShadow: '0px 4px 80px rgba(0, 43, 104, 0.1)',
    borderRadius: 10,
  },
  priceCardHeader: {
    background: theme.palette.blue.light,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 46,
  },
  priceCardFooter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 46,
  },
  pendingActions: {
    background: theme.palette.blue.dark,
  },
  // endedActions: {
  //   background: 'rgba(0,0,0,0.4)',
  // },
  whiteText: {
    color: theme.palette.white.main,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1),
      width: '100%',
    },
  },
  action: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
  },
  bottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
  },
  alert: {
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    alignItems: 'center',
  },
  infos: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  info: {
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  infoLabel: {
    color: theme.palette.secondary.main,
  },
  infoValue: {
    color: theme.palette.blue.light,
  },
}));

export default useStyles;
