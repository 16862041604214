import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  advantagesWrapper: {
    padding: '42px 146px',
    width: 'calc(100% - 84px)',
  },
  outdoorDetailsButton: {
    marginTop: 60,
  },
  cardsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  thanks: {
    display: 'inline-flex',
  },
  configureOfferButton: {
    // backgroundColor: `${theme.palette.white.main} !important`,
    borderRadius: 52,
    border: `2px solid ${theme.palette.blue.light} !important`,
    color: theme.palette.blue.light,
    whiteSpace: 'nowrap',
    width: 'fit-content',
    marginRight: 10,
  },
  configureOfferButtonMobile: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 18px)',
    backgroundColor: `${theme.palette.primary.main} !important`,
    borderRadius: 52,
    border: `2px solid ${theme.palette.primary.light} !important`,
    color: theme.palette.white.main,
    whiteSpace: 'nowrap',
  },
  swiperWrapper: {
    position: 'relative',
  },
  swiperSlide: {
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: 'calc(100% - 40px)',
    height: 300,
  },
  swiperSlideImage: {
    display: 'block',
    width: '100%',
  },
  swiperContainer: {
    width: '100%',
    paddingTop: 50,
    paddingBottom: 50,
  },
  slide: {
    border: `1px solid ${theme.palette.secondary.main}`,
    borderRadius: 4,
    backgroundColor: theme.palette.white.main,
    padding: 20,
    minWidth: 200,
  },
  slider: {
    '@media (max-width: 550px)': {
      display: 'none',
    },
  },
  swiper: {
    '@media (min-width: 550px)': {
      display: 'none',
    },
  },
  promoContainer: {
    marginTop: theme.spacing(1),
  },
  promoInput: {
    backgroundColor: '#fff!important',
    borderLeft: 'unset',
  },
  promoButton: {
    display: 'block!important',
    textAlign: 'center',
  },
  promoCardContent: {
    paddingBottom: '0 !important',
  },
  container: {
    width: 'auto',
    // maxWidth: '50%',
  },
  refsButtonWrapper: {
    display: 'flex',
    marginTop: 40,
  },
  goToTop: {
    position: 'absolute',
    zIndex: 1,
    bottom: 100,
    right: 30,
    transition: 'transform 0.4s ease-out',
    borderRadius: 10,
  },
}));

export default useStyles;
