import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import { useSnackbar } from 'notistack';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

import Popin from 'components/Popin';
import Icon from 'components/Icon';
import { UserContext, USER_ACTION_TYPE } from 'contexts/user';
import authApi from 'api/authApi';
import { passwordStructure } from 'utils/form-validation';
import useStyles from './style';

const PasswordPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { oktaAuth } = useOktaAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [, dispatch] = useContext(UserContext);
  const history = useHistory();

  const [userInputs, setUserInputs] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const [complexityPasswordError, setComplexityPasswordError] = useState(null);
  const [samePasswordError, setSamePasswordError] = useState(null);
  const [currentPassordError, setCurrentPasswordError] = useState(null);
  const [containsUpperCase, setContainsUpperCase] = useState(false);
  const [containsLowerCase, setContainsLowerCase] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [isUpToMinimumLength, setIsUpToMinimumLength] = useState(false);
  const [containsSpecialChars, setContainsSpecialChars] = useState(false);
  const [showPopin, setShowPopin] = useState(false);
  const [countDown, setCountDown] = useState(10);

  const handleDisconnect = async () => {
    dispatch({ type:  USER_ACTION_TYPE.REMOVE_USER });
    await oktaAuth.tokenManager.clear();
    history.push('/login');
  };

  const handleClosePopin = () => {
    setShowPopin(false);
  };

  const renderContent = () => {
    return (
      <Paper classes={{ root: classes.popinContentWrapper }}>
        <Typography>{t('dashboard.settings.password.success')}</Typography>
      </Paper>
    );
  };

  const handleSubmit = async () => {
    if (isFormValid()) {
      try {
        const storageInfos = JSON.parse(
          window.localStorage.getItem('okta-token-storage')
        );
        const clientId = _get(storageInfos, 'idToken.claims.email', null);
        await authApi.changePassword({
          userId: clientId,
          oldPassword: userInputs.oldPassword,
          newPassword: userInputs.newPassword,
        });
        setShowPopin(true);
      } catch (error) {
        enqueueSnackbar(t('error.unknown'), { variant: 'error' });
        setSubmitError(error);
      }
    }
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setUserInputs({ ...userInputs, [id]: value });
  };

  const getPasswordRules = () => {
    return passwordStructure(userInputs.newPassword);
  };

  const getVisibilityIcon = () => {
    return showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />;
  };

  const isFormValid = () => {
    const passwordRules = getPasswordRules();
    const goodPasswordCondition =
      passwordRules.containsUpperCase() &&
      passwordRules.containsLowerCase() &&
      passwordRules.containsSpecialChars() &&
      passwordRules.containsNumbers() &&
      passwordRules.isUpToMinimumLength();

    if (!goodPasswordCondition) setComplexityPasswordError(true);
    else setComplexityPasswordError(false);

    const goodPasswordMatchCondition =
      userInputs.confirmPassword === userInputs.newPassword;
    if (!goodPasswordMatchCondition) setSamePasswordError(true);
    else setSamePasswordError(false);

    if (!userInputs.oldPassword) setCurrentPasswordError(true);

    return (
      goodPasswordCondition &&
      goodPasswordMatchCondition &&
      !currentPassordError
    );
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (showPopin) {
      const timer =
        countDown > 0 && setInterval(() => setCountDown(countDown - 1), 1000);
      if (countDown < 1) handleDisconnect();
      return () => {
        clearInterval(timer);
      };
    }
  }, [showPopin, countDown]);

  const getPasswordHelperText = () => {
    return (
      <Box className={classes.container}>
        <Typography>
          {t('auth.passwordCreation.passwordPolicyRequirementsTitle')}
        </Typography>
        <ul className={classes.ul}>
          <li className={isUpToMinimumLength ? classes.liOk : classes.li}>
            <Typography>
              {t(
                'auth.passwordCreation.passwordPolicyRequirements.minimumCharacters'
              )}
            </Typography>
          </li>
          <li
            className={
              containsLowerCase && containsUpperCase && containsNumber
                ? classes.liOk
                : classes.li
            }
          >
            <Typography>
              {t(
                'auth.passwordCreation.passwordPolicyRequirements.typeOfCharacters'
              )}
            </Typography>
          </li>
          <li className={containsSpecialChars ? classes.liOk : classes.li}>
            <Typography>
              {t(
                'auth.passwordCreation.passwordPolicyRequirements.specialCharacter'
              )}
            </Typography>
            <Typography variant="caption" classes={{ root: classes.caption }}>
              {t('auth.passwordCreation.passwordPolicyRequirements.example')}
            </Typography>
          </li>
          <div
            style={{
              display: 'flex',
              marginLeft: -17,
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <Icon className="fas fa-info-circle" style={{ fontSize: 15 }} />
            <Typography style={{ lineHeight: 1, marginLeft: 5 }}>
              {t(
                'auth.passwordCreation.passwordPolicyRequirements.passwordMustNotContain'
              )}
            </Typography>
          </div>
        </ul>
      </Box>
    );
  };

  useEffect(() => {
    if (userInputs.newPassword) {
      setContainsUpperCase(getPasswordRules().containsUpperCase());
      setContainsLowerCase(getPasswordRules().containsLowerCase());
      setContainsSpecialChars(getPasswordRules().containsSpecialChars());
      setContainsNumber(getPasswordRules().containsNumbers());
      setIsUpToMinimumLength(getPasswordRules().isUpToMinimumLength());
    }
  }, [userInputs]);

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        className={classes.title}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box>
          <Typography variant="h3">
            {t('dashboard.settings.password.title')}
          </Typography>
        </Box>
      </Grid>
      <Grid container item spacing={3}>
        <Grid container item xs={12} lg={7}>
          <Box className={classes.container}>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <TextField
                  id="oldPassword"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  label={t('dashboard.settings.password.currentPassword')}
                  onChange={handleChange}
                  error={currentPassordError}
                  helperText={
                    currentPassordError &&
                    t('dashboard.settings.password.errors.emptyPassword')
                  }
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {getVisibilityIcon()}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="newPassword"
                  type={showPassword ? 'text' : 'password'}
                  label={t('dashboard.settings.password.newPassword')}
                  onChange={handleChange}
                  error={samePasswordError}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {getVisibilityIcon()}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {complexityPasswordError && (
                  <FormHelperText
                    error={complexityPasswordError}
                    component="div"
                  >
                    {getPasswordHelperText()}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item>
                <TextField
                  id="confirmPassword"
                  type={showPassword ? 'text' : 'password'}
                  label={t('dashboard.settings.password.confirmPassword')}
                  onChange={handleChange}
                  helperText={
                    samePasswordError &&
                    t('dashboard.settings.password.errors.samePassword')
                  }
                  error={samePasswordError}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                        >
                          {getVisibilityIcon()}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  fullWidth
                  classes={{ root: classes.button }}
                  onClick={handleSubmit}
                  disabled={
                    !getPasswordRules().containsUpperCase() ||
                    !getPasswordRules().containsLowerCase() ||
                    !getPasswordRules().containsSpecialChars() ||
                    !getPasswordRules().containsNumbers() ||
                    !getPasswordRules().isUpToMinimumLength() ||
                    userInputs.confirmPassword !== userInputs.newPassword
                  }
                >
                  <Typography classes={{ root: classes.buttonText }}>
                    {t('common.save')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        <Grid container item xs={12} lg={5}>
          {getPasswordHelperText()}
        </Grid>
      </Grid>
      <Popin
        open={showPopin}
        onClose={handleClosePopin}
        content={renderContent()}
        validateButtonLabel={`${t('common.validate')}(${countDown})`}
        showCancelButton={false}
        onValidate={handleDisconnect}
        enableTitleArea={false}
      />
    </Grid>
  );
};

export default PasswordPage;
