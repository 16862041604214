import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
  },
  topWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  splittingSelectorBox: {
    '& .EndStep_Content_Value_Franchise': {
      marginTop: '0 !important',
      '& > p': {
        textAlign: 'center',
      },
    },
  },
}));

export default useStyles;
