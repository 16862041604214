import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import RadioGroup from '@material-ui/core/RadioGroup';
import { useTranslation } from 'react-i18next';
import useStyles from './style';

const ReasonPage = (props) => {
  const { options } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [selectedValue, setSelectedValue] = useState();

  const isSelected = (option) => {
    return selectedValue === option.value;
  };

  const handleRadioChange = (option) => {
    setSelectedValue(option.value);

    if (option.goToNextPage) option.goToNextPage();
  };

  return (
    <>
      <Box
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body1" align="center" className={classes.title}>
          {t('dashboard.contact.reasons.title')}
        </Typography>
      </Box>

      <Box
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="flex-start"
      >
        <RadioGroup
          aria-label="reason"
          name="reason"
          className={classes.radioGroup}
        >
          {options.map((option) => {
            return (
              <Box
                p={3}
                key={option.value}
                onClick={() => handleRadioChange(option)}
                className={`${classes.radioContainer} ${
                  isSelected(option) ? classes.radioContainerSelected : ''
                }`}
              >
                <Typography align="center">{option.label}</Typography>
              </Box>
            );
          })}
        </RadioGroup>
      </Box>
    </>
  );
};

ReasonPage.propTypes = {
  options: PropTypes.array.isRequired,
};

export default ReasonPage;
