import {
  HOUSING_TYPE_APPARTEMENT,
  HOUSING_TYPE_MAISON,
  PNO_TYPE_APPARTEMENT,
  PNO_TYPE_LOCAL_COMMERCIAL,
  PNO_TYPE_MONUMENT_HISTORIQUE,
  PNO_TYPE_GARAGE,
  PNO_TYPE_MAISON,
  TYPE_POLICE_H,
  TYPE_POLICE_PNO,
} from '../common/common';
import ApartmentLottie from 'assets/lotties/apartment.json';
import GarageLottie from 'assets/lotties/garage.json';
import CommercialPremiseLottie from 'assets/lotties/localcommercial.json';
import HouseLottie from 'assets/lotties/house.json';

export const POLICY_PROPERTYS = {
  [TYPE_POLICE_H]: {
    [HOUSING_TYPE_MAISON]: { avatar: HouseLottie },
    [HOUSING_TYPE_APPARTEMENT]: { avatar: ApartmentLottie },
  },
  [TYPE_POLICE_PNO]: {
    [PNO_TYPE_MAISON]: { avatar: HouseLottie },
    [PNO_TYPE_APPARTEMENT]: { avatar: ApartmentLottie },
    [PNO_TYPE_MONUMENT_HISTORIQUE]: { avatar: ApartmentLottie },
    [PNO_TYPE_GARAGE]: { avatar: GarageLottie },
    [PNO_TYPE_LOCAL_COMMERCIAL]: { avatar: CommercialPremiseLottie },
  },
};
