import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Button, Typography, Hidden } from '@material-ui/core';
import { ArrowForwardIos as ArrowForwardIosIcon } from '@material-ui/icons';
import LinkIcon from '@material-ui/icons/Link';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Lottie from 'components/Lottie';
import HouseLottie from 'assets/lotties/house.json';
import { useStyles } from './style';

BlocPoliceInfo.propTypes = {
  police: PropTypes.object.isRequired,
  showPoliceDetailsButton: PropTypes.bool.isRequired,
};

function BlocPoliceInfo({ police, showPoliceDetailsButton }) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  const handleClickDetail = () => {
    history.push(`/polices/${police.policyNumber}`);
  };

  if (!police) return null;

  return (
    <Box display="flex" width={1} alignItems="center" flexDirection="column">
      <Typography variant="h5" color="secondary">
        {t('dashboard.sinistre.form.police.label')}
      </Typography>
      <Box mt={2} p={2} width="100%" className={classes.container}>
        <Grid container alignItems="stretch">
          <Grid container item xs={12} md={10} wrap="nowrap">
            <Grid item xs={4} md={2}>
              <Box>
                <div className={classes.visual}>
                  <Lottie animationData={HouseLottie} isActive height={48} />
                  <div className={classes.linkIconContainer}>
                    <LinkIcon className={classes.linkIcon} />
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={8} md={10}>
              <Box align="left">
                <Hidden smDown>
                  <Typography variant="subtitle1">
                    {t(
                      `dashboard.sinistre.form.police.${police?.contractType?.code.toLowerCase()}`
                    )}
                  </Typography>
                </Hidden>
                <Hidden mdUp>
                  <Typography variant="body1">
                    {t(
                      `dashboard.sinistre.form.police.${police?.contractType?.code.toLowerCase()}`
                    )}
                  </Typography>
                </Hidden>
                <Grid container>
                  <Grid item xs={12} md={6} align="left">
                    <Hidden smDown>
                      <Typography variant="caption">
                        {t('dashboard.sinistre.form.police.adress')}
                      </Typography>
                      <Typography variant="body1">
                        {police.policyKey}
                      </Typography>
                    </Hidden>
                    <Hidden mdUp>
                      <Typography variant="caption">
                        {police.policyKey}
                      </Typography>
                    </Hidden>
                  </Grid>
                  <Grid item xs={12} md={6} align="left">
                    <Hidden smDown>
                      <Typography variant="caption">
                        {t('dashboard.sinistre.form.police.policyNumber')}
                      </Typography>
                      <Typography variant="body1">
                        {police.policyNumber}
                      </Typography>
                    </Hidden>
                    <Hidden mdUp>
                      <Typography variant="caption">
                        {police.policyNumber}
                      </Typography>
                    </Hidden>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {showPoliceDetailsButton && (
            <Grid
              item
              container
              alignItems="center"
              justifyContent="flex-end"
              xs={0}
              md={2}
            >
              <Hidden smDown>
                <Button
                  variant="outlined"
                  endIcon={
                    <ArrowForwardIosIcon className={classes.buttonIcon} />
                  }
                  className={classes.button}
                  onClick={handleClickDetail}
                >
                  <span>{t('dashboard.sinistre.form.police.detail')}</span>
                </Button>
              </Hidden>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
}

export default BlocPoliceInfo;
