import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  policyContainer: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
    },
    [theme.breakpoints.down('sm')]: {
      padding: 0,
    },
  },
  attestationContainer: {
    paddingTop: theme.spacing(1),
  },
  alertBox: {
    width: '100%',
  },
  container: {
    borderRadius: 10,
    backgroundColor: '#fff',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  greyBox: {
    backgroundColor: '#F8F8F8',
    borderRadius: 10,
  },
  button: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  menu: {
    top: '53px !important',
  },
  menuItem: {
    display: 'flex',
  },
  menuItemIcon: {
    marginRight: 10,
  },
}));

export default useStyles;
