import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  stepsWrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1 1 auto',
    overflowY: 'auto',
  },
}));

export default useStyles;
