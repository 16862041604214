import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@material-ui/core';

import { SINISTRE_NATURE_CODE_AUTRE } from 'constants/common/sinistreNatureCode';
import { formatDateWithSystem } from 'utils';
import { useStyles } from '../style';

SinistreDetails.propTypes = {
  sinistre: PropTypes.object,
  sinistreData: PropTypes.object,
};

function SinistreDetails({ sinistre, sinistreData }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { claimsNumber, eventDate } = sinistre;

  const { label } = sinistreData[sinistre.nature.code] || {
    ...sinistreData[SINISTRE_NATURE_CODE_AUTRE],
    label: sinistre.nature.code,
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      height={1}
    >
      <Box>
        <Typography variant="h6">{t(label)}</Typography>
      </Box>
      <Box display="flex" justifyContent="flex-start" alignItems="center">
        <Box className={classes.info} mr={3}>
          <Typography variant="caption">N°</Typography>
          <Typography variant="body2">{claimsNumber}</Typography>
        </Box>
        <Box className={classes.info}>
          <Typography variant="caption">
            {t('dashboard.sinistre.list.date_decla')}
          </Typography>
          <Typography variant="body2">
            {formatDateWithSystem(eventDate)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default SinistreDetails;
