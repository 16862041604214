import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';

import ResilImpossible from './ResilImpossible';
import { UserContext } from 'contexts/user';
import policyApi from 'api/policyApi';
import ConfirmationSansEngag from './SansEngagement/Confirmation';
import SuccesSansEngag from './SansEngagement/Succes';

const MotifsResil = Object.freeze({
  SANS_ENGAGEMENT: 'SANS_ENGAGEMENT',
  MOTIF_1: 'MOTIF_1', // CECI EST UN PLACEHOLDER
  MOTIF_2: 'MOTIF_2', // CECI EST UN PLACEHOLDER
});

const TerminationPopin = ({
  delaiEnJoursDeResilApresNotifSouscripteur,
  motifsDeResiliation,
  isOpen,
  onClose,
  police,
  adresse,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [userState] = useContext(UserContext);

  const [succes, setSucces] = useState(false);
  const [onResilLoading, setOnResilLoading] = useState(false);

  let dateEffetResiliation = new Date();
  dateEffetResiliation.setDate(
    dateEffetResiliation.getDate() + delaiEnJoursDeResilApresNotifSouscripteur
  );
  const onValidate = async (motif) => {
    try {
      setOnResilLoading(true);
      await policyApi.resilierPolice(
        userState.auth,
        police.policyNumber,
        motif
      );

      setSucces(true);
    } catch (err) {
      enqueueSnackbar(err.message, { variant: 'error' });
      setSucces(false);
    } finally {
      setOnResilLoading(false);
    }
  };
  let peutResilierAvecMotif = (motifChoisi) =>
    motifsDeResiliation.findLast(
      (motif) => motif?.motifResiliation === motifChoisi
    )?.eligibilite || null;

  if (peutResilierAvecMotif(MotifsResil.SANS_ENGAGEMENT))
    return !succes ? (
      <ConfirmationSansEngag
        dateEffetResiliation={dateEffetResiliation}
        police={police}
        adresse={adresse}
        isOpen={isOpen}
        onClose={onClose}
        onValidate={() => onValidate(MotifsResil.SANS_ENGAGEMENT)}
        resiliationEnCours={onResilLoading}
      />
    ) : (
      <SuccesSansEngag
        dateEffetResiliation={dateEffetResiliation}
        isOpen={isOpen}
        onClose={onClose}
      />
    );

  // TODO: Rajouter ici les autres cas de Resil
  if (peutResilierAvecMotif(MotifsResil.MOTIF_1)) return <></>;

  return <ResilImpossible isOpen={isOpen} onClose={onClose} />;
};

TerminationPopin.propTypes = {
  delaiEnJoursDeResilApresNotifSouscripteur: PropTypes.number.isRequired,
  motifsDeResiliation: PropTypes.array.isRequired,
  police: PropTypes.object.isRequired,
  adresse: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

TerminationPopin.defaultProps = {
  isOpen: false,
};

export default TerminationPopin;
