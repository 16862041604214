import { Box, Grid, Hidden, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Star from '@material-ui/icons/Star';
import StarOutline from '@material-ui/icons/StarOutline';
import ContactIcon from 'assets/images/contact-icon.png';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { TRANSLATE_MONTH } from 'constants/index';
import { getTypeCard } from 'utils/card';

CardInfo.propTypes = {
  card: PropTypes.object.isRequired,
  onSetMainCard: PropTypes.func,
};

function CardInfo(props) {
  const classes = useStyles();
  const { card, onSetMainCard } = props;
  const { t } = useTranslation();

  const translateDate = (month, year) => {
    const monthTrans = t(TRANSLATE_MONTH[+month]);
    return `${monthTrans} ${year}`;
  };

  const handleSetMainCard = () => {
    onSetMainCard && onSetMainCard(card);
  };

  const INFO_CARDS = [
    {
      sizeMd: 3,
      label: t('dashboard.card.info.number_card'),
      content: `**** **** **** ${card.last4}`,
    },
    {
      sizeMd: 2,
      label: t('dashboard.card.info.expire'),
      content: translateDate(card.moisExp, card.anneeExp),
    },
    {
      sizeMd: 3,
      label: t('dashboard.card.info.email'),
      content: card.email,
    },
    {
      sizeMd: 2,
      label: t('dashboard.card.info.type'),
      content: t(getTypeCard(card.brand)),
    },
  ];

  return (
    <Box p={2} width="100%" className={classes.bankContainer}>
      <Grid container alignItems="stretch">
        <Grid container item xs={12} wrap="nowrap">
          <Box display="flex" alignItems="center" justifyContent="center">
            <div className={classes.visual}>
              <img src={ContactIcon} />
            </div>
          </Box>
          <Box align="left" width={1}>
            <Hidden smDown>
              <Typography variant="subtitle1">{card.name}</Typography>
            </Hidden>
            <Hidden mdUp>
              <Typography variant="body1">
                {t('dashboard.card.info.title')}
              </Typography>
            </Hidden>
            <Grid container xs={12} item>
              {INFO_CARDS.map((item, index) => (
                <Grid key={index} item xs={12} md={item.sizeMd} align="left">
                  <Hidden smDown>
                    <Typography variant="caption">{item.label}</Typography>
                    <Typography variant="body1">{item.content}</Typography>
                  </Hidden>
                  <Hidden mdUp>
                    <Typography variant="caption">{item.content}</Typography>
                  </Hidden>
                </Grid>
              ))}
              <Grid item xs={12} md={2} align="left">
                <Box display="flex" justifyContent="flex-end">
                  {card.principal && (
                    <IconButton size="small">
                      <Star fontSize="small" color="primary" />
                    </IconButton>
                  )}
                  {card.valid && !card.principal && (
                    <IconButton size="small" onClick={handleSetMainCard}>
                      <StarOutline fontSize="small" color="secondary" />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const useStyles = makeStyles((theme) => ({
  bankContainer: {
    backgroundColor: '#F2F7FF',
    boxSizing: 'border-box',
  },
  visual: {
    width: 58,
    height: 58,
    background: theme.palette.white.main,
    position: 'relative',
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: 48,
      height: 48,
    },
  },
}));

export default CardInfo;
