import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  guaranteesButton: {
    position: 'absolute',
    bottom: 0,
    left: '50%',
    transform: 'translate(-50%, 18px)',
    backgroundColor: `${theme.palette.white.main} !important`,
    borderRadius: 52,
    border: `2px solid ${theme.palette.blue.light} !important`,
    color: theme.palette.blue.light,
    whiteSpace: 'nowrap',
  },
  legalMansion: {
    fontSize: 13,
    textDecoration: 'none',
  },
  legalMansionLink: {
    color: 'inherit',
    fontWeight: 'bold',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  reminderCard: {
    borderRadius: 10,
    height: '100%',
  },
  cardContent: {
    display: 'flex',
    alignItems: 'center ',
    padding: '10px 20px',
    height: '100%',
  },
  imageWrapper: {
    minWidth: 250,
  },
  lottie: {
    minWidth: 200,
    maxWidth: 200,
  },
  textWrapper: {
    textAlign: 'left',
  },
  pricingContainer: {
    [theme.breakpoints.up('sm')]: {
      alignSelf: 'stretch',
    },
  },
  pricingWrapper: {
    alignSelf: 'stretch',
    height: '100%',
  },
  pricing: {
    height: '100%',
  },
  pricingCardContent: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    marginLeft: 12,
  },
  goToTop: {
    position: 'absolute',
    zIndex: 1,
    bottom: 100,
    right: 30,
    transition: 'transform 0.4s ease-out',
    borderRadius: 10,
  },
}));

export default useStyles;
