import React from 'react';
import PropTypes from 'prop-types';
import PaymentStep from 'views/PnoSouscription/Steps/PaymentStep';

const PaymentForSubscription = ({ onConfirm, data }) => {
  const address = `${data.contractAddress.address1} ${data.contractAddress.address2} ${data.contractAddress.zipCode} ${data.contractAddress.city}`;
  const premiumUuid = data?.policy?.premiumUuid;

  return (
    <PaymentStep
      onConfirm={onConfirm}
      premiumUuid={premiumUuid}
      address={address}
    />
  );
};

PaymentForSubscription.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.object,
};

export default PaymentForSubscription;
