import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import Confirmation from './Confirmation';
import Succes from './Succes';
import RetractImpossible from './RetractImpossible';
import { useSnackbar } from 'notistack';
import policyApi from 'api/policyApi';
import { UserContext } from 'contexts/user';

const RetractationPopin = ({
  delaiEnJoursDeRetractation,
  motifsDeResiliation,
  police,
  adresse,
  isOpen,
  onClose,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [userState] = useContext(UserContext);

  const [succes, setSucces] = useState(false);
  const [onRetractLoading, setOnRetractLoading] = useState(false);

  let peutSeRetracter =
    motifsDeResiliation.findLast(
      (motif) => motif.motifResiliation === 'RETRACTATION'
    )?.eligibilite || null;

  const onValidate = async () => {
    try {
      setOnRetractLoading(true);
      await policyApi.resilierPolice(
        userState.auth,
        police.policyNumber,
        'RETRACTATION'
      );

      setSucces(true);
    } catch (err) {
      enqueueSnackbar(err.message.toString(), { variant: 'error' });
      setSucces(false);
    } finally {
      setOnRetractLoading(false);
    }
  };

  if (!peutSeRetracter)
    return <RetractImpossible onClose={onClose} isOpen={isOpen} />;

  return !succes ? (
    <Confirmation
      police={police}
      isOpen={isOpen}
      onClose={onClose}
      loading={onRetractLoading}
      onValidate={onValidate}
      adresse={adresse}
    />
  ) : (
    <Succes isOpen={isOpen} onClose={onClose} />
  );
};

RetractationPopin.propTypes = {
  delaiEnJoursDeRetractation: PropTypes.number.isRequired,
  motifsDeResiliation: PropTypes.array,
  police: PropTypes.object.isRequired,
  adresse: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RetractationPopin;
