import { getHeaderFetch, responseWithJson } from './httpUtil';

const BASE_URL_V2 = '/v2/quotes';
const BASE_URL_V3 = '/v3/devis';

const devisApi = {
  genererDevisEtNotifierClient: async (parcoursUuid) => {
    const url = `${BASE_URL_V3}/${parcoursUuid}/notifierClient`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
    });
    return responseWithJson(response);
  },

  generateQuote: async (params) => {
    const url = `${BASE_URL_V2}/H`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  generatePnoQuote: async (params) => {
    const url = `${BASE_URL_V2}/PNO`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },
};

export default devisApi;
