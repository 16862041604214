import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, FormHelperText, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useOktaAuth } from '@okta/okta-react';
import { useSnackbar } from 'notistack';

import drsApi from 'api/drsApi';
import AutocompleteField from 'components/Field/AutoCompleteField';
import Field from 'components/Field';
import useStyles from 'views/Dashboard/Customer/ResilHamonPage/styles';

InfoAncienneCompagnie.propTypes = {
  form: PropTypes.object,
  onChange: PropTypes.func,
  auth: PropTypes.object,
  error: PropTypes.object,
  items: PropTypes.array,
};

function InfoAncienneCompagnie({ auth, form, onChange, error }) {
  const { hasError, msgError } = error;

  const classes = useStyles();
  const { authState } = useOktaAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(true);
  const [listSoc, setListSoc] = useState([]);

  const handleChange = (name, event) => {
    if (name === 'inssuranceId') {
      let inssurance = event.target.value
        ? listSoc.find((e) => e.label === event.target.value)
        : null;

      let inssuranceId = inssurance ? inssurance.code : null;
      onChange && onChange({ ...form, [name]: inssuranceId });
    } else {
      onChange && onChange({ ...form, [name]: event.target.value });
    }
  };

  useEffect(() => {
    drsApi
      .getListCompagnies(auth)
      .then((resultat) => setListSoc(resultat))
      .catch(() =>
        enqueueSnackbar(t('dashboard.resilHamon.form.message.error'), {
          variant: 'error',
        })
      )
      .finally(() => setLoading(false));
  }, [authState]);

  const getSelectedCompany = () =>
    form.inssuranceId
      ? listSoc.find((e) => e.code === form.inssuranceId)
      : null;

  return (
    <Box className={`${classes.box} boxForm`}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h5" color="secondary" className="typography">
            {t('dashboard.resilHamon.form.info.title')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AutocompleteField
            name="inssuranceId"
            label={t('dashboard.resilHamon.form.info.sel_inssurance')}
            value={getSelectedCompany()}
            options={listSoc}
            getOptionLabel={(item) => item.label}
            onChange={(e) => handleChange('inssuranceId', e)}
            variant="filled"
            disabled={loading}
          />
        </Grid>
        <Grid item xs={12}>
          <Field
            type="text"
            label={t('dashboard.resilHamon.form.info.input_number_police')}
            variant="filled"
            name="ancienPolicyNumber"
            onChange={(e) => handleChange('ancienPolicyNumber', e)}
            fullWidth
            disabled={loading}
          />
        </Grid>
        {hasError && (
          <Grid item>
            <FormHelperText error={true} variant="outlined">
              {msgError}
            </FormHelperText>
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

export default InfoAncienneCompagnie;
