import React from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';

import DashboardLayout from 'layouts/Dashboard';
import ContractsPage from './Contracts';

const Assistance = () => {
  const { t } = useTranslation();

  const items = [
    {
      id: 'policies',
      link: '/contrats',
      label: t('dashboard.menu.myContracts'),
      icon: 'fas fa-folder',
    },
  ];

  return (
    <DashboardLayout items={items}>
      <Switch>
        <SecureRoute exact path="/contrats" component={ContractsPage} />
        <SecureRoute path="/*" render={() => <Redirect to="/contrats" />} />
      </Switch>
    </DashboardLayout>
  );
};

export default Assistance;
