import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  textField: {
    marginTop: 0,
    marginBottom: 0,
  },
}));

export default useStyles;
