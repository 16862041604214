import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  greyBox: {
    backgroundColor: '#F8F8F8',
    borderRadius: 10,
  },
}));

export default useStyles;
