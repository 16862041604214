import PhoneNumber from 'awesome-phonenumber';

const isEmpty = (str) =>
  str === undefined || str === '' || /^\s*$/.test(str) || str === null;

const isContainsSlash = (str) =>
  str !== undefined && str !== null && str.includes('/');

const isEmail = (str) =>
  // eslint-disable-next-line no-useless-escape
  /^(?=.{1,64}@)[A-Za-z0-9_-]+(\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\.[A-Za-z0-9-]+)*(\.[A-Za-z]{2,})$/.test(
    str.toLowerCase()
  );
const isPhoneNumber = (phoneNumber) => {
  return new PhoneNumber(
    phoneNumber.value,
    phoneNumber.country.countryCode
  ).isValid();
};

const isFrenchPhoneNumber = (phoneNumber) => {
  return phoneNumber.country.dialCode === '33';
};

const isAddressValid = (address) => {
  if (address.address1 && address.city && address.postalCode) return true;

  return false;
};

const passwordStructure = (password) => ({
  containsUpperCase: () => {
    const regex = /[A-Z]/;
    return regex.test(password);
  },
  containsLowerCase: () => {
    const regex = /[a-z]/;
    return regex.test(password);
  },
  containsSpecialChars: () => {
    const regex = /[!#$%&()*+,-.:;<=>?@[\]^_{|}]/;
    return regex.test(password);
  },
  containsNumbers: () => {
    const regex = /\d/;
    return regex.test(password);
  },
  isUpToMinimumLength: () => {
    return password.length >= 8;
  },
});

export {
  isEmpty,
  isContainsSlash,
  isEmail,
  isPhoneNumber,
  isAddressValid,
  passwordStructure,
  isFrenchPhoneNumber,
};
