import collapse from './collapse/collapse.html';
import facadeInfiltration from './facadeInfiltration/facadeInfiltration.html';
import coverageDamage from './coverageDamage/coverageDamage.html';
import waterLoss from './waterLoss/waterLoss.html';
import looseOfRent from './looseOfRent/looseOfRent.html';
import replacementValue33 from './replacementValue33/replacementValue33.html';
import furnitureCoverage from './furnitureCoverage/furnitureCoverage.html';
import contractWithoutCommitment from './contractWithoutCommitment/contractWithoutCommitment.html';

const performantGuaranteesList = (t) => [
  {
    id: 'facadeInfiltration',
    title: t('common.pluses.pno.facadeInfiltration.title'),
    description: { __html: facadeInfiltration },
    categories: [],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales, conventions spéciales et dispositions particulières.',
  },
  {
    id: 'coverageDamage',
    title: t('common.pluses.pno.coverageDamage.title'),
    description: {
      __html: coverageDamage,
    },
    categories: [],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales, conventions spéciales et dispositions particulières.',
  },
  {
    id: 'waterLoss',
    title: t('common.pluses.pno.waterLoss.title'),
    categories: [],
    description: { __html: waterLoss },
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales, conventions spéciales et dispositions particulières.',
  },
  {
    id: 'looseOfRent',
    title: t('common.pluses.pno.looseOfRent.title'),
    description: { __html: looseOfRent },
    categories: [],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales, conventions spéciales et dispositions particulières.',
  },
  {
    id: 'replacementValue33',
    title: t('common.pluses.pno.replacementValue33.title'),
    categories: [],
    description: { __html: replacementValue33 },
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales, conventions spéciales et dispositions particulières.',
  },
  {
    id: 'furnitureCoverage',
    title: t('common.pluses.pno.furnitureCoverage.title'),
    categories: [],
    description: { __html: furnitureCoverage },
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales, conventions spéciales et dispositions particulières.',
  },
  {
    id: 'collapse',
    title: t('common.pluses.pno.collapse.title'),
    categories: [],
    description: { __html: collapse },
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales, conventions spéciales et dispositions particulières.',
  },
  {
    id: 'whithoutCommitment',
    title: t('common.pluses.mrh.contractWithoutCommitment.title'),
    categories: [],
    description: { __html: contractWithoutCommitment },
  },
];

export default performantGuaranteesList;
