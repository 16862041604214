import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Title from 'components/Title';
import QuestionsStep from 'components/QuestionsStep/QuestionsStep';
import VerandaLottie from 'assets/lotties/veranda.json';
import GarageLottie from 'assets/lotties/garage.json';
import OutdoorFacilitiesLottie from 'assets/lotties/outdoor-facilities.json';
import PoolLottie from 'assets/lotties/pool.json';

const PropertyTypeStep = ({ data, onConfirm, setUserData }) => {
  const { t } = useTranslation();
  return (
    <>
      <Title title={t('steps.equipmentsStep.title')} />
      <QuestionsStep
        propertyKey="equipements"
        choices={[
          {
            label: t('common.equipments.veranda'),
            value: 'veranda',
            asset: VerandaLottie,
          },
          {
            label: t('common.equipments.annex'),
            value: 'outbuilding',
            asset: GarageLottie,
            info: t('steps.equipmentsStep.annexInfo'),
          },
        ]}
        alternateChoices={[
          {
            label: t('common.equipments.yardWithoutPool'),
            value: 'gardenWithoutSwimmingPool',
            asset: OutdoorFacilitiesLottie,
          },
          {
            label: t('common.equipments.yardWithPool'),
            value: 'gardenWithSwimmingPool',
            asset: PoolLottie,
          },
        ]}
        allowMultiple
        onSelect={setUserData}
        onConfirm={onConfirm}
        // eslint-disable-next-line quotes
        emptyHelperText={t('steps.equipmentsStep.emptyEquipments')}
        value={data.equipements}
      />
    </>
  );
};

PropertyTypeStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
};

export default PropertyTypeStep;
