import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useTheme, useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import getHumanReadableString from 'utils/getHumanReadableString';
import { formatNumberWithoutDecimal } from 'utils';
import LowValueImage from 'assets/images/low_value.svg';
import useStyles from '../style';

const MySelection = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const { data } = props;

  const getSubscribedOutdoorFacilities = () => {
    const getCode = (landscapingOption) => (
      <div>
        <Typography variant="body1" className="label" component="span">
          {t('common.yardOption')}
        </Typography>
        {' :  '}
        <Typography variant="body2" className="value" component="span">
          {t(getHumanReadableString(landscapingOption))}
        </Typography>
      </div>
    );
    if (
      data.equipements.includes('gardenWithoutSwimmingPool') ||
      data.equipements.includes('gardenWithSwimmingPool')
    ) {
      if (
        data.landscaping.code === 'WITH_SWIM' ||
        data.landscaping.code === 'WITHOUT_SWIM'
      ) {
        return getCode(data.landscaping.code);
      } else return null;
    } else {
      return null;
    }
  };

  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Grid item xs={12}>
      <Card classes={{ root: classes.reminderCard }}>
        <CardContent align={mobile ? 'center' : 'left'}>
          <Grid
            container
            direction={mobile ? 'column' : 'row'}
            alignItems="center"
          >
            <Grid item xs={12} md={4}>
              <img
                src={LowValueImage}
                className={classes.lottie}
                alt="valeur"
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <div>
                <Typography variant="body1" className="label" component="span">
                  {t('common.worthOfProperty')}
                </Typography>
                {' :  '}
                <Typography variant="body2" className="value" component="span">
                  {`${formatNumberWithoutDecimal(
                    data.movableCapitalAmount.label
                  )} €`}
                </Typography>
              </div>
              <div>
                <Typography variant="body1" className="label" component="span">
                  {t('common.valuableItems')}
                </Typography>
                {' :  '}
                <Typography variant="body2" className="value" component="span">
                  {`${formatNumberWithoutDecimal(
                    data.valuableObjectAmount.label
                  )} €`}
                </Typography>
              </div>
              <div>
                <Typography variant="body1" className="label" component="span">
                  {t('common.deductible')}
                </Typography>
                {' :  '}
                <Typography variant="body2" component="span">
                  {`${formatNumberWithoutDecimal(data.deductible.label)} €`}
                </Typography>
              </div>
              {data.equipements?.length > 0 && getSubscribedOutdoorFacilities()}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};

MySelection.propTypes = {
  data: PropTypes.shape().isRequired,
};

export default MySelection;
