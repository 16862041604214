import moment from 'moment/moment';

import { POLICE_MOUVEMENT_ANNP, POLICE_MOUVEMENT_RE } from 'constants';

export const isPoliceEligibleADeclaSin = (police) => {
  if (!police) return false;

  const isUnDevis = police && police.status.code === POLICE_MOUVEMENT_ANNP;
  const isResilieeSansEffet = police && police.status.code.startsWith('RES');
  const isResilieIlYaPlusDe15J =
    police &&
    police.status.code.startsWith(POLICE_MOUVEMENT_RE) &&
    moment(police.statusEffectiveDate).isBefore(moment().subtract(15, 'days'));

  //const isDateEffetPoliceDansFutur = moment(police.startDate).isAfter(
  //  moment().date()
  //);

  const declarationSinImpossible =
    isUnDevis || isResilieeSansEffet || isResilieIlYaPlusDe15J; // || isDateEffetPoliceDansFutur

  return !declarationSinImpossible;
};
