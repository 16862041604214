import React from 'react';
import PropTypes from 'prop-types';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import MuiCheckbox from '@material-ui/core/Checkbox';

const Checkbox = (props) => {
  const { label, checked, value, fullWidth, helperText, labelPlacement = 'start', ...otherProps } = props;

  return (
    <div>
      <FormGroup>
        <FormControlLabel
          control={<MuiCheckbox color="secondary" {...otherProps} />}
          label={label}
          labelPlacement={labelPlacement}
          checked={checked}
        />
      </FormGroup>
    </div>
  );
};

Checkbox.propTypes = {
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  value: PropTypes.any,
  checked: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default Checkbox;
