import AddressStep from 'views/MrhSubscription/Steps/AddressStep';
import ResidenceTypeStep from 'views/MrhSubscription/Steps/ResidenceTypeStep';
import PropertyTypeStep from 'views/MrhSubscription/Steps/PropertyTypeStep';
import AssuredQualityStep from 'views/MrhSubscription/Steps/AssuredQualityStep';
import EquipmentsStep from 'views/MrhSubscription/Steps/EquipmentsStep';
import PropertyInfoStep from 'views/MrhSubscription/Steps/PropertyInfoStep';
import UserInfoStep from 'views/MrhSubscription/Steps/UserInfoStep';
import QuoteStep from 'views/MrhSubscription/Steps/QuoteStep';
import CurrentInsuranceStep from 'views/MrhSubscription/Steps/CurrentInsuranceStep';
import InsuranceSeniorityStep from 'views/MrhSubscription/Steps/InsuranceSeniorityStep';
import InsuranceStartStep from 'views/MrhSubscription/Steps/InsuranceStartStep';
import CompleteUserInfoStep from 'views/MrhSubscription/Steps/CompleteUserInfoStep';
import ResultStep from 'views/MrhSubscription/Steps/ResultStep';
import PaymentStep from 'views/PnoSouscription/Steps/PaymentStep/paymentForSubscription';
import PaymentPreloader from 'views/MrhSubscription/Steps/PaymentPreloader';
import IdentityStep from 'views/MrhSubscription/Steps/IdentityStep/index';
import EndStep from 'views/MrhSubscription/Steps/EndStep';
import ErrorStep from 'views/MrhSubscription/Steps/ErrorStep';

const stepsIds = {
  ADDRESS: 'address',
  RESIDENCE_TYPE: 'residenceType',
  PROPERTY_TYPE: 'propertyType',
  ASSURED_QUALITY: 'assuredQuality',
  PROPERTY_INFO: 'propertyInfo',
  EQUIPMENTS: 'equipments',
  USER_INFO: 'userInfo',
  QUOTE: 'quote',
  CURRENT_WARRANTY: 'currentWarranty',
  INSURANCE_SENIORITY: 'insuranceSeniority',
  INSURANCE_START: 'insuranceStart',
  CO_INSURED: 'coInsured',
  CHILDREN: 'children',
  COMPLETE_USER_INFO: 'completeUserInfo',
  RESULT: 'result',
  PAYMENT_LOADER: 'paymentLoader',
  PAYMENT: 'payment',
  IDENTITY: 'identity',
  END: 'end',
  ERROR: 'error',
};

const getSteps = (data) => [
  [
    {
      id: stepsIds.ADDRESS,
      component: AddressStep,
      category: 'common.stepsCategories.myGood',
    },
    {
      id: stepsIds.RESIDENCE_TYPE,
      component: ResidenceTypeStep,
      category: 'common.stepsCategories.myGood',
    },
    {
      id: stepsIds.PROPERTY_TYPE,
      component: PropertyTypeStep,
      category: 'common.stepsCategories.myGood',
    },
    {
      id: stepsIds.ASSURED_QUALITY,
      component: AssuredQualityStep,
      category: 'common.stepsCategories.myGood',
    },
    {
      id: stepsIds.PROPERTY_INFO,
      component: PropertyInfoStep,
      category: 'common.stepsCategories.myGood',
    },
    {
      id: stepsIds.EQUIPMENTS,
      component: EquipmentsStep,
      category: 'common.stepsCategories.myGood',
    },
    {
      id: stepsIds.USER_INFO,
      component: UserInfoStep,
      category: 'common.stepsCategories.myGood',
    },
  ],
  [
    {
      id: stepsIds.QUOTE,
      component: QuoteStep,
      // hideStepper: true,
      category: 'common.stepsCategories.rate',
    },
  ],
  [
    {
      id: stepsIds.CURRENT_WARRANTY,
      component: CurrentInsuranceStep,
      next:
        data.hasCurrentWarranty && data.hasCurrentWarranty
          ? stepsIds.INSURANCE_SENIORITY
          : stepsIds.INSURANCE_START,
      category: 'common.stepsCategories.subscription',
    },
    {
      id: stepsIds.INSURANCE_SENIORITY,
      component: InsuranceSeniorityStep,
      next: stepsIds.ResultStep,
      category: 'common.stepsCategories.subscription',
    },
    {
      id: stepsIds.INSURANCE_START,
      component: InsuranceStartStep,
      previous: stepsIds.CURRENT_WARRANTY,
      category: 'common.stepsCategories.subscription',
    },
    {
      id: stepsIds.RESULT,
      component: ResultStep,
      previous:
        data.hasCurrentWarranty && data.hasCurrentWarranty
          ? stepsIds.INSURANCE_SENIORITY
          : stepsIds.INSURANCE_START,
      category: 'common.stepsCategories.subscription',
    },
    {
      id: stepsIds.COMPLETE_USER_INFO,
      component: CompleteUserInfoStep,
      category: 'common.stepsCategories.subscription',
    },
    {
      id: stepsIds.IDENTITY,
      component: IdentityStep,
      category: 'common.stepsCategories.finalization',
    },
    {
      id: stepsIds.PAYMENT_LOADER,
      component: PaymentPreloader,
      category: 'common.stepsCategories.subscription',
    },
    {
      id: stepsIds.PAYMENT,
      component: PaymentStep,
      category: 'common.stepsCategories.subscription',
    },
  ],
  [
    {
      id: stepsIds.END,
      component: EndStep,
      category: 'common.stepsCategories.finalization',
    },
    {
      id: stepsIds.ERROR,
      component: ErrorStep,
      hideStepper: true,
      hidden: true,
    },
  ],
];

export default getSteps;
export { stepsIds };
