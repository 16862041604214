import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  inputLabelFormControl: {
    transform: 'translate(16px, 20px) scale(1)',
  },
  inputLabelShrink: {
    transform: 'translate(14px, -6px) scale(0.75)',
  },
}));

export default useStyles;
