import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Tooltip from '@material-ui/core/Tooltip';
import { PictureAsPdf } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import Popin from 'components/Popin';
import TabPanel from './TabPanel';
import { downloadFileFromLink } from 'utils';
import useAuth from 'hooks/useAuth';
import useStyles from './style';

const PreviewPremium = (props) => {
  const { object } = props;
  const auth = useAuth();
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [fileUrl, setFileUrl] = useState(null);
  const [error, setError] = useState(null);
  const classes = useStyles();

  const handleViewMoreClick = () => {
    setOpen(!open);
  };

  const handleClose = () => setOpen(false);

  const handleChange = (event, newTab) => {
    setError(false);
    setTab(newTab);
    generateDocument(newTab);
  };

  const generateDocument = async (currentTab) => {
    const doc = object.docs[currentTab];
    try {
      const blob = await doc.apiCall(auth);

      const url = window.URL.createObjectURL(new Blob([blob]));

      setFileUrl(url);
    } catch (error) {
      setError(true);
      setFileUrl(null);
      // enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  const handleDownload = () => {
    if (fileUrl) {
      const doc = object.docs[tab];

      downloadFileFromLink(fileUrl, doc.titleDownload, 'pdf');
    }
  };

  useEffect(() => {
    if (open) generateDocument(tab);
  }, [open]);

  const renderContent = () => {
    return (
      <div className={classes.tabsWrapper}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={tab}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          className={classes.tabs}
        >
          {object.docs.map((doc) => (
            <Tab
              classes={{ root: classes.tab, selected: classes.selectedTab }}
              key={`tab-${doc.id}`}
              label={doc.title}
            />
          ))}
        </Tabs>
        {object.docs.map((doc, index) => (
          <TabPanel
            key={`tabPanel-${object.id}`}
            tab={tab}
            index={index}
            fileUrl={fileUrl}
            error={error}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <Tooltip title="Visualisation du document">
        <IconButton
          onClick={handleViewMoreClick}
          variant="outlined"
          color="primary"
        >
          <PictureAsPdf />
        </IconButton>
      </Tooltip>

      <Popin
        title={object.title}
        open={open}
        onClose={handleClose}
        content={renderContent()}
        maxWidth="xl"
        showCancelButton={true}
        cancelButtonLabel={t('common.download')}
        onCancel={handleDownload}
        isCancelButtonDisabled={!fileUrl}
      />
    </>
  );
};

PreviewPremium.propTypes = {
  object: PropTypes.shape().isRequired,
};

export default PreviewPremium;
