import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Popin from 'components/Popin';
import Lottie from 'components/LottieReact';
import GiftListLottie from 'assets/lotties/giftList.json';
import Icon from 'components/Icon';
import useStyles from './style';

const Advantages = (props) => {
  const { title, guarantees } = props;
  const [showPopin, setShowPopin] = useState(false);
  const [guaranteeToShow, setGuaranteeToShow] = useState(null);
  const classes = useStyles();
  const theme = useTheme();

  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleClosePopin = () => {
    setShowPopin(false);
  };

  const handleShowPopin = (guarantee) => {
    if (guarantee.description) {
      setGuaranteeToShow(guarantee);
      setShowPopin(true);
    }
  };

  useEffect(() => {
    if (guaranteeToShow !== null) setShowPopin(true);
  }, [guaranteeToShow]);

  const renderContent = (guarantee) => {
    return (
      <>
        <div>
          {guarantee.examples &&
            guarantee.examples.map((example, index) => {
              return (
                <div key={index} className={classes.zone}>
                  <Typography classes={{ root: classes.example }}>
                    {example}
                  </Typography>
                </div>
              );
            })}
        </div>
        <div>
          {guarantee.description && (
            <div className={classes.zone}>
              <div dangerouslySetInnerHTML={guarantee.description} />
            </div>
          )}
        </div>
        {guarantee.categories.map((category, index) => {
          return (
            <Accordion
              key={index}
              defaultExpanded={true}
              classes={{
                root: classes.accordion,
                expanded: classes.accordionExpanded,
              }}
            >
              <AccordionSummary
                classes={{
                  root: classes.summary,
                  expandIcon: classes.expandIcon,
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography classes={{ root: classes.categoryTitle }}>
                  {category.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div dangerouslySetInnerHTML={category.description} />
              </AccordionDetails>
            </Accordion>
          );
        })}
        <div className={classes.zone}>
          <Typography classes={{ root: classes.additional }}>
            {guarantee.additional}
          </Typography>
        </div>
      </>
    );
  };

  return (
    <Grid container>
      {!isDownSm && (
        <Grid item xs={12} md={6} align="center">
          <img
            src={`${localStorage.getItem(
              'IMAGE_MANAGER_URL_GET'
            )}/assurimo/base/logo-assurimo.png`}
            alt="Assurimo"
            style={{
              maxWidth: 350,
              position: 'relative',
              top: '50%',
              marginTop: '-50px',
            }}
          />
        </Grid>
      )}
      <Grid container item xs={12} md={6} direction="column" spacing={3}>
        <Grid item>
          <Typography variant="h4" color="textPrimary">
            {title}
          </Typography>
        </Grid>
        <Grid item align="left" classes={{ root: classes.guarantees }}>
          {guarantees.map((guarantee) => {
            const { id, title } = guarantee;

            return (
              <div
                key={id}
                className={classes.advantage}
                onClick={() => handleShowPopin(guarantee)}
              >
                <Icon
                  className="fas fa-chevron-right"
                  classes={{ root: classes.icon }}
                />
                <Typography
                  color="textPrimary"
                  variant="subtitle2"
                  classes={{
                    root: classes.title,
                  }}
                >
                  {title}
                </Typography>
              </div>
            );
          })}
        </Grid>
      </Grid>
      {guaranteeToShow && (
        <Popin
          open={showPopin}
          onClose={handleClosePopin}
          title={guaranteeToShow.title}
          icon={guaranteeToShow.icon}
          content={renderContent(guaranteeToShow)}
        />
      )}
    </Grid>
  );
};

Advantages.propTypes = {
  title: PropTypes.string,
  guarantees: PropTypes.array.isRequired,
};

Advantages.defaultProps = {
  title: '',
};

export default Advantages;
