import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  updateButton: {
    alignSelf: 'flex-end',
  },
  updateButtonText: {
    color: theme.palette.primary.main,
  },
  forgetPassword: {
    fontSize: 12,
  },
  error: {
    color: theme.palette.red.main,
  },
}));

export default useStyles;
