import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import i18next from 'i18next';
import {
  LANGUAGES,
  PNO_TYPE_APPARTEMENT,
  PNO_TYPE_GARAGE,
  PNO_TYPE_LOCAL_COMMERCIAL,
  PNO_TYPE_MAISON,
  PNO_TYPE_MONUMENT_HISTORIQUE,
} from 'constants/index';
import { firstLetterUppercase } from 'utils/index';

const BienAssureInfo = (props) => {
  const { data, children, showTitle } = props;
  const { t } = useTranslation();

  const housingType = Array.isArray(data.housingType)
    ? data.housingType[0]
    : data.housingType;

  const traductionEnFeminin =
    i18next.resolvedLanguage === LANGUAGES.fr &&
    housingType === PNO_TYPE_MAISON;

  const isLocalCommercialOrGarage =
    housingType === PNO_TYPE_GARAGE ||
    housingType === PNO_TYPE_LOCAL_COMMERCIAL;

  const getHousingTypeLabel = () => {
    switch (housingType) {
      case PNO_TYPE_MAISON:
        return 'common.aHouse';
      case PNO_TYPE_APPARTEMENT:
      case PNO_TYPE_MONUMENT_HISTORIQUE:
        return 'common.anApartment';
      case PNO_TYPE_GARAGE:
        return 'common.aGarage';
      case PNO_TYPE_LOCAL_COMMERCIAL:
        return 'common.aCommercialPremise';
    }
  };

  return (
    <>
      {showTitle && (
        <>
          <Typography>
            {children && children}
            <Typography variant="h6" component="span" color="primary">
              {t('common.property')}
            </Typography>
          </Typography>
          <br />
        </>
      )}
      <Typography variant="body1" color="textPrimary">
        <Typography variant="body1" component="span" color="primary">
          {firstLetterUppercase(t(getHousingTypeLabel()))}
        </Typography>{' '}
        {!isLocalCommercialOrGarage && (
          <Typography variant="body1" component="span" color="primary">
            {`${t(
              `common.${data?.furnished ? 'furnished' : 'unFurnished'}`
            ).toLowerCase()}${traductionEnFeminin ? 'e' : ''}`}{' '}
          </Typography>
        )}
        <>
          {t('common.of')}
          <Typography variant="body1" component="span" color="primary">
            {` ${data.area} m²`}
          </Typography>
        </>{' '}
        {!isLocalCommercialOrGarage && (
          <>
            <Typography variant="body1" component="span" color="secondary">
              {t('pno.property.with')}{' '}
            </Typography>
            <Typography variant="body1" component="span" color="primary">
              {data.numberOfPrincipalRooms}{' '}
              {`${t('pno.property.room')}${
                data.numberOfPrincipalRooms > 1 ? 's' : ''
              } `}
            </Typography>
          </>
        )}
        {housingType === PNO_TYPE_MONUMENT_HISTORIQUE && (
          <Typography variant="body1" component="span" color="secondary">
            {t('common.and')} {t('common.historic')}{' '}
          </Typography>
        )}
      </Typography>
    </>
  );
};

BienAssureInfo.propTypes = {
  data: PropTypes.shape(),
  goToStep: PropTypes.func,
  children: PropTypes.node,
  showTitle: PropTypes.bool,
};

BienAssureInfo.defaultProps = {
  data: {},
  showTitle: true,
};

export default BienAssureInfo;
