import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Grid, Hidden, Typography } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Tooltip from '@material-ui/core/Tooltip';

import ContactIcon from 'assets/images/contact-icon.png';
import { TRANSLATE_MONTH } from 'constants';
import { getTypeCard } from 'utils/card';

const useStyles = makeStyles((theme) => ({
  visual: {
    width: 58,
    height: 58,
    background: theme.palette.white.main,
    position: 'relative',
    borderRadius: theme.spacing(1),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      width: 48,
      height: 48,
    },
  },
  button: {
    alignSelf: 'self-start',
  },
  container: {
    backgroundColor: theme.palette.grey.grey,
  },
  grid: {
    gap: 5,
    [theme.breakpoints.down('sm')]: {
      '&:not(:first-child)': {
        marginTop: 5,
      },
    },
  },
  typography: {
    textOverflow: 'ellipsis',
    minWidth: 0,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  name: {
    textTransform: 'capitalize',
    fontWeight: 700,
  },
}));

function CardDetail(props) {
  const { card, onModif } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const isDownXs = useMediaQuery(theme.breakpoints.down('xs'));
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const translateDate = (month, year) => {
    const monthTrans = t(TRANSLATE_MONTH[+month]);
    return `${monthTrans} ${year}`;
  };

  const INFO_CARDS = [
    {
      sizeMd: 4,
      label: t('dashboard.card.info.number_card'),
      content: `**** **** **** ${card.last4}`,
    },
    {
      sizeMd: 4,
      label: t('dashboard.card.info.expire'),
      content: translateDate(card.moisExp, card.anneeExp),
    },
    {
      sizeMd: 4,
      label: t('dashboard.card.info.type'),
      content: t(getTypeCard(card.brand)),
    },
    {
      sizeMd: 12,
      label: t('dashboard.card.info.email'),
      content: card.email,
      tooltip: true,
    },
  ];

  const handleModif = () => {
    onModif && onModif();
  };

  return (
    <Grid
      container
      item
      xs={12}
      wrap="nowrap"
      classes={{ root: classes.container }}
    >
      {!isDownXs && (
        <Box display="flex" alignItems="center" justifyContent="center">
          <div className={classes.visual}>
            <img src={ContactIcon} />
          </div>
        </Box>
      )}
      <Box align="left" width={1}>
        <Hidden smDown>
          <Typography variant="body1" classes={{ root: classes.name }}>
            {card.name}
          </Typography>
        </Hidden>

        <Box display="flex" alignItems="space-between">
          <Grid container xs={12} md={10} item style={{ overflow: 'hidden' }}>
            {INFO_CARDS.map((item, index) => (
              <Grid
                key={index}
                item
                xs={12}
                md={item.sizeMd}
                classes={{ root: classes.grid }}
              >
                <Hidden smDown>
                  <Typography variant="body2" color="secondary">
                    {item.label}
                  </Typography>
                  {item.tooltip ? (
                    <Tooltip
                      title={item.content}
                      aria-label={item.content}
                      classes={{ root: classes.tooltip }}
                    >
                      <Typography
                        variant="body2"
                        component="div"
                        classes={{ root: classes.typography }}
                      >
                        {item.content}
                      </Typography>
                    </Tooltip>
                  ) : (
                    <Typography
                      variant="body2"
                      component="div"
                      classes={{ root: classes.typography }}
                    >
                      {item.content}
                    </Typography>
                  )}
                </Hidden>
                <Hidden mdUp>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      width: '100%',
                    }}
                  >
                    <Typography variant="body2" color="secondary">
                      {item.label}
                    </Typography>
                    <div style={{ overflow: 'hidden' }}>
                      {item.tooltip ? (
                        <Tooltip
                          title={item.content}
                          aria-label={item.content}
                          classes={{ root: classes.tooltip }}
                          open={tooltipOpen}
                        >
                          <Typography
                            variant="body2"
                            component="div"
                            classes={{ root: classes.typography }}
                            onClick={() => setTooltipOpen(!tooltipOpen)}
                          >
                            {item.content}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography
                          variant="body2"
                          component="div"
                          classes={{ root: classes.typography }}
                          onClick={() => setTooltipOpen(!tooltipOpen)}
                        >
                          {item.content}
                        </Typography>
                      )}
                    </div>
                  </div>
                </Hidden>
              </Grid>
            ))}
          </Grid>
          {!isDownXs && (
            <Grid container item xs={2} style={{ marginRight: 50 }}>
              <Box
                width={1}
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <Button
                  align="center"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={handleModif}
                  classes={{ root: classes.button }}
                >
                  {t('dashboard.card.info.btn_update')}
                </Button>
              </Box>
            </Grid>
          )}
        </Box>
        {isDownXs && (
          <Grid container item>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ width: '100%', marginTop: 20 }}
            >
              <Button
                align="center"
                variant="outlined"
                color="secondary"
                size="small"
                onClick={handleModif}
                classes={{ root: classes.button }}
              >
                {t('dashboard.card.info.btn_update')}
              </Button>
            </Box>
          </Grid>
        )}
      </Box>
    </Grid>
  );
}

CardDetail.propTypes = {
  card: PropTypes.object.isRequired,
  onModif: PropTypes.func,
};

export default CardDetail;
