import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Price from 'components/Price';
import { formatNumber, formatDate } from 'utils';
import useStyles from '../../style';

const MyPricing = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { data, periodicity, paymentPlan, renderLegalMansion, onConfirm } =
    props;

  const formatSelectedDate = (date) => {
    return `${formatDate(date, 'DD/MM/YYYY')}`;
  };

  return (
    <Grid item xs={12} classes={{ root: classes.pricingWrapper }}>
      <Card
        className="EndStep_Hero_CardPrice"
        classes={{ root: classes.pricing }}
      >
        <CardContent className={classes.pricingCardContent}>
          <Price price={data.price} size="big" periodicity={periodicity.code} />
          <div className="EndStep_Hero_CardPrice_Infos">
            <div>
              <div>
                <Typography variant="body1">
                  {t('steps.resultStep.pricing.insuranceDate')}{' '}
                  <span>{formatSelectedDate(data.contractStartDate)}</span>
                </Typography>
              </div>
              <br />
            </div>
            <div>
              <Button variant="contained" color="primary" onClick={onConfirm}>
                {t('steps.resultStep.subscribe')}
              </Button>
            </div>
            <div>{renderLegalMansion()}</div>
          </div>
        </CardContent>
      </Card>
    </Grid>
  );
};

MyPricing.propTypes = {
  data: PropTypes.shape().isRequired,
  periodicity: PropTypes.shape().isRequired,
  renderLegalMansion: PropTypes.func.isRequired,
  paymentPlan: PropTypes.shape(),
  onConfirm: PropTypes.func.isRequired,
};

export default MyPricing;
