import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { firstLetterUppercase } from 'utils';

const DeductibleSelector = (props) => {
  const { options, deductible, onChange } = props;
  const { t } = useTranslation();

  return (
    <div className="EndStep_Content_Value_Franchise">
      <Typography variant="h4" color="textPrimary" align="center">
        {t('steps.quoteStep.yourDeductible')}
      </Typography>
      <Typography variant="body1" color="textPrimary" align="center">
        {t('steps.quoteStep.deductibleExplanation')}
      </Typography>
      <Grid
        container
        className="QuestionsStep"
        spacing={3}
        justifyContent="center"
      >
        {options.map((option) => {
          return (
            <Card
              key={option.code}
              variant="outlined"
              className="QuestionsStep__Card"
              classes={{
                root: deductible.code === option.code ? 'selected' : '',
              }}
              onClick={() => onChange(option)}
            >
              <CardHeader
                action={
                  <CheckCircle
                    color="primary"
                    style={{
                      opacity: deductible.code === option.code ? 1 : 0,
                    }}
                  />
                }
              />
              <CardContent className="QuestionsStep__Card__content--no-asset">
                <Typography
                  component="span"
                  variant="h5"
                  color={
                    deductible.code === option.code ? 'primary' : 'textPrimary'
                  }
                  align="center"
                  className="EndStep_Content_Value_Franchise_Value"
                >
                  {`${firstLetterUppercase(option.label)} €`}
                </Typography>
              </CardContent>
            </Card>
          );
        })}
      </Grid>
    </div>
  );
};

DeductibleSelector.propTypes = {
  options: PropTypes.array.isRequired,
  deductible: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default DeductibleSelector;
