import React from 'react';
import PropTypes from 'prop-types';
import { Route as NormalRoute } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';

const ContextRoute = (props) => {
  const { component, context, secure, children, ...rest } = props;

  const Component = component;
  const Context = context;

  const Route = secure ? SecureRoute : NormalRoute;

  return (
    <Route {...rest}>
      <Context>{component ? <Component {...rest} /> : children}</Context>
    </Route>
  );
};

ContextRoute.propTypes = {
  component: PropTypes.any,
  context: PropTypes.any,
  secure: PropTypes.bool,
  children: PropTypes.node,
  neededRights: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
};

export default ContextRoute;
