import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Visibility as VisibilityIcon } from '@material-ui/icons';
import { useSnackbar } from 'notistack';

import Popin from 'components/Popin';
import PdfViewVer from 'components/PdvViewver';
import { downloadFileFromBlob } from 'utils';
import { DOC_CONTRACTUEL } from 'constants/index';
import policyApi from 'api/policyApi';
import documentApi from 'api/documentApi';
import { UserContext } from 'contexts/user';
import { PAGE_SECTION_SHOW } from 'views/Dashboard/Customer/PolicyPage/helper';
import { useStyles } from './style';
/*
import NoticeInformationPNO from "public/files/202401_Notice_d'information_Assurimo.pdf";
import IpidPNO from 'public/files/202401_Fiche_IPID_Assurimo.pdf';
import ConditionsGeneralesMRH from 'public/files/Conditions_générales_MRH.pdf';
import IpidMRH from 'public/files/IPID_MRH.pdf';
 */

const GeneralConditions = ({ police, scrollRef }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [optionsPopin, setOptionsPopin] = useState({
    open: false,
  });
  const [loadingDoc, setLoadingDoc] = useState(null);
  const [userState] = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();
  const [docContractuels, setDocContractuels] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const res = await policyApi.documentContractuel(
          userState.auth,
          police.policyNumber
        );
        setDocContractuels(res);
      } catch (error) {
        //console.log(t('dashboard.relatedDocuments.error_generate'), error);
      }
    })();
  }, []);

  const previewFile = async (doc) => {
    setLoadingDoc(doc.id);
    try {
      const blob = await documentApi.download(userState.auth, doc.id);
      showPopinPreview(blob, doc);
    } catch (error) {
      //console.log(t('dashboard.relatedDocuments.error_generate'), error);
      enqueueSnackbar(t('dashboard.relatedDocuments.error_generate'), {
        variant: 'error',
      });
    } finally {
      setLoadingDoc(null);
    }
  };

  const showPopinPreview = (blob, doc) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    setOptionsPopin({
      open: true,
      onValidate: () =>
        downloadFileFromBlob(
          blob,
          `${t(
            DOC_CONTRACTUEL[doc.codeDocument]
              ? DOC_CONTRACTUEL[doc.codeDocument].label
              : doc.labelDefault
          )}.pdf`
        ),
      validateButtonLabel: t('common.download'),
      content: <PdfViewVer filePath={url} />,
      onCancel: () => setOptionsPopin({ open: false }),
    });
  };

  if (!docContractuels || !docContractuels.length) return <></>;

  return (
    PAGE_SECTION_SHOW[police.contractType.code].generalConditions && (
      <Grid container className={classes.quittances} ref={scrollRef}>
        <Box p={2} className={classes.container} width="100%">
          <Grid container>
            <Box mb={2}>
              <Typography variant="h5" color="secondary">
                {t('dashboard.relatedDocuments.title')}
              </Typography>
            </Box>

            <Grid item container direction="column">
              {docContractuels.map((doc, index) => (
                <Box
                  key={index}
                  padding={1}
                  mb={1}
                  className={classes.elementWrapper}
                >
                  <Grid
                    item
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography>
                      {t(
                        DOC_CONTRACTUEL[doc.codeDocument]
                          ? DOC_CONTRACTUEL[doc.codeDocument].label
                          : doc.labelDefault
                      )}
                    </Typography>
                    <div className={classes.buttonsWrapper}>
                      {loadingDoc === doc.id ? (
                        <CircularProgress color="primary" size={16} />
                      ) : (
                        <Button
                          startIcon={<VisibilityIcon />}
                          variant="outlined"
                          color="secondary"
                          onClick={() => previewFile(doc)}
                        >
                          {t('common.preview')}
                        </Button>
                      )}
                    </div>
                  </Grid>
                </Box>
              ))}
            </Grid>
          </Grid>
        </Box>
        <Popin
          onClose={() => setOptionsPopin({ open: false })}
          maxWidth="xl"
          enableTitleArea={false}
          cancelButtonLabel={t('common.cancel')}
          showCancelButton={true}
          {...optionsPopin}
        />
      </Grid>
    )
  );
};

/*
const DOCUMENTATION_GENERAL = {
  [TYPE_POLICE_H]: [
    {
      id: 'ConditionsGeneralesMRH',
      document: ConditionsGeneralesMRH,
      nameDownload: 'Conditions_générales_MRH.pdf',
      nameShow: 'common.generalConditions',
    },
    {
      id: 'IpidMRH',
      document: IpidMRH,
      nameDownload: 'IPID_MRH.pdf',
      nameShow: 'common.ipidPage',
    },
  ],
  [TYPE_POLICE_PNO]: [
    {
      id: 'NoticeInformationPNO',
      document: NoticeInformationPNO,
      nameDownload: "202401_Notice_d'information_Assurimo.pdf",
      nameShow: 'common.generalDispositions',
    },
    {
      id: 'IpidPNO',
      document: IpidPNO,
      nameDownload: '202401_Fiche_IPID_Assurimo.pdf',
      nameShow: 'common.ipidPage',
    },
  ],
};
 */

export default GeneralConditions;
