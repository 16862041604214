import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import _get from 'lodash/get';
import { useTranslation } from 'react-i18next';

import PriceCard from 'components/PriceCard/PriceCard';
import { formatDateWithSystem, calculerDateEcheance } from 'utils';
import useStyles from './style';

const ContractDetails = (props) => {
  const { policy, polichab } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const getAlertText = () => {
    let periodicity = '';
    if (policy.futureFractionnement) {
      if (policy.futureFractionnement === 'A')
        periodicity = t('common.annualized');
      else if (policy.futureFractionnement === 'M')
        periodicity = t('common.monthlyzed');

      const text = t('dashboard.police.paymentPeriodicity.updateExplanation', {
        periodicity: periodicity.toLowerCase(),
        year: new Date().getFullYear() + 1,
      });

      return text;
    }
    return null;
  };

  return (
    <>
      <Grid>
        <Grid item>
          <Typography variant="h5" color="secondary">
            {t('dashboard.police.details.title')}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container>
        <Grid item container xs={12} md={8}>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Box mb={2}>
                <Typography variant="caption" color="secondary">
                  {t('dashboard.police.details.info.address')}
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontSize: '1rem',
                  }}
                >
                  {`${polichab?.result.adress.address1}`}
                  {polichab?.result.adress.address2 &&
                    `${_get(polichab?.result.adress, 'address2', '') || ''}`}
                  {polichab?.result.adress.address2 &&
                    `${_get(polichab?.result.adress, 'address3', '') || ''}`}
                  {polichab?.result.adress.residency &&
                    `, ${t('common.residency')} ${_get(
                      polichab?.result.adress,
                      'residency',
                      ''
                    )}`}
                  {polichab?.result.adress.building &&
                    `, ${t('common.building')} ${_get(
                      polichab?.result.adress,
                      'building',
                      ''
                    )}`}
                  {`, ${polichab?.result.adress.zipCode} ${polichab?.result.adress.city}`}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="caption" color="secondary">
                {t('dashboard.policies.contractNumber')}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontSize: '1rem',
                }}
              >
                {policy.policyNumber}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12}>
            <Grid item xs={6}>
              <Typography variant="caption" color="secondary">
                {t('dashboard.policies.startDate')}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontSize: '1rem',
                }}
              >
                {formatDateWithSystem(policy.startDate)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="caption" color="secondary">
                {t('dashboard.policies.term')}
              </Typography>
              <Typography
                variant="body2"
                style={{
                  fontSize: '1rem',
                }}
              >
                {calculerDateEcheance(policy.contractAnniversaryDate)}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          container
          xs={12}
          md={4}
          className={classes.priceCardContainer}
          spacing={3}
          alignItems="center"
        >
          <Box width={280}>
            <PriceCard policy={policy} alertText={getAlertText()} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

ContractDetails.propTypes = {
  policy: PropTypes.shape().isRequired,
  polichab: PropTypes.shape(),
};

export default ContractDetails;
