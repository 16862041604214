import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  TextField,
  MenuItem,
  Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { UserContext } from 'contexts/user';

import { useSnackbar } from 'notistack';
import { useStyles } from './style';
import { generateUUID } from 'utils/uuid';
import contactApi from 'api/contactApi';
import { useHistory } from 'react-router-dom';

const ContactPage = ({
  thematique,
  numeroPolice,
  numeroSinistre,
  onSuccesBackUrl,
}) => {
  const { t } = useTranslation();
  const classes = useStyles(useStyles);
  const { enqueueSnackbar } = useSnackbar();
  const [userState] = useContext(UserContext);
  const history = useHistory();

  const categories = [
    {
      nom: 'sinistre_decla',
      label: t('dashboard.contact.support.thematiqueDeclarationSinistre'),
    },
    { nom: 'autre', label: t('dashboard.contact.support.thematiqueAutre') },
  ];

  const [form, setForm] = useState({
    objet: getCategorieByNom(thematique),
    message: '',
  });
  const [loading, setLoading] = useState(false);

  if (numeroPolice == null) {
    history.push('/');
  }

  const onSuccess = () => {
    onSuccesBackUrl ? history.push(onSuccesBackUrl) : history.push('/');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    contactApi
      .envoyerAuSupport(
        userState.auth,
        form.objet.label,
        numeroPolice,
        numeroSinistre,
        form.message
      )
      .then(() => {
        enqueueSnackbar(t('dashboard.contact.support.messageDeSucces'), {
          variant: 'success',
        });
        onSuccess();
      })
      .catch(() => {
        enqueueSnackbar('Une erreur est survenue', {
          variant: 'error',
        });
        setLoading(false);
      });
  };

  const majObjet = (object) => {
    let nouveauForm = { ...form, objet: object };
    setForm(nouveauForm);
  };

  const majMessage = (message) => {
    if (message.length <= 1000) {
      let nouveauForm = { ...form, message: message };
      setForm(nouveauForm);
    }
  };

  function getCategorieByNom(nom) {
    return categories?.find((categorie) => categorie.nom === nom) || null;
  }

  return (
    <>
      <Box>
        <Typography variant="h3">
          {t('dashboard.contact.support.titre')}
        </Typography>
      </Box>

      <form onSubmit={handleSubmit} className={classes.formContactLayout}>
        <Typography>{t('dashboard.contact.support.explications')}</Typography>

        <TextField
          select
          label={t('dashboard.contact.support.thematique')}
          name="categorie"
          variant="outlined"
          fullWidth
          value={form.objet.nom}
          onChange={(e) => majObjet(e.target.value)}
          disabled={!!thematique}
          required
        >
          {categories.map((categorie) => (
            <MenuItem key={generateUUID()} value={categorie.nom}>
              {categorie.label}
            </MenuItem>
          ))}
        </TextField>

        {numeroSinistre && (
          <TextField
            label={t('dashboard.contact.support.numeroSinistre')}
            name="numero_sinistre"
            variant="outlined"
            fullWidth
            multiline
            value={numeroSinistre}
            disabled
            required
          />
        )}
        <TextField
          label={t('dashboard.contact.support.message')}
          name="message"
          variant="outlined"
          fullWidth
          multiline
          rows={10}
          value={form.message}
          onChange={(e) => majMessage(e.target.value)}
          required
        />
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={loading}
        >
          {t('dashboard.contact.support.envoieMessage')}
        </Button>
      </form>
    </>
  );
};

ContactPage.propTypes = {
  thematique: PropTypes.string,
  numeroPolice: PropTypes.string.isRequired,
  numeroSinistre: PropTypes.string,
  onSuccesBackUrl: PropTypes.string,
};

export default ContactPage;
