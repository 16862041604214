import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormHelperText, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import AutocompleteField from 'components/Field/AutoCompleteField';
import Field from 'components/Field/index';

FormResilHamon.propTypes = {
  form: PropTypes.object,
  onChange: PropTypes.bool,
  error: PropTypes.object,
  items: PropTypes.array,
  data: PropTypes.object,
};

function FormResilHamon(props) {
  const { form, onChange, error = {}, items = [], data = {} } = props;
  const { hasError, msgError } = error;

  const [inputValue, setInputValue] = useState('');

  const { t } = useTranslation();

  const handleChange = (event) => {
    const { name, value } = event.target;
    onChange && onChange({ ...form, [name]: value });
  };

  const handleChangeCompany = (event) => {
    setInputValue(event.target.value);
  };

  const handleSelectCompany = (event) => {
    const company = items.find((item) => item.label === event.target.value);
    onChange({ inssuranceId: company.code });
  };

  const getOptionLabel = (option) => {
    return option.label;
  };

  const isFinish =
    data.terminationTodo &&
    data.terminationDo &&
    data.inssuranceName &&
    data.ancienPolicyNumber;
  const ancienSocPolice = isFinish
    ? items.find((s) => s.label == data.inssuranceName) || {}
    : {};

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h5" color="secondary" className="typography">
          {t('dashboard.resilHamon.form.info.title')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {isFinish ? (
          <Field
            type="text"
            label={t('dashboard.resilHamon.form.info.sel_inssurance')}
            variant="filled"
            value={`${ancienSocPolice.label}`}
            name="inssuranceId"
            fullWidth
            disabled
          />
        ) : (
          <AutocompleteField
            name="inssuranceId"
            label={t('dashboard.resilHamon.form.info.sel_inssurance')}
            value={data.inssuranceId}
            options={items}
            getOptionLabel={getOptionLabel}
            onChange={handleChangeCompany}
            onSelect={handleSelectCompany}
            variant="filled"
            size={'large'}
          />
        )}
      </Grid>
      <Grid item xs={12}>
        <Field
          type="text"
          label={t('dashboard.resilHamon.form.info.input_number_police')}
          variant="filled"
          value={data.ancienPolicyNumber}
          name="ancienPolicyNumber"
          onChange={handleChange}
          fullWidth
          disabled={isFinish}
        />
      </Grid>
      {hasError && (
        <Grid item>
          <FormHelperText error={true} variant="outlined">
            {msgError}
          </FormHelperText>
        </Grid>
      )}
    </Grid>
  );
}

export default FormResilHamon;
