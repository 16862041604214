/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import moment from 'moment';
import i18next from 'i18next';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ASSURIMO_INDIVIDUAL_EMAIL } from 'constants';

const lessThan12Month = (t) => (
  <Grid container justifyContent="center" spacing={1}>
    <Grid item xs={12}>
      <Typography variant="body1" align="center">
        {t('steps.insuranceSeniorityStep.hamon.yourContract')}{' '}
        <strong>{t('steps.insuranceSeniorityStep.hamon.must12')}</strong>{' '}
        {`${t('steps.insuranceSeniorityStep.hamon.terminate')}.`}
      </Typography>
    </Grid>
    {/* <Grid item xs={12}>
      <Typography variant="body1" align="center">
        Renseignez-vous auprès de votre assureur actuel pour connaître vos
        moyens de résiliation.
      </Typography>
    </Grid> */}
    <Grid item xs={12}>
      <Typography variant="body1" align="center">
        {t('steps.insuranceSeniorityStep.contact.contact')}{' '}
        <a href="mailto:particuliers@assurimo.fr">
          {ASSURIMO_INDIVIDUAL_EMAIL}
        </a>{' '}
        {`${t('steps.insuranceSeniorityStep.contact.smaCode')}.`}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body1" align="center">
        <b>{t('steps.insuranceSeniorityStep.seeYou')}</b>.
      </Typography>
    </Grid>
  </Grid>
);

const moreThan12Month = (data, t) => (
  <Grid container justifyContent="center" spacing={1}>
    <Grid item xs={12}>
      <Typography variant="body1" align="center">
        <b>{`${t('steps.insuranceSeniorityStep.great.great')} ! `}</b>
        {t('steps.insuranceSeniorityStep.great.termination')}
      </Typography>
    </Grid>
    <Grid item xs={12}>
      <Typography variant="body1" align="center">
        {t('steps.insuranceSeniorityStep.newContract.newContract')}{' '}
        <b>{t('steps.insuranceSeniorityStep.newContract.40days')}</b>,{' '}
        {t('steps.insuranceSeniorityStep.newContract.ie')}{' '}
        <b>
          {moment(data.contractStartDate)
            .locale(i18next.resolvedLanguage)
            // .add('day', 40)
            .format('D MMMM yyyy')}
        </b>
        {'. '}
        {t('steps.insuranceSeniorityStep.newContract.hamon')}
      </Typography>
    </Grid>
  </Grid>
);

const getSelectionText = (value, data, t) => {
  if (value === null || value === undefined) return null;
  if (value) return lessThan12Month(t);
  if (value === false) return moreThan12Month(data, t);
};

export default getSelectionText;
