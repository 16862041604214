import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Icon from 'components/Icon';
import { formatNumber } from 'utils/index';
import useStyles from './style';

const AttackTax = (props) => {
  const { price, isAnnual } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  return (
    !!price.annualSurtaxe && (
      <div
        style={{
          display: 'flex',
          gap: '5px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="body1" classes={{ root: classes.dashboardSmall }}>
          <Trans
            i18nKey={
              isAnnual
                ? 'common.includingAttackTax'
                : 'common.includingAttackTaxMensual'
            }
            values={{
              attackTaxPrice: formatNumber(price.annualSurtaxe),
            }}
          />
        </Typography>
        <ClickAwayListener onClickAway={handleTooltipClose}>
          <div>
            <Tooltip
              arrow
              placement="top"
              PopperProps={{
                disablePortal: true,
              }}
              onClose={handleTooltipClose}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={t('common.attackTaxExplanations')}
            >
              <IconButton
                onClick={handleTooltipOpen}
                classes={{ root: classes.infoButton }}
              >
                <Icon
                  className="fas fa-info-circle"
                  classes={{ root: classes.infoIconDashboard }}
                />
              </IconButton>
            </Tooltip>
          </div>
        </ClickAwayListener>
      </div>
    )
  );
};
AttackTax.propTypes = {
  price: PropTypes.shape({
    annualTTC: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    monthlyTTC: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    annualTTCDeBase: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    monthlyTTCDeBase: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    annualSurtaxe: PropTypes.number,
  }).isRequired,
  isAnnual: PropTypes.bool,
};

export default AttackTax;
