import React, { useRef, useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import _get from 'lodash/get';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import InputsStep from 'components/InputsStep/InputsStep';
import Title from 'components/Title';
import useAuth from 'hooks/useAuth';
import customerApi from 'api/customerApi';
import drsApi from 'api/drsApi';
import { MrhContext } from 'contexts/mrh';
import getMrhSubscriptionData from 'views/MrhSubscription/Steps/PaymentPreloader/getMrhSubscriptionData';
import {
  isEmpty,
  isEmail,
  isPhoneNumber,
  isFrenchPhoneNumber,
} from 'utils/form-validation';

import Loading from 'components/Loadding/Loading';
import EmailPopin from './emailPopin';
import PAGES_IDS from './emailPopin/pagesIds.json';
import useStyles from './style';
import {LANGUAGES} from "constants";
import ErrorPopin from './errorPopin/index';

const CompleteUserInfoStep = ({
  data,
  onConfirm,
  stepsContext,
  setUserData,
  subscriptionSource,
}) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [customerState, setCustomerState] = useState(null);
  const [emailPopinInfos, setEmailPopinInfos] = useState({
    open: false,
    defaultPage: PAGES_IDS.emailPage,
  });

  const [error, setError] = useState(null);

  const { parcoursId } = useContext(MrhContext);

  const isInitialMount = useRef(true);
  const isSecondMount = useRef(false);
  const auth = useAuth();

  const classes = useStyles();

  const handleValidate = async () => {
    const canContinue = await getCustomerState(auth.isAuthenticated);
    if (canContinue) {
      try {
        const body = getMrhSubscriptionData(data, parcoursId);
        console.log('body', body);
        const response = await customerApi.createProspect(
          body.user,
          subscriptionSource
        );
        setUserData('customerNumber', response.customerNumber);

        onConfirm();
      } catch (error) {
        console.log('error', error);
        setError(error);
      }
    }
  };

  const getCountries = async () => {
    const countriesList = await drsApi.list(auth, 'PAYS_FINSCAN');
    if (countriesList.length) {
      setCountries(countriesList);
    }
    setLoading(false);
  };

  const getCustomerState = async (isAuthenticated) => {
    const response = await customerApi.getCustomerState(data.contact.mail);
    setCustomerState(response.status);

    if (!isAuthenticated) {
      return forceUserToConnect(response.status);
    }
    return true;
  };

  const forceUserToConnect = (customerStatus) => {
    if (customerStatus === 'P' || customerStatus === 'C') {
      setEmailPopinInfos({ open: true, defaultPage: PAGES_IDS.connexionPage });
      return false;
    } else {
      setEmailPopinInfos({ open: false });
      return true;
    }
  };

  useEffect(() => {
    getCountries();
  }, []);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      isSecondMount.current = true;
    } else if (isSecondMount.current) {
      getCustomerState(auth.isAuthenticated);
      isSecondMount.current = false;
    }
  }, [auth]);

  return !loading ? (
    <>
      <Title
        title={
          auth.isAuthenticated
            ? t('steps.completeUserInfoStep.titleIfLoggedIn')
            : t('steps.completeUserInfoStep.title')
        }
      />

      <InputsStep
        fields={[
          {
            id: 'contact.gender',
            type: 'radio',
            choices: [
              {
                label: t('common.formFields.mr'),
                value: 'M.',
              },
              {
                label: t('common.formFields.ms'),
                value: 'MME',
              },
            ],
            direction: 'horizontal',
            invalid:
              isEmpty(data.contact.gender) ||
              (data.contact.gender !== 'M.' && data.contact.gender !== 'MME'),
            helperText: (() => {
              if (
                isEmpty(data.contact.gender) ||
                (data.contact.gender !== 'M.' && data.contact.gender !== 'MME')
              ) {
                return t('error.fields.fieldRequired');
              }
            })(),
            value: data.contact.gender || '',
          },
          {
            id: 'contact.lastName',
            label: t('common.formFields.lastname'),
            type: 'text',
            invalid: isEmpty(data.contact.lastName),
            helperText: (() => {
              if (isEmpty(data.contact.lastName)) {
                return t('error.fields.fieldRequired');
              }
            })(),
            value: data.contact.lastName || '',
          },
          {
            id: 'contact.firstName',
            label: t('common.formFields.firstname'),
            type: 'text',
            invalid: isEmpty(data.contact.firstName),
            helperText: (() => {
              if (isEmpty(data.contact.firstName)) {
                return t('error.fields.fieldRequired');
              }
            })(),
            value: data.contact.firstName || '',
          },
          {
            id: 'contact.mail',
            label: t('common.formFields.email'),
            type: 'text',
            invalid: isEmpty(data.contact.mail) || !isEmail(data.contact.mail),
            disabled: true, //customerState === 'P' || customerState === 'C',
            helperText: (() => {
              if (isEmpty(data.contact.mail)) {
                return t('error.fields.fieldRequired');
              }

              if (!isEmail(data.contact.mail)) {
                return t('error.fields.invalidEmailAddress');
              }
            })(),
            value: data.contact.mail || '',
            forceLowerCase: true,
            showExtraButton: () => (
              <Button
                className={classes.updateButton}
                onClick={(e) => {
                  setEmailPopinInfos({
                    open: true,
                    defaultPage: PAGES_IDS.emailPage,
                    update: true,
                  });
                  e.stopPropagation();
                  e.preventDefault();
                }}
              >
                <Typography
                  classes={{ root: classes.updateButtonText }}
                  variant="caption"
                >
                  {t('steps.completeUserInfoStep.updateEmail')}
                </Typography>
              </Button>
            ),
          },
          {
            id: 'contact.phone',
            label: t('common.formFields.cellPhone'),
            type: 'phone',
            invalid:
              isEmpty(_get(data, 'contact.phone.value', '')) ||
              !data.contact.phone ||
              !isPhoneNumber(data.contact.phone),
            helperText: (() => {
              if (
                isEmpty(_get(data, 'contact.phone.value', '')) ||
                !data.contact.phone
              ) {
                return t('error.fields.fieldRequired');
              }

              if (data.contact.phone && !isPhoneNumber(data.contact.phone)) {
                if (isFrenchPhoneNumber(data.contact.phone)) {
                  return t('error.fields.invalidFrenchPhone');
                } else return t('error.fields.invalidPhone');
              }
            })(),
            value: _get(data, 'contact.phone.value', null),
          },
          {
            id: 'contact.birthdate',
            label: t('common.formFields.birthDate'),
            type: 'date',
            variant: 'birthdate',
            initialFocusedDate: moment().subtract(25, 'years'),
            maxDate: moment().subtract(18, 'years'),
            minDate: moment().subtract(120, 'years'),
            invalid:
              isEmpty(data.contact.birthdate) ||
              !moment(data.contact.birthdate).isValid() ||
              moment()
                .utc()
                .subtract(18, 'years')
                .diff(moment(data.contact.birthdate).utc(), 'days') < 0 ||
              moment()
                .utc()
                .subtract(120, 'years')
                .diff(moment(data.contact.birthdate).utc(), 'days') > 0,
            helperText: (() => {
              if (isEmpty(data.contact.birthdate)) {
                return t('error.fields.fieldRequired');
              }
              if (!moment(data.contact.birthdate).isValid()) {
                return t('error.fields.invalidDate');
              }
              if (
                moment()
                  .utc()
                  .subtract(18, 'years')
                  .diff(moment(data.contact.birthdate).utc(), 'days') < 0
              ) {
                return t('error.fields.tooYoung');
              }
              if (
                moment()
                  .utc()
                  .subtract(120, 'years')
                  .diff(moment(data.contact.birthdate).utc(), 'days') > 0
              )
                return t('error.fields.tooOld');
            })(),
            value: data.contact.birthdate,
            defaultValue: null,
          },
          {
            id: 'contact.birthCity',
            label: t('common.formFields.birthCity'),
            type: 'text',
            inputProps: {
              maxLength: 50,
            },
            invalid: isEmpty(data.contact.birthCity),
            helperText: (() => {
              if (isEmpty(data.contact.birthCity)) {
                return t('error.fields.fieldRequired');
              }
              if (data.contact.birthCity.length > 50) {
                return 'Votre ville de naissance ne peut excéder 50 caractères.';
              }
            })(),
            value: data.contact.birthCity,
            defaultValue: null,
          },
          {
            style: { minWidth: '100.5%' },
            id: 'contact.countryBirth',
            label: t('common.formFields.birthCountry'),
            type: 'autocomplete',
            options: (i18next.resolvedLanguage === LANGUAGES.en) ? countries.sort((a, b) => a.labelEn.localeCompare(b.labelEn)) : countries.sort((a, b) => a.labelFr.localeCompare(b.labelFr)),
            getOptionLabel: (option) => {
              return typeof option === 'object'? ((i18next.resolvedLanguage === LANGUAGES.en) ? option.labelEn : option.labelFr) : option;
            },
            invalid: !data.contact.countryBirth,
            helperText: (() => {
              if (isEmpty(data.contact.countryBirth)) {
                return t('error.fields.fieldRequired');
              }
              if (data.contact.countryBirth) {
                return 'Votre pays de naissance ne peut excéder 50 caractères.';
              }
            })(),
            getOptionSelected: (option, value) => {
              return (i18next.resolvedLanguage === LANGUAGES.en) ? option.labelEn === value.labelEn : option.labelFr === value.labelFr;
            },
            value: data.contact.countryBirth,
          },
        ]}
        onChange={setUserData}
        onConfirm={handleValidate}
      />
      <EmailPopin
        open={emailPopinInfos.open}
        defaultPage={emailPopinInfos.defaultPage}
        update={emailPopinInfos.update}
        customerState={customerState}
        data={data}
        stepsContext={stepsContext}
        onClose={() => setEmailPopinInfos({ open: false })}
      />
      {error && (
        <ErrorPopin
          error={error}
          handleValidate={handleValidate}
          firstName={data.contact.firstName}
        />
      )}
    </>
  ) : (
    <Loading />
  );
};

CompleteUserInfoStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
  stepsContext: PropTypes.shape().isRequired,
  subscriptionSource: PropTypes.string.isRequired,
};

export default CompleteUserInfoStep;
