import React, { useEffect, useState, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { UserContext } from 'contexts/user';
import policyApi from 'api/policyApi';
import getColumns from './columns';
import DataGrid from 'components/DataGrid';

import useStyles from './style';
import { getMessageError } from 'constants/common/errorCode';

const CONTRACT_TYPES = ['DMI', 'DMP', 'DDO', 'DPJ'];
const contractTypes = CONTRACT_TYPES.join(',');
const PAYMENT_MODES = {
  prelevement: 'PRLV',
  virement: 'VIRE',
};

const Contracts = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { t } = useTranslation();
  const [user] = useContext(UserContext);

  const nextMonth = moment().utc(true).add(1, 'month').format('YYYY-MM');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedGroupment, setSelectedGroupment] = useState(nextMonth);
  const [showPlaceholder, setShowPlaceholder] = useState(
    selectedGroupment === 'none'
  );

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };

  const handlePageSizeChange = (pageSize) => {
    setRowsPerPage(pageSize);
    setPage(0);
  };

  const getContracts = async (groupment) => {
    try {
      setLoading(true);

      const response = await policyApi.getPoliciesByOfficeCode(
        user.auth,
        user.societeRattachement,
        contractTypes,
        groupment && groupment !== 'none' ? groupment : nextMonth
      );

      if (Array.isArray(response)) setRows(response);
    } catch (err) {
      history.push('/forbiddenAccess');
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const onChangePaymentMode = async (row) => {
    const newPaymentMode =
      row.quittanceDtoList[0].paymentMethod.code === PAYMENT_MODES.prelevement
        ? PAYMENT_MODES.virement
        : PAYMENT_MODES.prelevement;

    try {
      policyApi.changePaymentMode(
        user.auth,
        user.societeRattachement,
        row.policyNumber,
        newPaymentMode,
        nextMonth
      );
    } catch (err) {
      handleError(err);
    } finally {
      getContracts();
    }
  };

  const handleError = (error) => {
    console.error('Error', error);
    const messageError = getMessageError(error);
    enqueueSnackbar(t(messageError), { variant: 'error' });
  }

  const getGroupmentDates = () => {
    const dates = [];
    const now = moment().utc(true);
    // const momentNextMonth = now.clone().add(1, 'month');
    // const nextMonthObject = {
    //   value: momentDate.format('YYYY-MM'),
    //   label: moment(momentDate).locale('fr').format('MMMM YYYY'),
    // }

    for (let i = -1; i <= 12; i++) {
      const momentDate = now.clone().add(-i, 'month');
      const newDates = {
        value: momentDate.format('YYYY-MM'),
        label: moment(momentDate).locale('fr').format('MMMM YYYY'),
      };

      dates.push(newDates);
    }

    return dates;
  };

  const handleSearch = () => {
    getContracts(selectedGroupment);
  };

  useEffect(() => {
    getContracts();
  }, []);

  return (
    <Grid container>
      <Grid
        container
        item
        xs={12}
        className={classes.title}
        justify="space-between"
        alignItems="center"
      >
        <Box>
          <Typography variant="h3">
            {t('dashboard.menu.contractsPerMonth')}
          </Typography>
        </Box>
        <Box />
      </Grid>
      <Grid container spacing={3}>
        {/* <Grid item xs={12}>
          <Search
            filters={filters}
            onFilterChange={handleFilterChange}
            onSearchClick={handleSearch}
          />
        </Grid> */}
        <Grid item xs={12}>
          <Paper classes={{ root: classes.paper }}>
            <Select
              variant="outlined"
              onChange={(e) => setSelectedGroupment(e.target.value)}
              onFocus={() => setShowPlaceholder(false)}
              onClose={(e) => setShowPlaceholder(e.target.value === undefined)}
              value={selectedGroupment}
            >
              <MenuItem
                disabled={!showPlaceholder}
                value="none"
                className={!showPlaceholder ? classes.menuItemHiden : null}
              >
                Sélectionnez un groupement mensuel
              </MenuItem>
              {getGroupmentDates().map((date) => {
                return (
                  <MenuItem key={date.value} value={date.value}>
                    {date.label}
                  </MenuItem>
                );
              })}
            </Select>
            <Button
              classes={{ root: classes.searchButton }}
              variant="contained"
              color="secondary"
              endIcon={<SearchIcon />}
              onClick={handleSearch}
            >
              Rechercher
            </Button>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          {/* <Table
            columns={getColumns(onChangePaymentMode)}
            rows={rows}
            page={page}
            rowsPerPage={rowsPerPage}
            rowId={'policyNumber'}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            total={total}
            loading={loading}
          /> */}
          <div style={{ height: '80vh', width: '100%' }}>
            <DataGrid
              rows={rows}
              columns={getColumns(onChangePaymentMode)}
              getRowId={(row) => row.policyNumber}
              loading={loading}
              page={page}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
              pageSize={rowsPerPage}
            />
          </div>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Contracts;
