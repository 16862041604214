import React, {
  useEffect,
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useOktaAuth } from '@okta/okta-react';

import Login from 'components/Login';
import authApi from 'api/authApi';
import { LOGIN_STEPS } from 'constants';
import { MrhContext } from 'contexts/mrh';
import { stepsIds } from 'constants/steps/steps';

// eslint-disable-next-line react/display-name
const LoginView = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { authState, oktaAuth } = useOktaAuth();
  const [sessionToken, setSessionToken] = useState();
  const [currentStep, setCurrentStep] = useState(LOGIN_STEPS.LOGIN);
  const [userInputs, setUserInputs] = useState({});
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const stepsContext = useContext(MrhContext);

  const {
    redirectTo,
    hideButton,
    onLoginStepChange,
    goToNextStep,
    currentSubscriptionStep,
  } = props;

  const handleChange = (event) => {
    const { id, value } = event.target;
    setUserInputs({ ...userInputs, [id]: value });
  };

  const handleLoginStepChange = (step) => {
    setCurrentStep(step);
    if (onLoginStepChange) onLoginStepChange(step);
  };

  const validateLogin = async () => {
    try {
      setLoading(true);

      const response = await authApi.verifyUser(userInputs.login);

      if (response.ok) {
        const jsonResponse = await response.json();

        if (jsonResponse.activationNeeded) {
          enqueueSnackbar(t('error.authentication.accountNotActivated'), {
            variant: 'warning',
            persist: false,
          });
          return;
        }
        setError(null);
        handleLoginStepChange(LOGIN_STEPS.PASSWORD);
      } else {
        const jsonResponse = await response.json();

        if (jsonResponse.activationNeeded) {
          enqueueSnackbar(t('error.authentication.accountNotActivated'), {
            variant: 'warning',
            persist: false,
          });
          return;
        }
        throw new Error();
      }
    } catch (error) {
      console.log('error', error);
      setError(t('error.unknown'));
    } finally {
      setLoading(false);
    }
  };

  const handleLogIn = () => {
    setLoading(true);

    if (currentSubscriptionStep === stepsIds.RESULT && stepsContext) {
      const [, dispatch] = stepsContext;

      dispatch({
        type: 'SET_ENTRY',
        payload: {
          key: 'redirectToStep',
          value: stepsIds.COMPLETE_USER_INFO,
        },
      });
    }

    oktaAuth
      .signInWithCredentials({
        username: userInputs.login,
        password: userInputs.password,
      })
      .then((res) => {
        const { sessionToken } = res;

        setSessionToken(sessionToken);

        const redirectOptions = {
          sessionToken,
        };

        if (goToNextStep) {
          goToNextStep();
        }

        setCurrentStep(LOGIN_STEPS.LOGIN);

        if (redirectTo) {
          redirectOptions.originalUri = redirectTo;
        }

        setTimeout(() => {
          oktaAuth.signInWithRedirect(redirectOptions);
        }, 2000);
      })
      .catch(() => {
        setError(t('error.authentication.authenticationFailure'));
        setLoading(false);
      });
  };

  useImperativeHandle(ref, () => ({
    handleClickNext,
    handleGoPrevious,
  }));

  const handleClickNext = (event) => {
    if (event) event.preventDefault();
    if (currentStep === LOGIN_STEPS.LOGIN) validateLogin();
    if (currentStep === LOGIN_STEPS.PASSWORD) handleLogIn();
    if (currentStep === LOGIN_STEPS.RESET_PASSWORD) resetPassword();
  };

  const handleGoPrevious = (event) => {
    if (event) event.preventDefault();
    if (currentStep === LOGIN_STEPS.RESET_PASSWORD)
      handleLoginStepChange(LOGIN_STEPS.PASSWORD);
  };

  const isButtonDisabled = () => {
    const loginIsEmpty =
      currentStep === LOGIN_STEPS.LOGIN && !userInputs[LOGIN_STEPS.LOGIN];
    const passwordIsEmpty =
      currentStep === LOGIN_STEPS.PASSWORD && !userInputs[LOGIN_STEPS.PASSWORD];

    if (loginIsEmpty || passwordIsEmpty) return true;

    return false;
  };

  const resetPassword = async () => {
    try {
      setLoading(true);
      const response = await authApi.sendRecoveryLinkByMail(
        userInputs[LOGIN_STEPS.LOGIN]
      );

      if (!response.ok) {
        throw new Error();
      }

      enqueueSnackbar(t('success.authentication.changePasswordMailSend'), {
        variant: 'success',
        persist: false,
      });
    } catch (error) {
      setError(t('error.unknown'));
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hideButton) onLoginStepChange(currentStep);
  }, [currentStep]);

  return (
    <Login
      handleChange={handleChange}
      handleClickNext={handleClickNext}
      sessionToken={sessionToken}
      resetPassword={resetPassword}
      authState={authState}
      error={error}
      loading={loading}
      steps={LOGIN_STEPS}
      currentStep={currentStep}
      isButtonDisabled={isButtonDisabled}
      redirectTo={redirectTo}
      hideButton={hideButton}
      setCurrentStep={handleLoginStepChange}
    />
  );
});

LoginView.propTypes = {
  redirectTo: PropTypes.string,
  goToNextStep: PropTypes.func,
  hideButton: PropTypes.bool,
  onLoginStepChange: PropTypes.func,
  currentSubscriptionStep: PropTypes.string,
};

export default LoginView;
