import React, { useState } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { SecureRoute } from '@okta/okta-react';
import PhoneIcon from '@material-ui/icons/PhoneInTalk';
import Fab from '@material-ui/core/Fab';

import DashboardLayout from 'layouts/Dashboard';
import PolicyPage from './PolicyPage';
import ListePolicePage from './PoliciesPage';
import { SinistreDeclarationPage } from 'views/Dashboard/Customer/SinistreDeclarationPage';
import PasswordPage from './PasswordPage';
import CertificatesPage from './CertificatesPage/CertificatesPage';
import CertificatePage from './CertificatePage/CertificatePage';
import AccountPage from './AccountPage';
import Card from './Card';
import ResilHamon from './ResilHamon/index';
import ContactPopin from './ContactPopin';
import useStyles from './style';
import ReviewsPopin from './ReviewsPopin';
import PaymentForDashboard from 'views/PnoSouscription/Steps/PaymentStep/paymentForDashboard';

const items = [
  {
    id: 'policies',
    link: '/polices',
    label: 'dashboard.menu.myContracts',
    icon: 'fas fa-folder',
  },
  // {
  //   id: 'certificates',
  //   link: '/attestations',
  //   label: 'dashboard.menu.myCertificates',
  //   icon: 'fas fa-file',
  // },
  {
    id: 'account',

    label: 'dashboard.menu.account',
    icon: 'fas fa-user-cog',
    subItems: [
      {
        id: 'infos',
        link: '/compte/informations-personnelles',
        label: 'dashboard.menu.personalInformations',
        icon: 'far fa-address-card',
      },
      {
        id: 'password',
        link: '/compte/mot-de-passe',
        label: 'dashboard.menu.password',
        icon: 'fas fa-key',
      },
      {
        id: 'paymentMethods',
        link: '/compte/moyens-de-paiements',
        label: 'dashboard.menu.paymentMethods',
        icon: 'far fa-credit-card',
      },
    ],
  },
];

const Customer = () => {
  const classes = useStyles();
  const [contactPopinOpen, setContactPopinOpen] = useState(false);

  const handleOpenContactPopin = () => {
    setContactPopinOpen(true);
  };

  const handleCloseContactPopin = () => {
    setContactPopinOpen(false);
  };

  return (
    <>
      <DashboardLayout items={items}>
        <Switch>
          <SecureRoute
            exact
            path="/polices"
            render={() => <ListePolicePage />}
          />
          <SecureRoute
            exact
            path="/polices/:id/paiement/:premiumUuid"
            component={PaymentForDashboard}
          />
          <SecureRoute exact path="/polices/:id" component={PolicyPage} />
          {/* <SecureRoute
            exact
            path="/attestations"
            component={CertificatesPage}
          /> */}
          <SecureRoute exact path="/attestation" component={CertificatePage} />

          <SecureRoute
            exact
            path="/polices/:id/nouveau-sinistre"
            component={SinistreDeclarationPage}
          />

          <SecureRoute
            exact
            path="/compte/informations-personnelles"
            component={AccountPage}
          />
          <SecureRoute
            exact
            path="/compte/mot-de-passe"
            component={PasswordPage}
          />
          <SecureRoute path="/compte/moyens-de-paiements" component={Card} />
          <SecureRoute
            exact
            path="/polices/:id/resil-hamon"
            component={ResilHamon}
          />
          <SecureRoute
            exact
            path="/compte"
            component={AccountPage}
            render={() => <Redirect to="/compte/mot-de-passe" />}
          />
          <SecureRoute path="/*" render={() => <Redirect to="/polices" />} />
        </Switch>
      </DashboardLayout>

      <ContactPopin open={contactPopinOpen} onClose={handleCloseContactPopin} />
      <Fab
        color="primary"
        aria-label="add"
        classes={{ root: classes.contactActionButton }}
        onClick={handleOpenContactPopin}
      >
        <PhoneIcon />
      </Fab>
      <ReviewsPopin />
    </>
  );
};

export default Customer;
