import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lottie from 'react-lottie';

const LottieControl = (props) => {
  const [isStopped, setIsStopped] = useState(false);
  const [isPaused, setIsPaused] = useState(false);

  const { loop, autoplay, animationData, height, width } = props;

  const options = {
    loop,
    autoplay,
    animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Lottie
      options={options}
      height={height}
      width={width}
      isStopped={isStopped}
      isPaused={isPaused}
    />
  );
};

LottieControl.propTypes = {
  height: PropTypes.number,
  width: PropTypes.number,
  loop: PropTypes.bool,
  autoplay: PropTypes.bool,
  animationData: PropTypes.any.isRequired,
};

LottieControl.defaultProps = {
  height: 400,
  width: 400,
  loop: true,
  autoplay: true,
};

export default LottieControl;
