import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  valueWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  searchButtonWrapper: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  searchButton: {
    height: '100%',
    border: `4px double ${theme.palette.white.main}`,
  },
  menuItemHidden: {
    display: 'none',
  },
  tabsWrapper: {
    display: 'flex',
    padding: 20,
  },
  tabPanel: {
    width: '100%',
  },
  tabs: {
    overflow: 'inherit',
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tab: {
    backgroundColor: theme.palette.grey.light,
    color: theme.palette.white.main,
  },
  selectedTab: {
    backgroundColor: 'gray',
  },
}));

export default useStyles;
