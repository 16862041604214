import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useRouteMatch } from 'react-router';
import moment from 'moment';

import policyApi from 'api/policyApi';
import useStyles from './style';
import { getMessageError } from 'constants/common/errorCode';
import Header from 'components/DashboardHeader';
import Loader from 'components/Loader';
import BlocDateSurvenanceSin from './BlocDateSin';
import BlocPoliceInfo from './BlocPoliceInfo';
import BlocNatureSin from './BlocNatureSin';
import ClaimsiaPopin from './PopinClaimsia';
import SuiteDeclaration from './SuiteDeclaration';
import { isPoliceEligibleADeclaSin } from './helper';
import { SinistreExistantPopin } from './PopinSinistreExistant';
import { SinistreInfoCoordonnePopin } from './PopinSinistreInfo';
import { BoutonValider } from './BoutonValider';

const SinistreDeclarationPage = () => {
  const { authState } = useOktaAuth();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const [auth, setAuth] = useState(null);
  const [police, setPolice] = useState(null);
  const [natureSin, setNatureSin] = useState(null);
  const [reasonError, setReasonError] = useState(null);
  const [dateSurvenance, setDateSurvenance] = useState(
    moment().utc().format('YYYY/MM/DD')
  );
  const [dateSurvenanceError, setDateSurvenanceError] = useState(null);
  const [fromValide, setFromValidee] = useState(false);
  const [declaSinLoading, setDeclaSinLoading] = useState(false);
  const [showSuiteDeclaration, setShowSuiteDeclaration] = useState(false);
  const [showClaimsiaPopin, setShowClaimsiaPopin] = useState(false);
  const [lienPlatformClaims, setLienPlatformClaims] = useState(null);
  const [showPopinErreurCreation, setShowPopinErreurCreation] = useState(false);

  const {
    params: { id: policyNumber },
  } = useRouteMatch({
    path: '/polices/:id/nouveau-sinistre',
  });

  useEffect(() => {
    (async () => {
      const {
        isAuthenticated,
        accessToken: { accessToken: token },
      } = authState;
      const auth = { isAuthenticated, token };
      setAuth(auth);
      try {
        const res = await policyApi.getPolice(auth, policyNumber);
        setPolice(res);
      } catch (error) {
        const messageError = getMessageError(error);
        console.error(t(messageError), error);
        enqueueSnackbar(t(messageError), { variant: 'error' });
      }
    })();
  }, [authState, policyNumber]);

  const handleValidate = async () => {
    setFromValidee(true);

    if (!reasonError && !dateSurvenanceError) {
      try {
        setDeclaSinLoading(true);
        const response =
          await policyApi.verifiePossibiliteCreationEtEligibliteClaims(
            auth,
            policyNumber,
            {
              claimsDate: moment(dateSurvenance).format(
                'YYYY-MM-DD[T]HH:mm:ss[Z]'
              ),
              claimsNature: natureSin,
            }
          );

        if (response.eligibleClaimform) {
          setLienPlatformClaims(response.claimformLink);
          setShowClaimsiaPopin(true);
        } else if (!response.creationPossible) {
          setShowPopinErreurCreation(true);
        } else {
          setShowSuiteDeclaration(true);
        }
      } catch (error) {
        const messageError = getMessageError(error);
        console.error(t(messageError), error);
        enqueueSnackbar(t(messageError), { variant: 'error' });
      } finally {
        setDeclaSinLoading(false);
      }
    }
  };

  const griserBoutonDeclaration =
    !isPoliceEligibleADeclaSin(police) ||
    declaSinLoading ||
    (fromValide && dateSurvenanceError) ||
    (fromValide && reasonError);

  if (showSuiteDeclaration) {
    return (
      <SuiteDeclaration
        date={dateSurvenance}
        reason={natureSin}
        police={police}
      />
    );
  }

  return (
    <Box mb={10}>
      <Header
        title={t('dashboard.sinistre.form.header.title')}
        subtitle={''}
        mainAction={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.goBack()}
          >
            {t('dashboard.sinistre.form.header.btn_close')}
          </Button>
        }
      />

      {!police ? (
        <Loader />
      ) : (
        <Box className={classes.policyContainer}>
          <Grid container>
            <Box
              className={classes.container}
              width={1}
              display="flex"
              justifyContent="center"
            >
              <Grid item lg={8}>
                <BlocPoliceInfo
                  police={police}
                  showPoliceDetailsButton={false}
                />
                <BlocNatureSin
                  natureSin={natureSin}
                  setNatureSin={setNatureSin}
                  natureSinError={fromValide ? reasonError : null}
                  setNatureSinError={setReasonError}
                />
                <BlocDateSurvenanceSin
                  date={dateSurvenance}
                  setDate={setDateSurvenance}
                  dateError={fromValide ? dateSurvenanceError : null}
                  setDateError={setDateSurvenanceError}
                  police={police}
                />
              </Grid>
            </Box>
          </Grid>
          <BoutonValider
            onValidate={handleValidate}
            isDisabled={griserBoutonDeclaration}
            isLoading={declaSinLoading}
          />
        </Box>
      )}

      <SinistreInfoCoordonnePopin />

      <SinistreExistantPopin
        numeroPolice={policyNumber}
        showPopinErreurCreation={showPopinErreurCreation}
        onClose={() => setShowPopinErreurCreation(false)}
      />

      <ClaimsiaPopin
        police={police}
        showClaimsiaPopin={showClaimsiaPopin}
        onClose={() => setShowClaimsiaPopin(false)}
        natureSin={natureSin}
        dateSurvenance={dateSurvenance}
        lienPlatformClaims={lienPlatformClaims}
      />
    </Box>
  );
};

export { SinistreDeclarationPage };
