import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import Skeleton from '@material-ui/lab/Skeleton';
import Lottie from 'components/Lottie';
import HouseLottie from 'assets/lotties/house.json';

import useStyles from './style';

const MyCriteria = () => {
  const classes = useStyles();

  return (
    <Box mt={2} p={2} width="100%" className={classes.greyBox}>
      <Grid container>
        <Grid item xs={12}>
          <Box mb={4}>
            <Skeleton variant="rect" width={197} height={32} />
          </Box>
          <Typography>
            <Skeleton variant="rect" width={197} height={32} />
          </Typography>
          <Typography>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Typography>
        </Grid>
        {/* <Grid item md={2} xs={0}>
          <Hidden mdDown>
            <Lottie animationData={HouseLottie} isActive width={150} />
          </Hidden>
        </Grid> */}
      </Grid>
    </Box>
  );
};

export default MyCriteria;
