import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardContent, Hidden, Typography } from '@material-ui/core';

import Lottie from 'components/Lottie';
import BrokenGlassLottie from 'assets/lotties/claims/brokenGlass.json';
import EvenementClimatiqueLottie from 'assets/lotties/claims/climate.json';
import FireLottie from 'assets/lotties/claims/fire.json';
import OtherLottie from 'assets/lotties/claims/other.json';
import VandalismLottie from 'assets/lotties/claims/vandalism.json';
import WaterLottie from 'assets/lotties/claims/water.json';
import {
  SINISTRE_NATURE_CODE_AUTRE,
  SINISTRE_NATURE_CODE_BRIS_DE_GLACE,
  SINISTRE_NATURE_CODE_DEGAT_DES_EAUX,
  SINISTRE_NATURE_CODE_INCENDIE,
  SINISTRE_NATURE_CODE_VANDALISME,
  SINISTRE_NATURE_CODE_VOL,
} from 'constants/common/sinistreNatureCode';
import { formatDateWithSystem } from 'utils';
import { CLAIMS_STATUS_CODE } from 'constants/common/sinistreStatusCode';
import { useStyles } from './style';

SinistreItem.propTypes = {
  sinistre: PropTypes.object,
};

function SinistreItem({ sinistre }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    claimsNumber,
    eventDate,
    status: { code: statusCode },
    claimformLink,
  } = sinistre;
  const { asset, label } = sinistreData[sinistre.nature.code] || {
    ...sinistreData[SINISTRE_NATURE_CODE_AUTRE],
    label: sinistre.nature.code,
  };

  const { CLOSS, CLOSTER, EC, PREDECLA, PARCOURS } = CLAIMS_STATUS_CODE;

  return (
    <Box mb={2} width={1}>
      <Card className={classes.rootCard}>
        <CardContent className={classes.content}>
          <Box
            className={classes.details}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              className={classes.contract}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Box ml={2} mr={4} display="flex">
                <Hidden smDown>
                  <Lottie
                    animationData={asset}
                    isActive
                    width={116}
                    height={116}
                  />
                </Hidden>
                <Hidden mdUp>
                  <Lottie
                    animationData={asset}
                    isActive
                    width={80}
                    height={80}
                  />
                </Hidden>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                height={1}
              >
                <Box>
                  <Typography variant="h6">{t(label)}</Typography>
                </Box>
                <Box
                  display="flex"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Box className={classes.info} mr={3}>
                    <Typography variant="caption">N°</Typography>
                    <Typography variant="body2">{claimsNumber}</Typography>
                  </Box>
                  <Box className={classes.info}>
                    <Typography variant="caption">
                      {t('dashboard.sinistre.list.date_decla')}
                    </Typography>
                    <Typography variant="body2">
                      {formatDateWithSystem(eventDate)}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box className={classes.statusCard}>
              <Box className={classes.cardContainer}>
                <Card className={classes.card}>
                  {[EC.code, PREDECLA.code].includes(statusCode) && (
                    <>
                      <div className={`${classes.cardHeader} inProgress`}>
                        <Typography
                          //variant="subtitle2"
                          className={classes.statusText}
                        >
                          {t('dashboard.sinistre.list.stat_ec')}
                        </Typography>
                      </div>
                      {/* <div className={classes.cardFooter}>
                      <Button align='center' variant='contained' color='primary' size='small' endIcon={<ArrowForwardIcon />} component={Link} to='/sinistres/1'>
                        Suivre
                      </Button>
                    </div> */}
                    </>
                  )}

                  {[CLOSS.code, CLOSTER.code].includes(statusCode) && (
                    <>
                      <div className={`${classes.cardHeader} close`}>
                        <Typography
                          //variant="subtitle2"
                          className={classes.statusText}
                        >
                          {t('dashboard.sinistre.list.stat_close')}
                        </Typography>
                      </div>
                      {/* {
                      cost && (
                        <div className={classes.cardFooter}>
                          <Box>
                            <Typography variant="subtitle-2">{`Règlement de ${cost}€`}</Typography>
                          </Box> */}
                      {/* <Box>
                        <Button align='center' variant='outlined' color='secondary' size='small' endIcon={<ArrowForwardIcon />} component={Link} to='/sinistres/1' className={classes.cardButton}>
                          Détails
                        </Button>
                      </Box> */}
                      {/* </div>
                      )} */}
                    </>
                  )}

                  {[PARCOURS.code].includes(statusCode) && (
                    <>
                      <div
                        className={`${classes.cardHeader} inProgress`}
                        onClick={() => window.open(claimformLink, '_self')}
                      >
                        <Typography
                          variant="subtitle-2"
                          className={classes.statusText}
                        >
                          {t('dashboard.sinistre.list.stat_replay')}
                        </Typography>
                      </div>
                    </>
                  )}
                </Card>
              </Box>
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

const sinistreData = {
  [SINISTRE_NATURE_CODE_INCENDIE]: {
    asset: FireLottie,
    label: 'dashboard.sinistre.form.reason.incendie',
  },
  [SINISTRE_NATURE_CODE_DEGAT_DES_EAUX]: {
    asset: WaterLottie,
    label: 'dashboard.sinistre.form.reason.dde',
  },
  [SINISTRE_NATURE_CODE_VANDALISME]: {
    asset: VandalismLottie,
    label: 'dashboard.sinistre.form.reason.vandalisme',
  },
  [SINISTRE_NATURE_CODE_VOL]: {
    asset: EvenementClimatiqueLottie,
    label: 'dashboard.sinistre.form.reason.vol',
  },
  [SINISTRE_NATURE_CODE_BRIS_DE_GLACE]: {
    asset: BrokenGlassLottie,
    label: 'dashboard.sinistre.form.reason.bdg',
  },
  [SINISTRE_NATURE_CODE_AUTRE]: {
    asset: OtherLottie,
    label: 'dashboard.sinistre.form.reason.other',
  },
};

export default SinistreItem;
