import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Lottie from 'components/Lottie';
import ClockLottie from 'assets/lotties/clock.json';

const QuoteStepPreloader = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      className="EndStep"
      alignItems="center"
      justifyContent="center"
    >
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{
          marginTop: 32,
        }}
      >
        <div
          style={{
            maxWidth: 200,
          }}
        >
          <Lottie animationData={ClockLottie} isActive autoPlay />
        </div>
        <Grid container direction="column" alignItems="center">
          <Typography color="textPrimary" variant="h4" align="center">
            {t('steps.quoteStep.preloaderThanks')}
          </Typography>
        </Grid>
        <Grid container direction="column" alignItems="center">
          <Typography
            style={{
              marginTop: 32,
            }}
            color="textPrimary"
            variant="body1"
            align="center"
          >
            {t('steps.quoteStep.preloaderWIPpno')}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default QuoteStepPreloader;
