import React from 'react';
import PropTypes from 'prop-types';
import { Box, Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { addUnitTime } from 'utils/index';
import { PAGE_SECTION_SHOW } from 'views/Dashboard/Customer/PolicyPage/helper';
import { NB_DAYS_LIMIT_RAPPEL_HAMON } from 'views/Dashboard/Customer/ResilHamonPage';

AlertHamon.propTypes = {
  police: PropTypes.object.isRequired,
};

function AlertHamon({ police }) {
  const history = useHistory();
  const { t } = useTranslation();

  const policeNonConcerneeParResilHamon =
    !police?.informationPoliceHamon?.terminationTodo;

  const dateLimitRappel = addUnitTime(police.startDate, {
    nbDays: -1 * NB_DAYS_LIMIT_RAPPEL_HAMON,
  });

  const dateLimiteRappelDepassee =
    !dateLimitRappel || new Date().getTime() > dateLimitRappel.getTime();

  const documentsHamonDejaEnvoyes =
    police.informationPoliceHamon?.inssuranceName ||
    police.informationPoliceHamon?.ancienPolicyNumber;

  const resilFaiteParUnGestionnaire =
    police.informationPoliceHamon?.terminationDo;

  if (
    !PAGE_SECTION_SHOW[police.contractType.code].alertHamon ||
    policeNonConcerneeParResilHamon ||
    dateLimiteRappelDepassee ||
    resilFaiteParUnGestionnaire ||
    documentsHamonDejaEnvoyes
  ) {
    return <></>;
  }

  return (
    <Box>
      {documentsHamonDejaEnvoyes ? (
        <Alert severity={'success'}>
          {t('dashboard.resilHamon.alert.success.msg')}
        </Alert>
      ) : (
        <Alert
          severity={'warning'}
          action={
            <Button
              onClick={() =>
                history.push(`/polices/${police.policyNumber}/resil-hamon`)
              }
            >
              {t('dashboard.resilHamon.alert.success.btn')}
            </Button>
          }
        >
          {t('dashboard.resilHamon.alert.warning.msg')}
        </Alert>
      )}
    </Box>
  );
}

export default AlertHamon;
