import { useLocation } from 'react-router-dom';

const useQuery = (parameterName) => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  return {
    getParameter: () => urlParams.get(parameterName || null),
  };
};

export default useQuery;
