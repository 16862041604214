import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import AttackTax from './AttackTax';
import useStyles from './style';

const DashboardPrice = (props) => {
  const { price, periodicity } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const isAnnual = periodicity === 'A';

  return (
    <>
      {isAnnual && (
        <>
          <Typography variant="h6">
            {`${price.annualTTC} € / ${t('common.year')}`}
          </Typography>
          <AttackTax price={price} isAnnual={isAnnual} />
        </>
      )}
      {!isAnnual && (
        <>
          <Typography variant="h6">
            {`${price.monthlyTTC} € / ${t('common.month')}`}
          </Typography>
          <AttackTax price={price} isAnnual={isAnnual} />
          <Typography classes={{ root: classes.dashboardSmall }}>{` ${t(
            'common.namely'
          )} ${price.annualTTC} € / ${t('common.year')}`}</Typography>
        </>
      )}
    </>
  );
};

DashboardPrice.propTypes = {
  price: PropTypes.shape({
    annualTTC: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    monthlyTTC: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    annualTTCDeBase: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    monthlyTTCDeBase: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    annualSurtaxe: PropTypes.number,
  }).isRequired,
  isPromo: PropTypes.bool,
  periodicity: PropTypes.string.isRequired,
};

export default DashboardPrice;
