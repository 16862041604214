import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';

import Title from 'components/Title';
import Lottie from 'components/Lottie';
import Error from 'assets/lotties/error.json';

const DefaultErrorPage = () => {
  const { t } = useTranslation();

  return (
    <Box mt={6}>
      <Title title={t('error.subscription')} warning />
      <Lottie
        animationData={Error}
        isActive
        preventMouseLeaveEffectIfActive
        height={800}
      />
    </Box>
  );
};

export default DefaultErrorPage;
