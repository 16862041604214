import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import Lottie from 'components/Lottie';
import { RESIDENCE_TYPE_PRINCIPAL } from 'constants';
import { default as BienAssureInfoH } from 'views/MrhSubscription/Steps/QuoteStep/Details/BienAssureInfo';
import { default as BienAssureInfoDNO } from 'views/PnoSouscription/Steps/QuoteStep/Details/BienAssureInfo';
import useStyles from './style';
import { POLICY_PROPERTYS } from 'constants/policy';

const MyCriteria = (props) => {
  const { polichab, police } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  const COMMON_DATA = {
    ...polichab.result,
    housingType: [polichab.result.housingType],
    numberOfPrincipalRooms: polichab.result.numberOfPieces,
    equipements: Object.keys(polichab.result.equipment).filter(
      (equipment) =>
        polichab.result.equipment[equipment] === true ||
        polichab.result.equipment[equipment] === 'INF120'
    ),
  };

  return (
    <Box mt={2} p={2} width="100%" className={classes.greyBox}>
      <Grid container>
        <Grid item xs={12} md={isDownMd ? 12 : 10}>
          <Box mb={2}>
            <Typography variant="h6" color="secondary">
              {t('dashboard.police.details.myHome.title')}
            </Typography>
          </Box>

          {police.contractType.code === 'H' ? (
            <BienAssureInfoH
              showTitle={false}
              data={{
                ...COMMON_DATA,
                residenceType:
                  polichab.result.housingUsageType === RESIDENCE_TYPE_PRINCIPAL
                    ? t('common.mainResidence')
                    : t('common.secondaryResidence'),
              }}
            />
          ) : (
            <BienAssureInfoDNO showTitle={false} data={COMMON_DATA} />
          )}
        </Grid>
        <Hidden mdDown>
          <Grid item md={2} xs={0}>
            <Lottie
              animationData={
                POLICY_PROPERTYS[police.contractType.code][
                  COMMON_DATA.housingType
                ]?.avatar
              }
              isActive
              width={150}
            />
          </Grid>
        </Hidden>
      </Grid>
    </Box>
  );
};

MyCriteria.propTypes = {
  polichab: PropTypes.object,
  police: PropTypes.object,
};

export default MyCriteria;
