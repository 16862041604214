import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.white.main,
    zIndex: 10,
    '&:hover': {
      backgroundColor: theme.palette.white.main,
    },
  },
  title: {
    marginBottom: 15,
    textAlign: 'left',
  },
  subtitle: {
    fontSize: 22,
    textAlign: 'left',
    lineHeight: 1.25,
  },
  lightningSubtitle: {
    color: theme.palette.primary.main,
    fontSize: 22,
  },
}));

export default useStyles;
