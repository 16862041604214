import React, { useState, useContext } from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography';
import _get from 'lodash/get';

import { UserContext, USER_ACTION_TYPE } from 'contexts/user';
import { useTranslation } from 'react-i18next';
import Icon from 'components/Icon';
import useStyles from './style';
import Popin from 'components/Popin/index';
import PopinModificationTelephone from './PopinModificationTelephone';
import customerApi from 'api/customerApi';
import { useSnackbar } from 'notistack';
import { getMessageError } from 'constants/common/errorCode';

const Currentuser = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [showPopin, setShowPopin] = useState(false);
  const [user] = useContext(UserContext);
  const [ phoneNumber, setPhoneNumber ] = useState(null);
  const [ isValidateButtonDisabled, setValidateButtonDisabled ] = useState(true);
  const [userState, dispatch ] = useContext(UserContext);
  const [ loading, setLoading ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleClickEditPhoneNumber = () => {
    setShowPopin(true);
  }

  const handleClosePopin = () => {
    setShowPopin(false);
  };

  const handleUpdatePhoneNumber = (phoneNumber) => {
    setPhoneNumber(phoneNumber);
  }

  const handleFormValid = (isFormValid) => {
    setValidateButtonDisabled(!isFormValid);
  }

  const handleValidate = async () => {
    try {
      const params = { mobilePhone: phoneNumber.value, notifyClient: true, typeUpdate : "MAIL_MODIFY_PHONE_NUMBER" };
      await customerApi.updateClient(userState.auth, user.customerNumber, params);
      setShowPopin(false);
      dispatch({
        type: USER_ACTION_TYPE.MODIFY_USER_PHONE,
        payload: phoneNumber.value,
      });
    } catch (error) {
      console.log("erreur de mettre à jour le numéro de téléphone", error);
      const messageError = getMessageError(err);
      enqueueSnackbar(t(messageError), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  }

  return (<>
    <Box p={2} className={classes.container} width="100%">
      <Grid container item xs={12}>
        <Box mb={2}>
          <Typography variant="h5" color="secondary">
            {`${user.user.firstName} ${user.user.lastName}`}
          </Typography>
        </Box>
        <Grid item container>
          <Grid item container xs={12} md={9}>
            <Grid item xs={12} md={6}>
              <Box mb={2}>
                <Typography variant="caption" className={classes.infoLabel}>
                {t('dashboard.menu.myAccount.adresse')}
                </Typography>
                <Typography variant="body2">
                  {`${_get(user.address, 'address1', '') || ''} ${
                    _get(user.address, 'address2', '') || ''
                  }${_get(user.address, 'address3', '') || ''} ${_get(
                    user.address,
                    'zipCode',
                    ''
                  )} ${_get(user.address, 'city', '')} `}
                </Typography>
              </Box>
            </Grid>

            <Grid item xs={12} md={6}>
              <Typography variant="caption" className={classes.infoLabel}>
              {t('dashboard.menu.myAccount.tel')}
              </Typography>
              <Typography variant="body2">
                {user.contact.mobilePhone}
                <Icon
                  className="fas fa-pen"
                  color="secondary"
                  classes={{ root: classes.icon }}
                  onClick={handleClickEditPhoneNumber}
                />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="caption" className={classes.infoLabel}>
              {t('dashboard.menu.myAccount.mail')}
              </Typography>
              <Typography variant="body2">{user.login}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={3}>
            <Box display="flex" justifyContent="flex-end" width={1}>
              <Card className={classes.card}>
                <div className={classes.cardContent}>
                  <Typography
                    variant="subtitle2"
                    className={classes.clientNumberLabel}
                  >
                    {t('dashboard.menu.myAccount.cusNum')}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    className={classes.clientNumber}
                  >
                    {user.customerNumber}
                  </Typography>
                </div>
              </Card>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
    <Popin
      open={showPopin}
      onClose={handleClosePopin}
      title={t('dashboard.menu.myAccount.telModifyTitle')}
      content={<PopinModificationTelephone onChange={handleUpdatePhoneNumber} isFormValid={handleFormValid} />}
      setShowPopin={setShowPopin}
      validateButtonLabel={t('common.save')}
      cancelButtonLabel={t('common.cancel')}
      showCancelButton={true}
      maxWidth='sm'
      isValidateButtonDisabled={isValidateButtonDisabled}
      onValidate={handleValidate}
      validateLoading={loading}
    />
  </>
  );
};

export default Currentuser;
