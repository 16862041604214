export const HOUSING_TYPE_MAISON = 'M';
export const HOUSING_TYPE_APPARTEMENT = 'A';

export const PNO_TYPE_MAISON = 'M';
export const PNO_TYPE_APPARTEMENT = 'A';
export const PNO_TYPE_MONUMENT_HISTORIQUE = 'AMH';
export const PNO_TYPE_GARAGE = 'BG';
export const PNO_TYPE_LOCAL_COMMERCIAL = 'LC';

export const getHousingType = (data) => {
  return data.historical && data.housingType === PNO_TYPE_APPARTEMENT
    ? PNO_TYPE_MONUMENT_HISTORIQUE
    : data.housingType;
};
