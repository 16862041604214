import { makeStyles } from '@material-ui/core/styles';

const withStyles = makeStyles((theme) => ({
  item: {
    border: `1px solid ${theme.palette.grey.main}`,
    marginBottom: theme.spacing(2),
    width: '100%',
    position: 'relative',
  },
  roundedIcon: {
    borderRadius: '50%',
    background: theme.palette.grey.main,
  },
  button: {
    backgroundColor: theme.palette.greyBlue.main,
    [theme.breakpoints.down('md')]: {
      position: 'absolute',
      top: theme.spacing(2),
      right: theme.spacing(2),
    },
  },
  attestationContainer: {
    paddingTop: theme.spacing(1),
  },
  container: {
    borderRadius: 10,
    backgroundColor: '#fff',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
}));

export default withStyles;
