import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
// import Button from '@material-ui/core/Button';
// import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

const AutomaticForm = (props) => {
  const {
    title,
    availableOptions,
    onInputValueChange,
    onChange,
    // setManual,
    loading,
    value,
    minimumSearchLength,
  } = props;

  const { t } = useTranslation();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const getOptionSelected = (option, value) => option.name === value.name;
  // const filterOptions = () =>
  //   availableOptions.length === 0 ? [optionButton] : availableOptions;
  const getOptionLabel = (option) => {
    return option.properties && option.properties.label
      ? option.properties.label
      : option;
  };

  // const optionButton = (
  //   <Button
  //     fullWidth
  //     onClick={(e) => {
  //       setManual(true);
  //       setOpen(false);
  //       e.stopPropagation();
  //       e.preventDefault();
  //     }}
  //   >
  //     <Typography variant="body1">
  //       {t('steps.addressStep.manuallySetAddress')}
  //     </Typography>
  //   </Button>
  // );

  const renderInput = (params) => (
    <TextField
      {...params}
      label={title}
      variant="filled"
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <React.Fragment>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </React.Fragment>
        ),
      }}
    />
  );

  return (
    <Autocomplete
      fullWidth
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      // renderOption={getOptionLabel}
      options={availableOptions}
      // filterOptions={filterOptions}
      filterOptions={(options) => options}
      loadingText={t('common.formFields.dataLoading')}
      noOptionsText={`${t('common.formFields.noOptions')} - ${t(
        'common.formFields.noOptionsMinimumLength',
        {
          length: minimumSearchLength,
        }
      )}`}
      loading={loading}
      value={value}
      onInputChange={onInputValueChange}
      onChange={onChange}
      // PaperComponent={Link}
      renderInput={renderInput}
    />
  );
};

AutomaticForm.propTypes = {
  title: PropTypes.string.isRequired,
  availableOptions: PropTypes.array.isRequired,
  onInputValueChange: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  setManual: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  value: PropTypes.string,
  minimumSearchLength: PropTypes.number.isRequired,
};

export default AutomaticForm;
