import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import SearchIcon from '@material-ui/icons/Search';
import Field from 'components/Field';
import useStyles from './style';

const Search = (props) => {
  const classes = useStyles();
  const { filters, onSearchClick, onFilterChange } = props;

  const handleSearchClick = () => {
    onSearchClick();
  };

  const handleFilterChange = (event, filter) => {
    const { value } = event.target;

    onFilterChange({
      id: filter.id,
      operation: filter.operation,
      value,
    });
  };

  return (
    <Paper classes={{ root: classes.paper }}>
      <Grid container spacing={3}>
        {filters.map((filter) => (
          <Grid key={filter.id} item>
            <Field
              label={filter.label}
              type="text"
              onChange={(event) => handleFilterChange(event, filter)}
            />
          </Grid>
        ))}
        <Grid item classes={{ root: classes.searchButtonWrapper }}>
          <Button
            classes={{ root: classes.searchButton }}
            variant="contained"
            color="secondary"
            endIcon={<SearchIcon />}
            onClick={handleSearchClick}
          >
            Rechercher
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

Search.propTypes = {
  filters: PropTypes.array.isRequired,
  onSearchClick: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
};

export default Search;
