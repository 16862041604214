import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  cardContent: {
    minWidth: 300,
  },
  linksContainer: {
    marginTop: 20,
  },
  description: {
    textAlign: 'center',
  },
}));

export default useStyles;
