import _get from 'lodash/get';

import {
  POLICE_MOUVEMENT_RESEF,
  POLICE_MOUVEMENT_AN,
  POLICE_MOUVEMENT_ANNP,
  TYPE_POLICE_H,
  TYPE_POLICE_PNO,
} from 'constants/index';

const getFilteredPolicies = (policies) =>
  policies
    .filter((policy) => policy?.status?.code !== POLICE_MOUVEMENT_RESEF)
    .filter((policy) =>
      [TYPE_POLICE_H, TYPE_POLICE_PNO].includes(policy?.contractType?.code)
    )
    .sort((a, b) => {
      const statusA = a?.status?.code;
      const statusB = b?.status?.code;
      if (statusA === statusB) return 0;
      if (statusA === POLICE_MOUVEMENT_AN) return -1;
      if (statusB === POLICE_MOUVEMENT_AN) return 1;
      if (statusA === POLICE_MOUVEMENT_ANNP) return -1;
      if (statusB === POLICE_MOUVEMENT_ANNP) return 1;
      return 0;
    });

const getAdresseDepuisPoliceHab = (polichab, t) =>
  `${polichab?.result.adress.address1} ${
    polichab?.result.adress.address2
      ? _get(polichab?.result.adress, 'address2', '')
      : ''
  } ${
    polichab?.result.adress.address3
      ? _get(polichab?.result.adress, 'address3', '')
      : ''
  } ${
    polichab?.result.adress.residency
      ? `, ${t('common.residency')} ${_get(
          polichab?.result.adress,
          'residency',
          ''
        )}`
      : ''
  } ${
    polichab?.result.adress.building
      ? `, ${t('common.building')} ${_get(
          polichab?.result.adress,
          'building',
          ''
        )}`
      : ''
  }, ${polichab?.result.adress.zipCode} ${polichab?.result.adress.city}`.trim();

export { getAdresseDepuisPoliceHab, getFilteredPolicies };
