import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

const AuthErrorPopin = (props) => {
  const { firstName } = props;
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{
        marginTop: 15,
      }}
    >
      <Grid container direction="column">
        <Typography
          style={{
            marginLeft: 15,
          }}
        >
          {t('steps.paymentLoader.unknownError.hello')} {firstName},
        </Typography>
      </Grid>
      <Grid container direction="column">
        <Typography
          style={{
            marginTop: 15,
            marginLeft: 15,
          }}
        >
          {t('steps.paymentLoader.unknownError.body')}
          <a
            target="_blank"
            href="mailto:particuliers@assurimo.fr"
            rel="noreferrer"
          >
            {t('steps.paymentLoader.unknownError.emailParticulier')}.
          </a>
        </Typography>
        <Typography
          style={{
            marginTop: 15,
            marginLeft: 15,
          }}
        >
          {t('steps.paymentLoader.unknownError.thankYou')}
        </Typography>
        <Typography
          style={{
            marginTop: 15,
            marginLeft: 15,
          }}
        >
          {t('steps.paymentLoader.unknownError.sincerly')}
        </Typography>
        <Typography
          style={{
            marginTop: 15,
            marginLeft: 15,
            marginBottom: 15,
          }}
        >
          {t('steps.paymentLoader.unknownError.assurimoTeam')}
        </Typography>
      </Grid>
    </Grid>
  );
};

AuthErrorPopin.propTypes = {
  firstName: PropTypes.string.isRequired,
};

export default AuthErrorPopin;
