import React from 'react';
import PropTypes from 'prop-types';
import MuiTextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Info from '../Info';

const useStyles = makeStyles((theme) => ({
  inputField: {
    '& .MuiFilledInput-underline.Mui-error': {
      borderColor: '#f44336',
    },
  },
}));

const NumberField = (props) => {
  const { info, isError } = props;
  const classes = useStyles();

  return (
    <MuiTextField
      type="text"
      variant="filled"
      error={isError}
      className={classes.inputField}
      {...props}
      InputProps={{ endAdornment: info && <Info title={info} /> }}
    />
  );
};

NumberField.propTypes = {
  info: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default NumberField;
