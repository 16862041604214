import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import Slide from './Slide';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const DEFAULT_GO_TO_SLIDE_DELAY = 200;

function mod(a, b) {
  return ((a % b) + b) % b;
}

const Carousel = (props) => {
  const { slides, goToSlide, goToSlideDelay, animationConfig, offsetRadius } =
    props;

  const [index, setIndex] = useState(0);
  const [newSlide, setNewSlide] = useState(false);

  let goToIn;

  useEffect(() => {
    if (typeof goToSlide === 'number') {
      if (newSlide) {
        handleGoToSlide();
      } else if (index !== goToSlide && typeof window !== 'undefined') {
        window.clearTimeout(goToIn);
        goToIn = window.setTimeout(handleGoToSlide, goToSlideDelay);
      } else if (typeof window !== 'undefined') {
        window.clearTimeout(goToIn);
      }
    }
  }, [goToSlide, index]);

  const modBySlidesLength = (index) => {
    return mod(index, slides.length);
  };

  const moveSlide = (direction) => {
    const newIndex = modBySlidesLength(index + direction);
    setIndex(newIndex);
  };

  const getShortestDirection = (from, to) => {
    if (from > to) {
      if (from - to > slides.length - 1 - from + to) {
        return 1;
      } else return -1;
    } else if (to > from) {
      if (to - from > from + slides.length - 1 - to) {
        return -1;
      } else return 1;
    }
    return 0;
  };

  const handleGoToSlide = () => {
    if (typeof goToSlide !== 'number') {
      return;
    }

    const newGoToSlide = mod(goToSlide, props.slides.length);

    if (newGoToSlide !== index) {
      let direction = getShortestDirection(index, newGoToSlide);

      const newIndex = modBySlidesLength(index + direction);
      const isFinished = newIndex === newGoToSlide;

      setIndex(newIndex);
      setNewSlide(isFinished);

      props.onSlideChange(newIndex);
    }
  };

  const clampOffsetRadius = (offsetRadius) => {
    const upperBound = Math.floor((slides.length - 1) / 2);

    if (offsetRadius < 0) {
      return 0;
    }
    if (offsetRadius > upperBound) {
      return upperBound;
    }

    return offsetRadius;
  };

  const getPresentableSlides = () => {
    let { offsetRadius } = props;
    offsetRadius = clampOffsetRadius(offsetRadius);
    const presentableSlides = new Array();

    for (let i = -offsetRadius; i < 1 + offsetRadius; i++) {
      const slide = slides[modBySlidesLength(index + i)];
      presentableSlides.push(slide);
    }
    return presentableSlides;
  };

  return (
    <React.Fragment>
      <Wrapper>
        {getPresentableSlides().map((slide, presentableIndex) => {
          return (
            <Slide
              key={slide.key}
              content={slide.content}
              onClick={slide.onClick}
              offsetRadius={clampOffsetRadius(offsetRadius)}
              index={presentableIndex}
              animationConfig={animationConfig}
            />
          );
        })}
      </Wrapper>
    </React.Fragment>
  );
};

Carousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.any,
      content: PropTypes.object,
    })
  ).isRequired,
  goToSlide: PropTypes.number,
  showNavigation: PropTypes.bool,
  offsetRadius: PropTypes.number,
  animationConfig: PropTypes.object,
  goToSlideDelay: PropTypes.number,
  onSlideChange: PropTypes.func,
};

Carousel.defaultProps = {
  offsetRadius: 2,
  animationConfig: { tension: 120, friction: 14 },
  goToSlideDelay: DEFAULT_GO_TO_SLIDE_DELAY,
  goToSlide: null,
};

export default Carousel;
