import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Document, Page, pdfjs } from 'react-pdf/dist/esm/entry.webpack';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import useStyles from './style';

const PdfViewver = (props) => {
  const { filePath, setDocumentLoaded, roundedCorners, error } = props;
  const [allPageNumbers, setAllPageNumbers] = useState(); // default value is undefined.
  const [outerWidth, setOuterWidth] = useState();
  const { t } = useTranslation();
  const classes = useStyles();

  const PAGE_MAX_HEIGHT = 600; // maxHeight for scroll
  const CONTAINER_PADDING = 50;

  const onDocumentLoadSuccess = (pdf) => {
    if (setDocumentLoaded) setDocumentLoaded();

    const allPageNumbers = [];
    for (let p = 1; p < pdf.numPages + 1; p++) {
      allPageNumbers.push(p);
    }
    setAllPageNumbers(allPageNumbers);

    // just for fun
    setOuterWidth(document.getElementById('pdf-container').offsetWidth);
  };

  const renderLoader = () => {
    return (
      <div
        style={{
          with: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography classes={{ root: classes.loadingText }}>
          {t('common.loadingFile')}
        </Typography>
        <LinearProgress />
      </div>
    );
  };

  const renderError = (error) => {
    return (
      <div
        style={{
          with: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <Typography classes={{ root: classes.loadingText }}>
          {t('common.loadingFileError')}
        </Typography>
      </div>
    );
  };

  return (
    <>
      <div
        id="pdf-container"
        // The styling here is just for fun.
        style={{
          border: 'none',
          // display: 'flex',
          justifyContent: 'center',
          width: '100%',
          height: '100%',
          borderRadius: roundedCorners ? '3px' : 'none',
          backgroundColor: 'gray',
          padding: `${CONTAINER_PADDING}px`,
        }}
      >
        {error ? (
          renderError()
        ) : (
          <Document
            file={filePath} // example PDF file located in '/public/assets/docs/*'
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={renderError}
            onSourceError={renderError}
            loading={renderLoader}
            noData={renderLoader}
            error={renderError}
            options={{
              verbosity: pdfjs.VerbosityLevel.ERRORS,
              cMapPacked: true,
              cMapUrl: 'cmaps/',
            }}
          >
            <div
              style={{
                maxHeight: `${PAGE_MAX_HEIGHT}px`, // needed for scroll
                overflowY: 'scroll', // yes vertical scroll
                overflowX: 'hidden', // no horizontal scroll

                // just for fun
                border: '2px solid lightgray', // matches the scroll color
                borderRadius: '5px',
              }}
            >
              {allPageNumbers
                ? allPageNumbers.map((pageNumber) => (
                    <Page
                      key={`page-${pageNumber}`}
                      width={outerWidth - CONTAINER_PADDING * 2}
                      pageNumber={pageNumber}
                    /> // 'width' is just for fun.
                  ))
                : undefined}
            </div>
          </Document>
        )}
      </div>
    </>
  );
};

PdfViewver.propTypes = {
  filePath: PropTypes.string,
  setDocumentLoaded: PropTypes.func,
  roundedCorners: PropTypes.bool,
  error: PropTypes.bool,
};

PdfViewver.defaultProps = {
  roundedCorners: true,
  error: false,
};

export default PdfViewver;
