import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useOktaAuth } from '@okta/okta-react';
import { useSnackbar } from 'notistack';

import customerApi from 'api/customerApi';
// import { StepsContext } from 'contexts/steps';
import { UserContext, USER_ACTION_TYPE } from 'contexts/user';
import Popin from 'components/PopinMultiPages';
import Loader from 'components/Loader';
import authApi from 'api/authApi';
import EmailPage from './emailPage';
import PasswordPage from './passwordPage';
import ConfirmationPage from './confirmationPage';
import ForgotPasswordPage from './forgotPasswordPage';
import RecoveryMailSentPage from './recoveryMailSentPage';
import ConnexionPage from './connexionPage';
import EmailActivationPage from './emailActivationPage';
import PAGES from './pagesIds.json';
import { isEmpty, isEmail } from 'utils/form-validation';

const EmailPopin = (props) => {
  const { open, onClose, update, onOpen, defaultPage, data, stepsContext } =
    props;
  const userEmail = data.contact.mail;
  const { t } = useTranslation();
  const { authState, oktaAuth } = useOktaAuth();
  const { dispatch } = useContext(stepsContext);
  const [, dispatchUser] = useContext(UserContext);
  const { enqueueSnackbar } = useSnackbar();

  const [currentPage, setCurrentPage] = useState(defaultPage);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [loading, setLoading] = useState(null);
  const [verifyLoading, setVerifyLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(open);
  const [currentTitle, setCurrentTitle] = useState('');
  const [validateButtonLabel, setValidateButtonLabel] = useState(undefined);
  const [resetPasswordPreviousPage, setResetPasswordPreviousPage] = useState();
  const [cancelButtonLabel, setCancelButtonLabel] = useState(
    t('common.popin.btn_close')
  );
  const [validationErrors, setValidationErrors] = useState({});

  const getNewEmailState = () => {
    return customerApi.getCustomerState(email);
  };

  const verify = async (email = userEmail) => {
    setVerifyLoading(true);

    try {
      const response = await authApi.verifyUser(email);

      if (response.ok) {
        const jsonResponse = await response.json();

        if (jsonResponse.activationNeeded) {
          handleChangePage(PAGES.emailActivationPage);
          return;
        }
      } else {
        const jsonResponse = await response.json();
        if (jsonResponse.activationNeeded) {
          handleChangePage(PAGES.emailActivationPage);
        } else throw new Error();
      }
    } catch (error) {
      setError(t('error.unknown'));
    } finally {
      setVerifyLoading(false);
      // eslint-disable-next-line no-unsafe-finally
      return Promise.resolve();
    }
  };

  const validateEmail = async (newEmail = email, nextPage) => {
    setLoading(true);

    try {
      const newEmailState = await getNewEmailState();
      if (newEmailState.status === 'V')
        handleChangePage(PAGES.confirmationPage);
      else {
        const response = await authApi.verifyUser(newEmail);

        if (response.ok) {
          const jsonResponse = await response.json();

          if (jsonResponse.activationNeeded) {
            handleChangePage(PAGES.emailActivationPage);
            return;
          }

          if (nextPage) handleChangePage(nextPage);
          else return;
        } else {
          const jsonResponse = await response.json();
          if (jsonResponse.activationNeeded) {
            handleChangePage(PAGES.emailActivationPage);
            return;
          }

          throw new Error();
        }
      }
    } catch (error) {
      console.log('error', error);
      setError(t('error.unknown'));
    } finally {
      setLoading(false);
    }
  };

  const handleLogIn = (enteredPassword = password, userEmail = email) => {
    setLoading(true);

    oktaAuth
      .signInWithCredentials({
        username: userEmail,
        password: enteredPassword,
      })
      .then((res) => {
        const { sessionToken } = res;

        oktaAuth.signInWithRedirect({
          sessionToken,
          originalUri: '/souscription',
        });

        setIsOpen(false);
      })
      .catch(() => {
        setError(t('error.authentication.authenticationFailure'));
      });
    setLoading(false);
  };

  const validateEmailAndLogin = async (email, password) => {
    try {
      await validateEmail(email);
      await handleLogIn(email, password);
    } catch (error) {
      console.log('error', error);
      setError(t('error.unknown'));
    }
  };

  const getEmailErrors = (emailToCheck) => {
    if (isEmpty(emailToCheck)) return t('error.fields.fieldRequired');
    else if (!isEmail(emailToCheck))
      return t('error.fields.invalidEmailAddress');
    else return null;
  };

  const changeUserEmail = async () => {
    if (authState.isAuthenticated) {
      await oktaAuth.tokenManager.clear();
      dispatchUser({ type: USER_ACTION_TYPE.REMOVE_USER });
    }

    dispatch({
      type: 'SET_DATA',
      payload: {
        key: 'contact.mail',
        value: email,
      },
    });

    handleClose();
  };

  const handleChangePage = (page) => {
    setError(null);
    setValidationErrors({});
    setCurrentPage(page);
  };

  const handleEmailChange = (newEmail) => {
    setEmail(newEmail);
  };

  const handlePasswordChange = (enteredPassword) => {
    setPassword(enteredPassword);
  };

  const goToResetPassword = (previousPage) => {
    setResetPasswordPreviousPage(previousPage);
    setCurrentPage(PAGES.forgotPasswordPage);
  };

  const handleResetPassword = async () => {
    try {
      setLoading(true);
      const response = await authApi.sendRecoveryLinkByMail(email);

      if (!response.ok) {
        throw new Error();
      }

      enqueueSnackbar(t('success.authentication.changePasswordMailSend'), {
        variant: 'success',
        persist: false,
      });
    } catch (error) {
      setError(t('error.unknown'));
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    onClose();

    setEmail(null);
    setPassword(null);
    setValidationErrors({});
    setCurrentPage(defaultPage);
  };

  const pages = [
    {
      component: EmailPage,
      key: PAGES.emailPage,
      onChange: handleEmailChange,
      userEmail: email,
      onValidate: (newEmail) => {
        const error = getEmailErrors(newEmail);

        if (error) setValidationErrors({ email: error });
        else {
          setValidationErrors({});
          validateEmail(newEmail, PAGES.passwordPage);
        }
      },
    },
    {
      component: PasswordPage,
      key: PAGES.passwordPage,
      onChange: handlePasswordChange,
      onValidate: (enteredPassword) => handleLogIn(enteredPassword),
      previousPage: () => PAGES.emailPage,
      goToResetPassword: () => goToResetPassword(PAGES.passwordPage),
    },
    {
      component: ConfirmationPage,
      key: PAGES.confirmationPage,
      onValidate: changeUserEmail,
      previousPage: () => PAGES.emailPage,
      email: email,
    },
    {
      component: ForgotPasswordPage,
      key: PAGES.forgotPasswordPage,
      onValidate: handleResetPassword,
      previousPage: () => resetPasswordPreviousPage,
    },
    {
      component: RecoveryMailSentPage,
      key: PAGES.recoveryMailSentPage,
    },
    {
      component: ConnexionPage,
      key: PAGES.connexionPage,
      email: userEmail,
      onChange: handlePasswordChange,
      goToResetPassword: () => goToResetPassword(PAGES.connexionPage),
      onValidate: (email = userEmail, password) => {
        validateEmailAndLogin(password, email);
      },
    },
    {
      component: EmailActivationPage,
      key: PAGES.emailActivationPage,
      firstname: data.contact.firstName,
      onValidate: () => {
        onClose();
        onOpen();
      },
    },
  ];

  const updatePopinProps = () => {
    switch (currentPage) {
      case PAGES.emailPage:
        setValidateButtonLabel(t('common.next'));
        setCancelButtonLabel(undefined);
        setCurrentTitle(t('auth.accountUpdate.changeEmailTitle'));
        break;
      case PAGES.passwordPage:
        setValidateButtonLabel(t('common.validate'));
        setCancelButtonLabel(undefined);
        setCurrentTitle(t('auth.login.login'));
        break;
      case PAGES.confirmationPage:
        setValidateButtonLabel(t('common.yes'));
        setCancelButtonLabel(t('common.no'));
        setCurrentTitle(t('auth.login.login'));
        break;
      case PAGES.forgotPasswordPage:
        setValidateButtonLabel(t('common.yes'));
        setCancelButtonLabel(t('common.no'));
        setCurrentTitle(t('auth.recovery.title'));
        break;
      case PAGES.connexionPage:
        setValidateButtonLabel(t('common.validate'));
        setCancelButtonLabel(t('common.cancel'));
        setCurrentTitle(t('auth.login.login'));
        break;
      case PAGES.recoveryMailSentPage:
        setCurrentTitle(t('auth.recovery.title'));
        break;
      case PAGES.emailActivationPage:
        setCurrentTitle(t('auth.accountActivation.title'));
        setValidateButtonLabel(undefined);

        break;
    }
  };

  const executeValidateFunction = () => {
    const page = pages.find((page) => currentPage === page.key);
    if (currentPage === PAGES.emailPage) page.onValidate(email);
    else page.onValidate();
  };

  const init = async () => {
    await verify();
  };

  React.useEffect(() => {
    if (open && !update) init();
    setIsOpen(open);
  }, [open]);

  React.useEffect(() => {
    updatePopinProps();
  }, [currentPage]);

  React.useEffect(() => {
    setCurrentPage(defaultPage);
  }, [defaultPage]);

  return verifyLoading ? (
    <Loader />
  ) : (
    <Popin
      open={isOpen}
      onClose={handleClose}
      onCancel={handleClose}
      maxWidth="sm"
      enableTitleArea={false}
      pages={pages}
      title={currentTitle}
      setCurrentPage={handleChangePage}
      currentPage={currentPage}
      validateButtonLabel={validateButtonLabel}
      cancelButtonLabel={cancelButtonLabel}
      onValidate={executeValidateFunction}
      minHeight="inherit"
      loading={loading}
      error={error}
      validationErrors={validationErrors}
    />
  );
};

EmailPopin.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  update: PropTypes.bool,
  customerState: PropTypes.string,
  defaultPage: PropTypes.string,
  data: PropTypes.shape(),
  stepsContext: PropTypes.shape().isRequired,
};

EmailPopin.defaultProps = {
  open: false,
  defaultPage: PAGES.emailPage,
};

export default EmailPopin;
