import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  paper: {
    backgroundColor: theme.palette.background.default,
    border: '1px solid #d8d8d8',
    borderRadius: 3,
    position: 'relative',
  },
  title: {
    marginBottom: 20,
    textAlign: 'center',
  },
  button: {},
  buttonText: {
    color: theme.palette.white.main,
  },

  linearProgressRoot: {
    height: 2,
    borderRadius: 5,
    position: 'absolute',
    top: 0,
    width: '100%',
    left: 0,
  },
  linearProgressColorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  linearProgressBar: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main,
  },
  forgetPassword: {
    fontSize: 12,
  },
}));

export default useStyles;
