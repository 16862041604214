import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import QuestionsStep from 'components/QuestionsStep/QuestionsStep';
import Title from 'components/Title';
import HouseLottie from 'assets/lotties/house.json';
import HolidayHouseLottie from 'assets/lotties/holiday-house.json';
import { isEmpty } from 'utils/form-validation';

const ResidenceTypeStep = ({
  data,
  onConfirm,
  setUserData,
  setUserDataAndChangePage,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <Title title={t('steps.residenceTypeStep.title')} />
      <QuestionsStep
        propertyKey="residenceType"
        choices={[
          {
            label: t('common.mainResidence'),
            value: 'P',
            asset: HouseLottie,
            disabled: true,
          },
          {
            label: t('common.secondaryResidence'),
            value: 'S',
            asset: HolidayHouseLottie,
            disabled: true,
            ribbon: t('common.comingSoon'),
          },
        ]}
        onSelect={setUserData}
        onItemClick={setUserDataAndChangePage}
        onConfirm={onConfirm}
        invalid={isEmpty(data.residenceType) || data.residenceType.length !== 1}
        value={data.residenceType}
      />
    </>
  );
};

ResidenceTypeStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserDataAndChangePage: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
};

export default ResidenceTypeStep;
