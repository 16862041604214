import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';

import useStyles from './style';

const Confirmation = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { periodicity, onValidate } = props;

  return (
    <div className={classes.topWrapper}>
      <Box
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body1" align="center" className={classes.title}>
          {t(
            'dashboard.police.paymentPeriodicity.contractUpdateConfirmationTitle'
          )}
        </Typography>
      </Box>

      <Box
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        {periodicity.code === 'A' ? (
          <Typography variant="body1" color="textPrimary">
            <strong>{t('common.annually')}</strong>
            {' : '}
            {t('steps.quoteStep.annualPaymentMethod')}
          </Typography>
        ) : (
          <Typography variant="body1" color="textPrimary">
            <strong>{t('common.monthly')}</strong>
            {' : '}
            {t('steps.quoteStep.monthlyPaymentMethod')}
          </Typography>
        )}
      </Box>
      <div>
        <Box
          m={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button variant="contained" color="primary" onClick={onValidate}>
            {t('dashboard.police.paymentPeriodicity.confirmation')}
          </Button>
        </Box>
        <Box m={3}>
          <Typography variant="body1" align="center">
            {t('dashboard.police.paymentPeriodicity.contactUs')}
          </Typography>
          &nbsp;
          <Typography variant="body1" align="center" className={classes.title}>
            {t('common.assurimoPhoneNumber')}
          </Typography>
        </Box>
      </div>
    </div>
  );
};

Confirmation.propTypes = {
  periodicity: PropTypes.shape({
    code: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  onValidate: PropTypes.func.isRequired,
};

export default Confirmation;
