import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import GlobalContext from 'contexts/global';
import useGa from 'hooks/useGA';
import { MrhContext } from 'contexts/mrh';
import getSteps from 'constants/steps/steps';
import Footer from 'components/Footer';
import useStyles from './style';

const Steps = (props) => {
  const { id, routerParams, next, stay } = props;
  const { state, dispatch } = useContext(MrhContext);
  const isInitialMount = useRef(true);
  const classes = useStyles();
  const history = useHistory();
  const { gtag } = useGa();
  const { gaMrhSiteKey } = useContext(GlobalContext);

  const { currentStep, data } = state;
  const [subscriptionData, setSubscriptionData] = useState(data);
  const steps = getSteps(state);
  const step = steps[currentStep.group][currentStep.step];
  const showFooter = currentStep.step === 0 && currentStep.group === 0;
  const wrapperRef = React.useRef(null);

  const handleNextStep = () => {
    if (next && next[step.id]) {
      history.push(next[step.id]);
    } else
      dispatch({
        type: 'NEXT_STEP',
      });
  };

  const setUserData = (key, value) => {
    dispatch({
      type: 'SET_DATA',
      payload: {
        key,
        value,
      },
    });
  };

  const setUserDataAndChangePage = (key, value) => {
    dispatch({
      type: 'SET_DATA_AND_CHANGE_PAGE',
      payload: {
        key,
        value,
      },
    });
  };

  useEffect(() => {
    if (id) {
      dispatch({
        type: 'SET_STEP',
        payload: {
          stepId: id,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;

      if (id) {
        setSubscriptionData(null);
      }
    }
  });

  useEffect(() => {
    setSubscriptionData(data);
  }, [data]);

  useEffect(() => {
    gtag(step.id, `souscription/${step.id}`, `/${step.id}`, gaMrhSiteKey);
  }, [step]);

  return subscriptionData ? (
    <div ref={wrapperRef} className={classes.stepsWrapper}>
      {React.createElement(step.component, {
        key: step.id,
        data: subscriptionData,
        subscriptionSource: 'mrh',
        stepsContext: MrhContext,
        routerParams,
        onConfirm: handleNextStep,
        setUserData,
        setUserDataAndChangePage,
        stay,
        parentRef: wrapperRef,
      })}
      {showFooter && <Footer />}
    </div>
  ) : (
    <></>
  );
};

Steps.propTypes = {
  id: PropTypes.string,
  routerParams: PropTypes.shape(),
  next: PropTypes.func,
  stay: PropTypes.bool,
};

export default Steps;
