import { Box, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import premiumApi from 'api/premiumApi';
import Popin from 'components/Popin/index';
import TabPanel from 'components/TabPanel/index';
import useAuth from 'hooks/useAuth';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import CardList from './CardList';
import IframePayment from './IframePayment';
import { getMessageError } from 'constants/common/errorCode';

PaymentMonthlyConnected.propTypes = {
  isEnrolement: PropTypes.bool,
  premiumUuid: PropTypes.string.isRequired,
  onMessage: PropTypes.func,
  setLoading: PropTypes.func,
  onChangeCard: PropTypes.func,
  cards: PropTypes.array,
};

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
  boxContainer: {
    width: '100%',
  },
  boxTab: {
    borderBottom: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  textNotTransform: {
    textTransform: 'none',
  },
}));

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

const TAB_CARD_EXIST_VALUE = 0;
const TAB_CARD_NEW_VALUE = 1;

function PaymentMonthlyConnected(props) {
  const {
    isEnrolement,
    premiumUuid,
    onMessage,
    setLoading,
    cards,
    onChangeCard,
  } = props;

  const { t } = useTranslation();
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();

  const [value, setValue] = useState(
    cards.length ? TAB_CARD_EXIST_VALUE : TAB_CARD_NEW_VALUE
  );
  const [optionsPopin, setOptionsPopin] = useState({ open: false });

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const auth = useAuth();

  const handleChangeCard = (newList) => {
    onChangeCard && onChangeCard(newList);
  };

  const handlePaid = () => {
    const cardSel = cards.find((c) => c.selected);
    if (cardSel) {
      setOptionsPopin({
        open: true,
        onValidate: () => paidWithCard(cardSel),
        content: (
          <>
            <Box m={3}>
              {t('dashboard.card.item.msg_select_paid_prefix')}{' '}
              <b>**** {cardSel.last4}</b>{' '}
              {t('dashboard.card.item.msg_select_paid_suffix')}
            </Box>
          </>
        ),
      });
    }
  };

  const paidWithCard = async (card) => {
    try {
      if (auth.isAuthenticated) {
        setLoading(true);
        await premiumApi.paidWithCardExist(
          auth,
          premiumUuid,
          card.bankcardUuid
        );
        enqueueSnackbar(t('dashboard.card.item.msg_paid_success'), {
          variant: 'success',
        });
        onMessage({ data: { message: 'result', value: 'success' } });
      }
    } catch (error) {
      const messageError = getMessageError(error);
      console.error(t(messageError), error);
      enqueueSnackbar(t(messageError), { variant: 'error' });
    } finally {
      setOptionsPopin({ open: false });
      setLoading(false);
    }
  };

  return (
    <>
      <Grid container>
        <Grid container item xs={12} className={classes.title}>
          <Box className={classes.boxContainer}>
            <Box className={classes.boxTab}>
              <Tabs
                value={value}
                onChange={handleChangeTab}
                aria-label={t('dashboard.card.tabs.label')}
                variant="fullWidth"
              >
                <Tab
                  label={
                    <Typography
                      className={classes.textNotTransform}
                      variant="subtitle1"
                    >
                      {t('dashboard.card.tabs.cards')}
                    </Typography>
                  }
                  {...a11yProps(TAB_CARD_EXIST_VALUE)}
                />
                <Tab
                  label={
                    <Typography
                      className={classes.textNotTransform}
                      variant="subtitle1"
                    >
                      {t('dashboard.card.tabs.new_card')}
                    </Typography>
                  }
                  {...a11yProps(TAB_CARD_NEW_VALUE)}
                />
              </Tabs>
            </Box>
            <TabPanel value={value} index={TAB_CARD_EXIST_VALUE}>
              <CardList
                cards={cards}
                size="small"
                onUpdateCard={handleChangeCard}
                onPaid={handlePaid}
              />
            </TabPanel>
            <TabPanel value={value} index={TAB_CARD_NEW_VALUE}>
              <IframePayment
                premiumUuid={premiumUuid}
                isEnrolement={isEnrolement}
                onMessage={onMessage}
              />
            </TabPanel>
          </Box>
        </Grid>
      </Grid>

      <Popin
        title={t('common.popin.confirm_title')}
        validateButtonLabel={t('common.yes')}
        cancelButtonLabel={t('common.no')}
        showCancelButton
        {...optionsPopin}
        onClose={() => setOptionsPopin({ ...optionsPopin, open: false })}
        maxWidth="sm"
      />
    </>
  );
}

export default PaymentMonthlyConnected;
