import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Skeleton from '@material-ui/lab/Skeleton';
import PropTypes from 'prop-types';
import React from 'react';

CardSkeleton.propTypes = {
  length: PropTypes.number,
};

function CardSkeleton({ length = 9 }) {
  return (
    <Box>
      <Grid container>
        {Array.from(new Array(length)).map((x, index) => (
          <Grid item key={index} xs={12}>
            <Box padding={1}>
              <Skeleton variant="rect" width="100%" height={100} />
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default CardSkeleton;
