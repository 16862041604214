import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';

import Icon from 'components/Icon';
import { formatNumber } from 'utils/index';
import useStyles from './style';

const SmallPrice = (props) => {
  const { price, isPromo, periodicity, classBlink } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const isAnnual = periodicity === 'A';
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isDownSm = useMediaQuery(theme.breakpoints.down('sm'));

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };

  const renderAttackTax = () => {
    return (
      price.annualSurtaxe && (
        <div
          style={{
            display: 'flex',
            gap: '5px',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="body1" classes={{ root: classes.smallPrice }}>
            <Trans
              i18nKey={
                isAnnual
                  ? 'common.includingAttackTax'
                  : 'common.includingAttackTaxMensual'
              }
              values={{
                attackTaxPrice: formatNumber(price.annualSurtaxe),
              }}
            />
          </Typography>
          <ClickAwayListener onClickAway={handleTooltipClose}>
            <div>
              <Tooltip
                arrow
                placement="top"
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={handleTooltipClose}
                open={open}
                disableFocusListener
                disableHoverListener
                disableTouchListener
                title={t('common.attackTaxExplanations')}
              >
                <IconButton
                  onClick={handleTooltipOpen}
                  classes={{ root: classes.infoButton }}
                >
                  <Icon
                    className="fas fa-info-circle"
                    classes={{ root: classes.infoIcon }}
                  />
                </IconButton>
              </Tooltip>
            </div>
          </ClickAwayListener>
        </div>
      )
    );
  };

  return (
    <>
      {isAnnual && (
        <>
          {isPromo ? (
            <>
              <Typography variant="body1" className={classBlink}>
                <Typography
                  className={classes.priceSmall}
                  variant="inherit"
                  component="span"
                >
                  {`${price.annualTTC} € / ${t('common.year')} `}
                </Typography>
                {price.annualTTCDeBase && (
                  <>
                    {isDownSm && (
                      <Typography
                        variant="caption"
                        className={classes.priceOld}
                      >
                        {`${t('common.insteadOf')}`}
                      </Typography>
                    )}
                    <Typography
                      variant="caption"
                      className={
                        isDownSm ? classes.priceOld : classes.priceOldThrough
                      }
                    >
                      {` ${price.annualTTCDeBase} €`}
                    </Typography>
                  </>
                )}
              </Typography>
              {renderAttackTax()}
            </>
          ) : (
            <>
              <Typography variant="h6" className={`${classes.blink}`}>
                {`${price.annualTTC} € / ${t('common.year')}`}
              </Typography>
              {renderAttackTax()}
            </>
          )}
        </>
      )}
      {!isAnnual && (
        <>
          {isPromo ? (
            <>
              <Typography variant="body1" className={classBlink}>
                <Typography variant="inherit" component="span">
                  {`${price.monthlyTTC} € / ${t('common.month')} `}
                </Typography>
                {price.monthlyTTCDeBase &&
                  (isDownSm ? (
                    <Typography variant="caption" className={classes.priceOld}>
                      {`${t('common.insteadOf')} ${price.monthlyTTCDeBase} €`}
                    </Typography>
                  ) : (
                    <Typography
                      variant="caption"
                      className={
                        isDownSm ? classes.priceOld : classes.priceOldThrough
                      }
                    >
                      {` ${price.monthlyTTCDeBase} €`}
                    </Typography>
                  ))}
              </Typography>
              <div style={{ display: 'flex', gap: 5 }}>
                <Typography variant="caption" classes={{ root: classes.white }}>
                  {`${t('common.namely')} ${price.annualTTC} € / ${t(
                    'common.year'
                  )} `}
                </Typography>
                {isDownSm ? (
                  <Typography variant="caption" className={classes.priceOld}>
                    {`${t('common.insteadOf')} ${price.annualTTCDeBase} €`}{' '}
                  </Typography>
                ) : (
                  <Typography
                    variant="caption"
                    className={classes.priceOldThrough}
                  >
                    {` ${price.annualTTCDeBase} €`}
                  </Typography>
                )}
              </div>
              {renderAttackTax()}
            </>
          ) : (
            <>
              <Typography variant="h6" className={classBlink}>
                {`${price.monthlyTTC} € / ${t('common.month')}`}
              </Typography>
              {renderAttackTax()}
              <Typography variant="caption">{` ${t('common.namely')} ${
                price.annualTTC
              } € / ${t('common.year')}`}</Typography>
            </>
          )}
        </>
      )}
    </>
  );
};

SmallPrice.propTypes = {
  price: PropTypes.shape({
    annualTTC: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    monthlyTTC: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    annualTTCDeBase: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    monthlyTTCDeBase: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    annualSurtaxe: PropTypes.number,
  }).isRequired,
  isPromo: PropTypes.bool,
  periodicity: PropTypes.string.isRequired,
  classBlink: PropTypes.any,
};

export default SmallPrice;
