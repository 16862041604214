import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  // cardWrapper: {
  //   width: '100%',
  // },
  paperWrapper: {
    // padding: '10px 20px',
    // paddingBottom: '0',
    padding: 10,
    display: 'flex',
    fontFamily: 'Domine',
    fontWeight: 1000,
  },
  paper: {
    backgroundColor: theme.palette.background.default,
    padding: '20px 40px',
    // margin: '20px 40px',
    width: 'inherit',
  },
  cardTitle: {
    color: theme.palette.white.main,
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
  },
  card: {
    boxShadow:
      '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  },
  colorLine: {
    color: theme.palette.blue.main,
    backgroundColor: theme.palette.blue.main,
    height: 0.5,
  },
  detailsWrapper: {
    paddingTop: '0 !important',
    marginTop: '12px !important',
  },
  title: {
    fontWeight: 'bold',
  },
  stepHeader: {
    height: '5%',
  },
  paymentStepBody: {
    height: '100%',
    maxWidth: 600,
    display: 'flex',
  },
  inframeContainerBody: {
    height: '100%',
  },
  infoPayment: {
    fontStyle: 'italic',
    fontSize: 13,
  },
  iframeWrapper: {
    display: 'flex',
    height: '100%',
    padding: 10,
  },

  /////////

  contentWrapper: {
    display: 'flex',
    flexDirection: 'row-reverse',
    width: '100%',
    gap: 26,

    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse',
    },
  },
  titleWrapper: {
    marginBottom: -30,
    marginTop: -30,
  },
  titleDescription: {
    textAlign: 'center',
    margin: 'auto',
    marginBottom: 20,
    [theme.breakpoints.up('sm')]: {
      width: 600,
    },
  },
  paymentModule: {
    flex: 1,
    '& iframe': {
      minHeight: '740px !important',
    },
  },
  paymentInfos: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    paddingTop: 10,
  },
  total: {
    borderRadius: 9,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white.main,
    padding: 10,
    textAlign: 'center',
  },
  priceWrapper: {
    marginTop: 10,
  },
  price: {
    fontSize: 40,
  },
  text: {
    color: 'inherit',
  },
  details: {
    color: theme.palette.secondary.main,
  },
  detailsPaper: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    marginTop: 10,
  },
  detailsInfoBox: {
    alignSelf: 'center',
    backgroundColor: theme.palette.secondary.main,
    padding: 10,
    color: theme.palette.white.main,
    borderRadius: 9,
    fontWeight: 'normal !important',
  },
  attack: {
    display: 'flex',
    alignItems: 'center',
  },
  attackToolTip: {
    '& .MuiTooltip-tooltip': {
      alignSelf: 'center',
      backgroundColor: theme.palette.secondary.main,
      padding: 10,
      color: theme.palette.white.main,
      borderRadius: 9,
      fontWeight: 'normal !important',
    },
    '& .MuiTooltip-arrow': {
      color: theme.palette.secondary.main,
    },
  },
  infoButton: {
    padding: 0,
    marginLeft: 5,
  },
  infoIcon: {
    color: theme.palette.secondary.main,
  },
  priceLine: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  nextPayment: {
    color: theme.palette.secondary.main,
  },
  nextPaymentPaper: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    justifyContent: 'center',
    marginTop: 10,
  },
  nextPaymentInfoBox: {
    alignSelf: 'center',
    backgroundColor: theme.palette.secondary.main,
    padding: 10,
    color: theme.palette.white.main,
    borderRadius: 9,
    fontWeight: 'normal !important',
  },
  contentTitle: {
    textAlign: 'center',
  },
  caption: {
    marginLeft: 10,
  },
}));

export default useStyles;
