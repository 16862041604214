import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { formatDate } from 'utils';
import QuestionsStep from 'components/QuestionsStep/QuestionsStep';
import Title from 'components/Title';
import getCurrentWaranty12MonthText from './selectionText';

const PropertyTypeStep = ({ data, onConfirm, setUserData }) => {
  const { t } = useTranslation();
  return (
    <>
      <Title title={t('steps.insuranceSeniorityStep.title')} />
      <QuestionsStep
        propertyKey="currentWaranty12Month"
        choices={[
          {
            label: t('steps.insuranceSeniorityStep.lessThan12'),
            value: true,
          },
          {
            label: t('steps.insuranceSeniorityStep.moreThan12'),
            value: false,
          },
        ]}
        onSelect={(key, value) => {
          if (key === 'currentWaranty12Month' && value === false) {
            setUserData(
              'contractStartDate',
              formatDate(moment().add(40, 'day').startOf('day'))
            );
          }
          setUserData(key, value);
        }}
        onConfirm={onConfirm}
        invalid={
          data.currentWaranty12Month === null ||
          data.currentWaranty12Month === undefined ||
          data.currentWaranty12Month
        }
        getText={(value) => getCurrentWaranty12MonthText(value, data, t)}
        value={data.currentWaranty12Month}
      />
    </>
  );
};

PropertyTypeStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
};

export default PropertyTypeStep;
