import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  loginWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
    minWidth: 300,
    margin: 'auto',
    alignItems: 'center',
    justiyContent: 'center',
  },
  separator: {
    paddingTop: 30,
    paddingBottom: 30,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  horizontalBar: {
    width: '100%',
    height: 0,
    borderBottom: `1px solid ${theme.palette.grey.main}`,
  },
  or: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  buttonWrapper: {
    width: '100%',
    marginLeft: 42,
    marginRight: 42,
  },
  button: {
    width: '100%',
    height: 48,
    borderRadius: 3,
  },
  buttonText: {
    color: theme.palette.white.main,
  },
}));

export default useStyles;
