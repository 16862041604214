import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography, Hidden } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import { useTranslation } from 'react-i18next';

import Lottie from 'components/Lottie';
import HouseLottie from 'assets/lotties/house.json';
import useStyles from './styles';

PoliceInfo.propTypes = {
  police: PropTypes.object,
};

function PoliceInfo({ police }) {
  const classes = useStyles();

  const { t } = useTranslation();

  return (
    <Box className={classes.boxPolice}>
      <Typography
        variant="h5"
        color="secondary"
        className="typography"
        style={{ textAlign: 'center' }}
      >
        {t('dashboard.resilHamon.form.police.label')}
      </Typography>
      <Box mt={2} p={2} width="100%" className={classes.container}>
        <Grid container alignItems="stretch">
          <Grid container item xs={12} md={10} wrap="nowrap">
            <Grid item xs={4} md={2}>
              <Box>
                <div className={classes.visual}>
                  <Lottie animationData={HouseLottie} isActive height={48} />
                  <div className={classes.linkIconContainer}>
                    <LinkIcon className={classes.linkIcon} />
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={8} md={10}>
              <Box align="left">
                <Hidden smDown>
                  <Typography variant="subtitle1">
                    {t(
                      `dashboard.resilHamon.form.police.${police.contractType.code.toLowerCase()}`
                    )}
                  </Typography>
                </Hidden>
                <Hidden mdUp>
                  <Typography variant="body1">
                    {t(
                      `dashboard.resilHamon.form.police.${police.contractType.code.toLowerCase()}`
                    )}
                  </Typography>
                </Hidden>
                <Grid container>
                  <Grid item xs={12} md={6} align="left">
                    <Hidden smDown>
                      <Typography variant="caption">
                        {t('dashboard.resilHamon.form.police.adress')}
                      </Typography>
                      <Typography variant="body1">
                        {police.policyKey}
                      </Typography>
                    </Hidden>
                    <Hidden mdUp>
                      <Typography variant="caption">
                        {police.policyKey}
                      </Typography>
                    </Hidden>
                  </Grid>
                  <Grid item xs={12} md={6} align="left">
                    <Hidden smDown>
                      <Typography variant="caption">
                        {t('dashboard.sinistre.form.police.policyNumber')}
                      </Typography>
                      <Typography variant="body1">
                        {police.policyNumber}
                      </Typography>
                    </Hidden>
                    <Hidden mdUp>
                      <Typography variant="caption">
                        {police.policyNumber}
                      </Typography>
                    </Hidden>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>{' '}
    </Box>
  );
}

export default PoliceInfo;
