import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import SplittingSelector from 'views/MrhSubscription/Steps/QuoteStep/Details/SplittingSelector';
import useStyles from './style';

const ContractPeriodicity = (props) => {
  const { onValidate } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const splittingPeriodicities = [
    { code: 'M', label: t('steps.quoteStep.everyMonth') },
    { code: 'A', label: t('steps.quoteStep.everyYear') },
  ];

  const [periodicity, setPeriodicity] = useState(splittingPeriodicities[0]);

  const handleChange = (option) => {
    setPeriodicity(option);
  };

  const handleValidate = () => {
    onValidate(periodicity);
  };

  return (
    <div className={classes.topWrapper}>
      <div>
        <Box
          m={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Typography variant="body1" align="center" className={classes.title}>
            {t('dashboard.police.paymentPeriodicity.subTitle')}
          </Typography>
        </Box>
        <Box
          m={6}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          className={classes.splittingSelectorBox}
        >
          <SplittingSelector
            options={splittingPeriodicities}
            periodicity={periodicity}
            onChange={handleChange}
            showTitle={false}
          />
        </Box>
      </div>
      <div>
        <Box
          m={2}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button variant="contained" color="primary" onClick={handleValidate}>
            {t('common.update')}
          </Button>
        </Box>
        <Box m={3}>
          <Typography variant="body1" align="center">
            {t('dashboard.police.paymentPeriodicity.contactUs')}
          </Typography>
          &nbsp;
          <Typography variant="body1" align="center" className={classes.title}>
            {t('common.assurimoPhoneNumber')}
          </Typography>
        </Box>
      </div>
    </div>
  );
};

ContractPeriodicity.propTypes = {
  onValidate: PropTypes.func.isRequired,
};

export default ContractPeriodicity;
