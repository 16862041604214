import React from 'react';
import Box from '@material-ui/core/Box';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Popin from 'components/Popin';
import PaperImage from 'assets/images/paper.svg';
import Typography from '@material-ui/core/Typography';
import { TYPE_POLICE_H, TYPE_POLICE_PNO } from 'constants';

const Confirmation = ({
  police,
  adresse,
  isOpen,
  onClose,
  loading,
  onValidate,
}) => {
  const { t } = useTranslation();

  if (!police) return null;

  const getLabelFromPoliceType = (policeType) => {
    if (policeType === TYPE_POLICE_PNO)
      return t('dashboard.police.policyTypes.dno');
    if (policeType === TYPE_POLICE_H)
      return t('dashboard.police.policyTypes.hA');
    return policeType;
  };

  return (
    <Popin
      open={isOpen}
      onClose={onClose}
      title={t('dashboard.police.retractation.popin_title')}
      content={
        <Box
          style={{
            display: 'flex',
            margin: 32,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 25,
          }}
        >
          <Typography variant="h5">
            {t('dashboard.police.retractation.popin_head')}
          </Typography>
          <img
            src={PaperImage}
            alt="Assurimo"
            style={{
              width: 160,
            }}
          />
          <Typography variant="body3" style={{ textAlign: 'center' }}>
            {t('dashboard.police.retractation.info')}
          </Typography>
          <ul>
            <li>
              {t('dashboard.police.retractation.souscripteur')} :{' '}
              <ResilInfos>
                {police.subscriber?.title} {police.subscriber?.lastname}{' '}
                {police.subscriber?.firstname}
              </ResilInfos>
            </li>
            <li>
              {t('dashboard.police.retractation.typeContrat')} :{' '}
              <ResilInfos>
                {getLabelFromPoliceType(police.contractType?.code)}
              </ResilInfos>
            </li>
            <li>
              {t('dashboard.police.retractation.adresse')} :{' '}
              <ResilInfos>{adresse}</ResilInfos>
            </li>
            <li>
              {t('dashboard.police.retractation.motif')} :{' '}
              <ResilInfos>
                {t('dashboard.police.retractation.retractation')}{' '}
              </ResilInfos>
            </li>
          </ul>
          <Typography variant="body3" style={{ textAlign: 'center' }}>
            {t('dashboard.police.retractation.info_remboursement')}
          </Typography>
        </Box>
      }
      validateButtonLabel={t('dashboard.police.termination.confirmer')}
      maxWidth="sm"
      showCancelButton={true}
      onValidate={() => onValidate()}
      validateLoading={loading}
      useValidateLoader={true}
    />
  );
};

Confirmation.propTypes = {
  police: PropTypes.object.isRequired,
  adresse: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  onValidate: PropTypes.func.isRequired,
};

const ResilInfos = ({ children }) => (
  <Typography
    color={'primary'}
    display={'inline'}
    style={{ fontWeight: 'bold' }}
  >
    {children}
  </Typography>
);

export default Confirmation;
