import { getHeaderFetch, responseWithJson } from './httpUtil';

const BASE_URL = '/v3/ratings';

const ratingApi = {
  async getHRatings(params) {
    const url = `${BASE_URL}/H`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  async getPnoRatings(params) {
    const url = `${BASE_URL}/PNO`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderFetch(),
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },
};

export default ratingApi;
