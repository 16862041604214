import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import QuestionsStep from 'components/QuestionsStep/QuestionsStep';
import Title from 'components/Title';
import HouseLottie from 'assets/lotties/house.json';
import ApartmentLottie from 'assets/lotties/apartment.json';
import GarageLottie from 'assets/lotties/garage.json';
import CommercialPremiseLottie from 'assets/lotties/localcommercial.json';
import { isEmpty } from 'utils/form-validation';
import {
  PNO_TYPE_APPARTEMENT,
  PNO_TYPE_GARAGE,
  PNO_TYPE_LOCAL_COMMERCIAL,
  PNO_TYPE_MAISON,
} from 'constants/common/bienType';

const PropertyTypeStep = ({
  data,
  onConfirm,
  setUserData,
  setUserDataAndChangePage,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    if (
      [PNO_TYPE_LOCAL_COMMERCIAL, PNO_TYPE_GARAGE, PNO_TYPE_MAISON].includes(
        data.housingType
      )
    ) {
      setUserData('historical', false);
    }

    if (
      [PNO_TYPE_LOCAL_COMMERCIAL, PNO_TYPE_GARAGE].includes(data.housingType)
    ) {
      setUserData('furnished', false);
    }
  }, []);

  return (
    <>
      <Title title={t('pno.propertyTypeStep.title')} />
      <QuestionsStep
        propertyKey="housingType"
        // text="Exclusion des biens classés"
        choices={[
          {
            label: t('common.apartment'),
            value: PNO_TYPE_APPARTEMENT,
            asset: ApartmentLottie,
          },
          {
            label: t('common.house'),
            value: PNO_TYPE_MAISON,
            asset: HouseLottie,
          },
          {
            label: t('common.housingType.LC'),
            value: PNO_TYPE_LOCAL_COMMERCIAL,
            asset: CommercialPremiseLottie,
          },
          {
            label: t('common.housingType.BG'),
            value: PNO_TYPE_GARAGE,
            asset: GarageLottie,
          },
        ]}
        onSelect={setUserData}
        onItemClick={setUserDataAndChangePage}
        onConfirm={onConfirm}
        invalid={isEmpty(data.housingType)}
        value={data.housingType}
      />
    </>
  );
};

PropertyTypeStep.propTypes = {
  data: PropTypes.shape().isRequired,
  onConfirm: PropTypes.func.isRequired,
  setUserDataAndChangePage: PropTypes.func.isRequired,
  setUserData: PropTypes.func.isRequired,
};

export default PropertyTypeStep;
