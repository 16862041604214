import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './style';

const Popin = (props) => {
  const { open, onClose, content } = props;
  const classes = useStyles();
  const theme = useTheme();
  const isDownXs = useMediaQuery(theme.breakpoints.down('xs'));

  const handleClose = (event, reason) => {
    if (reason !== 'backdropClick') {
      onClose();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      scroll={'paper'}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      fullScreen={false}
      className={classes.dialog}
      disableEscapeKeyDown={true}
      hideBackdrop={true}
      disablePortal={true}
      disableScrollLock={true}
      BackdropProps={{ invisible: true }}
      style={{ position: 'initial' }}
      PaperProps={{
        style: !isDownXs
          ? {
              width: '370px',
              position: 'fixed',
              bottom: 10,
              right: 10,
              zIndex: 1200,
              margin: 0,
            }
          : {
              width: 'calc(100% - 20px)',
              position: 'fixed',
              bottom: 10,
              right: 10,
              left: 10,
              zIndex: 1200,
              margin: 0,
            },
      }}
    >
      <DialogContent className={classes.content}>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <IconButton
            size="small"
            onClick={onClose}
            className={classes.closeButton}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <div className={classes.contentWrapper}>{content ? content : null}</div>
      </DialogContent>
    </Dialog>
  );
};

Popin.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  content: PropTypes.node,
  onValidate: PropTypes.func,
  disableEscapeKeyDown: PropTypes.bool,
  dialogActionsClasses: PropTypes.object,
  useValidateLoader: PropTypes.bool,
  validateLoading: PropTypes.bool,
};

Popin.defaultProps = {
  open: false,
  isValidateButtonDisabled: false,
  isCancelButtonDisabled: false,
  useValidateLoader: false,
  validateLoading: false,
};

export default Popin;
