import React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const ForgotPasswordPage = () => {
  const { t } = useTranslation();

  return (
    <Box
      m={2}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <Typography variant="subtitle1">
        {t('auth.recovery.sureToResetPassword')}
      </Typography>
      <br />
      <Typography variant={'body1'} color="secondary">
        {t('auth.recovery.resetExplanation')}
      </Typography>
    </Box>
  );
};

export default ForgotPasswordPage;
