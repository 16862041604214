import React from 'react';
import Box from '@material-ui/core/Box';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

import PaperImage from 'assets/images/paper.svg';
import Popin from 'components/Popin';
import { formatDate } from 'utils';
import { TYPE_POLICE_H, TYPE_POLICE_PNO } from 'constants';

const ConfirmationSansEngag = ({
  dateEffetResiliation,
  police,
  adresse,
  isOpen,
  onClose,
  resiliationEnCours,
  onValidate,
}) => {
  const { t } = useTranslation();

  if (!police) return null;

  const getLabelFromPoliceType = (policeType) => {
    if (policeType === TYPE_POLICE_PNO)
      return t('dashboard.police.policyTypes.dno');
    if (policeType === TYPE_POLICE_H)
      return t('dashboard.police.policyTypes.hA');
    return policeType;
  };

  return (
    <Popin
      open={isOpen}
      onClose={onClose}
      title={t('dashboard.police.termination.popin_title')}
      content={
        <Box
          style={{
            display: 'flex',
            margin: 32,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 25,
          }}
        >
          <Typography variant="h5">
            {t('dashboard.police.termination.popin_head')}
          </Typography>
          <img
            src={PaperImage}
            alt="Assurimo"
            style={{
              width: 160,
            }}
          />
          <Typography variant="body3" style={{ textAlign: 'center' }}>
            {t('dashboard.police.termination.info')}
          </Typography>
          <ul>
            <li>
              {t('dashboard.police.termination.souscripteur')} :{' '}
              <ResilInfos>
                {police.subscriber?.title} {police.subscriber?.lastname}{' '}
                {police.subscriber?.firstname}
              </ResilInfos>
            </li>
            <li>
              {t('dashboard.police.termination.typeContrat')} :{' '}
              <ResilInfos>
                {getLabelFromPoliceType(police.contractType?.code)}
              </ResilInfos>
            </li>
            <li>
              {t('dashboard.police.termination.adresse')} :{' '}
              <ResilInfos>{adresse}</ResilInfos>
            </li>
            <li>
              {t('dashboard.police.termination.motif')} :{' '}
              <ResilInfos>
                {t('dashboard.police.termination.sans_engagement')}
              </ResilInfos>
            </li>
          </ul>

          <Typography as={'span'} variant="body3">
            <Trans
              i18nKey="dashboard.police.termination.info_date_effet"
              values={{
                dateEffetResil: formatDate(dateEffetResiliation, 'DD/MM/YYYY'),
              }}
              components={{ style: <ResilInfos /> }}
              tOptions={{ interpolation: { escapeValue: false } }}
            />
          </Typography>
        </Box>
      }
      validateButtonLabel={t('dashboard.police.termination.confirmer')}
      maxWidth="sm"
      showCancelButton={true}
      onValidate={() => onValidate()}
      validateLoading={resiliationEnCours}
      useValidateLoader={true}
    />
  );
};

ConfirmationSansEngag.propTypes = {
  dateEffetResiliation: PropTypes.object.isRequired,
  police: PropTypes.object.isRequired,
  adresse: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  resiliationEnCours: PropTypes.bool.isRequired,
  onValidate: PropTypes.func.isRequired,
};

const ResilInfos = ({ children }) => (
  <Typography
    color={'primary'}
    display={'inline'}
    style={{ fontWeight: 'bold' }}
  >
    {children}
  </Typography>
);

export default ConfirmationSansEngag;
