import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Popin from 'components/Popin';
import PropTypes from 'prop-types';
import React from 'react';
import './../index.scss';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(3),
  },
  content: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  categorieTitle: {
    fontWeight: 'bold',
  },
  zone: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
    color: '#000000!important',
    fontStyle: 'italic',
    fontWeight: 'bold',
    fontSize: '0.9rem!important',
  },
}));

const GuaranteePopin = (props) => {
  const { guarantee, open, onClose } = props;
  const classes = useStyles();

  const renderContent = (guarantee) => {
    return (
      <>
        <Box textAlign="center" className={classes.title}>
          <Typography variant="h6">{guarantee.title}</Typography>
        </Box>
        <Box className={classes.content}>
          {guarantee.categories.map((category, index) => (
            <Box key={index}>
              <Typography className={classes.categorieTitle}>
                {category.title}
              </Typography>
              <Box color="#000000">
                <div dangerouslySetInnerHTML={category.description} />
              </Box>
            </Box>
          ))}
        </Box>

        <Box>
          <Typography variant="body2" className={classes.zone}>
            {guarantee.additional}
          </Typography>
        </Box>
      </>
    );
  };

  return (
    <Popin
      open={open}
      onClose={onClose}
      content={renderContent(guarantee)}
      maxWidth="sm"
      enableTitleArea={false}
    />
  );
};

GuaranteePopin.propTypes = {
  guarantee: PropTypes.shape({
    title: PropTypes.string,
    icon: PropTypes.string,
    additional: PropTypes.string,
    categories: PropTypes.array,
    description: PropTypes.array,
    examples: PropTypes.array,
  }).isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

GuaranteePopin.defaultProps = {
  open: false,
};

export default GuaranteePopin;
