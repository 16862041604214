import React from 'react';
import PropTypes from 'prop-types';
import { Box, Grid, Typography } from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { useTranslation } from 'react-i18next';

import SinisterReason from 'components/SinisterReason/SinisterReason';
import { POLICY_PROPERTYS } from 'constants/policy/policyParams';
import Lottie from 'components/Lottie';
import { sinistreData } from '../BlocNatureSin';
import useStyles from '../style';
import Popin from 'components/Popin';

const PopinClaimsiaContent = ({ reason, date, policy }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const reasonObject = sinistreData.find((r) => reason === r.reason);

  if (policy == null) return null;

  const asset =
    POLICY_PROPERTYS[policy?.contractType?.code][policy?.housingType]?.avatar;

  return (
    <Box className={classes.policyContainer}>
      <Box
        mt={1}
        width={1}
        display="flex"
        flexDirection="column"
        justifyContent="center"
      >
        <Grid container xs={12} lg={12}>
          <Box
            mb={2}
            display="flex"
            width={1}
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="body2" color="secondary">
              {t('dashboard.sinistre.popin.declare')}
            </Typography>
          </Box>
          <Box
            mb={4}
            display="flex"
            width={1}
            alignItems="center"
            flexDirection="column"
          >
            <SinisterReason
              asset={reasonObject?.asset}
              label={t(reasonObject?.label)}
              selected
            />
          </Box>
        </Grid>
        <Grid>
          <Box
            mb={2}
            display="flex"
            width={1}
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="body2" color="secondary">
              {t('dashboard.sinistre.popin.occured')}
            </Typography>
          </Box>
          <Box
            mb={4}
            display="flex"
            width={1}
            alignItems="center"
            flexDirection="column"
          >
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inlined"
                inputVariant="filled"
                format="DD/MM/yyyy"
                className="date-picker-input"
                label={t('dashboard.sinistre.form.info.date')}
                value={date}
                maxDateMessage={null}
                minDateMessage={null}
                invalidDateMessage={t('error.fields.invalidDate')}
                disabled
              />
            </MuiPickersUtilsProvider>
          </Box>
        </Grid>
        <Grid>
          <Box
            mb={2}
            display="flex"
            width={1}
            alignItems="center"
            flexDirection="column"
          >
            <Typography variant="body2" color="secondary">
              {t('dashboard.sinistre.popin.at')}
            </Typography>
          </Box>
          <Box
            mb={4}
            display="flex"
            width={1}
            alignItems="center"
            justifyContent="center"
            className={classes.visual}
          >
            <div className={classes.addressWrapper}>
              <Box>
                <Lottie animationData={asset} isActive height={92} />
              </Box>

              <Box>
                <Typography variant="caption">
                  {t('dashboard.certificates.address')}
                </Typography>
                <Typography variant="body1">{policy?.policyKey}</Typography>
              </Box>
            </div>
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

PopinClaimsiaContent.propTypes = {
  date: PropTypes.string,
  reason: PropTypes.string,
  policy: PropTypes.object,
};

const ClaimsiaPopin = ({
  natureSin,
  dateSurvenance,
  police,
  showClaimsiaPopin,
  onClose,
  lienPlatformClaims,
}) => {
  const { t } = useTranslation();

  return (
    <Popin
      title={t('dashboard.sinistre.form.info.continue')}
      content={
        <PopinClaimsiaContent
          reason={natureSin}
          date={dateSurvenance}
          policy={police}
        />
      }
      open={showClaimsiaPopin}
      onClose={onClose}
      validateButtonLabel={t('dashboard.sinistre.form.info.continue')}
      onValidate={() => {
        if (lienPlatformClaims) window.location.replace(lienPlatformClaims);
      }}
      showCancelButton
    />
  );
};

ClaimsiaPopin.propTypes = {
  natureSin: PropTypes.string,
  dateSurvenance: PropTypes.string.isRequired,
  police: PropTypes.object,
  showClaimsiaPopin: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  lienPlatformClaims: PropTypes.string,
};

export default ClaimsiaPopin;
