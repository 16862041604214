import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  accordion: {
    // border: '1px solid rgba(0, 0, 0, .125)',
    border: 'none',
    boxShadow: 'none',
    borderBottom: 0,
    '&:first-child': {
      borderTop: 0,
    },
    '&:before': {
      display: 'none',
    },
    // '&$expanded': {
    //   margin: 'auto',
    // },
  },
  accordionExpanded: {
    margin: '0 !important',
  },
  expandIcon: {
    marginTop: '0 !important',
    marginBottom: '0 !important',
  },
  summary: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    // borderBottom: '1px solid rgba(0, 0, 0, .125)',
    boder: 'none',
    marginBottom: -1,
    minHeight: '30px !important',
    // '&$expanded': {
    //   minHeight: '30px !important',
    // },
    marginTop: '0 !important',
  },
  zone: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: 8,
    paddingBottom: 8,
  },
  example: {
    fontSize: 13,
    fontStyle: 'italic',
  },
  detailsButton: {
    marginTop: 10,
  },

  additional: {
    fontSize: 13,
    fontWeight: 'bolder',
    fontStyle: 'italic',
  },
  categoryTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  advantage: {
    paddingTop: 10,
    paddingBottom: 10,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: 30,
  },
  icon: {
    width: 'auto',
    color: theme.palette.primary.main,
    marginRight: 10,
  },
  title: {
    lineHeight: 1,
    '&:hover': {
      fontSize: 20,
    },
  },
  guarantees: {
    marginBottom: 50,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 140,
    },
  },
}));

export default useStyles;
