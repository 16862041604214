import React from 'react';
import PropTypes from 'prop-types';
import { Card, Typography, Box } from '@material-ui/core';
import Popover from '@material-ui/core/Popover';
import { useTranslation } from 'react-i18next';
import Lottie from 'components/Lottie';
import AlertOrange from 'assets/lotties/warningning.json';

import useStyles from './style';
import Price from '../Price/index';

const PriceCard = (props) => {
  const { policy, alertText } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const paymentSplitting = policy.paymentSplitting.code;
  const isAnnual = paymentSplitting === 'A';

  const price = {
    annualTTC: policy.annualTtc,
    monthlyTTC: isAnnual ? null : policy.montantFractionnement,
    annualTTCDeBase: null,
    monthlyTTCDeBase: null,
    annualSurtaxe: policy.annualSurtaxe,
  };

  const handleOpenAlertPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <Box className={classes.priceCardContainer}>
        <Card className={classes.priceCard}>
          <div
            className={`${classes.priceCardHeader} ${classes.priceCardHeaderClickable}`}
            onClick={alertText && handleOpenAlertPopover}
          >
            <Typography className={classes.priceSplitting}>{`${
              isAnnual
                ? t('dashboard.police.price.annualFee')
                : t('dashboard.police.price.monthlyPrice')
            }`}</Typography>
            {alertText && (
              <span>
                <Lottie
                  animationData={AlertOrange}
                  isActive
                  preventMouseLeaveEffectIfActive
                  height={38}
                />
              </span>
            )}
          </div>
          <div className={classes.priceCardFooter}>
            <Price
              price={price}
              size="dashboard"
              periodicity={paymentSplitting}
            />
          </div>
        </Card>
      </Box>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <Box p={2} className={classes.popover}>
          <Typography classes={{ root: classes.typography }}>
            {alertText}
          </Typography>
        </Box>
      </Popover>
    </>
  );
};

PriceCard.propTypes = {
  policy: PropTypes.shape().isRequired,
  alertText: PropTypes.string,
};

export default PriceCard;
