import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  titleWrapper: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    // height: 30,
  },
  title: {
    fontSize: 17,
    fontWeight: 'bolder',
    alignSelf: 'flex-end',
    minHeight: 10,
  },
  content: {
    padding: 0,
  },
  icon: { width: 'auto', marginRight: 10, color: theme.palette.primary.main },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

export default useStyles;
