import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  '@keyframes blinker': {
    from: { opacity: '1' },
    to: { opacity: '0' },
  },
  priceWrapper: {
    display: 'flex',
    // alignItems: 'center',
    position: 'relative',
    height: 40,
    width: 40,
    borderRadius: '50%',
    backgroundColor: theme.palette.prussiaBlue.main,
  },
  price: {
    display: 'flex',
    position: 'relative',
    alignItems: 'center',
    // justifyContent: 'center',
    // padding: 10,
  },
  smallPrice: {
    color: theme.palette.white.main,
    fontSize: 13,
  },
  unit: {
    color: theme.palette.white.main,
    fontSize: 20,
  },
  decimalWrapper: {
    height: 32,
    position: 'relative',
    marginLeft: 1,
  },
  decimal: {
    color: theme.palette.white.main,
    position: 'absolute',
    lineHeight: 'normal',
    fontSize: 14,
    fontFamily: 'Domine',
    top: 2,
  },
  periodWrapper: {
    height: 38,
    width: 'auto',
    position: 'relative',
  },
  period: {
    color: theme.palette.white.main,
    position: 'absolute',
    fontSize: 12,
    bottom: 2,
    left: 0,
    fontFamily: 'Domine',
  },
  blink: {
    animationName: '$blinker',
    animationDuration: '0.4s',
    animationTimingFunction: 'linear',
    animationIterationCount: 6,
    animationDirection: 'alternate',
  },
  priceOldThrough: {
    textDecoration: 'line-through',
    color: '#7d6565',
    fontFamily: 'Domine',
    fontSize: 12,
  },
  priceOld: {
    color: '#7d6565',
    fontFamily: 'Domine',
    fontSize: 12,
  },
  priceSmall: {
    color: theme.palette.white.main,
  },
  infoIcon: {
    color: theme.palette.white.main,
    fontSize: 18,
    width: 'auto',
  },
  infoIconDashboard: {
    color: theme.palette.secondary.main,
    fontSize: 18,
    width: 'auto',
  },
  infoButton: {
    padding: 0,
  },
  white: {
    color: theme.palette.white.main,
  },
  dashboardSmall: {
    fontSize: 12,
  },
}));

export default useStyles;
