import React, { useState, useEffect } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import Hidden from '@material-ui/core/Hidden';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next'
import { useSnackbar } from 'notistack';
import authApi from 'api/authApi';
import Lottie from 'components/Lottie';
import Icon from 'components/Icon';
import { passwordStructure } from 'utils/form-validation';
import useStyles from './style';
import Home from 'assets/lotties/home.json';
import HomeMobile from 'assets/lotties/home-mobile.json';

const Recovery = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { recoveryToken } = useParams();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userInputs, setUserInputs] = useState({});
  const [complexityPasswordError, setComplexityPasswordError] = useState(null);
  const [samePasswordError, setSamePasswordError] = useState(null);
  const [containsUpperCase, setContainsUpperCase] = useState(false);
  const [containsLowerCase, setContainsLowerCase] = useState(false);
  const [containsNumber, setContainsNumber] = useState(false);
  const [isUpToMinimumLength, setIsUpToMinimumLength] = useState(false);
  const [containsSpecialChars, setContainsSpecialChars] = useState(false);

  const verifyRecoveryToken = async () => {
    try {
      await authApi.verifyRecoveryToken(recoveryToken);
    } catch (error) {
      enqueueSnackbar(t('error.authentication.recoveryTokenExpired'), { variant: 'error' });
      setTimeout(() => {
        history.push('/');
      }, 5000);
    }
  };

  const resetPasword = async () => {

    if (isFormValid()) {
      try {
        setLoading(true);

        await authApi.resetPassword(
          recoveryToken,
          userInputs.newPassword
        );

        enqueueSnackbar(t('success.authentication.passwordChanged'), {
          variant: 'success',
        });

        history.push('/');

      } catch (error) {
        if (error.errorCode && error.errorMessage) {
            const message = error.errorMessage[i18next.resolvedLanguage];
            enqueueSnackbar(message, { variant: 'error' });
          } else {
           setError(t('error.unknown'));
          }
      } finally {
        setLoading(false);
      }
    }
  };

  const isFormValid = () => {
    const passwordRules = getPasswordRules();
    const goodPasswordCondition =
      passwordRules.containsUpperCase() &&
      passwordRules.containsLowerCase() &&
      passwordRules.containsSpecialChars() &&
      passwordRules.containsNumbers() &&
      passwordRules.isUpToMinimumLength();

    if (!goodPasswordCondition) setComplexityPasswordError(true);
    else setComplexityPasswordError(false);

    const goodPasswordMatchCondition =
      userInputs.confirmPassword === userInputs.newPassword;
    if (!goodPasswordMatchCondition) setSamePasswordError(true);
    else setSamePasswordError(false);

    return goodPasswordCondition && goodPasswordMatchCondition;
  };

  const handleChange = (event) => {
    const { id, value } = event.target;
    setUserInputs({ ...userInputs, [id]: value });
  };

  const getPasswordHelperText = () => {
    return (
      <Box className={classes.rulesWrapper}>
        <Typography classes={{ root: classes.rulesTitle }}>
          {t('auth.passwordCreation.passwordPolicyRequirementsTitle')}
        </Typography>
        <ul className={classes.ul}>
          <li className={isUpToMinimumLength ? classes.liOk : classes.li}>
            <Typography>
              {t(
                'auth.passwordCreation.passwordPolicyRequirements.minimumCharacters'
              )}
            </Typography>
          </li>
          <li
            className={
              containsLowerCase && containsUpperCase && containsNumber
                ? classes.liOk
                : classes.li
            }
          >
            <Typography>
              {t(
                'auth.passwordCreation.passwordPolicyRequirements.typeOfCharacters'
              )}
            </Typography>
          </li>
          <li className={containsSpecialChars ? classes.liOk : classes.li}>
            <Typography>
              {t(
                'auth.passwordCreation.passwordPolicyRequirements.specialCharacter'
              )}
            </Typography>
            <Typography variant="caption" classes={{ root: classes.caption }}>
              {t('auth.passwordCreation.passwordPolicyRequirements.example')}
            </Typography>
          </li>
          <div
            style={{
              display: 'flex',
              marginLeft: -17,
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <Icon className="fas fa-info-circle" style={{ fontSize: 15 }} />
            <Typography style={{ lineHeight: 1, marginLeft: 5 }}>
              {t(
                'auth.passwordCreation.passwordPolicyRequirements.passwordMustNotContain'
              )}
            </Typography>
          </div>
        </ul>
      </Box>
    );
  };

  const getVisibilityIcon = () => {
    return showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />;
  };

  const getPasswordRules = () => {
    return passwordStructure(userInputs.newPassword);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  useEffect(() => {
    if (recoveryToken) verifyRecoveryToken();
  }, [recoveryToken]);

  useEffect(() => {
    if (userInputs.newPassword) {
      setContainsUpperCase(getPasswordRules().containsUpperCase());
      setContainsLowerCase(getPasswordRules().containsLowerCase());
      setContainsSpecialChars(getPasswordRules().containsSpecialChars());
      setContainsNumber(getPasswordRules().containsNumbers());
      setIsUpToMinimumLength(getPasswordRules().isUpToMinimumLength());
    }
  }, [userInputs]);

  return (
    <div className={classes.wrapper}>
      <Box className={classes.boxLottie}>
        <Hidden xsDown>
          <Lottie animationData={Home} isActive width={'100%'} />
        </Hidden>
        <Hidden smUp>
          <Lottie animationData={HomeMobile} isActive />
        </Hidden>
      </Box>
      <div className={classes.container}>
        <Paper
          variant="elevation"
          elevation={0}
          className={classes.logoWrapper}
        >
          <img
            src={`${localStorage.getItem(
              'IMAGE_MANAGER_URL_GET'
            )}/assurimo/base/logo-assurimo.png`}
            className={classes.logo}
            width={380}
          />
        </Paper>

        <Paper variant="elevation" elevation={0} className={classes.paper}>
          {loading && (
            <LinearProgress
              variant="indeterminate"
              classes={{
                root: classes.linearProgressRoot,
                colorPrimary: classes.linearProgressColorPrimary,
                bar: classes.linearProgressBar,
              }}
            />
          )}
          <Typography variant="subtitle2" classes={{ root: classes.title }}>
            {t('auth.recovery.title')}
          </Typography>
          <Grid container>
            <Grid container item spacing={3}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <TextField
                    id="newPassword"
                    type={showPassword ? 'text' : 'password'}
                    label={t('dashboard.settings.password.newPassword')}
                    onChange={handleChange}
                    error={samePasswordError || complexityPasswordError}
                    variant="outlined"
                    fullWidth
                    helperText={
                      complexityPasswordError &&
                      t(
                        'dashboard.settings.password.errors.verifyPasswordRules'
                      )
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {getVisibilityIcon()}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    id="confirmPassword"
                    type={showPassword ? 'text' : 'password'}
                    label={t('dashboard.settings.password.confirmPassword')}
                    onChange={handleChange}
                    helperText={
                      samePasswordError &&
                      t('dashboard.settings.password.errors.samePassword')
                    }
                    error={samePasswordError}
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                          >
                            {getVisibilityIcon()}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid container item>
                  {getPasswordHelperText()}
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    fullWidth
                    classes={{ root: classes.button }}
                    onClick={resetPasword}
                    disabled={
                      !getPasswordRules().containsUpperCase() ||
                      !getPasswordRules().containsLowerCase() ||
                      !getPasswordRules().containsSpecialChars() ||
                      !getPasswordRules().containsNumbers() ||
                      !getPasswordRules().isUpToMinimumLength() ||
                      userInputs.confirmPassword !== userInputs.newPassword
                    }
                  >
                    <Typography classes={{ root: classes.buttonText }}>
                      {t('common.validate')}
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default Recovery;
