import { TYPE_PERSON_LINK_ENFANT } from 'constants/common/common';
import {
  getHeaderFetch,
  responseWithJson,
  responseWithVoid,
  getHeaderCustomFetch,
} from './httpUtil';

const BASE_V2_URL = '/v2/customers';
const BASE_V3_URL = '/v3/customers';

const customerApi = {
  async getUserInfo(auth, customerNumber) {
    const url = `${BASE_V2_URL}/${customerNumber}`;
    const response = await fetch(url, { headers: getHeaderFetch(auth) });

    return responseWithJson(response);
  },

  async getPolicies(auth, customerNumber) {
    const url = `${BASE_V2_URL}/${customerNumber}/policies`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify({ page: 1, size: 20 }),
      headers: getHeaderFetch(auth),
    });

    return responseWithJson(response);
  },

  async createVisitor(params) {
    const url = `${BASE_V2_URL}/visitor/individual`;

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: getHeaderFetch(),
    });

    return responseWithJson(response);
  },

  async createProspect(params) {
    const url = `${BASE_V2_URL}/prospect/individual`;

    console.log('fetch', JSON.stringify(params));
    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(params),
      headers: getHeaderFetch(),
    });

    return responseWithJson(response);
  },

  async getCustomerState(mail) {
    const url = `${BASE_V2_URL}/etat?mail=${mail}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: getHeaderFetch(),
    });

    return responseWithJson(response);
  },

  async getBankCardUsed(auth, customerNumber) {
    const url = `${BASE_V2_URL}/${customerNumber}/usedBankcard`;

    const response = await fetch(url, { headers: getHeaderFetch(auth) });
    return responseWithJson(response);
  },

  async getChildren(auth, customerNumber) {
    const url = `${BASE_V2_URL}/${customerNumber}/persons?linkType=${TYPE_PERSON_LINK_ENFANT}`;

    const response = await fetch(url, { headers: getHeaderFetch(auth) });
    return responseWithJson(response);
  },

  async getPersons(auth, customerNumber) {
    const url = `${BASE_V2_URL}/${customerNumber}/persons`;

    const response = await fetch(url, { headers: getHeaderFetch(auth) });
    return responseWithJson(response);
  },

  async addPerson(auth, customerNumber, params) {
    const url = `${BASE_V2_URL}/${customerNumber}/persons`;

    const response = await fetch(url, {
      headers: getHeaderFetch(auth),
      method: 'POST',
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  async updatePerson(auth, customerNumber, personUuid, params) {
    const url = `${BASE_V2_URL}/${customerNumber}/persons/${personUuid}`;

    const response = await fetch(url, {
      headers: getHeaderFetch(auth),
      method: 'PUT',
      body: JSON.stringify(params),
    });
    return responseWithJson(response);
  },

  async deletePerson(auth, customerNumber, personUuid) {
    const url = `${BASE_V2_URL}/${customerNumber}/persons/${personUuid}`;

    const response = await fetch(url, {
      headers: getHeaderFetch(auth),
      method: 'DELETE',
    });
    return responseWithJson(response);
  },

  async listCard(auth, customerNumber) {
    const url = `${BASE_V2_URL}/${customerNumber}/cards`;

    const response = await fetch(url, { headers: getHeaderFetch(auth) });
    return responseWithJson(response);
  },

  async setMainCard(auth, customerNumber, bankcardUuid) {
    const url = `${BASE_V2_URL}/${customerNumber}/main-card/${bankcardUuid}`;

    const response = await fetch(url, {
      headers: getHeaderFetch(auth),
      method: 'PUT',
    });
    return responseWithJson(response);
  },
  async uploadFileToGed(auth, customerNumber, fileType, params) {
    const url = `${BASE_V2_URL}/${customerNumber}/ged/upload/${fileType}`;

    const response = await fetch(url, {
      method: 'POST',
      headers: getHeaderCustomFetch(auth),
      body: params,
    });
    return responseWithVoid(response);
  },
  async unsubcribe(params) {
    const url = `${BASE_V2_URL}/unsubscribing`;

    const response = await fetch(url, {
      headers: getHeaderFetch(),
      method: 'POST',
      body: JSON.stringify(params),
    });
    return responseWithVoid(response);
  },

  async callFinScan(auth, customerNumber, params) {
    const url = `${BASE_V2_URL}/${customerNumber}/finscan`;

    const response = await fetch(url, {
      headers: getHeaderFetch(auth),
      method: 'POST',
      body: JSON.stringify(params),
    });

    return responseWithJson(response);
  },

  async updateClient(auth, customerNumber, params) {
    const url = `${BASE_V3_URL}/${customerNumber}/update`;

    const response = await fetch(url, {
      headers: getHeaderFetch(auth),
      method: 'POST',
      body: JSON.stringify(params),
    });

    return responseWithJson(response);
  },
};

export default customerApi;
