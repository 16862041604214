import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import i18next from 'i18next';

import Guarantees from 'components/Guarantees/index';
import guaranteesListMrh from 'constants/guarantees/mrh';
import guaranteesListPno from 'constants/guarantees/pno';

import useStyles from './style';
import { TYPE_POLICE_H, TYPE_POLICE_PNO } from 'constants/index';
import { PAGE_SECTION_SHOW } from 'views/Dashboard/Customer/PolicyPage/helper';

const GuaranteesZone = ({ police, scrollRef }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { contractType } = police;
  const { code } = contractType;
  const [guaranteesList, setGuaranteesList] = useState();

  const getGuaranteesList = () => {
    switch (code) {
      case TYPE_POLICE_H:
        setGuaranteesList(guaranteesListMrh(t));
        return;
      case TYPE_POLICE_PNO:
        setGuaranteesList(guaranteesListPno(t));
        return;
      default:
        setGuaranteesList(null);
        return;
    }
  };

  useEffect(() => {
    getGuaranteesList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.resolvedLanguage]);

  return PAGE_SECTION_SHOW[police.contractType.code].guarantees &&
    guaranteesList ? (
    <Grid container className={classes.guaranteesContainer} ref={scrollRef}>
      <Box p={2} className={classes.container} width="100%">
        <Grid container>
          <Box mb={2} width="100%">
            <Typography variant="h5" color="secondary">
              {t('dashboard.police.guarantees.title')}
            </Typography>
          </Box>
        </Grid>
        <Guarantees
          guarantees={guaranteesList}
          showButton={false}
          removePadding={true}
          hideDocuments={true}
          typeGuarantee={code}
        />
      </Box>
    </Grid>
  ) : (
    <></>
  );
};

GuaranteesZone.propTypes = {
  police: PropTypes.shape(),
  scrollRef: PropTypes.object,
};

export default GuaranteesZone;
