import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

const Title = (props) => {
  const { title, children, warning, variant, marginBottom, align, maxWidth } =
    props;

  return (
    <Container>
      <Box mb={marginBottom}>
        <Typography
          style={{
            maxWidth: maxWidth || 560,
            margin: 'auto',
          }}
          variant={variant}
          align={align || 'center'}
          color={warning ? 'primary' : 'secondary'}
        >
          {title ? (
            <div dangerouslySetInnerHTML={{ __html: title }} />
          ) : (
            children
          )}
        </Typography>
      </Box>
    </Container>
  );
};
Title.propTypes = {
  title: PropTypes.string,
  warning: PropTypes.bool,
  variant: PropTypes.string,
  marginBottom: PropTypes.number,
  align: PropTypes.string,
  maxWidth: PropTypes.number,
};

Title.defaultProps = {
  variant: 'h4',
  marginBottom: 5,
};

export default Title;
