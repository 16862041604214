import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { ASSURIMO_INDIVIDUAL_EMAIL, ASSURIMO_PHONE_NUMBER } from 'constants';
import Popin from 'components/Popin';
import PaperImage from 'assets/images/paper.svg';

const RetractImpossible = ({ isOpen, onClose }) => {
  const { t } = useTranslation();

  return (
    <Popin
      open={isOpen}
      onClose={onClose}
      title={t('dashboard.police.retractation.popin_title')}
      maxWidth="sm"
      content={
        <Box
          style={{
            display: 'flex',
            margin: 32,
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 25,
          }}
        >
          <img
            src={PaperImage}
            alt="Assurimo"
            style={{
              width: 160,
            }}
          />
          <Typography variant="h5" style={{ textAlign: 'center' }}>
            {t('dashboard.police.retractation.erreur_head')}
          </Typography>

          <Typography variant="body3" style={{ textAlign: 'center' }}>
            <Trans
              i18nKey="dashboard.police.retractation.erreur_info"
              values={{
                numeroSupport: ASSURIMO_PHONE_NUMBER,
                mailSupport: ASSURIMO_INDIVIDUAL_EMAIL,
              }}
              components={{
                tel: (
                  <a
                    style={{ whiteSpace: 'nowrap' }}
                    href={`tel:${ASSURIMO_PHONE_NUMBER}`}
                  />
                ),
                mail: <a href={`mailto:${ASSURIMO_INDIVIDUAL_EMAIL}`} />,
              }}
            />
          </Typography>
        </Box>
      }
      showCancelButton={false}
      isValidateButtonDisabled={false}
      useValidateLoader={true}
    />
  );
};

RetractImpossible.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

export default RetractImpossible;
