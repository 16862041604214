import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Card, Typography } from '@material-ui/core';

import { CLAIMS_STATUS_CODE } from 'constants/common/sinistreStatusCode';
import { useStyles } from '../style';

SinistreStatut.propTypes = {
  sinistre: PropTypes.object,
};

function SinistreStatut({ sinistre }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    status: { code: statusCode },
  } = sinistre;

  const { CLOSS, CLOSTER, EC, PREDECLA, PARCOURS } = CLAIMS_STATUS_CODE;

  return (
    <Box className={classes.statusCard} style={{ marginBottom: 10 }}>
      <Box className={classes.cardContainer}>
        <Card className={classes.card}>
          {[EC.code, PREDECLA.code, PARCOURS.code].includes(statusCode) && (
            <>
              <div className={`${classes.cardHeader} inProgress`}>
                <Typography className={classes.statusText}>
                  {t('dashboard.sinistre.list.stat_ec')}
                </Typography>
              </div>
            </>
          )}
          {[CLOSS.code, CLOSTER.code].includes(statusCode) && (
            <>
              <div className={`${classes.cardHeader} close`}>
                <Typography className={classes.statusText}>
                  {t('dashboard.sinistre.list.stat_close')}
                </Typography>
              </div>
            </>
          )}
        </Card>
      </Box>
    </Box>
  );
}

export default SinistreStatut;
