import React from 'react';
import PropTypes from 'prop-types';
import { Trans } from 'react-i18next';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

const ConfirmationPage = (props) => {
  const { email } = props;

  return (
    <>
      <Box
        m={2}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <Typography variant="body1">
          <Trans
            i18nKey="auth.accountUpdate.sureToChangeEmail"
            values={{ email: email }}
          />
        </Typography>
      </Box>
    </>
  );
};

ConfirmationPage.propTypes = {
  onValidate: PropTypes.func.isRequired,
  email: PropTypes.string,
};

export default ConfirmationPage;
