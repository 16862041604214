import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  beneficiariesWrapper: {
    paddingTop: theme.spacing(1),
  },
  container: {
    borderRadius: 10,
    backgroundColor: '#fff',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
  details: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  cardContent: {
    background: theme.palette.blue.light,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(2),
    borderRadius: 10,
  },
  clientNumber: {
    fontWeight: 'bold',
    color: theme.palette.white.main,
  },
  clientNumberLabel: {
    fontWeight: 'bold',
    color: theme.palette.white.main,
  },
  infoLabel: {
    color: theme.palette.secondary.main,
  },
}));

export default useStyles;
