import { Container, Grid } from '@material-ui/core';
import { FormControl } from '@material-ui/core/index';
import { makeStyles } from '@material-ui/core/styles';
import Field from 'components/Field/index';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty, isPhoneNumber } from 'utils/form-validation';
import React from 'react';

PopinModificationTelephone.propTypes = {
  onChange: PropTypes.func,
  isFormValid: PropTypes.func
};

const useStyles = makeStyles((theme) => ({
  popupContent: {
    padding: theme.spacing(2),
  },
}));

function PopinModificationTelephone(props) {
  const classes = useStyles();
  const { onChange, isFormValid } = props;

  const { t } = useTranslation();

  const handleChange = (event) => {
    const value = event.target.value;
    const isValidPhoneNumber = validerPhoneNumber(value);
    isFormValid(isValidPhoneNumber);
    onChange && onChange(value)
  }

  const validerPhoneNumber = phoneNumber => {
    if(isEmpty(phoneNumber) || !isPhoneNumber(phoneNumber)){
      return false;
    }

    return true;
  }

  return (
    <Container>
      <Grid container spacing={2} className={classes.popupContent}>
          <Grid item xs={12}>
            <FormControl
              fullWidth
              margin="dense"
            >
              <Field
                fullWidth
                label= {t('dashboard.menu.myAccount.tel')}
                type= 'phone'
                onChange={(e) => handleChange(e)}
              />
            </FormControl>
          </Grid>
      </Grid>
    </Container>
  );
}

export default PopinModificationTelephone;
