import React, { useState } from 'react';
import authApi from 'api/authApi';
import { Button, TextField, Paper, Typography, Grid } from '@material-ui/core';
import { isPhoneNumber, isEmail, isEmpty } from 'utils/form-validation';
import { Link } from 'react-router-dom';
import useStyles from './style';

const initialState = {
  customerNumber: '',
  email: '',
  phone: '',
  question: '',
  answer: '',
};
const Signup = () => {
  const classes = useStyles();

  const [state, setState] = useState(initialState);
  const [emailError, setEmailError] = useState(state.error);
  const [clientNumberError, setClientNumberError] = useState(false);
  const [phoneError, setPhoneError] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState(null);

  const handleChange = (event) => {
    setState({ ...state, [event.target.id]: event.target.value });
  };

  const isFormValid = () => {
    const emailCondition = isEmail(state.email);
    const phoneNumberConditon = isPhoneNumber(state.phone);
    const clientNumberCondition = !isEmpty(state.customerNumber);

    if (!emailCondition) {
      if (isEmpty(state.email))
        setEmailError('Vous devez saisir votre adresse mail');
      else setEmailError('Le format de votre adresse mail est invalide');
    }

    if (!phoneNumberConditon) {
      if (isEmpty(state.phone))
        setPhoneError('Vous devez saisir votre numero de téléphone');
      else setPhoneError('Le format de votre numéro de téléphone est invalide');
    }

    if (!clientNumberCondition) {
      setClientNumberError('Vous devez saisir votre numéro client');
    }

    return emailCondition && phoneNumberConditon && clientNumberCondition;
  };

  const handleSignUp = async () => {
    if (isFormValid()) {
      const profile = {
        email: state.email,
        login: state.email,
        primaryPhone: state.phone,
        customerNumber: state.customerNumber,
      };

      try {
        await authApi.signup({ profile });
        setEmailSent(true);
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  return (
    <div className={classes.wrapper}>
      <Paper variant="elevation" elevation={0} className={classes.paper}>
        <Typography classes={{ root: classes.title }}>
          Nouveau compte
        </Typography>

        <Grid container direction="column" spacing={2}>
          {emailSent && (
            <Grid item>
              <Typography>
                Un email d'activation vous a été envoyé à l'adresse{' '}
                {state.email}.{' '}
              </Typography>
              <Typography>
                Cliquez sur le lien d'activation pour activer votre compte
                client.
              </Typography>
              <Button
                variant="contained"
                type="submit"
                color="primary"
                fullWidth
                component={Link}
                classes={{ root: classes.button }}
                to={'/'}
              >
                <Typography classes={{ root: classes.buttonText }}>
                  Aller vers la page de connexion
                </Typography>
              </Button>
            </Grid>
          )}
          {error && (
            <Grid item>
              <Typography>{error}</Typography>
            </Grid>
          )}
          {!error && !emailSent && (
            <>
              <Grid item>
                <TextField
                  id="customerNumber"
                  type="number"
                  label="Numéro client"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  helperText={!!clientNumberError && clientNumberError}
                  error={!!clientNumberError}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="email"
                  type="email"
                  label="Email"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  helperText={!!emailError && emailError}
                  error={!!emailError}
                />
              </Grid>
              <Grid item>
                <TextField
                  id="phone"
                  type="phone"
                  label="Téléphone"
                  onChange={handleChange}
                  variant="outlined"
                  fullWidth
                  helperText={!!phoneError && phoneError}
                  error={!!phoneError}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  fullWidth
                  classes={{ root: classes.button }}
                  onClick={handleSignUp}
                >
                  <Typography classes={{ root: classes.buttonText }}>
                    Créer mon compte
                  </Typography>
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
    </div>
  );
};

export default Signup;
