import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  policyContainer: {
    paddingTop: theme.spacing(6),
    borderRadius: 10,
  },
  container: {
    backgroundColor: '#fff',
    marginTop: theme.spacing(2),
    padding: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  confirmContainer: {
    background: theme.palette.orange.main,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
  },
  box: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',

    '&.boxForm': {
      marginBottom: theme.spacing(3),
      marginTop: theme.spacing(3),

      '& .typography': {
        textAlign: 'center',
      },
    },
  },
  boxPolice: {
    width: '100%',
    marginBottom: theme.spacing(3),
    marginTop: theme.spacing(3),

    '& .typography': {
      textAlign: 'center',
    },
  },
}));

export default useStyles;
