import { getHeaderFetch, responseWithJson } from './httpUtil';

const BASE_URL = '/v2/drs';

const drsApi = {
  async list(auth, drsType) {
    const url = `${BASE_URL}/${drsType}`;

    const response = await fetch(url, { headers: getHeaderFetch(auth) });
    return responseWithJson(response);
  },
};

export default drsApi;
