import brokenGlassGuaranted from './brokenGlass/guaranteed.html';
import brokenGlassNotGuaranted from './brokenGlass/notGuaranteed.html';
import climateGuaranted from './climate/guaranteed.html';
import climateNotGuaranted from './climate/notGuaranteed.html';
import defenseGuaranted from './defense/guaranteed.html';
import defenseNotGuaranted from './defense/notGuaranteed.html';
import electricGuaranted from './electric/guaranteed.html';
import electricNotGuaranted from './electric/notGuaranteed.html';
import fireGuaranted from './fire/guaranteed.html';
import fireNotGuaranted from './fire/notGuaranteed.html';
import juridicGuaranted from './juridic/guaranteed.html';
import juridicNotGuaranted from './juridic/notGuaranteed.html';
import liabilityGuaranted from './liability/guaranteed.html';
import liabilityNotGuaranted from './liability/notGuaranteed.html';
import naturalDisasterGuaranted from './naturalDisaster/guaranteed.html';
import naturalDisasterNotGuaranted from './naturalDisaster/notGuaranteed.html';
import outdoorFacilitiesGuaranted from './outdoorFacilities/guaranteed.html';
import outdoorFacilitiesCoveredProperties from './outdoorFacilities/coveredProperties.html';
import outdoorFacilitiesNotGuaranted from './outdoorFacilities/notGuaranteed.html';
import theftGuaranted from './theft/guaranteed.html';
import theftNotGuaranted from './theft/notGuaranteed.html';
import waterGuaranted from './water/guaranteed.html';
import waterNotGuaranted from './water/notGuaranteed.html';
import BrokenGlass from 'assets/lotties/guarantee/brokenGlass.json';
import Water from 'assets/lotties/guarantee/water.json';
import Climate from 'assets/lotties/guarantee/climate.json';
import Fire from 'assets/lotties/guarantee/fire.json';
import Vol from 'assets/lotties/guarantee/vol.json';
import Defense from 'assets/lotties/guarantee/recoursResponsable.json';
import ResponsabiliteCivil from 'assets/lotties/guarantee/responsabiliteCivil.json';
import Asisstance from 'assets/lotties/guarantee/assistance.json';
import ElectricDamage from 'assets/lotties/guarantee/electricDamage.json';

const guaranteesList = (t) => [
  {
    id: 'fire',
    title: t('common.guarantees.fire.title'),
    examples: t('common.guarantees.fire.examples', { returnObjects: true }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: fireGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: fireNotGuaranted },
      },
    ],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
    icon: 'fas fa-fire',
    link: '#',
    symbol: Fire,
  },
  {
    id: 'electric',
    title: t('common.guarantees.electric.title'),
    examples: t('common.guarantees.electric.examples', { returnObjects: true }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: electricGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: electricNotGuaranted },
      },
    ],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
    icon: 'fas fa-plug',
    link: '#',
    symbol: ElectricDamage,
  },
  {
    id: 'climate',
    title: t('common.guarantees.climate.title'),
    examples: t('common.guarantees.climate.examples', { returnObjects: true }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: climateGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: climateNotGuaranted },
      },
    ],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
    icon: 'fas fa-poo-storm',
    link: '#',
    symbol: Climate,
  },
  {
    id: 'water',
    title: t('common.guarantees.water.title'),
    examples: t('common.guarantees.water.examples', { returnObjects: true }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: waterGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: waterNotGuaranted },
      },
    ],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
    icon: 'fas fa-tint',
    link: '#',
    symbol: Water,
  },
  {
    id: 'brokenGlass',
    title: t('common.guarantees.glass.title'),
    examples: t('common.guarantees.glass.examples', { returnObjects: true }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: brokenGlassGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: brokenGlassNotGuaranted },
      },
    ],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
    icon: 'fas fa-columns',
    link: '#',
    symbol: BrokenGlass,
  },
  {
    id: 'naturalDisaster',
    title: t('common.guarantees.natural.title'),
    examples: t('common.guarantees.natural.examples', { returnObjects: true }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: naturalDisasterGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: naturalDisasterNotGuaranted },
      },
    ],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
    icon: 'fas fa-house-damage',
    link: '#',
    symbol: Climate,
  },
  {
    id: 'theft',
    title: t('common.guarantees.theft.title'),
    examples: t('common.guarantees.theft.examples', { returnObjects: true }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: theftGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: theftNotGuaranted },
      },
    ],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
    icon: 'fas fa-hammer',
    link: '#',
    symbol: Vol,
  },
  {
    id: 'liability',
    title: t('common.guarantees.publicLiability.title'),
    examples: t('common.guarantees.publicLiability.examples', {
      returnObjects: true,
    }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: liabilityGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: liabilityNotGuaranted },
      },
    ],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
    icon: 'fas fa-id-card-alt',
    link: '#',
    symbol: ResponsabiliteCivil,
  },
  {
    id: 'defense',
    title: t('common.guarantees.defense.title'),
    examples: t('common.guarantees.defense.examples', { returnObjects: true }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: defenseGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: defenseNotGuaranted },
      },
    ],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
    icon: 'fas fa-shield-alt',
    link: '#',
    symbol: Defense,
  },
  {
    id: 'juridicProtection',
    title: t('common.guarantees.legalProtection.title'),
    examples: t('common.guarantees.legalProtection.examples', {
      returnObjects: true,
    }),
    categories: [
      {
        title: 'Ce que nous garantissons',
        description: { __html: juridicGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: juridicNotGuaranted },
      },
    ],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
    icon: 'fas fa-gavel',
    link: '#',
    symbol: Defense,
  },
  {
    id: 'assistance',
    title: t('common.guarantees.assistance.title'),
    examples: t('common.guarantees.assistance.examples', {
      returnObjects: true,
    }),
    categories: [],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
    icon: 'fas fa-headset',
    link: '#',
    symbol: Asisstance,
  },
  {
    id: 'outdoorFacilities',
    title: t('common.guarantees.exteriorFittings.title'),
    examples: t('common.guarantees.exteriorFittings.examples', {
      returnObjects: true,
    }),
    categories: [
      {
        title: 'Biens couverts',
        description: { __html: outdoorFacilitiesCoveredProperties },
      },
      {
        title: 'Ce que nous garantissons',
        description: { __html: outdoorFacilitiesGuaranted },
      },
      {
        title: 'Ce que nous ne garantissons pas',
        description: { __html: outdoorFacilitiesNotGuaranted },
      },
    ],
    additional:
      'Pour l’intégralité des conditions de garanties, se reporter aux conditions générales.',
    icon: 'fas fa-umbrella-beach',
    link: '#',
  },
];

export default guaranteesList;
