import React, { useEffect, useState } from 'react';
import { Box, Button, Grid } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useSnackbar } from 'notistack';
import { useOktaAuth } from '@okta/okta-react';
import { useTranslation } from 'react-i18next';
import { useRouteMatch } from 'react-router';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

import { getMessageError } from 'constants/common/errorCode';
import policyApi from 'api/policyApi';
import Header from 'components/DashboardHeader';
import Popin from 'components/Popin/index';
import Loader from 'components/Loader';
import { WhiteContainedButton } from 'components/WhiteContainedButton';
import BlocDateSurvenanceSin from './BlocDateSin';
import BlocPoliceInfo from './BlocPoliceInfo';
import BlocNatureSin from './BlocNatureSin';
import PopinClaimsia from './PopinClaimsia';
import SuiteDeclaration from './SuiteDeclaration';
import useStyles from './style';
import { isPoliceEligibleADeclaSin } from './helper';

const SinistreDeclarationPage = () => {
  const { authState } = useOktaAuth();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const today = moment().utc().format('YYYY/MM/DD');

  const [auth, setAuth] = useState(null);
  const [police, setPolice] = useState(null);
  const [natureSin, setNatureSin] = useState(null);
  const [reasonError, setReasonError] = useState(null);
  const [date, setDate] = useState(today);
  const [dateError, setDateError] = useState(null);
  const [fromValide, setFromValidee] = useState(false);
  const [declaSinLoading, setDeclaSinLoading] = useState(false);
  const [showSuiteDeclaration, setShowSuiteDeclaration] = useState(false);
  const [showClaimsiaPopin, setShowClaimsiaPopin] = useState(false);
  const [magicLink, setMagicLink] = useState(null);

  const {
    params: { id: policyNumber },
  } = useRouteMatch({
    path: '/polices/:id/nouveau-sinistre',
  });

  useEffect(() => {
    (async () => {
      const {
        isAuthenticated,
        accessToken: { accessToken: token },
      } = authState;
      const auth = { isAuthenticated, token };
      setAuth(auth);
      try {
        const res = await policyApi.getPolice(auth, policyNumber);
        setPolice(res);
      } catch (error) {
        const messageError = getMessageError(error);
        console.error(t(messageError), error);
        enqueueSnackbar(t(messageError), { variant: 'error' });
      }
    })();
  }, [authState, policyNumber]);

  const handleValidate = async () => {
    setFromValidee(true);

    if (!reasonError && !dateError) {
      try {
        setDeclaSinLoading(true);
        const response = await policyApi.checkIfEligibleToClaimsIA(
          auth,
          policyNumber,
          {
            claimsDate: moment(date).format('YYYY-MM-DD[T]HH:mm:ss[Z]'),
            claimsNature: natureSin,
          }
        );

        if (response.eligibleClaimform) {
          // Ouvre une popin pour déclaration via Claimsia
          setMagicLink(response.claimformLink);
          setShowClaimsiaPopin(true);
        } else {
          // Redirige vers page de la suite de déclaration standard
          setShowSuiteDeclaration(true);
        }
      } catch (error) {
        const messageError = getMessageError(error);
        console.error(t(messageError), error);
        enqueueSnackbar(t(messageError), { variant: 'error' });
      } finally {
        setDeclaSinLoading(false);
      }
    }
  };

  const griserBoutonDeclaration = !isPoliceEligibleADeclaSin(police);

  if (showSuiteDeclaration) {
    return <SuiteDeclaration date={date} reason={natureSin} police={police} />;
  }

  return (
    <Box mb={10}>
      <Header
        title={t('dashboard.sinistre.form.header.title')}
        subtitle={''}
        mainAction={
          <Button
            variant="outlined"
            color="primary"
            onClick={() => history.goBack()}
          >
            {t('dashboard.sinistre.form.header.btn_close')}
          </Button>
        }
        otherActions={[]}
      />

      {!police ? (
        <Loader />
      ) : (
        <Box className={classes.policyContainer}>
          <Grid container>
            <Box
              className={classes.container}
              width={1}
              display="flex"
              justifyContent="center"
            >
              <Grid container lg={8}>
                <BlocPoliceInfo
                  police={police}
                  showPoliceDetailsButton={false}
                />
                <BlocNatureSin
                  natureSin={natureSin}
                  setNatureSin={setNatureSin}
                  natureSinError={fromValide && reasonError}
                  setNatureSinError={setReasonError}
                />
                <BlocDateSurvenanceSin
                  date={date}
                  setDate={setDate}
                  dateError={fromValide && dateError}
                  setDateError={setDateError}
                  police={police}
                />
              </Grid>
            </Box>
          </Grid>
          <Grid container>
            <Box
              display="flex"
              width={1}
              alignItems="center"
              flexDirection="column"
              className={classes.confirmContainer}
            >
              <Box my={3} width={1} display="flex" justifyItems="center">
                <Grid container justifyContent="center">
                  <WhiteContainedButton
                    align="center"
                    variant="contained"
                    color="primary"
                    onClick={() => handleValidate()}
                    disabled={declaSinLoading || griserBoutonDeclaration}
                  >
                    {t('dashboard.sinistre.form.info.declare')}
                    {declaSinLoading && (
                      <CircularProgress
                        classes={{ root: classes.circularProgress }}
                        color="primary"
                        size={16}
                      />
                    )}
                  </WhiteContainedButton>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Box>
      )}
      <Popin
        title={t('dashboard.sinistre.form.info.continue')}
        content={
          <PopinClaimsia reason={natureSin} date={date} policy={police} />
        }
        open={showClaimsiaPopin}
        onClose={() => {
          setShowClaimsiaPopin(false);
        }}
        validateButtonLabel={t('dashboard.sinistre.form.info.continue')}
        onValidate={() => {
          if (magicLink) window.location.replace(magicLink);
        }}
        showCancelButton
      />
    </Box>
  );
};

export { SinistreDeclarationPage };
