import React from 'react';
import PropTypes from 'prop-types';
import MuiPhoneNumber from 'material-ui-phone-number';

const PhoneField = (props) => {
  const { id, onChange, ...otherProps } = props;

  const handleChange = (value, country) => {
    let newValue = value;

    if (country.countryCode === 'fr') {
      newValue = value.replaceAll(' ', '').replace('+330', '+33');
    }
    onChange({
      target: {
        id: id,
        value: { value: newValue, country },
      },
    });
  };

  return (
    <MuiPhoneNumber
      defaultCountry={'fr'}
      disableAreaCodes
      variant="filled"
      onChange={handleChange}
      {...otherProps}
    />
  );
};

PhoneField.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default PhoneField;
