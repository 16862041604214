import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useStyles from './style';

const NextStepButton = (props) => {
  const { t } = useTranslation();
  const {
    title = t('common.next'),
    onClick,
    disabled,
    cancelButtonTitle,
    onCancelClick,
  } = props;
  const classes = useStyles();

  return (
    <Grid container justifyContent="center" classes={{ root: classes.wrapper }}>
      {cancelButtonTitle && (
        <Button
          align="center"
          onClick={onCancelClick}
          disabled={disabled}
          variant="outlined"
          color="primary"
        >
          {cancelButtonTitle}
        </Button>
      )}
      <Button
        align="center"
        onClick={onClick}
        disabled={disabled}
        variant="contained"
        color="primary"
      >
        {title}
      </Button>
    </Grid>
  );
};

NextStepButton.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  cancelButtonTitle: PropTypes.string,
  onCancelClick: PropTypes.func,
};

NextStepButton.defaultProps = {
  disabled: false,
};

export default NextStepButton;
