import {
  Box,
  Paper,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@material-ui/core';
import noCard from 'assets/lotties/no-card.json';
import Lottie from 'components/Lottie';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import CardItem from './CardItem';

CardList.propTypes = {
  cards: PropTypes.array,
  onSetMainCard: PropTypes.func,
  onGotoPolice: PropTypes.func,
  setOptionsPopin: PropTypes.func,
};

function CardList(props) {
  const { cards, onSetMainCard, onGotoPolice, setOptionsPopin } = props;

  const { t } = useTranslation();

  return (
    <>
      <Box width="100%">
        {cards.length ? (
          <TableContainer component={Paper}>
            <Table aria-label="simple table">
              <TableBody>
                {cards.map((card, index) => (
                  <CardItem
                    key={index}
                    card={card}
                    onSetMainCard={onSetMainCard}
                    onGotoPolice={onGotoPolice}
                    setOptionsPopin={setOptionsPopin}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <>
            <Lottie animationData={noCard} isActive height={480} />
            <Typography align="center" color="inherit">
              {t('dashboard.card.list.no_card')}
            </Typography>
          </>
        )}
      </Box>
    </>
  );
}

export default CardList;
