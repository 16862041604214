import { Button } from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

function QuittanceItemButton(props) {
  const { onClick, isPaid } = props;
  const { t } = useTranslation();

  const handleClick = () => {
    onClick && onClick();
  };

  return !isPaid ? (
    <div>
      <Button
        variant="outlined"
        color="secondary"
        startIcon={<CreditCardIcon />}
        onClick={handleClick}
      >
        {t('dashboard.police.premiums.paid_btn')}
      </Button>
    </div>
  ) : null;
}

QuittanceItemButton.propTypes = {
  onClick: PropTypes.func,
  isPaid: PropTypes.bool.isRequired,
};

export default QuittanceItemButton;
