import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';

const useStyles = makeStyles((theme) => ({
  quittances: {
    paddingTop: theme.spacing(1),
  },
  container: {
    borderRadius: 10,
    backgroundColor: '#fff',
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(4),
    },
  },
}));

function Quittances() {
  const classes = useStyles();

  return (
    <Grid container className={classes.quittances}>
      <Box p={2} className={classes.container} width="100%">
        <Grid container>
          <Box mb={2}>
            <Typography variant="h5" color="secondary">
              <Skeleton variant="text" width={100} />
            </Typography>
          </Box>
          <Grid item container>
            <Skeleton
              variant="rect"
              width={315}
              height={122}
              style={{
                marginBottom: 10,
              }}
            />
            <Skeleton variant="rect" width={315} height={122} />
          </Grid>
        </Grid>
      </Box>
    </Grid>
  );
}

export default Quittances;
