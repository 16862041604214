import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'moment/locale/fr';
import 'moment/locale/en-gb';
import { Box, Grid } from '@material-ui/core';

import useStyles from 'views/Dashboard/Customer/SinistreDeclarationPage/style';
import { WhiteContainedButton } from 'components/WhiteContainedButton';
import CircularProgress from '@material-ui/core/CircularProgress';

const BoutonValider = ({ isDisabled, isLoading, onValidate }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Grid container>
      <Box
        display="flex"
        width={1}
        alignItems="center"
        flexDirection="column"
        className={classes.confirmContainer}
      >
        <Box my={3} width={1} display="flex" justifyItems="center">
          <Grid container justifyContent="center">
            <WhiteContainedButton
              align="center"
              variant="contained"
              color="primary"
              onClick={onValidate}
              disabled={isDisabled}
            >
              {t('dashboard.sinistre.form.info.declare')}
              {isLoading && (
                <CircularProgress
                  classes={{ root: classes.circularProgress }}
                  color="primary"
                  size={16}
                />
              )}
            </WhiteContainedButton>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

BoutonValider.propTypes = {
  isDisabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onValidate: PropTypes.func.isRequired,
};

export { BoutonValider };
