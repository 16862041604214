import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent } from '@material-ui/core';

import BrokenGlassLottie from 'assets/lotties/claims/brokenGlass.json';
import EvenementClimatiqueLottie from 'assets/lotties/claims/climate.json';
import FireLottie from 'assets/lotties/claims/fire.json';
import OtherLottie from 'assets/lotties/claims/other.json';
import VandalismLottie from 'assets/lotties/claims/vandalism.json';
import WaterLottie from 'assets/lotties/claims/water.json';
import {
  SINISTRE_NATURE_CODE_AUTRE,
  SINISTRE_NATURE_CODE_BRIS_DE_GLACE,
  SINISTRE_NATURE_CODE_DEGAT_DES_EAUX,
  SINISTRE_NATURE_CODE_INCENDIE,
  SINISTRE_NATURE_CODE_VANDALISME,
  SINISTRE_NATURE_CODE_VOL,
} from 'constants/common/sinistreNatureCode';

import SinistreDetails from './Details';
import SinistreStatut from './Statut';
import SinistreLottie from './Lottie';
import SinistreActions from './Actions';
import { useStyles } from './style';

SinistreItem.propTypes = {
  sinistre: PropTypes.object,
};

function SinistreItem({ sinistre }) {
  const classes = useStyles();

  return (
    <Box mb={2} width={1}>
      <Card className={classes.rootCard}>
        <CardContent className={classes.content}>
          <Box
            className={classes.details}
            justifyContent="space-between"
            alignItems="center"
          >
            <Box
              className={classes.contract}
              display="flex"
              justifyContent="flex-start"
              alignItems="center"
            >
              <SinistreLottie sinistre={sinistre} sinistreData={sinistreData} />
              <SinistreDetails
                sinistre={sinistre}
                sinistreData={sinistreData}
              />
            </Box>

            <Box
              display="flex"
              flexDirection={'column'}
              justifyContent="space-between"
              alignItems="center"
            >
              <SinistreStatut sinistre={sinistre} />
              <SinistreActions sinistre={sinistre} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
}

const sinistreData = {
  [SINISTRE_NATURE_CODE_INCENDIE]: {
    asset: FireLottie,
    label: 'dashboard.sinistre.form.reason.incendie',
  },
  [SINISTRE_NATURE_CODE_DEGAT_DES_EAUX]: {
    asset: WaterLottie,
    label: 'dashboard.sinistre.form.reason.dde',
  },
  [SINISTRE_NATURE_CODE_VANDALISME]: {
    asset: VandalismLottie,
    label: 'dashboard.sinistre.form.reason.vandalisme',
  },
  [SINISTRE_NATURE_CODE_VOL]: {
    asset: EvenementClimatiqueLottie,
    label: 'dashboard.sinistre.form.reason.vol',
  },
  [SINISTRE_NATURE_CODE_BRIS_DE_GLACE]: {
    asset: BrokenGlassLottie,
    label: 'dashboard.sinistre.form.reason.bdg',
  },
  [SINISTRE_NATURE_CODE_AUTRE]: {
    asset: OtherLottie,
    label: 'dashboard.sinistre.form.reason.other',
  },
};

export default SinistreItem;
