import { useEffect, useState, useContext } from 'react';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { UserContext } from 'contexts/user';
import documentApi from 'api/documentApi';

export default function (codeRisque, codeTarif, compagnie) {
  const [docContractuels, setDocContractuels] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const [state] = useContext(UserContext);
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      try {
        const response = await documentApi.list(
          state.auth,
          { codeRisque, codeTarif, compagnie }
        );

        setDocContractuels(response);
      } catch (err) {
        console.log(err);
        enqueueSnackbar(t('steps.resultStep.error_generate'), { variant: 'error' });
      }
    })();
  }, [codeRisque, codeTarif, compagnie]);

  return { docContractuels };
}
