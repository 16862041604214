import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  Typography,
  CardHeader,
  CardContent,
  CardActions,
} from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { useTheme } from '@material-ui/core/styles';
import Lottie from 'components/Lottie';
import './SinisterReason.scss';

const SinisterReason = ({ selected, asset, label, onClick }) => {
  const [fade, setFade] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    setTimeout(() => {
      setFade(true);
    }, 10);

    return () => {
      setFade(false);
    };
  }, []);

  return (
    <Grid
      item
      xs={6}
      md={4}
      className={`SinisterReason_Choice ${fade ? 'fade' : ''}`}
    >
      <Card
        variant="outlined"
        className="SinisterReason__Card small"
        classes={{
          root: selected ? 'selected' : '',
        }}
        style={{ position: 'relative' }}
        onClick={onClick}
      >
        <CardHeader
          action={
            <CheckCircle
              color="primary"
              style={{ opacity: selected ? 1 : 0 }}
            />
          }
          style={{ position: 'absolute', width: '100%' }}
        />
        <CardContent className="SinisterReason__Card__content--asset">
          <Lottie
            animationData={asset}
            isActive={selected}
            width={126}
            height={126}
            preventMouseLeaveEffectIfActive
          />
        </CardContent>
        <CardActions
          className={`${
            selected ? 'selected' : ''
          } SinisterReason__Card__title`}
        >
          <CardActions>
            <Typography
              align="center"
              //justifyContent="center"
              variant="body1"
              style={{
                color: selected
                  ? theme.palette.orange.main
                  : theme.palette.blue.main,
              }}
            >
              {label}
            </Typography>
          </CardActions>
        </CardActions>
      </Card>
    </Grid>
  );
};

SinisterReason.propTypes = {
  selected: PropTypes.bool,
  asset: PropTypes.any,
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

SinisterReason.defaultProps = {
  selected: false,
};

export default SinisterReason;
