import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { UserContext } from 'contexts/user';
import PolicyItem from 'components/PolicyItem/PolicyItem';
import PolicyItemSkeleton from 'components/PolicyItem/skeleton';
import { useSnackbar } from 'notistack';
import customerApi from 'api/customerApi';
import {
  POLICE_MOUVEMENT_AN,
  POLICE_MOUVEMENT_ANNP,
  TYPE_POLICE_H,
  TYPE_POLICE_PNO,
} from 'constants/index';
import { getMessageError } from 'constants/common/errorCode';

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(4),
  },
}));

const ListePolicePage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [userState] = useContext(UserContext);

  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(true);

  const getFilteredPolicies = (policies) =>
    policies
      .filter((policy) =>
        [TYPE_POLICE_H, TYPE_POLICE_PNO].includes(policy?.contractType?.code)
      )
      .sort((a, b) => {
        const statusA = a?.status?.code;
        const statusB = b?.status?.code;
        if (statusA === statusB) return 0;
        if (statusA === POLICE_MOUVEMENT_AN) return -1;
        if (statusB === POLICE_MOUVEMENT_AN) return 1;
        if (statusA === POLICE_MOUVEMENT_ANNP) return -1;
        if (statusB === POLICE_MOUVEMENT_ANNP) return 1;
        return 0;
      });

  const getPolicies = async () => {
    try {
      setLoading(true);
      const response = await customerApi.getPolicies(
        userState.auth,
        userState.customerNumber
      );
      if (Array.isArray(response.entities)) {
        const filteredPolicies = getFilteredPolicies(response.entities);
        setPolicies(filteredPolicies);
      }
    } catch (err) {
      console.error(err);
      const messageError = getMessageError(err);
      enqueueSnackbar(t(messageError), { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPolicies();
  }, []);

  const renderPolicies = () => {
    if (!loading) {
      if (policies.length)
        return policies.map((police) => (
          <PolicyItem key={police.policyNumber} policy={police} />
        ));
      else {
        return (
          <Box
            className={classes.actions}
            display="flex"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Typography>{t('dashboard.policies.noResult')}</Typography>
          </Box>
        );
      }
    } else
      return (
        <>
          <PolicyItemSkeleton />
          <PolicyItemSkeleton />
          <PolicyItemSkeleton />
        </>
      );
  };

  return (
    <>
      <Grid container>
        <Grid
          container
          item
          xs={12}
          className={classes.title}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box>
            <Typography variant="h3">
              {t('dashboard.menu.myContracts')}
            </Typography>
          </Box>
        </Grid>
        <Grid container item xs={12}>
          {renderPolicies()}
        </Grid>
      </Grid>
    </>
  );
};

ListePolicePage.propTypes = {
  policies: PropTypes.array,
  onReload: PropTypes.func,
};

export default ListePolicePage;
