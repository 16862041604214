import React, { useState, useEffect } from 'react';
import i18next from 'i18next';
import { useOktaAuth } from '@okta/okta-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import _get from 'lodash/get';

import { LANGUAGES } from 'constants';
import customerApi from 'api/customerApi';
import Title from 'components/Title';
import FileInput from 'components/FileInput/FileInput';
import NextStepButton from 'components/NextStepButton/NextStepButton';

const StyledTextField = withStyles({
  root: {
    width: 250,
  },
})(TextField);

const identityTypes = {
  CNI: 'cni',
  PASSPORT: 'passport',
};

const fileTypes = {
  CNI_RECTO: 'CLI_CNI_R',
  CNI_VERSO: 'CLI_CNI_V',
  PASSPORT: 'CLI_PASSPORT',
};

const defaultCniFiles = {
  CLI_CNI_R: {
    name: '',
    file: '',
    type: fileTypes.CNI_RECTO,
  },
  CLI_CNI_V: {
    name: '',
    file: '',
    type: fileTypes.CNI_VERSO,
  },
};

const defaultPassportFiles = {
  CLI_PASSPORT: {
    name: '',
    file: '',
    type: fileTypes.PASSPORT,
  },
};

const IdentityStep = (props) => {
  const { onConfirm, setUserData, routerParams } = props;

  const { authState } = useOktaAuth();
  const [showErrors, setShowErrors] = useState(false);
  const [identityFiles, setIdentityFiles] = useState(
    i18next.resolvedLanguage === LANGUAGES.fr
      ? defaultCniFiles
      : defaultPassportFiles
  );
  const [identityType, setIdentityType] = useState(
    i18next.resolvedLanguage === LANGUAGES.fr
      ? identityTypes.CNI
      : identityTypes.PASSPORT
  );

  const [error, setError] = useState(null);
  const [callError, setCallError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [auth, setAuth] = useState(null);
  const customerNumber = _get(routerParams, 'customerNumber', null);
  const { t } = useTranslation();

  const handleChangeType = (type) => {
    setIdentityType(type);
    if (type === identityTypes.CNI) setIdentityFiles(defaultCniFiles);
    if (type === identityTypes.PASSPORT) setIdentityFiles(defaultPassportFiles);
  };

  const handleChangeFile = (key, evt) => {
    const newFiles = { ...identityFiles };

    newFiles[key] = {
      name: evt.target.files[0].name,
      file: evt.target.files[0],
      type: key,
    };

    setIdentityFiles(newFiles);
  };

  const getErrors = () => {
    if (
      identityType !== identityTypes.CNI &&
      identityType !== identityTypes.PASSPORT
    ) {
      // eslint-disable-next-line quotes
      return t('steps.identityStep.errors.empty');
    }
    if (
      identityType === identityTypes.CNI &&
      (!identityFiles[fileTypes.CNI_RECTO].file ||
        !identityFiles[fileTypes.CNI_VERSO].file)
    )
      // eslint-disable-next-line quotes
      return t('steps.identityStep.errors.emptyCni');

    if (
      identityType === identityTypes.PASSPORT &&
      !identityFiles[fileTypes.PASSPORT].file
    )
      return t('steps.identityStep.errors.emptyPassport');

    return null;
  };

  const handleValidate = async () => {
    setShowErrors(true);

    if (!error) {
      // setLoading(true);

      if (!customerNumber) {
        setUserData('identityFiles', identityFiles);
        onConfirm();
      } else {
        try {
          Promise.all(
            Object.keys(identityFiles).map(async (fileKey) => {
              const file = identityFiles[fileKey];
              const formData = new FormData();
              formData.append('file', file.file);

              await customerApi.uploadFileToGed(
                auth,
                customerNumber,
                file.type,
                formData
              );
              return Promise.resolve();
            })
          );
          onConfirm();
          setLoading(false);
        } catch (error) {
          setCallError(error);
        }
      }
    }
  };

  useEffect(() => {
    const auth = {
      isAuthenticated: authState.isAuthenticated,
      token: _get(authState, 'accessToken.accessToken'),
    };
    setAuth(auth);

    const currentError = getErrors();
    setError(currentError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [identityFiles, identityTypes]);

  return (
    <>
      <Box align="center" mt={4} mb={4} />
      <Title title={t('steps.identityStep.title')} />
      <Container maxWidth="sm" className="IdentityStep">
        <Grid container alignItems="center" justifyContent="center">
          <Grid
            container
            alignItems="center"
            justifyContent="center"
            spacing={3}
          >
            <Grid container>
              <Typography variant="body1" align="center">
                {t('steps.identityStep.toSubscribe')}
                {'.'}
              </Typography>
            </Grid>
            <Grid container alignItems="center" justifyContent="center">
              <Box mt={3} mb={3}>
                <FormControl fullWidth margin="dense">
                  <StyledTextField
                    select
                    // eslint-disable-next-line quotes
                    label={t('steps.identityStep.chooseIdentity')}
                    error={showErrors && !!error}
                    helperText={showErrors && error}
                    value={identityType}
                    variant="filled"
                    onChange={(evt) => handleChangeType(evt.target.value)}
                  >
                    <MenuItem value={identityTypes.CNI}>
                      {t('steps.identityStep.identityCard')}
                    </MenuItem>
                    <MenuItem value={identityTypes.PASSPORT}>
                      {t('steps.identityStep.passport')}
                    </MenuItem>
                  </StyledTextField>
                </FormControl>
              </Box>
            </Grid>
            {identityType === identityTypes.CNI && (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <FileInput
                  dataKey="recto_cni"
                  label={t('steps.identityStep.maxSizeFrontCNI')}
                  value={_get(identityFiles, `${fileTypes.CNI_RECTO}.file`, '')}
                  fileName={_get(
                    identityFiles,
                    `${fileTypes.CNI_RECTO}.name`,
                    ''
                  )}
                  onChange={(evt) => handleChangeFile(fileTypes.CNI_RECTO, evt)}
                />
                <FileInput
                  dataKey="verso_cni"
                  label={t('steps.identityStep.maxSizeBackCNI')}
                  value={_get(identityFiles, `${fileTypes.CNI_VERSO}.file`, '')}
                  fileName={_get(
                    identityFiles,
                    `${fileTypes.CNI_VERSO}.name`,
                    ''
                  )}
                  onChange={(evt) => handleChangeFile(fileTypes.CNI_VERSO, evt)}
                />
              </Grid>
            )}
            {identityType === identityTypes.PASSPORT && (
              <Grid
                container
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <FileInput
                  dataKey="passport"
                  label={t('steps.identityStep.maxSizePassport')}
                  value={_get(identityFiles, `${fileTypes.PASSPORT}.file`, '')}
                  fileName={_get(
                    identityFiles,
                    `${fileTypes.PASSPORT}.name`,
                    ''
                  )}
                  onChange={(evt) => handleChangeFile(fileTypes.PASSPORT, evt)}
                />
              </Grid>
            )}
            {showErrors && callError && (
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                }}
              >
                <Typography variant="body1" color="error">
                  {callError}
                </Typography>
              </div>
            )}
          </Grid>
          <NextStepButton
            onClick={handleValidate}
            disabled={!!error || loading}
          />
        </Grid>
      </Container>
    </>
  );
};

IdentityStep.propTypes = {
  onConfirm: PropTypes.func.isRequired,
  data: PropTypes.shape().isRequired,
  setUserData: PropTypes.func.isRequired,
  routerParams: PropTypes.any,
};

export default IdentityStep;
