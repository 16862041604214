import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  footerWrapper: {
    textAlign: 'center',
    marginTop: 20,
    marginBottom: 20,
    flex: '1 1 auto',
    position: 'relative',
    minHeight: 150,
    padding: 0,
  },
  footer: {
    position: 'absolute',
    bottom: 0,
    marginLeft: 20,
    marginRight: 20,
  },
  link: {
    color: 'inherit',
  },
}));

export default useStyles;
